import AuthLayout from "./views/AuthLayout";
import Verify from "./components/Verify";
import ForgotPassword from "./components/ForgotPassword";
import RequestCredentials from "./components/RequestCredentials";
import Landing from "@/packages/landing/components/Landing.vue";
import SetPassword from "./components/SetPassword";
import LandingActivation from "./components/LandingActivation";
import Login from "./components/Login";

export default [
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        component: Landing,
        children: [
          {
            path: "",
            name: "Login",
            component: Login,
          },
          {
            path: "forgot-password",
            name: "forgotPassword",
            component: ForgotPassword,
          },
          {
            path: "set-password/:token/:email",
            name: "setPassword",
            component: SetPassword,
          },
          {
            path: "authenticate/:token",
            name: "LandingActivation",
            component: LandingActivation,
          },
        ],
      },
      {
        path: "verify/:code",
        name: "verify",
        component: Verify,
      },
      {
        path: "request-credentials",
        component: RequestCredentials,
        name: "RequestCredentials",
      },
    ],
  },
];
