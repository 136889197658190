<template>
  <v-card class="mx-auto mb-2" flat>
    <v-card-text class="mt-n5">
      <v-row>
        <v-col cols="12" md="2">
          <v-badge
            bottom
            bordered
            color="success"
            style="margin-bottom: 0"
            icon="mdi-check"
            offset-x="37"
            offset-y="37"
          >
            <v-avatar size="150">
              <v-img :src="profilePicture"></v-img>
            </v-avatar>
          </v-badge>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "UserAvatar",
  props: {
    avatar: {
      required: true,
    },
  },
  computed: {
    profilePicture() {
      return `${this.avatar}`;
    },
  },
};
</script>
