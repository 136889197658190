<template>
  <v-container fluid>
    <v-card class="pa-2">
      <v-toolbar flat dense>
        <v-btn @click="backToContracts()" color="error" text>
          <v-icon class="mr-1" size="20">mdi-arrow-left</v-icon>
          back to contracts
        </v-btn>
      </v-toolbar>
      <v-tabs v-model="programmeTabs" grow>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="(item, i) in tabs"
          :key="i"
          class="app--tabs"
          :disabled="item.disabled"
        >
          <v-icon class="mx-2">{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-tab>
        <v-tabs-items v-model="programmeTabs">
          <v-tab-item> <Objectives /> </v-tab-item>
          <v-tab-item> <Activities /> </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import Objectives from "./Objectives";
import Activities from "./Activities";
import programmeMixin from "../programmeMixin";
export default {
  name: "TabView",
  components: { Objectives, Activities },
  mixins: [programmeMixin],
  data() {
    return {
      programmeTabs: null,
      backRoute: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backRoute = from;
      if (vm.contract.length === 0) {
        vm.$store.dispatch("Programme/fetchContract");
      } else {
        vm.$store.dispatch(
          "Programme/getObjectives",
          vm.$route.params.documentNo
        );
      }
    });
  },
  computed: {
    tabs() {
      return [
        {
          title: "Objectives",
          icon: "account_circle",
          name: "objectives",
          disabled: false,
        },
        // {
        //   title: "Assigned Activities",
        //   icon: "school",
        //   name: "activities",
        //   disabled: false,
        // },
      ];
    },
  },
  methods: {
    // handleTabChange() {
    //   if (this.programmeTabs === 0) {
    //     this.$refs.objectivesRef.closeRow();
    //     this.$store.dispatch(
    //       "Programme/getObjectives",
    //       this.$route.params.documentNo
    //     );
    //   } else if (this.programmeTabs === 1) {
    //     this.$store.dispatch("Programme/fetchActivities", { type: "Standard" });
    //   }
    // },
    backToContracts() {
      Event.$emit("route-change", this.backRoute.name);
    },
  },
};
</script>
