<template>
  <v-card class="pa-4" flat>
    <v-card flat class="mb-6 ctext-lg-h5 ml-n4">
      <span class="stat_section_heading"> My Statistics </span>
    </v-card>
    <v-card flat>
      <StatsListCards :stats="stats" />
      <v-card class="mt-6 d-flex" flat style="display: flex; flex-wrap: wrap">
        <PieChartVue :pieData="pieData" style="flex: 1; margin-right: 10px" />
        <BarChart :barData="barData" style="flex: 1; margin-left: 10px" />
      </v-card>
    </v-card>
  </v-card>
</template>
<script>
import StatsListCards from "./StatsListCards.vue";
import PieChartVue from "./PieChart.vue";
import BarChart from "./BarChart.vue";

export default {
  name: "Statistics",
  props: {
    activities: {
      required: true,
    },
  },
  components: {
    StatsListCards,
    PieChartVue,
    BarChart,
  },
  computed: {
    stats() {
      return [
        {
          caption: "Objectives",
          value: this.objectives ? this.objectives.length : 0,
          icon: "maps_home_work",
          avatarColor: "#CCCCCC",
          iconColor: "#000000",
        },
        {
          caption: "Activities",
          value: this.ActivitiesCount ? this.ActivitiesCount.length : 0,
          icon: "view_list",
          avatarColor: "#AAAAAA",
          iconColor: "#666666",
        },
        {
          caption: "Completed Objectives",
          value: this.completedObjectives
            ? this.completedObjectives.length > 0
              ? this.completedMilestones.length
              : 0
            : 0,
          icon: "done_all",
          avatarColor: "#00CC00",
          iconColor: "#66FF66",
        },
        {
          caption: "Completed Activities",
          value: this.completedActivities
            ? this.completedActivities.length > 0
              ? this.completedActivities.length
              : 0
            : 0,
          icon: "sync",
          avatarColor: "#007700",
          iconColor: "#33FF33",
        },
        {
          caption: "Accepted Narrative",
          value: this.acceptedNarrative
            ? this.acceptedNarrative.length > 0
              ? this.acceptedNarrative.length
              : 0
            : 0,
          icon: "task_alt",
          avatarColor: "#55CC55",
          iconColor: "#FFFFFF",
        },
        {
          caption: "Rejected Narratives",
          value: this.rejectedActivities ? this.rejectedActivities.length : 0,
          icon: "close",
          avatarColor: "#dc143c",
          iconColor: "#fff",
        },
      ];
    },
    objectives() {
      return this.$store.getters["Programme/programmeGetters"]("objectives");
    },
    ActivitiesCount() {
      return this.activities;
    },
    rejectedActivities() {
      return this.activities
        ? this.activities.filter((activity) => {
            return activity.rejected !== false;
          })
        : [];
    },
    completedObjectives() {
      return this.objectives
        ? this.objectives.filter((objective) => {
            return objective.submitted === true;
          })
        : [];
    },
    completedActivities() {
      return this.activities
        ? this.activities.filter((activity) => {
            return activity.submitted === true;
          })
        : [];
    },
    acceptedNarrative() {
      return this.activities
        ? this.activities.filter((task) => {
            return task.accepted === true;
          })
        : [];
    },
    pieData() {
      return {
        labels: this.stats.map((cap) => {
          return cap.caption;
        }),
        data: this.stats.map((val) => {
          return val.value;
        }),
        title: "Visual pie data",
        backgroundColor: this.stats.map((bg) => {
          return bg.avatarColor;
        }),
      };
    },
    barData() {
      return {
        labels: this.stats.map((cap) => {
          return cap.caption;
        }),
        data: this.stats.map((val) => {
          return val.value;
        }),
        title: "Visual bar data",
        backgroundColor: this.stats.map((bg) => {
          return bg.avatarColor;
        }),
      };
    },
  },
  methods: {},
};
</script>
<style></style>
