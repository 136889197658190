import { AuthService } from "../auth";
import helper from "../utils/helpers/helper";
import moment from "moment";

export default {
  data() {
    return {
      url: process.env.VUE_APP_FILE_URL,
    };
  },
  computed: {
    user() {
      return AuthService.user;
    },
    contractloading() {
      return this.$store.getters["Programme/programmeGetters"](
        "contractloading"
      );
    },
    contract() {
      return this.$store.getters["Programme/programmeGetters"]("contracts");
    },
    objectiveloading() {
      return this.$store.getters["Programme/programmeGetters"](
        "objectiveloading"
      );
    },
    selectedObjective() {
      return this.$store.getters["Programme/programmeGetters"](
        "selectedObjective"
      );
    },
    activityloading() {
      return this.$store.getters["Programme/programmeGetters"](
        "activityloading"
      );
    },
    selectedActivity() {
      return this.$store.getters["Programme/programmeGetters"](
        "selectedActivity"
      );
    },
    editActivityloading() {
      return this.$store.getters["Programme/programmeGetters"](
        "editActivityloading"
      );
    },
    canAssign() {
      return this.selectedActivity.ownerCode === this.user.no;
    },
    canEdit() {
      return !this.selectedActivity.submitted;
    },
    canReact() {
      return (
        !this.selectedActivity.accepted &&
        !this.selectedActivity.rejected &&
        this.selectedActivity.submitted
      );
    },
    selectedContract() {
      return this.$store.getters["Programme/programmeGetters"](
        "selectedContract"
      );
    },
    inAssignedActivities() {
      return this.$route.path.includes("activities");
    },
  },
  methods: {
    truncateText(text) {
      const words = text.split(" ");
      if (words.length <= 10) {
        return text;
      } else {
        const truncated = words.slice(0, 10).join(" ");
        return truncated + (this.showFullText ? "" : "...");
      }
    },
    getStatusColor(val) {
      return helper.getColor(val);
    },
    formatDate: function (date, type = 3) {
      switch (type) {
        case 1:
          return moment(date).format("LT"); // 1:03 PM
        case 2:
          return moment(date).format("LTS"); // 1:03:13 PM
        case 3:
          return moment(date).format("L"); // 09/29/2021
        case 4:
          return moment(date).format("l"); // 9/29/2021
        case 5:
          return moment(date).format("LL"); // September 29, 2021
        case 6:
          return moment(date).format("ll"); // Sep 29, 2021
        case 7:
          return moment(date).format("LLL"); // September 29, 2021 1:03 PM
        case 8:
          return moment(date).format("lll"); // Sep 29, 2021 1:03 PM
        case 9:
          return moment(date).format("LLLL"); // Wednesday, September 29, 2021 1:03 PM
        case 10:
          return moment(date).format("llll"); // Wed, Sep 29, 2021 1:03 PM
      }
    },
  },
  watch: {
    selectedObjective() {
      if (this.selectedObjective !== null) {
        this.headColumn = 4;
        this.itemColumn = 8;
      } else {
        this.headColumn = 12;
        this.itemColumn = 0;
      }
    },
    contract() {
      this.$store.dispatch(
        "Programme/getObjectives",
        this.$route.params.documentNo
      );
    },
  },
};
