<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 6017.37 3997.92"
    height="100%"
    width="100%"
    xml:space="preserve"
    class="login-svg"
  >
    <text
      x="50%"
      y="0"
      text-anchor="middle"
      alignment-baseline="middle"
      class="login--text"
    >
      Programme Management
    </text>
    <g>
      <path
        class="st0"
        d="M6.41,4001.95c0-1333.33,0-2666.67,0-4000c2000,0,4000,0,6000,0c0,1333.33,0,2666.67,0,4000
          C4006.41,4001.95,2006.41,4001.95,6.41,4001.95z M4202.45,894.01c-2.16,0.51-3.42,1.91-4,4c-1.33,1.34-2.66,2.67-4,4.01
          c-8.91,4.18-8.14,12.22-7.97,20.02c-4.84,3.1-6.91,8.24-7.42,13.28c-1.03,10.2-1.26,20.54,1.48,30.78
          c0.23-15.02,4.82-29.35,5.79-44.18c6.06-5.32,8.25-12.17,8-20.01c2.16-0.5,3.42-1.91,4.01-3.99c1.33-1.33,2.67-2.67,4-4
          c1.34-1.34,2.67-2.68,4.01-4.02c1.33-1.33,2.66-2.66,3.98-3.98c1.34-1.34,2.68-2.67,4.02-4.01c5.02-0.33,7.93-2.75,7.96-8.03
          c4.47,0.04,9.11,0.6,12.04-3.98c2.61-0.26,5.45,0.05,7.8-0.87c13.38-5.24,27.23-3.35,40.96-3.04c4.94,0.11,6.35,2.66,5.27,8.08
          c-0.51,2.57-1.23,5.1-1.84,7.65c-2.54,10.54-3.29,11.58-14.43,12.27c-9.33,0.57-18.12,2.08-25.65,8.03
          c-1.34,1.34-2.69,2.67-4.03,4.01c-3.33-0.02-6.28,0.72-7.98,3.99c-1.34,1.34-2.68,2.68-4.02,4.02c-3.28,1.69-4.03,4.65-3.98,8
          c-3.54,1.57-3.99,4.68-3.99,8.01c-3.91,1.62-6.93,4.54-7.26,8.6c-0.97,11.71-2.08,23.41,2.52,34.89c2,5,3.41,9.9,8.71,12.35
          c1.34,1.34,2.67,2.69,4.01,4.03c1.33,1.33,2.66,2.66,3.98,3.98c1.34,1.34,2.69,2.67,4.03,4.01c1.73,3.21,4.68,3.97,7.99,3.99
          c5.13,6.09,11.99,8.07,19.58,7.89c7.29-0.18,14.71,0.01,21.82-1.31c5.92-1.1,8.71,1.14,10.94,5.82c1.71,3.61,3.68,7.09,5.29,10.74
          c2.18,4.92,1.32,7.57-3.18,9.75c-11.58,5.61-23.85,9.42-36.6,6.65c-8.97-1.95-18.44-2.8-26.71-7.55c-4.7-2.7-9.72-4.31-15.24-3.86
          c-1.34-1.34-2.68-2.68-4.02-4.01c-0.21-0.46-0.33-1-0.65-1.37c-0.19-0.22-0.67-0.19-1.02-0.28c0.59,0.51,1.19,1.02,1.78,1.54
          c1.34,1.34,2.68,2.68,4.01,4.02c1.94,4.61,5.84,6.97,10.2,8.74c18.76,7.62,38.24,8.67,58.08,6.29
          c13.53-1.62,22.89-11.92,34.76-17.07c2.09-0.91,3.26-3.92,4.86-5.96c2.1-0.56,3.46-1.88,4-4c1.34-1.34,2.67-2.67,4.01-4.01
          c5.19-0.13,7.73-2.92,7.99-7.98c3.36-1.66,4.01-4.67,3.99-8.01c1.34-1.34,2.67-2.68,4.01-4.03c10.93-13.74,11.91-29.99,11.83-46.53
          c-0.04-8.09-0.96-15.79-7.86-21.32c-0.16-3.78,0.8-7.91-2.59-10.82c-2.25-1.93-3.65-3.45-0.38-5.77c3.62-2.57,8.54-3.27,10.97-7.55
          c1.94-0.39,4.14-0.31,5.77-1.24c6.16-3.52,6.52-6.74,1.6-12.56c-0.8-0.95-2.21-1.4-3.34-2.08c-0.62-2.06-1.89-3.47-4.03-4
          c0.04-3.34-0.66-6.31-3.97-8c-1.33-1.33-2.67-2.67-4-4.01c-1.33-1.33-2.67-2.67-4-4c-1.34-1.34-2.68-2.67-4.02-4.01
          c-1.72-3.23-4.65-4.03-8-3.98c-1.6-3.48-4.68-3.98-8.02-3.98c-1.54-3.58-4.68-3.97-8-4c-0.67-1.13-1.08-2.57-2.03-3.32
          c-7.92-6.25-11.23-5.52-14.14,3.44c-2.44,7.49-4.57,15.09-6.46,22.73c-1.55,6.23,0.41,9.62,6.94,12.09
          c2.38,0.9,5.19,0.66,7.81,0.94c5.4,7.95,12.2,14.5,20.03,20.02c0.22,1.2,0.01,2.9,0.73,3.53c5.27,4.6,4.62,8.66-0.72,12.55
          c-1.34,1.34-2.67,2.69-4.01,4.03c-11.65,2-11.69,2-11.92,10.85c-0.08,3.24-0.03,6.5,0.16,9.74c0.71,11.87-0.35,23.32-6.93,33.73
          c-0.98,1.54-0.9,3.75-1.32,5.64c-1.34,1.34-2.67,2.68-4.01,4.02c-1.33,1.33-2.67,2.67-4,4c-16.52,8.41-33.25,15.18-52.26,8.3
          c-2.33-0.85-5.2-0.23-7.81-0.29c-1.6-3.49-4.68-3.99-8.02-3.98c-1.34-1.34-2.67-2.68-4.01-4.03c-0.54-2.11-1.87-3.44-3.98-3.99
          c-1.34-1.33-2.69-2.67-4.03-4c-0.23-2.64,0.3-5.67-0.81-7.86c-7.95-15.69-7.79-31.79-3.18-48.24c3.17-1.75,3.93-4.7,4.01-8
          c3.3-1.7,4.07-4.64,3.99-8.02c1.34-1.34,2.68-2.68,4.02-4.02c3.36,0.08,6.34-0.62,8-3.97c1.33-1.35,2.67-2.69,4-4.04
          c6.65-0.09,13.3-0.15,19.95-0.26c21.29-0.35,21.3-0.36,26.23-20.66c0.62-2.57,1.34-5.14,1.59-7.76c0.92-9.34-2.7-14.24-11.73-14.8
          c-7.95-0.5-16.17-1.25-23.87,0.22c-8.7,1.65-19,1.47-24.03,11.43c-4.47-0.06-9.13-0.68-12.04,3.98c-5,0.32-7.9,2.74-7.98,7.98
          c-1.34,1.34-2.67,2.68-4.01,4.02c-2.12,0.53-3.45,1.86-3.99,3.98C4205.13,891.34,4203.79,892.68,4202.45,894.01z M2506.35,1017.91
          c1.34-1.34,2.68-2.68,4.02-4.01c2.32-2.18,5.66-3.94,6.8-6.62c7.4-17.44,9.15-34.94-1.33-52.01c-0.32-0.52-0.99-0.83-1.49-1.25
          c-0.29-5.03-2.75-7.9-7.98-8.02c-1.34-1.34-2.68-2.67-4.01-4.01c-0.12-5.22-2.99-7.68-8.02-7.98c-7.25-8.51-17.15-7.97-26.95-7.98
          c-9.2-0.01-18.34-0.09-24.92,7.98c-3.32,0.01-6.27,0.78-7.99,3.99c-1.34,1.34-2.68,2.68-4.02,4.02c-1.33,1.33-2.67,2.67-4,4
          c-3.32,1.67-4,4.65-3.98,7.98c-1.34,1.34-2.68,2.68-4.01,4.02c-12.62,17.86-8.32,35.36,1.23,52.68c1.38,2.49,4.18,2.81,6.77,3.19
          c1.34,1.34,2.68,2.67,4.02,4.01c0.53,2.13,1.86,3.47,4,4c0.61,1.86,0.63,4.44,1.91,5.48c14.9,12.12,40,13.88,55.93,3.9
          c1.3-0.81,1.38-3.56,2.03-5.41c3.34,0.04,6.38-0.55,8.02-3.97C2503.68,1020.58,2505.02,1019.25,2506.35,1017.91z M1550.37,1101.9
          c1.33-1.33,2.67-2.67,4-4c1.33-1.33,2.67-2.67,4-4c3.42-1.64,4.04-4.66,3.96-8.03c3.47-0.26,6.25-1.6,7.99-4.81
          c10.61-19.57,9.25-40.42,6.2-61.31c-0.54-3.68-2.55-7.48-6.17-9.74c-1.34-1.34-2.68-2.68-4.02-4.01c0-3.33-0.56-6.37-3.97-8
          c-0.6-2.08-1.9-3.45-4.02-4.01c-0.56-2.1-1.89-3.43-3.99-3.99c-0.56-2.12-1.9-3.45-4.01-4.01c-2.49-7.46-2.49-7.46-11.99-7.98
          c-1.34-1.34-2.68-2.68-4.02-4.02c-4.58-5.41-10.71-7.65-17.49-7.79c-10.56-0.21-21.13-0.18-31.68,0.26
          c-2.28,0.09-4.47,2.31-6.7,3.55c-3.31,0.06-6.41,0.54-8.03,3.99c-3.32,0.03-6.37,0.59-8,4.01c-3.32,0.02-6.33,0.67-8.01,3.99
          c-9.25,1.42-14.55,6.79-15.97,16.01c-3.86,1.87-7.56,4.25-12.12,4c-9.9-8.6-18.56-18.19-24.68-29.92c-0.52-1-2.17-1.4-3.3-2.09
          c-1.34-1.34-2.68-2.68-4.02-4.02c-3.64-7.19-10.15-9.32-16.95-10.88c-3.3,5.09,0.45,8.43,0.89,12.19
          c1.17,10.13-0.13,12.51-9.06,17.29c-3,1.61-9.26,0.4-7.82,6.36c0.84,3.47,5.5,2.66,8.58,3.39c5.11,1.22,10.31,3.59,15.35,3.3
          c14.02-0.81,27.55,1.72,41.14,4.23c-0.31,5.94,0.69,12.33-1.2,17.72c-4.34,12.43-2.17,25-2.56,37.5
          c-0.18,5.7,2.29,10.27,7.77,12.77c0.03,4.43-0.81,9.15,3.97,12.02c0.63,2.05,1.88,3.48,4.03,4.01c-0.02,3.33,0.72,6.29,3.98,7.99
          c1.33,1.33,2.67,2.67,4,4c1.34,1.34,2.68,2.68,4.02,4.02c1.7,3.27,4.67,3.99,8.01,3.98c1.58,3.52,4.67,4,8.01,3.99
          c1.54,3.57,4.68,3.97,7.99,4.01c2.1,4.68,5.83,7.42,10.83,7.56c9.97,0.28,20.24,1.6,29.84-0.29c11.36-2.24,23.99-3.74,31.2-15.24
          C1547.69,1104.58,1549.03,1103.24,1550.37,1101.9z M4198.36,1006.02c-1.34-1.34-2.68-2.68-4.02-4.01c0.31-6.82-0.89-12.88-8-15.98
          c-0.16-2.48-0.28-4.96-0.53-7.43c-0.05-0.53-0.62-1-0.94-1.5c-0.66,0.93-1.8,1.81-1.89,2.78c-0.24,2.68,1.09,4.74,3.49,6.04
          c-0.28,6.79,0.9,12.86,7.99,15.97c1.34,1.34,2.68,2.67,4.02,4.01c0.17,0.48,0.26,1.01,0.54,1.41c0.17,0.24,0.64,0.25,0.98,0.37
          C4199.45,1007.13,4198.91,1006.57,4198.36,1006.02z M3482.36,894.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.7-0.22-1.07-0.32
          c0.58,0.56,1.17,1.11,1.75,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3488.91,893.49,3485.53,894.13,3482.36,894.01z M3562.36,942.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.7-0.22-1.07-0.32
          c0.58,0.56,1.17,1.11,1.76,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3568.91,941.49,3565.53,942.13,3562.36,942.01z M3442.36,870.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.7-0.22-1.07-0.32
          c0.58,0.56,1.17,1.11,1.75,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3448.91,869.49,3445.53,870.13,3442.36,870.01z M3462.36,882.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.71-0.22-1.07-0.31
          c0.59,0.56,1.17,1.11,1.76,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3468.91,881.49,3465.53,882.13,3462.36,882.01z M3502.36,906.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.71-0.22-1.07-0.31
          c0.59,0.56,1.17,1.11,1.76,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3508.91,905.49,3505.53,906.13,3502.36,906.01z M3522.36,918.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.7-0.22-1.07-0.32
          c0.58,0.56,1.17,1.11,1.75,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3528.91,917.49,3525.53,918.13,3522.36,918.01z M3542.36,930.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.71-0.22-1.07-0.31
          c0.59,0.56,1.17,1.11,1.76,1.67c1.31,3.09,3.41,5.48,6.66,6.41c0.87,0.25,2.59-0.52,3.04-1.31c0.42-0.74,0.05-2.57-0.64-3.18
          C3548.91,929.49,3545.53,930.13,3542.36,930.01z M3582.36,954.01c-0.18-0.49-0.28-1.05-0.58-1.45c-0.19-0.24-0.71-0.22-1.07-0.31
          c0.59,0.56,1.17,1.11,1.76,1.67c3.17,7.01,9.13,8.4,16,7.99c1.33,3.71,3.15,7.42-2.27,9.69c-1.78,0.75-3.33,2.48-2.24,4.65
          c0.41,0.82,2.08,1.36,3.21,1.4c2.61,0.09,5.32,0.15,7.85-0.39c9.71-2.04,11.52-8.77,4.69-17.26c-0.76-0.95-2.22-1.33-3.35-1.98
          c-0.11-1.25-0.12-2.52-0.37-3.74c-0.11-0.57-0.72-1.52-1.01-1.48c-1.62,0.19-2.24,1.34-1.31,2.73c0.65,0.98,1.84,1.62,2.79,2.4
          c-1.65,3.42-4.76,3.97-8.08,4.08C3595.19,955.02,3589.23,953.6,3582.36,954.01z M4790.37,1666.02c-3.46-4.44-8.37-6.32-13.62-7.38
          c-0.94-0.19-2.62,0.79-3.1,1.69c-0.32,0.59,0.63,2.34,1.47,3c4.61,3.63,10.14,2.3,15.37,2.55c-1.31,3.54-2.62,7.08-3.93,10.63
          c1.02,0.4,2.09,1.13,3.2,1.2c2.61,0.17,5.26,0.19,7.87-0.02c7.48-0.6,9.23-3.14,7.1-10.21c-0.57-1.88-1.48-3.65-2.05-5.53
          c-0.76-2.48-1.23-5.94-4.16-5.82c-3.98,0.17-2.04,3.87-2.34,6.2C4795.71,1665.77,4792.92,1665.75,4790.37,1666.02z M3538.34,953.88
          l0.14,0.14c-3.84-5.25-9.48-3.95-14.64-3.8c-7.06,0.21-9.27,2.39-9.35,9.89c-0.22,21.27-0.26,42.55-0.08,63.83
          c0.1,12.52,1.65,13.84,13.65,13.88c1.33,0,2.66,0.02,3.99-0.06c7.51-0.43,9.95-2.27,10.01-9.35c0.2-22.6,0.05-45.21-0.03-67.81
          C3542.01,957.81,3540.5,955.61,3538.34,953.88z M4818.29,2122.38c-2.58,11.88-9.2,21.91-14.86,32.38c-1.56,2.89-3.57,5.65-4.5,8.74
          c-0.94,3.1-1.04,6.77,2.84,8.4c3.06,1.28,5.42-0.93,6.59-3.11c6.29-11.69,12.31-23.52,18.27-35.39c1.48-2.94,2.64-6.1,3.44-9.28
          c0.64-2.54,0.6-5.33-1.32-7.53c-2.57-2.93-5.9-2.33-9.18-1.75c-6.18-2.44-12.23-5.37-18.58-7.23c-14.15-4.15-27.62-3.19-39.48,6.83
          c-10.17,8.59-20.82,16.63-30.62,25.62c-19.48,17.86-42.12,30.17-66.7,39.18c-15.6,5.72-30.88,12.27-47.16,16.17
          c-10.51,2.52-11.45,5.3-8.01,15.66c5.26,15.85,9.58,31.84,9.63,48.72c0.01,1.83,0.66,4.02,4.29,4.4
          c5.89-0.46,11.52-4.25,17.08-7.65c28.43-17.42,55.26-37.12,80.41-58.99c16.33-14.2,34.16-24.94,55.86-28.45
          c2.48-0.4,5.64-1.49,6.97-3.35c6.58-9.22,13.11-18.61,11.16-31c-0.7-4.48-5.93-8.71-2.84-13.04c4.16-5.82,9.74,0.14,14.73-0.01
          C4810.3,2121.6,4814.3,2122.14,4818.29,2122.38z M4386.47,2002.79c15.65-12.15,19.01-22.42,12.51-39.29
          c-5.06-13.15-14.2-22.35-27.81-26.43c-13.33-3.99-26.67-8.18-40.71-9.04c-6.59-0.41-9.95-2.76-12.07-10.22
          c-6.73-23.7-23.39-34.5-47.67-32.8c-3.95,0.28-7.86,1.25-11.79,1.91c-7.01-5.56-4.61-13.44-4.62-20.45
          c-0.15-77.9-0.07-155.81-0.13-233.71c-0.01-7.97-0.57-15.94-0.87-23.91c0.02-6.67,0.35-13.35,0.01-19.99
          c-1.85-35.71-25.35-59.19-61.19-61.29c-2.66-0.16-5.33-0.08-8-0.08c-204.65,0-409.3-0.02-613.95,0.07
          c-6.62,0-13.37,0.46-19.84,1.78c-27.74,5.65-46.78,28.16-47.84,56.32c-0.3,7.98,0.11,15.99,0.2,23.99
          c-0.09,5.99-0.26,11.98-0.26,17.97c-0.01,242.46-0.01,484.92-0.01,727.38c0,4.66,0.16,9.33-0.01,13.99
          c-1.24,32.71,24.96,58.8,51.67,65.09c10.42,2.45,20.78,3.75,31.53,3.74c172.52-0.15,345.04-0.1,517.56-0.11
          c5.33,0,10.66,0.04,15.98-0.24c5.25-0.28,7.45-4.28,4.12-7.94c-19.73-21.68-23.32-48.79-24.25-76.1
          c-0.51-15.05,2.06-30.44,5.01-45.33c4.25-21.48,10.04-42.69,18.17-63.09c14.16-35.56,32.52-68.39,61.39-94.52
          c7.09-6.42,14.99-11.05,23.55-14.72c9.03-3.87,13.83-1.76,17.56,6.81c4.8,11.04,10.9,52.57,9.36,64.6
          c-0.34,2.62-1.36,5.14-1.8,7.75c-0.66,3.92-1.48,7.87-1.54,11.81c-0.35,24.45-5.99,47.13-20.13,67.58
          c-5.68,8.21-9.55,17.58-7.94,28.21c0.55,3.65,2.13,6.2,5.86,7.03c1.95,0.44,3.96,0.59,5.88,1.12c20.06,5.59,20.06,5.6,20.05,26.19
          c-4.66,3.64-4.97,9.37-6.21,14.35c-5.43,21.88-10.44,43.86-15.44,65.83c-1.78,7.84-1.03,8.58,7.84,9.2
          c1.98,0.14,3.98-0.12,5.98-0.2c-2.1,8.31-4.6,16.54-6.24,24.94c-6.3,32.41-5.18,63.46,17.81,90.12
          c-7.98,0.37-15.95,1.06-23.93,1.06c-114.66,0.07-229.31,0.01-343.97,0.08c-27.15,0.02-24.11-2.77-24.16,24.99
          c-0.17,89.99-0.18,179.98-0.3,269.97c-0.01,5.98-0.14,11.98-0.56,17.95c-0.2,2.9-2.23,4.91-5.22,5.04
          c-7.31,0.32-14.64,0.52-21.96,0.52c-481.29,0.02-962.57,0.02-1443.86,0.04c-9.33,0-18.65,0.3-27.98,0.46
          c-7.32-6.57-14.86-12.92-21.91-19.76c-18.08-17.56-33.42-17.86-50.91-0.63c-7.04,6.93-14.02,13.93-21.02,20.9
          c-6.82-1.19-13.63-2.57-20.6-1.29c-5.58-2.12-11.46-3.66-16.67-6.46c-18.27-9.82-37.83-16.22-57.45-22.53
          c-8.85-2.85-17.77-5.16-27.04-6.58c-9.77-1.5-12.74-5.09-12.32-14.77c0.74-17.37-1.87-34.42-3.39-51.58
          c-0.92-10.34-1.97-11.96-11.58-12.04c-32.6-0.28-65.21-0.37-97.81-0.28c-8.42,0.02-10.53,2.24-10.71,10.79
          c-0.36,17.3-0.21,34.6-0.47,51.9c-0.17,11.07-0.73,11.56-11.88,14.84c-1.28,0.38-2.53,0.88-3.83,1.12
          c-34.28,6.32-65.95,19.58-96.37,36.18c-7.13,0.19-14.25,0.38-21.38,0.57c-10.05-9.7-20.23-19.27-30.09-29.17
          c-4.54-4.56-9.57-6.45-15.99-6.58c-9.27-0.19-16.92,2.75-23.34,9.29c-8.38,8.54-16.8,17.05-25.2,25.58c-2,0.02-3.99,0.07-5.99,0.05
          c-28.56-0.36-25.36,3.17-25.38-25.81c-0.09-116.51-0.05-233.01-0.03-349.52c0-20.98,0.61-19.28-19.63-19.5
          c-1.31-0.01-2.63,0.37-3.94,0.56c-8.26-1.25-16.42-4.09-24.34-2.99c-3.66,7.04-2.4,13.12-2.41,18.98
          c-0.07,156.39-0.07,312.78-0.01,469.17c0,6.62,0.02,13.4,1.33,19.84c2.05,10,8.86,16.2,18.82,18.28c4.5,0.94,9.26,0.76,13.91,0.86
          c11.97,0.27,23.94,0.41,35.9,0.61c0.21,5.52-2.17,10.24-4.35,15.13c-7.87,17.59-14.43,35.65-19.23,54.34
          c-2.43,9.48-0.94,21.87-8.9,27.5c-7.8,5.52-19.34,1.59-29.24,1.74c-0.66,0.01-1.32-0.1-1.99-0.15
          c-4.77-24.88-4.75-50.25-7.59-75.33c-0.82-7.26-0.76-14.62-1.41-21.91c-2.18-24.48-4.62-48.94-6.73-73.43
          c-1.82-21.19-2.3-42.55-5.33-63.56c-2.2-15.23-2.23-30.5-3.84-45.7c-2.25-21.13-4.7-42.24-6.67-63.39
          c-1.91-20.51-2.61-41.16-5.25-61.56c-1.54-11.9-3.04-23.74-3.36-35.71c-0.18-6.78-2.06-13.12-4.05-19.47
          c-0.51-12.58-1.85-25.1-3.04-37.6c-1.5-15.83-4.45-31.5-5.38-47.41c-0.85-14.53-2.79-29.02-4.81-43.44
          c-1.92-13.75-4.74-27.37-6.82-41.1c-3.16-20.95-5.53-42.03-9.13-62.9c-4.61-26.74-9.9-53.37-15.32-79.96
          c-2.12-10.38-5.34-20.54-8.15-30.78c-1.14-4.14-3.96-6.72-8-8.18c-21.86-7.93-44.01-14.59-67.48-15.54
          c-8.89-5.13-7.87-14.81-10.32-22.84c-0.81-2.67,1.36-4.72,3.33-6.7c13.88-13.98,20.08-30.86,18.51-50.54l1.27-1.02
          c3.34,0.74,6.09-0.11,8.04-3.04c3.17-2.03,3.58-5.02,2.8-8.34c-0.13-0.53-0.77-1.11-1.31-1.31c-0.3-0.12-1.16,0.39-1.29,0.78
          c-0.8,2.39-1.46,4.83-2.16,7.25c-3.32-3.06-3.09-7.1-3.11-11.11c-0.04-10.64-0.14-21.29-0.22-31.93
          c3.08-9.46-1.53-19.77,3.52-29.23c1.2-2.25-0.46-6.45-1.61-9.44c-3.45-9.04-10.19-15.41-18.17-20.46
          c-4.1-2.59-8.57-3.78-12.66,0.37c-2.58,2.63-5.71,2.95-9.15,2.41c-4.59-0.71-9.19-1.33-13.8-1.91
          c-31.91-4.06-51.94,21.25-52.47,48.23c-0.25,12.64,1,25.16,3.07,37.61c-1.78,0.88-3.95,1.37-5.27,2.7
          c-12.26,12.33-9.56,22.22,7.12,26.53c0.64,0.17,1.32,0.18,1.97,0.29c4.13,0.66,5.36,3.56,5.09,7.17c-0.2,2.62-0.93,5.21-1.53,7.79
          c-4.7,20.06-9.42,40.11-14.14,60.17c-12.92,0.34-24.34,6.07-36.09,10.37c-7.55,2.76-8.62,6.27-4.93,14.11
          c9.81,20.8,14.22,42.52,13.6,65.58c-0.36,13.31-1.66,26.33-3.75,39.48c-2.93,18.5-7.4,36.24-15.66,53.13
          c-5.13,10.5-11.06,19.69-22.54,23.99c-7.99,2.99-11,8.97-10.34,17.27c0.32,3.97,0.11,7.98,0.14,11.96
          c-7.21-0.13-14.43-0.61-21.62-0.31c-11.01,0.47-16.73,6.05-17.87,16.71c-0.21,1.94-0.05,3.93-0.06,5.89
          c-0.05,6.58-3.06,11.35-9.1,13.85c-3.58,1.48-7.44,2.31-11.17,3.43c-21.79-14.26-42.03-30.21-58.08-51.08
          c-5.25-6.82-11.74-12.66-16.22-20.16c-2.11-3.52-5.29-5.95-9.56-6.53c-0.4-4.9-3.08-8.97-5.96-12.42
          c-17.56-20.99-36.46-40.44-61.17-53.38c-20.36-10.67-41.41-18.45-64.76-16.21c-47.98,4.6-70.84,39.48-67.06,80.01
          c1.47,15.69,4.94,31.34,9.29,46.75c7.39,26.15,15.59,52.17,19.85,79.09c3.86,24.45,2.17,47.8-16.25,67.04
          c-2.73,2.85-5.05,6.25-6.91,9.74c-5.08,9.5-7.69,19.02-5.51,30.46c4.3,22.61,13.37,43.08,24.88,62.56
          c10.13,17.15,21.42,33.6,32.04,50.47c13.1,20.79,26.52,41.4,38.9,62.62c11.39,19.54,21.26,39.89,22.32,63.2
          c-4.05-3.41-8.03-6.91-12.17-10.21c-7.27-5.78-14.5-11.55-23.61-14.49c-15.69-5.07-31.19-4.86-47.07-0.55
          c-15.54,4.22-29.64,11.81-44.44,17.74c-14.93,5.98-26.86,2.65-38.24-8.08c-27.24-25.68-59.82-36.63-97.01-32.32
          c-19.48,2.26-34.03,13.36-40.13,31.65c-4.91,14.73-2.7,30.62,5.46,44.38c7.79,13.12,15.18,26.51,25.02,38.42
          c8.48,10.27,15.15,21.91,20.4,34.22c5.56,13.03,9.13,26.21,2.89,40.25c-7.13,16.07-2.42,29.54,9.24,41.83
          c17,17.93,35.53,34.05,55.16,48.97c18.03,13.7,35.46,28.03,51.01,44.59c12.68,13.51,19.86,28.22,11.68,46.72
          c-3.68,8.32-1.5,15.68,2.9,22.7c2.1,3.35,4.97,6.22,7.6,9.22c14.25,16.27,32.22,27.69,50.55,38.61
          c20.01,11.91,40.06,23.74,58.93,37.44c4.29,3.11,8.71,6.21,12.34,10.01c8.48,8.87,12.41,18.65,5.45,30.66
          c-5.72,9.87-4.95,20.22-1.17,30.84c2.47,6.95,5.02,13.67,9.26,19.78c17.71,25.53,40.41,45.75,66.19,62.74
          c2.77,1.83,5.83,3.23,8.56,5.12c0.89,0.61,1.4,2.1,1.61,3.27c0.3,1.62-0.74,2.33-2.47,2.35c-5.32,0.04-10.65,0.25-15.97,0.25
          c-37.99,0.03-75.99-0.15-113.98,0.07c-27.55,0.16-40.37,11.8-43.55,38.89c-0.39,3.29-0.2,6.66-0.21,9.99
          c-0.03,6.67-0.03,13.33-0.01,20c0.09,29.51-2.45,27.12,26.68,27.12c1343.11,0.01,2686.23,0.01,4029.35,0c6,0,12.01,0.12,17.99-0.18
          c7.39-0.38,9.63-2.37,9.73-9.68c0.25-18.66,0.2-37.32,0-55.98c-0.04-3.2-0.59-6.98-2.34-9.48c-7.85-11.22-17.31-20.37-32.25-20.68
          c-4.66-0.09-9.33-0.04-14-0.04c-79.99,0-159.97,0.06-239.96-0.1c-7.05-0.01-14.52,1.85-22.12-2.54c1.57-7.81,3.26-15.29,0.69-23.42
          c-2.14-6.77,2.37-13.12,8.35-16.73c6.22-3.75,12.94-7,19.85-9.22c13.9-4.47,28.15-7.82,42.11-12.13
          c20.27-6.26,41.25-10.15,60.48-19.94c12.59-6.41,20.06-14.86,19.53-29.43c-0.34-9.25,3.9-16.61,10.13-23.11
          c5.58-5.83,11.83-10.57,19.08-14.28c16.03-8.2,32.69-14.85,49.44-21.36c17.36-6.75,34.71-13.58,51.01-22.75
          c13.7-7.71,23.16-18.27,21.92-35.02c-1.08-14.61,5.11-25.36,15.09-35.32c14.18-14.15,30.09-26.19,45.54-38.75
          c10.79-8.78,17.84-19.48,21.69-32.55c0.73-2.48,1.45-5.36,0.91-7.77c-3.61-16.15-10.65-29.5-26.37-37.89
          c-10.55-5.63-21.93-4.89-32.66-8.02c-5.31-1.55-10.29-1.3-15.67-0.14c-13.64,2.92-27.18,5.62-40.09,11.51
          c-11.45,5.22-22.85,4.43-33.88-3.57c-7.64-5.54-13.87-12.42-21.08-18.27c-26.98-21.87-53.81-26.19-81.94-5.76
          c-12.53,9.1-23.12,19.78-32.56,31.98c-17.5,22.63-40.45,32-68.62,24.87c-8.61-2.18-16.97-2.91-25.63-2.47
          c-0.79-5.83,2.36-10.35,5.45-14.61c5.85-8.08,11.98-15.98,18.29-23.71c24.83-30.41,50.15-60.44,74.56-91.19
          c15.34-19.34,28.7-40.17,37.03-63.65c6.83-19.23,7.2-37.51-8.19-53.97c-9.41-10.06-12.91-22.84-12.16-36.94
          c0.64-12.19,3.32-23.82,8.17-34.76c8.9-20.1,18.68-39.77,30.53-58.39c22.16-34.82,44.17-69.74,65.84-104.87
          c12.65-20.5,22.3-42.23,26.31-66.38c2.37-14.29-1.02-26.24-9.69-37.21c-2.88-3.65-6.04-7.09-8.92-10.74
          c-13.6-17.26-17.62-37.37-14.76-58.42c2.14-15.72,5.14-31.41,9.24-46.9c8.33-31.45,18.38-62.44,23.22-94.86
          c2.14-14.34,1.7-27.77-1.8-41.36c-7.88-30.58-35.85-49.8-61.32-53.44c-19.15-2.74-38.27-1.04-56.98,6.12
          c-34.48,13.19-63.1,33.79-86.13,62.58c-10.06,12.57-17.89,26.46-25.78,40.34c-6.59,11.61-16.04,18.97-28.75,23.19
          c-14.44,4.79-29.26,4.56-43.51,2.17c-21.92-3.68-43.81-3.63-65.72-2.76c-17.39,0.69-34.06,5.3-48.27,16.29
          c-2.65-0.2-5.4-0.01-7.94-0.65c-34.9-8.69-70.23-15.14-105.81-20.35c-8.7-1.28-10.03-2.98-8.69-11.97
          c5.42-36.2,11.1-72.35,16.52-108.55c1.48-9.85,2.71-19.76,3.47-29.69c0.53-6.82-2.44-9.97-9.21-10.23
          c-9.31-0.35-18.64-0.17-27.97-0.22c-0.27-2.77-1.18-5.28-4.28-5.66c-3.87-0.47-7.81-0.71-11.71-0.64
          c-5.74,0.1-9.22-1.81-12.24-7.36c-3.78-6.93-10.7-7.05-16.22-1.78c-2.74,2.62-2.92,7.48-7.45,8.56
          c-0.05-7.28,1.21-14.68-1.13-21.77c-4.36-13.22-8.33-26.61-13.64-39.45c-8.86-21.42-19.3-42.18-26.26-64.39
          c-1.18-3.77-3.47-7.17-4.96-10.86c-2.16-5.39-6.32-8.64-11.52-10.2c-14.52-4.37-28.77-10.2-44.4-9.33
          c-5.42-5.79-11.6-11.06-16.03-17.54c-4.05-5.93-1.04-12.34,5.39-13.85c26.86-6.3,36.75-18.78,37.03-45.83
          C4386.7,2022.71,4386.52,2012.75,4386.47,2002.79z M3154.24,2317.83c-3.53,0.33-6.05,2.03-8.05,5.05
          c-18.64,28.14-26.98,57.85-20.57,92.26c6.8,36.47,25.35,63.94,55.93,83.19c36.76,23.14,75.94,25.55,116.23,9.43
          c4.4-1.76,8.52-4.05,8.69-9.73c4.67-2.48,9.41-4.84,14-7.47c45.01-25.75,73.98-72.74,64.56-129.05
          c-17.07-101.96-130.54-132.36-198.4-78.67C3173.97,2292.89,3163.66,2304.88,3154.24,2317.83z M3310.61,2166.3
          c11.11-6,21.76-12.64,31.51-20.78c50.18-41.86,59.25-116.45,20.37-168.62c-39.36-52.8-112.35-65.96-167.09-30.14
          c-18.67,12.22-33.06,28.47-44.65,47.36c-3.55,0.04-6.36,1.29-8.26,4.5c-21.79,36.7-24.57,74.71-8.46,114.3
          c6.71,16.49,18.09,29.13,30.5,41.4c21.4,21.15,46.39,32.78,76.81,31.7c4.66-0.17,9.35-0.35,13.98,0.03
          c17.23,1.4,32.59-5.1,48.08-11.08C3307.36,2173.45,3310.16,2170.82,3310.61,2166.3z M4464.95,2154.05
          c8.23,8.24,16.75,15.99,29.79,18.77c0-8.05,0-14.7,0-21.35c0-134.66,0-269.31,0-403.97c0-6-0.21-12.01,0.15-17.99
          c0.64-10.52,7.33-16.02,18.73-15.55c0.9,0.04,1.76,1.65,2.54,2.62c0.06,0.07-0.67,1.02-1.17,1.23c-1.18,0.51-2.43,1.09-3.68,1.16
          c-14.07,0.82-16.21-1.15-16.53-15.23c-0.12-5.33-0.03-10.67-0.03-16c0-161.99,0-323.97-0.01-485.96c0-6,0.26-12.03-0.24-17.99
          c-1.19-14.13-9.94-23.16-24.15-24.83c-5.27-0.62-10.65-0.3-15.98-0.3c-75.99-0.01-151.99-0.01-227.98-0.01
          c-728.61,0-1457.21,0-2185.82-0.05c-8.62,0-17.34,1.2-25.84-1.34c-0.38-2.6,0.07-5.75-1.26-7.7c-8.12-11.94-16.53-23.7-25.16-35.29
          c-1.41-1.89-4.25-3.15-6.66-3.7c-1.51-0.35-3.66,0.89-5.08,1.98c-0.66,0.51-0.77,2.64-0.27,3.58c1.54,2.89,3.42,5.6,5.31,8.29
          c7.54,10.76,16.12,20.87,20.91,33.35c-7.3,0.3-14.61,0.84-21.91,0.85c-85.28,0.06-170.55,0.04-255.83,0.05
          c-5.33,0-10.71-0.32-15.97,0.29c-13.41,1.54-21.94,9.65-23.77,22.97c-0.9,6.55-0.68,13.28-0.68,19.94
          c-0.03,379.08-0.06,758.16,0.06,1137.25c0.01,23.71-5.34,20.9,22.75,32.65c1.2,0.5,2.59,0.56,3.89,0.82
          c6.89,4.44,14.15,6.95,22.44,4.75c2.42-8.28,1.36-16.32,1.36-24.25c0.06-373.22,0.04-746.43,0.08-1119.65
          c0-25.72-2.66-23.5,23.66-23.5c887.05-0.02,1774.11-0.02,2661.16-0.01c5.33,0,10.66,0.06,15.99,0.21c4.87,0.14,7.08,3.04,7.19,7.65
          c0.15,6.66,0.2,13.33,0.2,19.99c0.01,294.57,0,589.15,0.04,883.72c0,20.34-2.46,17.58,16.29,30.56
          C4461.01,2153.11,4463.1,2153.4,4464.95,2154.05z M1894.37,2418.56c0.02,65.97-0.15,131.93,0.14,197.9
          c0.16,34.98,18.62,58.42,48.77,73.8c2.93,1.49,6.14,2.85,9.35,3.28c13.12,1.76,26.02,4.39,39.51,4.37
          c179.91-0.33,359.81-0.22,539.72-0.22c4.66,0,9.36,0.36,13.99-0.09c11.87-1.16,23.92-2.32,34.9-7.13
          c21.8-9.54,36.48-26.14,45.13-48.22c4.22-10.78,4.49-21.99,4.49-33.36c-0.07-337.83-0.06-675.65-0.06-1013.48
          c0-2.67,0.09-5.33-0.02-8c-1.38-33.87-17.63-57.76-48.48-71.63c-11.11-4.99-22.88-6.05-35.06-6.04
          c-189.9,0.17-379.8,0.12-569.71,0.12c-4.66,0-9.33-0.08-13.99,0.13c-3.3,0.15-6.8,0.14-9.84,1.24
          c-25.56,9.28-45.32,24.66-54.71,51.42c-3.63,10.34-4.13,20.74-4.13,31.43c0.04,233.88,0.03,467.76,0.05,701.64
          c0,5.99,0.29,11.98,0.44,17.97c-0.28,8.66-1.24,17.36-0.66,25.96c0.75,11.07-4.31,17.2-13.86,21.6
          c-10.91,5.03-21.74,10.16-33.56,12.56c-13.66,2.77-27.42,5.02-41.09,7.68c-6.34,1.23-8.1,4.08-6.14,9.63
          c2.43,6.88,5.32,13.5,7.06,20.71c3.5,14.44,3.97,28.93,3.84,43.6c-0.1,11.01,2.89,12.73,13.4,7.91
          c16.38-7.51,32.13-16.09,46.46-27.15C1878.2,2430.15,1886.35,2424.44,1894.37,2418.56z M3088.11,1293.68
          c-412.54,0-825.07,0-1237.61,0c-8,0-16.01-0.23-23.99,0.11c-35.53,1.53-64.09,28.97-67.36,64.53
          c-3.14,34.1,22.25,67.86,56.56,74.34c7.76,1.47,15.93,0.99,23.91,0.99c831.74,0.04,1663.48,0.04,2495.22,0.03
          c5.33,0,10.68,0.18,15.99-0.15c37.14-2.27,65.32-33.18,65-70.34c-0.36-41.2-34.08-68.61-66.12-69.39
          c-7.33-0.18-14.66-0.13-21.99-0.13C3914.51,1293.67,3501.31,1293.68,3088.11,1293.68z M2998.55,2053.38
          c0-4.66,0.11-9.32-0.02-13.98c-0.9-33.31-15.92-60.61-39.93-81.77c-16.77-14.78-37.24-24.41-60.34-28.91
          c-31.25-6.09-58.94,0.71-85.7,15.95c-14.04,8-25.86,18.57-36.94,30.15c-32.24,33.71-44.74,72.97-33.28,118.86
          c11.9,47.66,42.34,76.6,89.39,89.54c13.27,3.65,26.39,2.42,39.59,2.7c19.18,0.41,36.23-6.8,52.76-15.72
          c10.74-5.79,18.38-15.26,27.82-22.56C2982.78,2123.74,2998.98,2092.7,2998.55,2053.38z M2873.06,1605.7
          c-17.55-2.73-38.55,2.75-56.61,14.05c-23.51,14.71-44.96,32.24-60.06,56.88c-16.53,26.97-21.73,55.91-16.58,85.97
          c6.5,37.98,28.24,66.68,61.36,86.6c19.37,11.65,41.14,15.69,62.9,15.88c15.58,0.14,31.26-4.46,46.12-10.85
          c26.46-11.37,46.54-30.63,64.87-51.81c7.27-8.39,12.12-18.69,16.55-29.29c7.52-17.96,6.61-36.33,7.06-54.9
          c0.43-17.94-4.61-34.78-13.45-49.4C2961.01,1628.78,2926.61,1604.35,2873.06,1605.7z M3260.92,1606.11
          c-27.09-4.13-51.1,8.06-74.15,23.62c-9.5,6.41-17.17,14.75-24.82,22.97c-22.38,24.04-37.73,51.27-35.71,85.62
          c0.23,3.98,0.1,7.98,0.05,11.97c-0.2,15.56,4.09,29.9,10.16,44.16c6.25,14.67,17.63,24.7,27.98,35.9
          c13.05,14.12,28.98,23.44,47.23,28.24c17.09,4.5,34.33,11.32,52.45,3.85c1.17-0.48,2.63-0.33,3.95-0.38
          c15.79-0.58,29.79-6.64,42.66-15.07c9.39-6.15,17.68-13.95,26.84-20.5c21.36-15.3,35.43-35.76,42.8-60.69
          c14.46-48.91,4.18-92.48-32.54-127.71C3324.74,1615.97,3296.51,1602.65,3260.92,1606.11z M2998.04,2380.08
          c0-7.97,1.41-16.26-0.23-23.87c-9.36-43.31-35-73.35-75.67-90.91c-15.7-6.78-32-7.37-48.73-7.68
          c-32.49-0.59-59.31,11.69-82.36,33.61c-7.71,7.33-15.3,14.81-22.59,22.55c-27.73,29.45-34.68,65-27.98,103.45
          c4.95,28.36,19.7,51.84,41.72,70.59c24.35,20.73,53.04,30.49,84.41,29.45c19.76-0.65,38.95-6.85,56.47-17.16
          c14.47-8.52,26.66-19.56,38.7-31.1C2987.17,2444.66,3000.47,2415.59,2998.04,2380.08z M1230.45,2041.75c0-0.01,0-0.03,0-0.04
          c30.66,0,61.32,0.12,91.97-0.07c10.06-0.06,11.73-1.73,11.79-11.74c0.19-30.66,0.07-61.31,0.06-91.97c0-1.33-0.08-2.67-0.21-3.99
          c-0.47-4.66-3.14-7.25-7.73-7.49c-5.32-0.27-10.66-0.27-15.99-0.28c-38.66-0.02-77.31-0.02-115.97-0.01
          c-18.66,0-37.32-0.14-55.98,0.11c-10.06,0.14-11.73,1.74-11.79,11.74c-0.19,30.66-0.07,61.31-0.06,91.97
          c0,1.33,0.08,2.67,0.21,3.99c0.46,4.66,3.13,7.23,7.73,7.49c4.65,0.26,9.32,0.27,13.99,0.27
          C1175.8,2041.76,1203.12,2041.75,1230.45,2041.75z M3470.72,977.97c0,16.55-0.18,33.11,0.08,49.65c0.12,7.82,2.04,9.57,9.23,10.05
          c3.3,0.22,6.62,0.22,9.92,0.11c10.03-0.33,11.99-1.83,12.05-11.37c0.2-31.12,0.26-62.24,0-93.35c-0.1-12-3.35-14.58-15.91-14.8
          c-1.98-0.03-3.98-0.03-5.95,0.11c-7.01,0.49-9.25,2.5-9.36,9.95C3470.55,944.86,3470.71,961.42,3470.72,977.97z M4734.27,1719.91
          c-0.02,0-0.05,0-0.07,0c0-18.56,0.15-37.11-0.07-55.67c-0.09-7.65-2.2-9.61-9.21-9.99c-3.3-0.18-6.62-0.09-9.93,0.07
          c-4.53,0.21-7.41,2.51-7.9,7.16c-0.28,2.63-0.31,5.29-0.31,7.94c-0.02,32.47-0.03,64.95-0.01,97.42c0,2.65-0.04,5.32,0.26,7.94
          c0.75,6.56,3.73,9.69,9.83,10.57c1.31,0.19,2.64,0.23,3.96,0.27c10.67,0.37,13.27-1.71,13.4-12.03
          C4734.43,1755.7,4734.27,1737.81,4734.27,1719.91z M4770.21,1734.87c-0.04,0-0.07,0-0.11,0c0-14.56,0.17-29.13-0.08-43.68
          c-0.11-6.34-2.34-8.49-8.2-8.87c-3.95-0.26-7.95-0.23-11.89,0.08c-3.62,0.28-6.13,2.36-6.63,6.14c-0.35,2.61-0.52,5.27-0.52,7.9
          c-0.04,23.83-0.04,47.66-0.01,71.49c0,2.64,0.01,5.31,0.39,7.92c0.87,5.95,4.32,8.97,10.71,9.66c1.31,0.14,2.64,0.11,3.97,0.12
          c9.76,0.11,12.13-1.75,12.31-11.04C4770.41,1761.35,4770.21,1748.11,4770.21,1734.87z M4698.22,1733.52c0.02,0,0.05,0,0.07,0
          c0-6.62,0.03-13.24-0.01-19.86c-0.04-7.94-0.01-15.89-0.26-23.83c-0.15-4.63-2.91-7.17-7.52-7.51c-2.64-0.19-5.29-0.27-7.94-0.21
          c-9.7,0.22-11.65,1.82-11.72,11.67c-0.21,25.82-0.08,51.64-0.04,77.46c0,1.98,0.07,4.01,0.54,5.92c1.03,4.15,3.65,7.05,7.94,7.87
          c2.57,0.49,5.26,0.58,7.89,0.56c8.38-0.07,10.82-2.1,10.98-10.35C4698.41,1761.32,4698.22,1747.42,4698.22,1733.52z
           M3458.26,967.98c0-21.25,0.14-42.5-0.07-63.75c-0.08-7.61-2.25-9.66-9.28-9.98c-3.96-0.18-7.98-0.1-11.91,0.34
          c-3.7,0.41-5.81,2.99-6.17,6.64c-0.33,3.29-0.36,6.62-0.37,9.94c-0.02,36.52-0.02,73.04,0,109.57c0,3.31,0.04,6.65,0.36,9.94
          c0.35,3.65,2.42,6.24,6.14,6.65c3.94,0.43,7.95,0.51,11.91,0.34c7.08-0.31,9.24-2.33,9.32-9.93
          C3458.41,1007.82,3458.26,987.9,3458.26,967.98z M4662.13,1745.74c-0.03,0-0.07,0-0.1,0c0-10.59,0.14-21.19-0.06-31.78
          c-0.09-4.61-2.95-7.12-7.53-7.51c-1.98-0.17-3.97-0.28-5.95-0.21c-9.88,0.34-13.36,3.26-13.61,13.02
          c-0.4,15.22-0.19,30.46-0.15,45.68c0.01,3.96,0,7.97,0.56,11.88c0.62,4.26,3.37,7.26,7.63,8.1c3.21,0.63,6.58,0.7,9.86,0.58
          c6.79-0.24,9.1-2.56,9.29-9.97C4662.32,1765.61,4662.13,1755.67,4662.13,1745.74z M4806.2,1746.26c-0.02,0-0.04,0-0.06,0
          c0-10.66,0.09-21.33-0.04-31.99c-0.06-4.66-2.7-7.29-7.36-7.79c-1.32-0.14-2.66-0.2-3.99-0.21c-9.61-0.1-12.02,1.85-12.13,11.31
          c-0.22,18.66-0.08,37.33-0.05,55.99c0,1.98,0.21,3.98,0.5,5.95c0.43,2.92,2.22,4.84,5.07,5.37c2.59,0.48,5.28,0.72,7.92,0.66
          c7.32-0.18,9.87-2.32,10.07-9.3C4806.41,1766.26,4806.2,1756.25,4806.2,1746.26z M1724.28,999.98c0.11-3.54-1.44-5.48-4.4-5.79
          c-19.25-1.99-38.02,1.12-56.62,5.67c-1.84,0.45-3.51,1.77-5.17,2.82c-1.93,1.23-2.37,3.38-1.55,5.12c0.77,1.62,2.36,3.3,3.99,3.95
          c1.64,0.66,3.83,0.36,5.68,0.01c15.49-2.9,30.79-7.02,46.79-6.19c2.52,0.13,5.28-0.63,7.59-1.7
          C1722.21,1003.14,1723.24,1001.14,1724.28,999.98z M1418.06,1236.9c0.3,3.53,0.22,5.57,0.7,7.47c0.47,1.86,1.27,4.18,3.76,3.77
          c1.76-0.29,3.51-1.38,4.96-2.52c0.97-0.76,1.74-2.13,2-3.36c3.38-16.33,8.95-31.9,15.78-47.07c0.78-1.74,1.07-3.95,0.81-5.84
          c-0.25-1.81-1.05-4.21-2.41-5.07c-2.54-1.6-5.39-0.29-6.84,2.05C1426.86,1202.34,1421.84,1220.14,1418.06,1236.9z M1533.81,1564.31
          c-0.09-3.91-1.95-6.58-4.77-8.56c-12.58-8.84-23.39-19.41-32.2-32.05c-1.1-1.58-2.69-2.91-4.27-4.05c-2.32-1.68-5.02-1.81-7.4-0.29
          c-2.61,1.66-2.53,4.46-1.88,7.09c0.31,1.23,1.03,2.52,1.92,3.43c12.11,12.26,24.19,24.54,36.53,36.57
          c1.69,1.65,4.68,2.55,7.13,2.68C1531.82,1569.28,1533.33,1566.83,1533.81,1564.31z M1834.87,1017.96c1.69-0.48,4.87-0.11,5.19-3.77
          c0.19-2.19-1.1-3.69-2.99-4.57c-18.25-8.44-37.95-11.18-57.49-14.52c-2.02-0.34-3.51,1.46-4.22,3.48
          c-0.72,2.06-0.22,3.94,1.31,5.36c0.93,0.86,2.15,1.62,3.37,1.93c16.03,4.08,32.06,8.12,48.13,12.02
          C1830.06,1018.36,1832.15,1017.96,1834.87,1017.96z M1426.27,1422.33c0.14,1.74-0.19,3.93,0.55,5.67
          c5.97,14.03,12.03,28.03,18.37,41.9c1.04,2.28,3.28,4.29,5.45,5.68c1.37,0.87,4.17,1.09,5.34,0.24c1.25-0.9,1.61-3.37,1.83-5.22
          c0.14-1.2-0.56-2.57-1.07-3.78c-6.44-15.29-12.87-30.6-19.45-45.83c-0.75-1.74-2.22-3.31-3.72-4.52c-0.85-0.69-2.53-0.89-3.64-0.6
          c-1.1,0.3-2.26,1.33-2.83,2.35C1426.51,1419.31,1426.56,1420.77,1426.27,1422.33z M1942,1073.12c0.8-3.68-0.92-6.62-3.47-8.71
          c-7.12-5.82-14.25-11.7-21.86-16.84c-7.06-4.77-14.65-8.78-22.2-12.77c-2.46-1.3-5.48-0.3-6.87,2.17
          c-1.33,2.36-0.94,5.09,1.18,7.21c0.46,0.46,0.94,0.95,1.5,1.24c14.72,7.57,27.27,18.34,40.87,27.57c2.11,1.43,4.42,2.79,6.84,3.48
          C1940.3,1077.13,1941.32,1075.05,1942,1073.12z M3582.09,1004.41c0.02,0,0.04,0,0.06,0c0-8.59,0.23-17.18-0.08-25.75
          c-0.21-5.82-2.69-7.91-8.83-8.28c-3.29-0.2-6.61-0.2-9.89,0.02c-5.99,0.39-8.47,2.54-8.61,8.5c-0.31,13.86-0.09,27.74-0.27,41.61
          c-0.22,17.18,0.88,18.05,19.99,17.08c4.58-0.23,7.46-2.78,7.57-7.41C3582.24,1021.58,3582.09,1012.99,3582.09,1004.41z
           M1406.42,1316.45c1.7,15.98,3.1,30.32,4.87,44.61c0.33,2.67,2.85,4.34,5.84,3.95c3-0.39,4.59-2.53,4.76-5.35
          c0.23-3.92,0.99-8.17-0.15-11.74c-3.68-11.58-2.96-23.4-3.1-35.2c-0.03-2.63-0.16-5.26-0.34-7.88c-0.2-2.92-1.66-5.18-4.69-5.14
          c-2.81,0.04-4.96,2.18-5.53,4.77C1407.12,1308.91,1406.8,1313.5,1406.42,1316.45z M1636.61,1620.33c-0.98-1.25-1.86-3.42-3.42-4.18
          c-4.73-2.31-9.75-4-14.6-6.07c-12.14-5.17-24.19-10.53-36.39-15.55c-2.49-1.02-5.21-0.21-6.43,2.73c-0.89,2.15,0.43,3.77,1.69,5.31
          c0.42,0.51,0.87,1.06,1.43,1.37c15.04,8.34,30.3,16.25,46.84,21.24c2.39,0.72,5.25,0.47,7.77,0.01
          C1635.48,1624.83,1636.5,1622.91,1636.61,1620.33z M4846.48,1952.18c-3.24-16.35-7.31-33.6-14.87-49.84
          c-0.27-0.57-0.78-1.19-1.33-1.44c-1.15-0.51-2.38-1.03-3.61-1.11c-2.5-0.16-3.55,1.82-3.71,3.74c-0.22,2.58-0.36,5.44,0.53,7.78
          c5.16,13.63,8.45,27.74,11.59,41.91c0.4,1.83,1.57,3.65,2.82,5.09c1.25,1.44,3.04,2.81,5.18,1.59c1.06-0.6,2.12-1.58,2.63-2.65
          C4846.24,1956.13,4846.14,1954.68,4846.48,1952.18z M4767.95,1806.59c-1.06,0.57-2.93,0.97-3.15,1.81
          c-0.44,1.71-0.71,4.17,0.2,5.41c9.71,13.33,19.65,26.48,29.55,39.67c0.78,1.04,1.59,2.4,2.68,2.79c1.73,0.62,3.95,1.21,5.53,0.63
          c2.73-1,3.55-3.64,2.87-6.48c-0.3-1.26-0.69-2.63-1.46-3.63c-9.13-12.1-18.27-24.2-27.59-36.15
          C4774.56,1808.05,4771.7,1806.19,4767.95,1806.59z M4774.29,2212.94c-1.68-4.94-3.48-5.73-5.5-5.48c-1.22,0.15-2.51,0.96-3.46,1.82
          c-11.25,10.24-21.26,21.83-34.1,30.34c-2.01,1.33-3.46,4.06-4.26,6.44c-0.51,1.51,0.2,3.93,1.21,5.31
          c1.22,1.67,3.47,1.67,5.28,0.39c13.56-9.57,26.18-20.23,37.25-32.65C4773.01,2216.55,4774.5,2213.7,4774.29,2212.94z
           M4635.57,2301.66c-1.69,0-0.29,0.29,0.93-0.04c15.29-4.19,29.72-10.63,44.09-17.16c1.64-0.74,2.96-2.58,3.94-4.21
          c0.57-0.95,0.6-2.72,0.07-3.69c-0.52-0.96-2.01-1.86-3.13-1.94c-1.9-0.14-3.97,0.05-5.75,0.69c-14.23,5.17-28.41,10.46-42.58,15.78
          c-1.19,0.45-2.32,1.29-3.24,2.19c-1.4,1.39-2.7,2.98-1.8,5.19C4628.92,2300.42,4630.4,2301.53,4635.57,2301.66z M4850.25,2032.15
          c0.29-0.01,0.57-0.02,0.86-0.03c-0.52-7.24-0.96-14.48-1.7-21.69c-0.08-0.75-1.86-1.82-2.87-1.85c-1.02-0.03-2.88,0.95-2.98,1.67
          c-0.99,7.18-0.74,14.67-2.7,21.53c-2.63,9.2-2.2,18.41-2.55,27.66c-0.07,1.95,0.16,4,0.75,5.85c0.32,1.01,1.58,2.13,2.61,2.38
          c1.04,0.25,2.74-0.16,3.42-0.92c3.68-4.14,5.1-9.21,5.16-14.66C4850.31,2045.44,4850.25,2038.8,4850.25,2032.15z M4676.05,1654.46
          c-3.19,0.67-6.33,1.54-6.62,5.54c-0.03,0.4,2.19,1.45,3.28,1.34c3.29-0.34,6.37-1.43,6.68-5.41
          C4679.42,1655.53,4677.25,1654.97,4676.05,1654.46z M4756.09,1650.52c-1.01,0.68-2.9,1.58-2.8,1.96c0.99,3.77,4.05,4.85,7.5,4.78
          c0.98-0.02,2.86-1.45,2.76-1.82C4762.57,1651.67,4759.48,1650.78,4756.09,1650.52z M4699.58,1648.8c-0.25-0.5-0.37-1.18-0.77-1.48
          c-2.78-2.1-9.22,0.44-9.56,3.76c-0.05,0.47,0.33,1.16,0.73,1.46C4692.78,1654.66,4699.15,1652.15,4699.58,1648.8z M4736.09,1642.52
          c-1.01,0.69-2.91,1.58-2.82,1.97c0.98,3.8,4.07,4.85,7.52,4.78c0.99-0.02,2.88-1.45,2.78-1.82
          C4742.59,1643.68,4739.49,1642.78,4736.09,1642.52z M4656.22,1662.33c-1.55,0.63-3.46,1.13-5.04,2.16
          c-0.98,0.64-1.45,2.07-2.15,3.14c1.1,0.58,2.26,1.74,3.28,1.63c1.85-0.2,3.77-0.91,5.38-1.88c0.99-0.59,1.45-2.06,2.15-3.13
          C4658.75,1663.67,4657.65,1663.09,4656.22,1662.33z M3432.88,862.44c-0.11-2.4-1.57-3.68-3.74-3.83c-1.17-0.08-2.82,0.28-3.51,1.08
          c-1.35,1.56,1.48,4.75,4.44,4.71C3431.02,864.37,3431.96,863.11,3432.88,862.44z M4723.58,1640.08c-1.02-0.58-2.03-1.6-3.07-1.63
          c-1.06-0.04-2.15,0.89-3.23,1.39c1.02,0.58,2.02,1.6,3.06,1.63C4721.41,1641.5,4722.5,1640.59,4723.58,1640.08z M4639.67,1671.47
          c-0.46-0.17-0.97-0.57-1.37-0.47c-1.64,0.4-1.73,1.16-0.22,1.88c0.36,0.17,1.03-0.03,1.42-0.29
          C4639.72,1672.45,4639.63,1671.86,4639.67,1671.47z"
      />
      <path
        class="st1"
        d="M4826.97,3197.9c8.67-0.45,17.02,0.29,25.63,2.47c28.17,7.13,51.12-2.24,68.62-24.87
          c9.44-12.21,20.03-22.89,32.56-31.98c28.14-20.42,54.96-16.1,81.94,5.76c7.21,5.85,13.44,12.73,21.08,18.27
          c11.03,7.99,22.43,8.79,33.88,3.57c12.91-5.89,26.45-8.59,40.09-11.51c5.39-1.15,10.36-1.41,15.67,0.14
          c10.73,3.14,22.11,2.4,32.66,8.02c15.72,8.39,22.76,21.74,26.37,37.89c0.54,2.41-0.18,5.28-0.91,7.77
          c-3.85,13.07-10.9,23.78-21.69,32.55c-15.45,12.57-31.36,24.61-45.54,38.75c-9.99,9.96-16.18,20.71-15.09,35.32
          c1.24,16.75-8.22,27.31-21.92,35.02c-16.3,9.17-33.64,16.01-51.01,22.75c-16.75,6.51-33.41,13.15-49.44,21.36
          c-7.25,3.71-13.49,8.45-19.08,14.28c-6.23,6.5-10.46,13.85-10.13,23.11c0.53,14.57-6.93,23.02-19.53,29.43
          c-19.24,9.79-40.21,13.68-60.48,19.94c-13.95,4.31-28.21,7.66-42.11,12.13c-6.9,2.22-13.62,5.47-19.85,9.22
          c-5.98,3.6-10.5,9.96-8.35,16.73c2.58,8.13,0.88,15.61-0.69,23.42c7.6,4.39,15.07,2.53,22.12,2.54
          c79.99,0.16,159.97,0.1,239.96,0.1c4.67,0,9.33-0.05,14,0.04c14.95,0.3,24.41,9.46,32.25,20.68c1.75,2.5,2.31,6.28,2.34,9.48
          c0.2,18.66,0.25,37.33,0,55.98c-0.1,7.31-2.35,9.31-9.73,9.68c-5.99,0.3-11.99,0.18-17.99,0.18
          c-1343.12,0.01-2686.23,0.01-4029.35,0c-29.13,0-26.59,2.39-26.68-27.12c-0.02-6.67-0.02-13.33,0.01-20
          c0.01-3.33-0.17-6.7,0.21-9.99c3.19-27.09,16-38.72,43.55-38.89c37.99-0.22,75.99-0.05,113.98-0.07c5.32,0,10.65-0.21,15.97-0.25
          c1.73-0.01,2.77-0.72,2.47-2.35c-0.21-1.17-0.73-2.65-1.61-3.27c-2.73-1.89-5.79-3.29-8.56-5.12
          c-25.78-16.99-48.48-37.22-66.19-62.74c-4.24-6.11-6.79-12.83-9.26-19.78c-3.78-10.62-4.55-20.97,1.17-30.84
          c6.96-12.01,3.03-21.79-5.45-30.66c-3.63-3.8-8.06-6.9-12.34-10.01c-18.86-13.7-38.92-25.53-58.93-37.44
          c-18.34-10.92-36.3-22.34-50.55-38.61c-2.63-3-5.5-5.87-7.6-9.22c-4.4-7.02-6.57-14.38-2.9-22.7c8.18-18.5,1-33.21-11.68-46.72
          c-15.55-16.56-32.98-30.89-51.01-44.59c-19.63-14.92-38.16-31.05-55.16-48.97c-11.66-12.29-16.37-25.75-9.24-41.83
          c6.23-14.04,2.66-27.22-2.89-40.25c-5.25-12.3-11.92-23.95-20.4-34.22c-9.84-11.91-17.23-25.3-25.02-38.42
          c-8.16-13.76-10.37-29.65-5.46-44.38c6.09-18.29,20.65-29.39,40.13-31.65c37.2-4.31,69.77,6.65,97.01,32.32
          c11.38,10.73,23.31,14.05,38.24,8.08c14.8-5.93,28.9-13.51,44.44-17.74c15.87-4.31,31.38-4.52,47.07,0.55
          c9.11,2.94,16.34,8.71,23.61,14.49c4.14,3.29,8.12,6.8,12.17,10.21c5.53,16.47,13.9,31.64,21.56,47.13
          c7.47,15.12,18.08,27.8,30.49,39.09c7.2,6.55,15.35,10.39,25.29,9.93c3.32-0.16,6.67,0.13,9.99-0.01
          c30.91-1.28,51.63,13.55,65.02,40.53c6.22,12.54,11.88,25.22,16.03,38.63c4.71,15.23,9.82,30.35,15.3,45.33
          c5.94,16.25,12.66,32.16,24.25,45.47c5.46,6.27,7.89,13.69,7.81,22.06c-0.07,7.32-1.11,15.02,0.76,21.89
          c3.75,13.82,2.78,27.73,3.24,41.64c0.2,5.99-0.93,12.33,0.66,17.91c4.49,15.74,2.73,31.73,3.4,47.62
          c0.28,6.64-0.23,13.47,1.14,19.88c5.04,23.49,1.85,47.03,2.81,75.42c-7.68-12.38-12.9-20.56-17.89-28.88
          c-13.68-22.77-26.41-46.16-41.06-68.29c-26.37-39.84-52.04-80.15-80.25-118.79c-28.59-39.15-58.81-77.08-91.51-112.63
          c-36.4-39.58-74.21-78.08-117.52-110.62c-28.21-21.19-57.73-40.19-88.37-57.55c-3.89-2.2-8.15-5.45-12.71-1.37
          c-2.64,2.36,0.73,7.07,8.5,10.95c24.42,12.22,47.59,26.37,69.46,42.78c30.82,23.13,60.97,47,88.13,74.45
          c8.9,9,17.92,17.88,26.83,26.88c6.09,6.15,12.44,12.1,18.04,18.69c11.16,13.12,21.49,26.96,32.87,39.87
          c16.67,18.93,32.11,38.8,47.36,58.85c24.91,32.76,48.32,66.56,70.48,101.25c7.87,12.31,16.58,24.11,24.03,36.66
          c19.99,33.71,39.54,67.67,59.16,101.6c1.8,3.1,3.95,5.23,7.52,5.8c-0.48,4.53-0.83,9.08-1.46,13.59
          c-1.63,11.73-1.81,23.48-0.52,35.23c0.49,4.49,3.13,6.97,7.71,7.21c7.82,0.41,15.65,0.8,23.47,1.2c0.18,2.67,1,5.22,4.07,5.21
          c3.1-0.01,3.82-2.6,3.94-5.27c18.67-1.21,37.34-1.14,55.99,0.39c4.51,6.12,10.98,6.44,17.83,6.43c43.75-0.1,87.5-0.05,131.25-0.07
          c2.64,0,5.3-0.08,7.93-0.38c4.55-0.51,7.24-3.8,6-8.27c-5.79-20.89-2.08-42.17-2.64-63.25c-0.21-7.8,2.78-10.23,10.83-11.44
          c2.57-0.39,5.28,0.14,7.93,0.24c0.1,2.33,0.19,4.67,0.29,7.01c-2.28,9.69-5.2,19.28-6.67,29.08c-1.58,10.51-1.65,21.22-0.15,31.84
          c0.65,4.57,3.25,7.07,7.88,7.3c2.66,0.13,5.32,0.16,7.98,0.16c65.87,0.08,131.73,0.15,197.6,0.21c1.33,0,2.66-0.02,3.99-0.12
          c3.15-0.23,4.6-2.8,3.3-5.41c-2.43-4.9-6.17-8.59-10.37-11.94c-11.07-8.81-23.86-14.3-36.81-19.63
          c-10.35-4.26-22.13-5.08-31.06-12.82c-2.27-1.96-5.01-0.96-7.63-0.96c-15.68-0.01-28.09-10.18-42.6-14
          c-5.23-1.38-5.92-6.56-3.65-11.54c9.79-1.82,10.84-2.41,10.89-8.05c0.04-4.62,0.35-9.57-1.1-13.82c-2.67-7.82-2.5-15.71-2.54-23.66
          c-0.1-23.89-6.02-47.42-4.41-71.41c-4.59-13.52-3.34-27.57-3.84-41.48c-0.17-4.6-0.68-9.3-1.85-13.74
          c-2.94-11.17-1.85-22.44-1.56-33.71c0.07-2.82,2.04-4.78,4.22-6.41c8.03,0.12,16.09,0.38,23.32-4.1c1.77,2.76,4.22,5.3,5.2,8.32
          c9.66,29.87,22.02,58.38,39.94,84.37c4.56,6.61,3.67,11.64-2.35,17.94c-11.04,11.56-22.59,22.63-33.72,34.1
          c-6.26,6.45-6.32,8.63-0.07,14.97c17.76,18.03,35.58,36.02,53.7,53.69c10.5,10.24,26.92,10.2,37.53,0.27
          c7.78-7.28,15.01-15.14,22.73-22.49c9.09-8.65,12.23-8.97,22.68-2.74c15.46,9.22,31.53,17.18,48.12,24.13
          c2.32,0.97,4.21,2.96,6.3,4.47c0.66,10.2-2.08,19.44-6.74,28.54c-8.23,16.09-18.84,30.24-31.89,42.65
          c-2.37,2.25-5.1,4.21-7.08,6.77c-1.38,1.78-0.04,4.07,1.99,4.41c3.88,0.66,7.86,0.89,11.8,0.89c31.79,0.01,63.58-0.08,95.38-0.13
          c40.25,0,80.49,0,120.73,0c43.65,0.05,87.3,0.09,130.96,0.14c6.61,0.36,13.22,1.04,19.83,1.05c108.25,0.07,216.49,0.07,324.74,0
          c6.61,0,13.21-0.71,19.82-1.08c5.27,4.84,10.15,2.44,14.97-0.83c19.06,0.27,38.12,0.54,57.18,0.81c8.63,0.38,17.26,1.1,25.9,1.1
          c370.57,0.06,741.14,0.05,1111.71,0.06c8.66,0,17.32,0.2,25.99,0.31c6.99,5.79,15.3,3.14,22.97,3.15
          c204.32,0.16,408.65,0.14,612.97,0.09c7.97,0,15.95-0.53,23.92-0.81c21.24,0.12,20.88-0.09,20.74-21.03c0-0.67,0-1.33,0-2
          c0-83.87,0.02-167.74-0.04-251.61c0-6.64-0.59-13.27-0.9-19.91c8.19-0.23,16.37-0.47,24.56-0.7c0.03,90.42,0.05,180.84,0.1,271.26
          c0,5.97,0.1,11.96,0.61,17.9c0.15,1.71,1.58,4.32,2.97,4.73c7.1,2.13,14.43,2.22,21.52-0.06c1.41-0.45,2.83-3.01,3.01-4.73
          c0.55-5.27,0.64-10.59,0.65-15.9c0.05-34.57-0.04-69.14,0.08-103.72c0.02-7.29,0.68-14.58,1.05-21.87
          c6.82-1.5,8.15-8.32,11.92-12.68c12.09-13.96,23.1-28.9,35.64-42.42c8.02-8.65,17.58-16.09,27.24-22.97
          c15.13-10.77,30.58-21.43,50.95-18.19c7.09,1.13,12.51-2.35,17.71-7.12c14.8-13.56,26.02-29.97,37.11-46.38
          c11.56-17.11,24.84-32.58,39.75-46.77C4808.06,3202.61,4816.21,3197.45,4826.97,3197.9z M4749.1,3415.87c2.12,1.14,4,0.54,5.57-0.7
          c7.29-5.73,14.64-11.4,21.73-17.38c25.87-21.83,53.18-41.72,81.15-60.71c52.31-35.5,106.57-67.48,165.32-91.35
          c23.98-9.74,48.05-19.06,73.58-24.14c9.07-1.8,17.9-4.79,26.79-7.43c1.78-0.53,3.5-1.72,4.83-3.03c0.42-0.41-0.22-2.94-0.93-3.25
          c-1.68-0.74-3.81-1.32-5.54-0.94c-54.04,11.82-105.82,30.13-155.2,55.07c-10.65,5.38-20.89,11.59-31.27,17.5
          c-12.69,7.22-25.64,14.04-37.93,21.89c-48.09,30.71-94.44,63.89-139.13,99.36c-4.1,3.25-8.52,6.52-10.17,11.89
          C4747.62,3413.6,4748.72,3414.94,4749.1,3415.87z M4676.92,3477.05c-1.12-0.54-2.54-1.81-3.29-1.49c-2.36,1-4.82,2.24-6.61,4.01
          c-20.85,20.61-41.59,41.33-62.31,62.07c-1.37,1.37-2.35,3.13-3.38,4.8c-0.26,0.42-0.2,1.55,0.07,1.71
          c1.09,0.62,2.34,1.37,3.52,1.35c3.56-0.07,6.11-2.31,8.49-4.68c20.29-20.23,40.58-40.45,60.79-60.76c1.32-1.33,2.01-3.32,2.83-5.08
          C4677.27,3478.48,4676.97,3477.71,4676.92,3477.05z M4713.08,3445.72c-0.42-0.59-0.68-1.39-1.15-1.54
          c-1.15-0.37-2.85-1.06-3.52-0.55c-9.38,7.06-18.06,14.87-24.58,24.75c-0.42,0.63,0.33,2.62,1.12,3.3c0.79,0.68,2.77,1.11,3.46,0.6
          c9.45-6.98,17.95-14.97,24.65-24.71C4713.35,3447.13,4713.08,3446.29,4713.08,3445.72z M4721.68,3440.46c1.27-0.2,2.83,0,3.76-0.67
          c5.91-4.27,11.24-9.2,15.22-15.37c0.55-0.85,0.41-2.72-0.19-3.57c-0.59-0.84-2.57-1.69-3.26-1.31c-7.57,4.16-13.98,9.6-17.31,17.85
          C4719.63,3438.07,4721.05,3439.42,4721.68,3440.46z"
      />
      <path
        class="st1"
        d="M1894.88,2313.71c-0.15-5.99-0.44-11.98-0.44-17.97c-0.02-233.88-0.01-467.76-0.05-701.64
          c0-10.69,0.5-21.09,4.13-31.43c9.39-26.77,29.15-42.15,54.71-51.42c3.03-1.1,6.54-1.09,9.84-1.24c4.66-0.22,9.33-0.13,13.99-0.13
          c189.9,0,379.8,0.05,569.71-0.12c12.18-0.01,23.95,1.04,35.06,6.04c30.85,13.87,47.1,37.76,48.48,71.63c0.11,2.66,0.02,5.33,0.02,8
          c0,337.83-0.01,675.65,0.06,1013.48c0,11.38-0.26,22.59-4.49,33.36c-8.65,22.08-23.34,38.67-45.13,48.22
          c-10.98,4.81-23.03,5.97-34.9,7.13c-4.62,0.45-9.32,0.09-13.99,0.09c-179.91,0-359.81-0.11-539.72,0.22
          c-13.49,0.02-26.38-2.61-39.51-4.37c-3.21-0.43-6.43-1.79-9.35-3.28c-30.16-15.38-48.62-38.82-48.77-73.8
          c-0.29-65.96-0.12-131.93-0.14-197.9c3.31-6.74,9.24-11.22,14.4-16.27c12.06-11.83,17.38-26.25,17.6-42.83
          c0.15-11.26,0.39-22.52,0.19-33.78c-0.11-6.37,1.65-11.5,6.36-15.95c3.31-3.13,6.49-6.74,8.59-10.73
          c1.98-3.77,0.41-7.81-2.87-10.67c-2.86-2.5-6.18-2.53-8.91-0.01c-4.14,3.82-8.87,5.34-14.41,5.57
          c-10.56,0.44-17.49,5.15-18.28,16.6C1896.96,2311.61,1895.63,2312.64,1894.88,2313.71z M1950.23,2463.75
          c0,20.65,0.3,41.31-0.13,61.95c-0.21,9.95,4.84,16.17,12.12,21.51c4.69,3.43,10.13,2.84,15.41,2.84
          c37.97,0.04,75.94,0.02,113.91,0.02c2,0,4.01,0.08,5.99-0.12c11.91-1.2,17.87-6.07,20.68-18.1c2.41-10.32,4.39-20.67,4.09-31.5
          c-0.56-19.97-0.21-39.96-0.14-59.95c0.04-13.3-0.09-26.47-3.34-39.6c-3.15-12.7-9.42-20.04-21.95-22.26
          c-2.6-0.46-5.31-0.37-7.97-0.37c-36.64-0.02-73.28-0.02-109.92,0.01c-2.66,0-5.44-0.18-7.94,0.53
          c-13.21,3.72-21.59,11.57-21.03,25.09C1950.85,2423.76,1950.23,2443.77,1950.23,2463.75z M1950.23,2208.24
          c0,20.63,0.26,41.27-0.12,61.9c-0.17,9.11,4.47,14.91,10.83,20.18c4.47,3.7,9.78,3.71,15.13,3.71c38.6-0.01,77.21,0,115.81-0.01
          c2.66,0,5.4,0.19,7.96-0.36c9.25-1.99,16.02-7.88,17.94-16.74c2.51-11.59,3.59-23.58,4.21-35.45c0.69-13.28,0.2-26.61,0.15-39.93
          c-0.08-19.24,0.43-38.43-3.3-57.56c-1.56-8.01-4.9-13.54-11.54-17.45c-5.25-3.08-10.92-4.49-17.13-4.47
          c-37.27,0.13-74.54,0.06-111.82,0.08c-1.99,0-4.02-0.02-5.97,0.31c-14.39,2.43-21.91,10.98-22.09,25.9
          C1950.05,2168.3,1950.23,2188.27,1950.23,2208.24z M2033.74,2038.01c0,0.01,0,0.01,0,0.02c17.97,0,35.94,0.02,53.91-0.01
          c3.99-0.01,8.07,0.29,11.95-0.41c9.35-1.69,15.96-7.75,18.14-16.52c2.54-10.21,4.28-20.95,4.15-31.44
          c-0.42-33.16,2.79-66.42-3.11-99.46c-2.44-13.68-8.96-21.69-22.41-23.74c-3.91-0.6-7.97-0.34-11.96-0.34
          c-33.28-0.02-66.56-0.02-99.83,0c-3.99,0-8.04-0.26-11.96,0.33c-14.41,2.14-22.25,10.86-22.32,25.62
          c-0.2,39.93-0.23,79.87,0.01,119.8c0.1,17.23,8.57,25.79,25.54,26.08C1995.14,2038.26,2014.44,2038.01,2033.74,2038.01z
           M1949.72,1695.42c0,19.97,0.01,39.94,0,59.9c0,6.06,0.47,11.87,4.66,16.88c5.49,6.56,12.15,9.91,20.82,9.8
          c15.31-0.2,30.62-0.02,45.92-0.02c23.96,0,47.92-0.18,71.88,0.05c9.48,0.09,16.66-3.47,22.27-10.92c3.9-5.17,4.04-11.18,4.05-17.14
          c0.08-38.6,0.29-77.21-0.07-115.81c-0.17-18.3-8.75-27.54-26.61-27.79c-38.6-0.55-77.21-0.51-115.81-0.04
          c-18.2,0.22-26.77,9.3-27.06,27.18C1949.46,1656.81,1949.7,1676.12,1949.72,1695.42z M2360.41,1668.98
          c54.59,0,109.18,0.03,163.77-0.04c5.97-0.01,12.05,0.09,17.87-0.99c7.79-1.45,11.99-7.43,12.49-15.83
          c0.64-10.64-2.37-15.75-10.86-18.92c-5.78-2.16-11.77-1.93-17.75-1.93c-110.51-0.01-221.02-0.02-331.53,0.05
          c-5.28,0-10.7,0.27-15.81,1.46c-9.84,2.29-15.33,9.71-14.66,18.57c0.65,8.58,7.44,15.91,16.76,17.18
          c5.24,0.71,10.63,0.42,15.95,0.42C2251.23,1668.99,2305.82,1668.98,2360.41,1668.98z M2360.6,2437.75
          c55.87,0,111.74,0.01,167.61-0.02c5.32,0,10.64-0.07,15.94-0.39c4.3-0.26,8.09-2.44,9.07-6.58c2-8.43,3.57-17.75-0.89-25.25
          c-3.8-6.39-12.21-2.98-18.62-3.62c-1.32-0.13-2.66-0.02-3.99-0.02c-113.07,0-226.14,0-339.21,0.01c-4.66,0-9.32-0.11-13.96,0.15
          c-7.41,0.42-9.81,2.49-10.11,9.42c-1.01,23.91-0.13,26.55,24.55,26.4C2247.52,2437.51,2304.06,2437.75,2360.6,2437.75z
           M2360.26,2145.88c-55.87,0-111.74,0-167.61,0c-5.32,0-10.65-0.14-15.96,0.15c-8.33,0.45-9.9,2.29-10.4,11.25
          c-0.07,1.33-0.05,2.66-0.06,3.99c-0.21,18.2,1.77,20.41,20.63,20.43c115.73,0.08,231.46,0.08,347.19,0
          c18.64-0.01,20.68-2.31,20.43-20.63c-0.04-2.66-0.02-5.34-0.4-7.96c-0.52-3.58-2.54-6.32-6.3-6.61c-5.95-0.46-11.94-0.6-17.91-0.6
          C2473.33,2145.86,2416.79,2145.88,2360.26,2145.88z M2359.09,1922.21c59.31,0,118.63,0.06,177.94-0.04
          c16.58-0.03,17.49-1.12,17.45-16.42c-0.04-14.75-0.85-15.57-15.98-15.92c-2.66-0.06-5.33-0.02-8-0.02c-113.3,0-226.59,0-339.89,0
          c-4.66,0-9.34-0.12-13.99,0.14c-7.89,0.44-9.82,2.13-10.17,9.33c-0.26,5.31-0.14,10.66,0.28,15.96c0.29,3.64,2.75,6.16,6.45,6.42
          c5.97,0.43,11.97,0.53,17.96,0.53C2247.12,1922.23,2303.1,1922.22,2359.09,1922.21z M2295.27,1756.98
          c35.24,0,70.48,0.05,105.71-0.05c5.28-0.01,10.72-0.04,15.82-1.23c9.61-2.25,14.16-9.67,12.91-19.47
          c-1.14-8.9-6.25-14.45-15.21-16.02c-3.24-0.57-6.61-0.6-9.92-0.61c-71.14-0.03-142.28-0.08-213.42,0.07
          c-5.23,0.01-10.66,0.77-15.65,2.31c-7.5,2.31-11.76,9.18-11.55,16.79c0.25,8.83,5.17,15.51,13.74,17.21
          c5.15,1.02,10.56,0.95,15.85,0.96C2227.45,1757.02,2261.36,1756.98,2295.27,1756.98z M2298.76,2013.75c34.57,0,69.14,0,103.71,0
          c4.65,0,9.32,0.16,13.96-0.11c9.8-0.57,13.28-3.84,13.7-13.22c0.84-18.59-1.16-22.73-20.36-22.65
          c-75.13,0.29-150.25,0.11-225.38,0.15c-3.97,0-7.96,0.27-11.91,0.67c-2.93,0.3-4.8,2.28-5.4,5.06c-0.55,2.57-0.75,5.24-0.83,7.88
          c-0.63,19.66,1.52,22.12,20.81,22.19C2224.3,2013.84,2261.53,2013.74,2298.76,2013.75z M2296.28,2489.97c-35.23,0-70.47,0-105.7,0
          c-4.65,0-9.32-0.11-13.96,0.15c-7.46,0.41-9.89,2.47-10.18,9.32c-1.05,24.23,0.05,26.58,24.44,26.48
          c71.8-0.3,143.59-0.11,215.39-0.11c3.32,0,6.66,0.12,9.97-0.09c10.56-0.65,13.6-3.93,13.99-15.03
          c0.58-16.65-1.73-21.04-20.27-20.84C2372.07,2490.25,2334.17,2489.97,2296.28,2489.97z M2296.6,2233.88
          c-35.9,0-71.8,0.07-107.7-0.03c-24.02-0.06-23.27-0.77-22.44,25.13c0.02,0.66,0.19,1.32,0.34,1.96c0.94,4.32,3.75,7.43,7.86,8.01
          c5.88,0.83,11.92,0.76,17.89,0.76c70.47,0.04,140.94,0.03,211.4,0.03c3.99,0,7.99,0.14,11.96-0.08
          c10.87-0.59,13.91-3.69,14.29-14.7c0.54-15.65-0.71-21.45-19.93-21.22C2372.39,2234.21,2334.5,2233.88,2296.6,2233.88z
           M2505.71,1756.9c10.65,0,21.3,0.17,31.94-0.06c7.21-0.15,13.04-3.39,15.78-10.23c5.28-13.2-1.15-24.27-15.12-26.49
          c-3.26-0.52-6.63-0.5-9.95-0.51c-14.64-0.05-29.28-0.01-43.92-0.03c-5.34-0.01-10.65,0.07-15.73,2.06
          c-3.87,1.51-7.02,3.9-8.75,7.71c-6.04,13.34,1.33,26.51,15.81,27.44C2485.71,1757.42,2495.73,1756.9,2505.71,1756.9z
           M2507.39,1978.06c0-0.06,0-0.12,0-0.18c-9.99,0-19.97-0.07-29.96,0.02c-18.65,0.17-19.42,0.97-19.01,18.83
          c0.32,13.71,2.89,16.73,16.13,16.93c18.64,0.29,37.29-0.27,55.92,0.18c24.03,0.57,24.94-3.12,23.72-27.22
          c-0.3-5.85-2.77-8.34-8.87-8.47C2532.69,1977.86,2520.04,1978.06,2507.39,1978.06z M2507.65,2234.08c0-0.07,0-0.14,0-0.2
          c-9.32,0-18.64,0.08-27.96-0.02c-21.94-0.23-21.59,0.17-21.15,22.62c0.04,1.97,0.24,4.04,0.88,5.88c1.14,3.31,3.59,5.85,6.98,6.5
          c3.88,0.75,7.92,0.83,11.9,0.85c17.98,0.08,35.96-0.34,53.92,0.13c21.84,0.57,22.71-4.33,22.24-22.95
          c-0.03-1.33-0.05-2.67-0.18-3.99c-0.59-5.97-2.75-8.59-8.67-8.73C2532.95,2233.88,2520.3,2234.08,2507.65,2234.08z
           M2507.37,2490.14c0-0.03,0-0.05,0-0.08c-11.93,0-23.87-0.3-35.78,0.1c-9.73,0.33-12.52,3.63-13.1,13.62
          c-0.08,1.32-0.06,2.65-0.06,3.98c-0.03,15.02,2.3,17.76,16.9,17.97c18.55,0.27,37.12-0.29,55.66,0.17
          c24.25,0.6,23.86-4.11,23.41-25.47c-0.01-0.66-0.09-1.32-0.17-1.98c-0.68-5.65-2.91-8.09-9.09-8.22
          C2532.56,2489.95,2519.96,2490.14,2507.37,2490.14z"
      />
      <path
        class="st1"
        d="M4253.3,1608.84c0.3,7.97,0.86,15.94,0.87,23.91c0.07,77.9-0.02,155.81,0.13,233.71
          c0.01,7.02-2.38,14.89,4.63,20.45c-2.68,1.75-5.46,3.37-8.05,5.26c-23.33,17.05-25.78,47.7-5.37,68.14
          c2.75,2.75,6.03,4.97,9.07,7.43c-0.13,29.64-0.28,59.29-0.37,88.93c-0.05,15.81-0.12,31.62,0.12,47.43
          c0.11,7.56,0.43,7.56,11.35,9.67c5.98,4.03,11.78,8.38,17.99,12.01c15.58,9.1,32.11,14.52,50.57,12.9
          c13.49-1.19,21.21-7.39,24.54-21.07c0.62-2.54,0.95-5.16,1.41-7.74c15.63-0.88,29.88,4.96,44.4,9.33c5.2,1.56,9.36,4.82,11.52,10.2
          c1.48,3.69,3.78,7.09,4.96,10.86c6.96,22.21,17.41,42.97,26.26,64.39c5.31,12.84,9.28,26.23,13.64,39.45
          c2.34,7.09,1.08,14.49,1.13,21.77c-5.82,0.16-11.66,0.18-17.47,0.52c-3.81,0.22-5.52,2.83-5.98,6.41
          c-7.28-0.03-14.58-0.28-21.85-0.02c-9.1,0.32-11.52,2.27-13.19,11.47c-2.48,13.66-4.46,27.41-6.65,41.13
          c-6-0.05-12.03,0.29-17.98-0.25c-9.05-0.82-15.26,3.52-20.51,10.08c-1.25,1.55-2.74,3.09-3.42,4.89
          c-2.72,7.21-8.67,9.85-15.46,10.97c-13.72,2.25-27.44,5.38-41.25,5.97c-21.27,0.9-42.61-0.03-63.93-0.19
          c0-20.58,0-20.59-20.06-26.18c-1.92-0.53-3.93-0.69-5.88-1.12c-3.73-0.84-5.31-3.39-5.86-7.03c-1.62-10.63,2.26-20,7.94-28.21
          c14.14-20.45,19.78-43.14,20.13-67.58c0.06-3.95,0.88-7.9,1.54-11.81c0.44-2.61,1.47-5.14,1.8-7.75
          c1.54-12.03-4.55-53.56-9.36-64.6c-3.73-8.56-8.53-10.68-17.56-6.81c-8.56,3.66-16.46,8.3-23.55,14.72
          c-28.87,26.13-47.23,58.96-61.39,94.52c-8.13,20.41-13.93,41.61-18.17,63.09c-2.94,14.89-5.52,30.28-5.01,45.33
          c0.93,27.31,4.52,54.41,24.25,76.1c3.33,3.66,1.13,7.66-4.12,7.94c-5.32,0.28-10.65,0.24-15.98,0.24
          c-172.52,0.01-345.04-0.04-517.56,0.11c-10.74,0.01-21.1-1.28-31.53-3.74c-26.71-6.29-52.92-32.38-51.67-65.09
          c0.18-4.66,0.01-9.32,0.01-13.99c0-242.46,0-484.92,0.01-727.38c0-5.99,0.17-11.98,0.26-17.97c6.64,0.28,13.28,0.81,19.92,0.81
          c236.3,0.04,472.59,0.04,708.89,0.01C4238.79,1610.45,4246.17,1611.15,4253.3,1608.84z M3709.98,2270.04
          c-6.78,3.15-8.86,9.72-10.87,15.94c-5.16,15.98-5.12,19.41-0.56,36.69c5.19,19.66,16.49,34.47,35.3,42.91
          c12.83,5.75,26.3,8.34,40.45,8.29c54.41-0.18,108.82-0.05,163.23-0.09c13.88-0.01,27.54-0.65,41.41-3.22
          c13.27-2.46,22.98-9.53,32.85-17.16c1.69-1.31,1.71-4.79,2.5-7.26c7.52-6.54,14.65-13.37,20.03-21.94
          c13.92-22.16,14.97-45.01,3.05-68.03c-11.67-22.54-30.83-34.61-56.22-34.74c-69.07-0.37-138.15-0.07-207.23-0.2
          c-18.48-0.03-33.36,7.44-45.86,20.48C3720.17,2249.94,3714.18,2259.42,3709.98,2270.04z M3880.46,1850.05
          c-88.53,0-177.05-0.01-265.58,0.01c-5.99,0-12-0.11-17.95,0.39c-6.09,0.52-9.4,4.18-10.07,10.4c-0.29,2.63-0.27,5.32-0.13,7.98
          c0.46,8.58,3.71,12.21,12,12.81c5.3,0.38,10.64,0.17,15.97,0.17c174.39,0.01,348.78,0.01,523.17,0c4.66,0,9.33,0.15,13.98-0.08
          c11.41-0.57,14.12-3.32,14.6-14.34c0.68-15.37-0.88-17.03-16.42-17.32c-3.99-0.08-7.99-0.01-11.98-0.01
          C4052.18,1850.05,3966.32,1850.05,3880.46,1850.05z M3878.07,1981.88c-87.86,0-175.71-0.01-263.57,0.02
          c-5.98,0-11.99-0.01-17.95,0.44c-5.23,0.39-8.72,3.6-9.3,8.79c-0.51,4.57-0.44,9.3,0.1,13.87c0.51,4.33,3.64,7.37,7.82,7.98
          c5.23,0.77,10.61,0.68,15.92,0.68c177.04,0.03,354.09,0.03,531.13,0.01c4.65,0,9.31-0.12,13.96-0.38c3.51-0.2,6.8-1.76,8.17-4.89
          c3.3-7.58,3.82-15.92,0.04-23.07c-2.77-5.25-9.58-3.15-14.76-3.4c-3.98-0.19-7.98-0.04-11.98-0.04
          C4051.12,1981.88,3964.59,1981.88,3878.07,1981.88z M3878.64,2142.09c87.89,0,175.77,0.01,263.66-0.03
          c5.97,0,11.97-0.19,17.91-0.77c1.69-0.16,4.22-1.68,4.67-3.11c2.22-7.06,3.35-14.59-0.62-21.18c-2.71-4.51-8.38-3-12.93-3.21
          c-3.32-0.15-6.66-0.04-9.99-0.04c-175.77,0-351.54,0-527.32,0c-3.99,0-7.99,0.15-11.98-0.01c-14.07-0.55-16.1,7.57-15.17,17.65
          c0.56,6.1,4.09,9.83,10.15,10.31c5.96,0.48,11.97,0.36,17.96,0.36C3702.87,2142.1,3790.76,2142.09,3878.64,2142.09z
           M3749.94,1757.95c11.83,0.12,19.94-7.81,20.14-19.72c0.19-10.97-8.51-19.54-20.42-20.11c-11.85-0.57-19.34,7.19-19.31,20.02
          C3730.37,1750.92,3737.2,1757.82,3749.94,1757.95z M3875.86,1757.91c12.73,0.27,22.26-8.31,22.22-20.02
          c-0.03-9.43-10.8-19.57-21.01-19.77c-11.03-0.22-18.22,6.82-18.72,18.31C3857.77,1749.45,3864.46,1757.66,3875.86,1757.91z
           M4009.5,1718.12c-10.22-0.45-20.75,8.66-21.77,18.82c-0.92,9.16,9.73,20.55,19.61,20.98c10.27,0.45,20.68-8.55,21.78-18.82
          C4030.08,1730.05,4019.33,1718.55,4009.5,1718.12z"
      />
      <path
        class="st2"
        d="M4505.93,3553.88c-7.97,0.28-15.95,0.81-23.92,0.81c-204.32,0.05-408.65,0.07-612.97-0.09
          c-7.67-0.01-15.98,2.64-22.97-3.15c-0.07-28.77-0.14-57.53-0.21-86.29c0.08-9.29,0.23-18.59,0.23-27.88
          c0.02-115.54,0.03-231.08,0-346.62c-0.01-26.56-0.17-53.12-0.26-79.68c0.13-29.98,0.27-59.96,0.38-89.94
          c0.01-2.66-0.04-5.33-0.27-7.98c-0.39-4.56-2.9-7.46-7.5-7.75c-5.98-0.37-11.98-0.37-17.98-0.37
          c-473.75-0.01-947.49-0.01-1421.24,0c-5.99,0-11.99,0.09-17.98,0.28c-3.55,0.11-6.26,1.94-8.35,4.74
          c-9.27-9.21-19.66-17.39-26.62-28.73c9.33-0.16,18.65-0.46,27.98-0.46c481.29-0.02,962.57-0.02,1443.86-0.04
          c7.32,0,14.65-0.2,21.96-0.52c2.99-0.13,5.02-2.14,5.22-5.04c0.42-5.97,0.55-11.96,0.56-17.95c0.11-89.99,0.13-179.98,0.3-269.97
          c0.05-27.76-2.99-24.97,24.16-24.99c114.66-0.07,229.31-0.01,343.97-0.08c7.98,0,15.95-0.69,23.92-1.05
          c20.99,16.16,45.73,25.16,70.58,31.48c33.32,8.47,67.63,13.56,102.37,13.07c3.32-0.05,6.7-0.02,9.96,0.49
          c3.56,0.56,5.75,3.04,6.73,6.51c-6.5,2.18-13.21,1.32-19.83,1.34c-27.29,0.1-54.57-0.02-81.86,0.13
          c-3.63,0.02-7.91-1.41-11.47,3.79c4.17,10.43,10.64,20.41,16.42,30.7c2.12,3.77,5.74,5.27,10.07,5.23
          c4.66-0.05,9.32,0.07,13.97,0.07c41.93,0.01,83.85-0.07,125.78,0.03c26.84,0.06,24.46-2.84,24.47,24.73
          c0.03,161.05,0,322.1,0.06,483.15c0,7.95-0.97,15.99,1.35,23.82c-2.53,2.43-3.34,5.52-3.34,8.89c-0.01,18.34-0.01,36.67,0.02,55.01
          c0,3.38,0.9,6.44,3.5,8.81c-2.46,7.13-1.53,14.52-1.53,21.8c-0.06,84.59-0.05,169.17-0.01,253.76
          C4505.43,3540.57,4505.76,3547.22,4505.93,3553.88z M3981.84,3042.63c-0.53,7.13,3.12,12.79,6.72,18.35
          c43.76,67.6,106.8,101.42,186.46,103.97c30.69,0.99,60.75-5.7,88.86-18.55c25.43-11.63,49.59-25.46,67.12-48.28
          c14.61-10.16,24.08-24.79,32.87-39.6c21.51-36.26,32.12-75.3,31.3-117.97c-0.61-32.01-7.38-62.11-20.95-90.57
          c-27.22-57.04-71.87-95.03-131.55-114.88c-10.53-3.5-20.97-8.91-32.62-8.74c-27.21,0.41-54.46-2.19-81.53,3.79
          c-27.07,5.98-52.35,16.41-74.79,31.97c-38.52,26.71-67.75,61.33-84.19,106.18c-9.76,26.63-12.73,54.15-14.21,81.98
          c-0.38,7.12,2.83,14.36,3.09,21.59c0.7,19.66,7.66,37.67,13.73,55.92C3974.01,3033.44,3976.63,3038.98,3981.84,3042.63z
           M4176.55,3350.34c0-0.06,0-0.11,0-0.17c43.28,0,86.56,0,129.84,0c17.98,0,35.96,0.29,53.93-0.13
          c15.98-0.37,25.01-8.68,25.44-24.54c0.7-25.95,0.64-51.93,0.24-77.89c-0.2-13.36-8.77-22.51-21.9-24.43
          c-5.88-0.86-11.96-0.51-17.94-0.51c-113.19-0.02-226.38-0.02-339.58-0.02c-3.99,0-7.99-0.11-11.98,0.04
          c-20.58,0.77-28.87,8.93-29.11,29.43c-0.27,23.3-0.09,46.61-0.04,69.91c0.01,3.32,0.02,6.7,0.62,9.94
          c1.78,9.64,9.16,16.76,18.76,18.12c3.27,0.46,6.64,0.24,9.96,0.24C4055.37,3350.35,4115.96,3350.34,4176.55,3350.34z"
      />
      <path
        class="st3"
        d="M1625.5,3466.94c-2.64-0.11-5.35-0.64-7.92-0.25c-8.05,1.21-11.04,3.65-10.83,11.44
          c0.57,21.08-3.15,42.35,2.64,63.25c1.24,4.47-1.45,7.76-6,8.27c-2.62,0.3-5.28,0.38-7.93,0.38c-43.75,0.02-87.5-0.03-131.25,0.07
          c-6.84,0.02-13.31-0.3-17.83-6.43c6.62-0.38,13.25-1.07,19.87-1.07c29.97-0.01,59.93,0.27,89.9,0.22
          c5.1-0.01,10.52,1.43,16.45-2.46c-3.34-9.99-11.48-15.62-19.72-20.82c-19.86-12.52-42.69-18.16-64.15-26.96
          c-15.36,1.33-26.87-10.59-41.49-12.16c-2.79-0.3-6.88-3.3-7.56-5.81c-2.62-9.78-10.01-9.25-17.31-9.24
          c-7.99,0.01-15.97,0.3-23.96,0.47c-11.29,0.09-22.58,0.04-33.86,0.35c-5.15,0.14-6.18,4.13-6.45,8.38
          c-1.64,3.85-3.28,7.7-4.91,11.55c-3.56-0.57-5.72-2.7-7.52-5.8c-19.62-33.92-39.17-67.89-59.16-101.6
          c-7.45-12.55-16.16-24.35-24.03-36.66c-22.16-34.68-45.57-68.49-70.48-101.25c-15.25-20.05-30.69-39.93-47.36-58.85
          c-11.38-12.92-21.71-26.75-32.87-39.87c-5.6-6.58-11.94-12.53-18.04-18.69c-8.91-9-17.92-17.88-26.83-26.88
          c-27.16-27.45-57.31-51.32-88.13-74.45c-21.87-16.41-45.04-30.56-69.46-42.78c-7.77-3.89-11.15-8.59-8.5-10.95
          c4.56-4.07,8.82-0.83,12.71,1.37c30.64,17.36,60.16,36.36,88.37,57.55c43.32,32.54,81.13,71.05,117.52,110.62
          c32.7,35.55,62.92,73.48,91.51,112.63c28.21,38.64,53.88,78.94,80.25,118.79c14.65,22.13,27.38,45.52,41.06,68.29
          c4.99,8.32,10.21,16.5,17.89,28.88c-0.96-28.39,2.23-51.93-2.81-75.42c-1.38-6.41-0.86-13.24-1.14-19.88
          c-0.67-15.89,1.09-31.88-3.4-47.62c-1.59-5.58-0.46-11.92-0.66-17.91c-0.47-13.91,0.5-27.82-3.24-41.64
          c-1.86-6.86-0.83-14.57-0.76-21.89c0.08-8.37-2.34-15.79-7.81-22.06c-11.59-13.31-18.31-29.23-24.25-45.47
          c-5.48-14.97-10.59-30.09-15.3-45.33c-4.15-13.41-9.8-26.09-16.03-38.63c-13.39-26.98-34.11-41.81-65.02-40.53
          c-3.32,0.14-6.67-0.15-9.99,0.01c-9.94,0.46-18.09-3.37-25.29-9.93c-12.41-11.29-23.02-23.96-30.49-39.09
          c-7.66-15.49-16.03-30.66-21.56-47.13c-1.06-23.31-10.93-43.67-22.32-63.2c-12.38-21.22-25.8-41.83-38.9-62.62
          c-10.62-16.86-21.91-33.32-32.04-50.47c-11.51-19.48-20.58-39.95-24.88-62.56c-2.18-11.44,0.43-20.96,5.51-30.46
          c1.87-3.49,4.19-6.89,6.91-9.74c18.42-19.24,20.11-42.59,16.25-67.04c-4.25-26.92-12.46-52.94-19.85-79.09
          c-4.35-15.41-7.83-31.06-9.29-46.75c-3.79-40.53,19.07-75.41,67.06-80.01c23.35-2.24,44.4,5.54,64.76,16.21
          c24.71,12.94,43.61,32.39,61.17,53.38c2.88,3.45,5.57,7.52,5.96,12.42c-3.88,3.62-7.94,7.06-11.59,10.89
          c-14.25,14.93-21.83,34.62-9.56,57.97c6.23,11.87,13.71,22.88,22.6,32.49c24.3,26.24,49.35,51.84,78.5,72.9
          c5.44,3.93,10.9,7.87,14.18,13.71c10.23,18.23,25.98,21.17,44.35,16.18c6.53-1.77,12.99-2.39,19.62-2.43
          c0.39,3.95,1.96,8.23,0.96,11.79c-5.65,20.29-2.85,40.99-3.71,61.5c-0.25,5.95-0.1,12.12-1.68,17.76
          c-2.03,7.25-2.04,14.46-2.04,21.75c-0.02,105.21-0.02,210.42,0.06,315.63c0,5.95-0.22,12.2,1.43,17.78
          c2.34,7.91,2.2,15.77,2.22,23.72c0.12,53.27,0.17,106.54,0.29,159.81c0.01,5.98-0.87,12.36,0.86,17.86
          c3.51,11.15,2.76,22.38,2.87,33.63c0.15,15.31,0.06,30.63,0.11,45.95c0.02,4.66,0.12,9.32,0.32,13.97
          c0.12,2.73,3.57,6.01,4.71,4.75c1.21-1.34,2.81-3.71,2.37-4.97c-4.35-12.32-2.8-25.04-2.88-37.61
          c-0.23-35.29-0.16-70.58-0.3-105.87c-0.02-5.98,0.68-12.33-1.07-17.84c-2.92-9.19-2.62-18.39-2.64-27.66
          c-0.13-75.91-0.18-151.82-0.31-227.73c-0.01-6.65,0.91-13.69-0.92-19.85c-2.94-9.87-2.74-19.7-2.77-29.64
          c-0.06-19.31-0.03-38.62,0-57.93c0.01-4.66-0.87-9.64,0.46-13.91c4.68-15.02,3.1-30.34,3.22-45.57
          c0.25-31.96,0.17-63.92,0.33-95.89c0.03-5.97-0.68-12.33,1.09-17.83c2.95-9.18,2.41-18.39,2.86-27.65
          c0.26-5.28,0.96-10.54,1.46-15.82c6.86-0.56,12.5,3.64,18.91,4.87c4.47,0.86,8.87,2.61,13.34,2.77c11.84,0.4,16.86-7.07,12.3-17.86
          c-1-2.38-2.81-4.41-4.11-6.68c-1.6-2.8-0.77-5.68,2.13-6.07c8.63-1.15,8.13-7,7.59-13.13c76.1,0.01,152.19,0.03,228.29,0.04
          c1.99,6.35,3.87,12.69,4.05,19.47c0.32,11.97,1.82,23.81,3.36,35.71c2.64,20.41,3.34,41.05,5.25,61.56
          c1.97,21.15,4.43,42.26,6.67,63.39c1.62,15.2,1.64,30.46,3.84,45.7c3.03,21,3.5,42.37,5.33,63.56c2.11,24.49,4.55,48.95,6.73,73.43
          c0.65,7.29,0.59,14.65,1.41,21.91c2.84,25.08,2.82,50.45,7.59,75.32c-0.73,15.06,1.69,29.87,2.93,44.8
          c1.46,17.56,2.67,35.1,5.49,52.5c-2.26,16.44,3.84,31.42,8.16,46.71c-2.18,1.63-4.15,3.59-4.22,6.41
          c-0.29,11.26-1.37,22.54,1.56,33.71c1.17,4.44,1.68,9.14,1.85,13.74c0.51,13.91-0.75,27.96,3.84,41.48
          c-1.61,23.99,4.31,47.52,4.41,71.41c0.03,7.95-0.13,15.84,2.54,23.66c1.45,4.24,1.14,9.19,1.1,13.82
          c-0.05,5.64-1.1,6.23-10.89,8.05c-13.84-0.11-27.68-0.22-41.52-0.33C1652.55,3466.17,1639.03,3466.55,1625.5,3466.94z
           M1517.87,3045.75c0.48-9.13-0.49-18.64,1.79-27.29c2.95-11.24,2.69-22.37,2.9-33.63c0.21-11.32-1.66-23.03,0.65-33.87
          c4.5-21.16,2.72-42.36,3.51-63.55c0.2-5.29,0.03-10.82,1.55-15.78c2.2-7.21,2.21-14.42,2.26-21.72
          c0.08-11.98,0.13-23.97,0.15-35.95c0.01-6,1.24-11.78,2.26-17.68c1.64-9.55,5.33-16.1,15.25-18.25c4.58-0.99,6.42-4.39,6.42-8.82
          c-0.01-13.98,0.17-27.97-0.26-41.94c-0.23-7.56-2.87-14.66-6.93-21.36c-0.55,10.68-2.78,21.27,0.72,31.61
          c2.87,8.48,1.94,17.13,1.89,25.73c-0.04,7.69-0.34,7.68-8.79,10.61c-14.65,5.07-14.7,5.07-14.97,21.29
          c-0.15,9.32-0.04,18.64-0.15,27.96c-0.06,4.65,0.68,9.65-0.75,13.89c-3.95,11.71-2.89,23.68-3.14,35.6
          c-0.21,9.98,1.63,20.42-0.67,29.86c-4.51,18.5-2.66,37.04-3.39,55.57c-0.26,6.64,0.65,13.57-0.92,19.89
          C1511.69,3000.27,1515.26,3022.72,1517.87,3045.75z"
      />
      <path
        class="st4"
        d="M1699.25,3207.12c-2.83-17.4-4.03-34.94-5.49-52.5c-1.24-14.93-3.66-29.74-2.93-44.8
          c0.67,0.06,1.33,0.16,1.99,0.15c9.9-0.15,21.44,3.78,29.24-1.74c7.96-5.63,6.47-18.02,8.9-27.5c4.8-18.7,11.36-36.75,19.23-54.34
          c2.18-4.88,4.56-9.6,4.35-15.13c2.54-2,5.79-3.53,7.5-6.08c6.62-9.83,12.84-19.94,19.02-30.06c3.81-6.25,3.68-8.14-1.79-13.81
          c-10.53-10.9-21.33-21.55-32.06-32.26c-14.36-14.32-11.2-13.01-3.12-26.33c10.31-4.41,17.37-12.08,22.21-22.01
          c8.4-8.52,16.82-17.04,25.2-25.58c6.42-6.54,14.06-9.48,23.34-9.29c6.43,0.13,11.45,2.02,15.99,6.58
          c9.86,9.89,20.04,19.46,30.1,29.17c7.04,9.65,7.66,9.93,16.28,4.75c2.03-1.22,3.41-3.52,5.08-5.32
          c30.42-16.6,62.09-29.86,96.37-36.18c1.3-0.24,2.55-0.74,3.83-1.12c11.14-3.29,11.7-3.78,11.88-14.84
          c0.27-17.3,0.12-34.6,0.47-51.9c0.18-8.55,2.29-10.76,10.71-10.79c32.6-0.09,65.21-0.01,97.81,0.28
          c9.62,0.08,10.67,1.7,11.58,12.04c1.52,17.17,4.13,34.22,3.39,51.58c-0.41,9.69,2.56,13.27,12.32,14.77
          c9.27,1.42,18.19,3.74,27.04,6.58c19.61,6.31,39.18,12.71,57.45,22.53c5.21,2.8,11.1,4.34,16.67,6.45
          c1.54,7.04,7.47,8.48,13.06,8.95c4.32,0.36,5.94-4.15,7.55-7.64c7-6.97,13.98-13.97,21.02-20.9c17.5-17.23,32.84-16.92,50.91,0.63
          c7.05,6.85,14.59,13.19,21.91,19.76c6.97,11.34,17.36,19.52,26.63,28.74c3.11,3.51,6.91,6.89,2.07,11.79
          c-2.81,2.84-5.59,5.7-8.4,8.53c-11.26,11.33-22.54,22.63-33.78,33.98c-3.14,3.17-4.06,6.77-1.43,10.74
          c8.38,12.65,16.78,25.28,25.18,37.92c1.41,4.43,2.29,9.13,4.33,13.24c10.43,21,16.55,43.34,22.18,65.99
          c4.99,20.11,3.12,18.2,23.84,18.34c14.65,0.1,29.3,0.05,43.95,0.31c4.57,0.08,7.47,2.95,7.64,7.54c0.33,8.65,0.25,17.31,0.36,25.96
          c0.07,5.3-0.5,10.76,3.86,14.95c-4.58,7.13-4.58,14.27,0,21.41c-5.96,6.07-3.11,13.89-4.08,20.94c-0.63,4.59-2.87,7.39-7.52,7.65
          c-5.31,0.3-10.64,0.28-15.97,0.31c-13.32,0.08-26.64,0.05-39.95,0.19c-4.39,0.05-7.55,1.91-8.57,6.65
          c-0.98,4.54-2.51,8.97-3.36,13.53c-5.57,29.7-16.58,57.44-30.75,83.94c-5.63,10.53-12.26,20.54-18.47,30.76
          c-4.58,7.54-4.57,9.13,1.41,15.66c4.05,4.42,8.43,8.52,12.64,12.79c7.95,8.05,16.05,15.97,23.77,24.25
          c3.93,4.22,3.82,8.12,0.16,12.49c-2.99,3.56-6.35,6.81-9.64,10.12c-11.75,11.8-23.54,23.55-35.3,35.34
          c-3.75,3.76-7.94,7.24-8.6,13.04c-7.96,2.08-15.52,6.03-24.11,5.13c-8.21-3.04-15.08-7.85-21-14.41
          c-6.23-6.91-12.98-13.38-19.75-19.77c-7.01-6.62-8.63-6.64-17.57-1.86c-1.76,0.94-3.52,1.89-5.2,2.96
          c-25.51,16.24-53.46,26.57-82.59,33.95c-7.7,1.95-15.6,3.13-23.31,5.05c-7.39,1.85-10,4.93-10.07,12.63
          c-0.16,19.3,0.07,38.6,0.14,57.9c-40.25,0-80.49,0-120.74,0c0.03-19.15,0.2-38.3,0.03-57.45c-0.07-8.44-0.45-9.1-8.18-11.23
          c-18.37-5.05-36.86-9.66-55.31-14.45c-2.09-1.52-3.98-3.5-6.3-4.47c-16.6-6.95-32.67-14.91-48.12-24.13
          c-10.45-6.23-13.58-5.91-22.68,2.74c-7.72,7.34-14.95,15.21-22.73,22.49c-10.61,9.93-27.03,9.97-37.53-0.27
          c-18.12-17.67-35.94-35.66-53.7-53.69c-6.24-6.34-6.18-8.52,0.07-14.97c11.13-11.48,22.68-22.54,33.72-34.1
          c6.02-6.3,6.91-11.33,2.35-17.94c-17.92-25.99-30.28-54.5-39.94-84.37c-0.98-3.02-3.43-5.56-5.2-8.32
          c0.41-11.45-2.72-22.39-5.08-33.41c-1.68-7.82-3.88-9.49-12.46-9.82C1708.55,3206.33,1703.9,3206.89,1699.25,3207.12z
           M1906.6,3209.89c-0.01,3.38,0.8,6.37,3.87,8.3c6,26.13,21.36,47.03,39.92,64.96c22.17,21.42,48.74,36.19,79.37,42.88
          c11.04,2.41,21.9,5.2,33.3,3.14c9.7-2.09,19.55-3.63,29.06-6.36c30.87-8.87,56.65-25.86,77.74-50.04
          c38.63-44.29,50.58-95.21,37.29-152.12c-2.54-10.87-4.92-22.15-12.64-31.07c-1.36-3.69-2.28-7.61-4.15-11.02
          c-11.98-21.87-30.64-37.86-48.75-54.25c-3.83-3.46-8.86-5.78-14.39-6.17c-1.69-1.47-3.37-2.93-5.06-4.4
          c-1.59-2.78-4.36-3.22-7.15-3.63c-39.02-22.2-78.05-21.45-117.11-0.11c-6.11,0.69-11.21,3.7-16.27,6.9
          c-19.1,12.07-36.08,26.09-49.5,44.83c-20.65,28.82-30.68,60.91-33.55,95.64C1897.1,3175.42,1902.29,3192.64,1906.6,3209.89z"
      />
      <path
        class="st3"
        d="M4826.97,3197.9c-10.76-0.44-18.91,4.71-26.38,11.82c-14.92,14.2-28.19,29.66-39.75,46.77
          c-11.09,16.41-22.31,32.82-37.11,46.38c-5.2,4.77-10.62,8.24-17.71,7.12c-20.37-3.24-35.82,7.42-50.95,18.19
          c-9.66,6.87-19.21,14.32-27.24,22.97c-12.54,13.52-23.55,28.46-35.64,42.42c-3.77,4.35-5.1,11.17-11.92,12.68
          c-0.45-6.57-1.24-13.13-1.27-19.71c-0.15-25.79-0.16-51.58-0.02-77.37c0.09-15.85,0.54-31.7,0.83-47.56
          c14.65-0.01,29.31,0.19,43.95-0.11c8.44-0.17,10.39-2.26,10.6-10.95c0.29-11.98,0.13-23.97,0.11-35.96
          c-0.01-6.66,0.1-13.33-0.26-19.97c-0.33-6.07-2.53-8.05-8.74-8.55c-3.31-0.27-6.65-0.17-9.98-0.23
          c-5.99-0.11-11.99,0.03-17.96-0.39c-5.24-0.37-7.96-4.38-7.19-9.81c0.19-1.31,0.5-2.6,0.84-3.88c7-26.24,13.93-52.5,21.07-78.7
          c1.57-5.76,3.92-11.3,5.79-16.98c1.46-4.46,4.71-6.18,9.08-6.22c8.66-0.08,17.31-0.12,25.97-0.12c20.65-0.02,41.29,0.13,61.94-0.06
          c14.28-0.13,15.27-1.06,15.49-14.24c0.23-13.98,0.13-27.97,0.04-41.96c-0.12-19.42-0.17-19.5-20.17-19.57
          c-43.95-0.15-87.91-0.22-131.86-0.32c0.16-6.27,0.34-12.54,0.46-18.82c0.07-3.88,0.88-7.45,3.72-10.31
          c25.81,1.37,51.61,2.82,77.43,4.08c27.84,1.36,55.68,2.55,83.52,3.76c4.63,0.2,9.27,0.24,13.91,0.18c2.52-0.03,4.07-3.63,2.74-5.92
          c-3.77-6.48-9.31-11.18-15.47-15.22c-8.92-5.85-18.82-9.71-28.43-14.2c-16.66-7.79-34.61-12.04-51.43-19.5
          c-7.65-3.39-11.78-7.75-13.39-15.37c3.27-1.06,6.31-2.55,6.42-6.5c0.18-5.93,0.03-11.87,0.02-17.81
          c12.43-3.13,12.48-3.12,13.06-13.07c0.97-16.57,2.06-33.14,2.72-49.72c0.65-16.6,0.83-33.22,1.22-49.83
          c0.3-12.76,2.62-24.87,8.72-36.41c6.2-11.73,11.34-24.02,16.69-36.18c14.72-33.45,28.62-67.22,39-102.31
          c5.84-19.74,10.77-39.55,11.6-60.43c0.71-17.82-6.02-30.89-19.76-41.02c-5.33-3.92-10.97-7.51-16.81-10.63
          c-13.51-7.23-27.88-12.37-42.41-17.18c-6.18-2.05-13.32-1.78-18.44-6.78c14.21-10.99,30.88-15.6,48.27-16.29
          c21.91-0.87,43.8-0.92,65.72,2.76c14.25,2.39,29.07,2.62,43.51-2.17c12.7-4.21,22.16-11.58,28.75-23.19
          c7.88-13.88,15.72-27.76,25.78-40.34c23.03-28.79,51.65-49.39,86.13-62.58c18.71-7.16,37.84-8.85,56.98-6.12
          c25.47,3.64,53.44,22.86,61.32,53.44c3.5,13.59,3.95,27.02,1.8,41.36c-4.85,32.43-14.89,63.41-23.22,94.86
          c-4.1,15.49-7.1,31.18-9.24,46.9c-2.86,21.04,1.16,41.16,14.76,58.42c2.88,3.66,6.04,7.09,8.92,10.74
          c8.67,10.97,12.06,22.93,9.69,37.21c-4.01,24.15-13.67,45.88-26.31,66.38c-21.67,35.13-43.68,70.05-65.84,104.87
          c-11.85,18.62-21.63,38.29-30.53,58.39c-4.85,10.94-7.53,22.58-8.17,34.76c-0.75,14.11,2.75,26.88,12.16,36.94
          c15.39,16.46,15.01,34.74,8.19,53.97c-8.33,23.47-21.69,44.31-37.03,63.65c-24.4,30.75-49.72,60.78-74.56,91.19
          c-6.31,7.73-12.44,15.63-18.29,23.71C4829.33,3187.55,4826.18,3192.07,4826.97,3197.9z"
      />
      <path
        class="st2"
        d="M3088.11,1293.68c413.2,0,826.41,0,1239.61,0c7.33,0,14.66-0.05,21.99,0.13
          c32.04,0.78,65.76,28.19,66.12,69.39c0.32,37.15-27.86,68.07-65,70.34c-5.31,0.32-10.66,0.15-15.99,0.15
          c-831.74,0-1663.48,0.01-2495.22-0.03c-7.98,0-16.16,0.48-23.91-0.99c-34.31-6.49-59.7-40.25-56.56-74.34
          c3.28-35.56,31.83-63,67.36-64.53c7.98-0.34,15.99-0.11,23.99-0.11C2263.03,1293.67,2675.57,1293.68,3088.11,1293.68z
           M1916.44,1384.09c-1.33-1.34-2.67-2.68-4-4.02c-1.33-1.33-2.66-2.67-4-4c-1.33-1.33-2.66-2.67-4-4c-1.36-1.35-2.72-2.71-4.08-4.06
          c-2.4-5.7-0.91-11.64-1.03-17.47c-0.27-12.6-6.59-20.74-18.14-25.28c-12.49-4.91-22.95-2.31-31.46,8.07
          c-5.97,4.18-7.69,10.28-7.79,17.14c-0.09,6.69,0.17,13.24,6.53,17.5c1.26,1.29,2.51,2.59,3.77,3.88c4.83,7.88,12.8,9.18,20.87,9.08
          c5.47-0.06,10.59,0.24,15.47,2.77c1.35,1.36,2.71,2.72,4.07,4.05c1.38,1.27,2.77,2.54,4.15,3.81c1.33,1.33,2.67,2.67,4,4
          c1.36,1.36,2.72,2.71,4.08,4.07c2.39,4.38,5.37,7.71,10.35,3.42c2.84-2.44,5.35-5.42,7.41-8.55c1.7-2.58-0.37-4.73-2.23-6.53
          C1919.1,1386.68,1917.77,1385.39,1916.44,1384.09z M3180.45,1321.69c-378.64,0-757.28,0-1135.92,0c-6.67,0-13.34-0.13-20,0.11
          c-8.18,0.3-9.56,1.47-9.67,9.29c-0.24,16.66-0.21,33.33-0.12,49.99c0.14,27.47-2.82,24.81,24.38,24.81
          c759.94,0.03,1519.89,0.02,2279.83,0.02c4.67,0,9.34,0.09,14-0.1c8.02-0.33,9.5-1.56,9.62-9.33c0.24-16,0.27-32,0.12-47.99
          c-0.28-30.46,3.68-26.76-26.32-26.76C3937.72,1321.67,3559.08,1321.69,3180.45,1321.69z"
      />
      <path
        class="st5"
        d="M4653.63,2454.4c5.11,5,12.26,4.73,18.44,6.78c14.54,4.81,28.9,9.95,42.41,17.18
          c5.83,3.12,11.48,6.7,16.81,10.63c13.74,10.13,20.47,23.2,19.76,41.02c-0.83,20.88-5.76,40.7-11.6,60.43
          c-10.38,35.09-24.28,68.87-39,102.31c-5.35,12.16-10.49,24.45-16.69,36.18c-6.1,11.54-8.42,23.65-8.72,36.41
          c-0.39,16.61-0.57,33.23-1.22,49.83c-0.65,16.58-1.75,33.15-2.72,49.72c-0.58,9.94-0.64,9.94-13.06,13.07
          c-11.14,0.01-11.89,0.72-10.96,9.62c0.55,5.21,1.51,10.25,4.51,14.69c1.62,7.62,5.74,11.97,13.39,15.37
          c16.82,7.46,34.76,11.71,51.43,19.5c9.61,4.49,19.52,8.34,28.43,14.2c6.15,4.04,11.69,8.74,15.47,15.22
          c1.34,2.29-0.22,5.89-2.74,5.92c-4.64,0.06-9.28,0.03-13.91-0.18c-27.84-1.21-55.69-2.41-83.52-3.76
          c-25.81-1.26-51.62-2.71-77.42-4.08c-0.91-9.72-1.83-19.44-2.74-29.14c2.11-8.69,4.22-17.39,6.32-26.09
          c3.26,0.21,6.62,0.01,9.77,0.73c5.14,1.17,10.1,3.12,15.23,4.4c4.44,1.11,8.59-0.48,9.12-5.09c1.32-11.57,8.15-20.5,15.17-31.36
          c-10.42,0-18.92,0.08-27.43-0.02c-8.61-0.11-17.25-0.07-25.83-0.69c-8.92-0.64-10.09-1.87-12.05-10.22
          c-2.57-10.96-5.19-21.92-7.4-32.96c-1.32-6.61-5.88-8.18-11.49-8.74c1.27-27.81-0.02-55.34-7.86-82.33
          c-2.57-8.83-3.22-18.23-5.82-27.05c-2.59-8.78-1.81-18.49-7.34-26.43c-2.08-23.21-9.1-45.45-13.39-68.23
          c-1.23-6.53-3.7-8.03-11.48-8.1c-25.88-0.26-51.76-0.33-77.64-0.47c-1-3.47-3.19-5.95-6.75-6.51c-3.27-0.51-6.64-0.54-9.96-0.49
          c-34.74,0.5-69.05-4.6-102.37-13.07c-24.85-6.32-49.59-15.32-70.58-31.48c-22.99-26.67-24.11-57.72-17.81-90.13
          c1.63-8.4,4.13-16.63,6.24-24.94c25.07-9.46,51.2-15.05,77.21-21.09c22.85,3.79,45.86,1.13,68.8,1.67
          c8.84,0.21,9.63-0.76,11.44-9.89c0.88-4.45,0.85-9.09,1.24-13.64c3.83-0.92,7.62-2.14,11.5-2.7c20.06-2.88,39.31-7.21,51.72-25.63
          c1.41-2.08,4.23-3.16,6.17-4.95c3.04-2.79,2.49-5.91-1.29-7.72c-12.05-5.79-25.11-8.4-37.87-11.95
          c-19.12-5.31-19.13-5.28-18.53-24.77c2.19-13.71,4.17-27.47,6.65-41.13c1.67-9.2,4.08-11.15,13.19-11.47
          c7.27-0.26,14.57,0,21.85,0.02c0.1,2.63,0.23,5.25,0.31,7.88c0.14,4.6,2.29,7.61,7.04,7.64c20.36,0.11,40.72,0.1,61.08,0
          c4.83-0.03,6.87-3.13,6.95-7.69c0.05-2.62-0.02-5.25-0.04-7.88c9.32,0.05,18.66-0.13,27.97,0.22c6.77,0.26,9.74,3.41,9.21,10.23
          c-0.76,9.93-1.99,19.84-3.47,29.69c-5.43,36.2-11.11,72.35-16.52,108.55c-1.35,8.99-0.02,10.7,8.69,11.97
          c35.57,5.21,70.9,11.66,105.81,20.35C4648.23,2454.39,4650.98,2454.21,4653.63,2454.4z"
      />
      <path
        class="st5"
        d="M1711.04,2372.58c-1.3-0.26-2.69-0.32-3.89-0.82c-28.1-11.75-22.74-8.94-22.75-32.65
          c-0.13-379.08-0.09-758.16-0.06-1137.25c0-6.65-0.22-13.38,0.68-19.94c1.82-13.32,10.36-21.43,23.77-22.97
          c5.27-0.6,10.65-0.29,15.97-0.29c85.28-0.01,170.55,0.01,255.83-0.05c7.3-0.01,14.61-0.55,21.91-0.85
          c4.12,1.09,8.19,0.68,12.22-0.51c8.5,2.54,17.23,1.34,25.84,1.34c728.61,0.06,1457.21,0.05,2185.82,0.05
          c75.99,0,151.99,0,227.98,0.01c5.33,0,10.72-0.32,15.98,0.3c14.2,1.68,22.96,10.7,24.15,24.83c0.5,5.96,0.24,11.99,0.24,17.99
          c0.01,161.99,0.01,323.97,0.01,485.96c0,5.33-0.09,10.67,0.03,16c0.32,14.08,2.46,16.05,16.53,15.23c1.25-0.07,2.5-0.65,3.68-1.16
          c0.5-0.21,1.23-1.16,1.17-1.23c-0.78-0.97-1.64-2.58-2.54-2.62c-11.4-0.47-18.09,5.02-18.73,15.55
          c-0.36,5.98-0.15,11.99-0.15,17.99c0,134.66,0,269.31,0,403.97c0,6.65,0,13.29,0,21.35c-13.04-2.78-21.56-10.53-29.78-18.77
          c0.4-7.96,1.11-15.92,1.12-23.89c0.07-118.66,0.05-237.33,0.05-355.99c0-189.33-0.01-378.66,0.01-567.99
          c0-20.26,0.17-19.87-19.78-19.77c-1.33,0.01-2.67,0-4,0c-902.65,0-1805.3,0-2707.96,0c-4,0-8-0.1-12,0.06
          c-9.89,0.41-10.9,1.46-11.24,12.12c-0.19,5.99-0.06,12-0.06,18C1711.08,1601.92,1711.06,1987.25,1711.04,2372.58z"
      />
      <path
        class="st2"
        d="M1711.04,2372.58c0.02-385.33,0.04-770.65,0.06-1155.98c0-6-0.13-12,0.06-18
          c0.34-10.66,1.35-11.72,11.24-12.12c3.99-0.16,8-0.06,12-0.06c902.65,0,1805.3,0,2707.96,0c1.33,0,2.67,0.01,4,0
          c19.95-0.1,19.79-0.49,19.78,19.77c-0.03,189.33-0.01,378.66-0.01,567.99c0,118.66,0.02,237.33-0.05,355.99
          c0,7.97-0.72,15.93-1.12,23.89c-1.85-0.66-3.95-0.94-5.5-2.01c-18.75-12.98-16.29-10.21-16.29-30.56
          c-0.04-294.57-0.03-589.15-0.04-883.72c0-6.66-0.05-13.33-0.2-19.99c-0.11-4.61-2.32-7.51-7.19-7.65
          c-5.33-0.16-10.66-0.21-15.99-0.21c-887.05-0.01-1774.11-0.01-2661.16,0.01c-26.32,0-23.66-2.22-23.66,23.5
          c-0.04,373.22-0.02,746.43-0.08,1119.65c0,7.93,1.06,15.97-1.36,24.25C1725.19,2379.53,1717.93,2377.02,1711.04,2372.58z"
      />
      <path
        class="st1"
        d="M1646.59,2649.78c-76.1-0.01-152.19-0.03-228.29-0.04c-6.8-5.03-13.72-9.9-20.38-15.12
          c-8.18-6.42-16.68-6.62-25.63-2.04c-5.24,2.68-10.36,5.61-15.72,8.01c-4.01,1.79-7.63,0.68-10.25-3.05
          c0.03-38.45,0.06-76.9,0.09-115.35c-0.03-3.99,0.18-8-0.14-11.97c-0.66-8.31,2.36-14.28,10.34-17.27
          c11.49-4.3,17.41-13.5,22.54-23.99c8.26-16.89,12.73-34.63,15.66-53.13c2.08-13.15,3.38-26.18,3.75-39.48
          c0.62-23.06-3.79-44.78-13.6-65.58c-3.69-7.84-2.62-11.34,4.93-14.11c11.75-4.3,23.18-10.03,36.1-10.37
          c9.05,14.23,22.18,22.5,38.3,26.67c7.9,2.05,15.87,1.09,23.81,1.53c8.58,0.48,15.28-2.7,21.42-8.64
          c6.69-6.47,10.61-13.52,8.93-23.01c23.47,0.96,45.61,7.61,67.48,15.54c4.04,1.46,6.86,4.05,8,8.18
          c2.81,10.23,6.04,20.39,8.15,30.78c5.42,26.59,10.71,53.22,15.32,79.96c3.6,20.87,5.97,41.95,9.13,62.9
          c2.07,13.73,4.9,27.35,6.82,41.1c2.01,14.43,3.96,28.92,4.81,43.44c0.93,15.91,3.87,31.58,5.38,47.41
          C1644.74,2624.69,1646.09,2637.2,1646.59,2649.78z"
      />
      <path
        class="st2"
        d="M4253.3,1608.84c-7.13,2.32-14.51,1.61-21.8,1.62c-236.3,0.03-472.59,0.03-708.89-0.01
          c-6.64,0-13.28-0.53-19.92-0.81c-0.09-8-0.5-16.01-0.2-23.99c1.06-28.16,20.1-50.67,47.84-56.32c6.47-1.32,13.22-1.78,19.84-1.78
          c204.65-0.09,409.3-0.07,613.95-0.07c2.67,0,5.34-0.08,8,0.08c35.83,2.1,59.34,25.58,61.19,61.29
          C4253.65,1595.49,4253.32,1602.17,4253.3,1608.84z"
      />
      <path
        class="st1"
        d="M2998.55,2053.38c0.43,39.32-15.77,70.36-46.66,94.25c-9.43,7.3-17.07,16.76-27.82,22.56
          c-16.53,8.92-33.58,16.12-52.76,15.72c-13.2-0.28-26.31,0.95-39.59-2.7c-47.05-12.94-77.5-41.88-89.39-89.54
          c-11.45-45.89,1.04-85.15,33.28-118.86c11.07-11.58,22.89-22.16,36.94-30.15c26.76-15.24,54.46-22.04,85.7-15.95
          c23.1,4.5,43.57,14.13,60.34,28.91c24.02,21.17,39.04,48.47,39.93,81.77C2998.66,2044.05,2998.55,2048.72,2998.55,2053.38z"
      />
      <path
        class="st1"
        d="M2873.06,1605.7c53.54-1.35,87.94,23.08,112.16,63.14c8.84,14.62,13.89,31.46,13.45,49.4
          c-0.45,18.57,0.46,36.94-7.06,54.9c-4.44,10.6-9.29,20.89-16.55,29.29c-18.33,21.18-38.4,40.43-64.87,51.81
          c-14.86,6.39-30.55,10.99-46.12,10.85c-21.76-0.2-43.53-4.23-62.9-15.88c-33.12-19.92-54.85-48.62-61.36-86.6
          c-5.15-30.06,0.04-59,16.58-85.97c15.1-24.63,36.55-42.16,60.06-56.88C2834.52,1608.44,2855.51,1602.97,2873.06,1605.7z"
      />
      <path
        class="st1"
        d="M3260.92,1606.11c35.59-3.47,63.82,9.85,86.9,32c36.73,35.22,47.01,78.8,32.54,127.71
          c-7.37,24.93-21.44,45.39-42.8,60.69c-9.15,6.55-17.44,14.35-26.84,20.5c-12.87,8.43-26.87,14.48-42.66,15.07
          c-1.33,0.05-2.78-0.1-3.95,0.38c-18.13,7.47-35.36,0.64-52.45-3.85c-18.25-4.8-34.18-14.12-47.23-28.24
          c-10.35-11.2-21.73-21.23-27.98-35.9c-6.07-14.27-10.36-28.61-10.16-44.16c0.05-3.99,0.18-7.99-0.05-11.97
          c-2.02-34.36,13.32-61.58,35.71-85.62c7.65-8.22,15.32-16.56,24.82-22.97C3209.83,1614.17,3233.84,1601.98,3260.92,1606.11z"
      />
      <path
        class="st1"
        d="M2998.04,2380.08c2.43,35.51-10.87,64.57-36.27,88.93c-12.03,11.54-24.23,22.58-38.7,31.1
          c-17.52,10.31-36.71,16.51-56.47,17.16c-31.38,1.03-60.07-8.72-84.41-29.45c-22.02-18.75-36.77-42.22-41.72-70.59
          c-6.7-38.45,0.25-73.99,27.98-103.45c7.29-7.74,14.89-15.22,22.59-22.55c23.04-21.92,49.87-34.2,82.36-33.61
          c16.73,0.3,33.03,0.9,48.73,7.68c40.67,17.56,66.31,47.61,75.67,90.91C2999.45,2363.83,2998.04,2372.11,2998.04,2380.08z"
      />
      <path
        class="st2"
        d="M3150.75,1994.12c11.59-18.89,25.98-35.14,44.65-47.36c54.74-35.83,127.73-22.67,167.09,30.14
          c38.88,52.17,29.81,126.75-20.37,168.62c-9.75,8.14-20.4,14.78-31.51,20.78c-3.26,0.41-6.62,0.44-9.76,1.29
          c-10.24,2.76-20.38,5.87-31.11,6.19c-41.06,1.22-75.61-13.22-101.85-44.78c-23.13-27.82-32.25-60.06-26.82-96.48
          C3143.05,2019.26,3149.24,2007.34,3150.75,1994.12z"
      />
      <path
        class="st2"
        d="M3154.24,2317.83c9.41-12.95,19.72-24.94,32.41-34.98c67.85-53.69,181.33-23.29,198.4,78.67
          c9.43,56.31-19.55,103.29-64.56,129.05c-4.59,2.63-9.33,4.98-14,7.47c-3.3,0.21-6.82-0.27-9.85,0.74
          c-12.89,4.28-26.15,4.37-39.43,3.94c-45.84-1.51-79.53-23.89-101.9-62.71c-20.13-34.94-20.95-71.86-5.51-109.13
          C3151.56,2326.63,3154.58,2322.79,3154.24,2317.83z"
      />
      <path
        class="st4"
        d="M1346.33,2637.55c2.61,3.72,6.24,4.84,10.25,3.05c5.36-2.4,10.48-5.33,15.72-8.01
          c8.96-4.59,17.45-4.38,25.63,2.04c6.65,5.22,13.57,10.09,20.38,15.12c0.54,6.13,1.04,11.98-7.59,13.13
          c-2.9,0.39-3.73,3.27-2.13,6.07c1.3,2.27,3.1,4.3,4.11,6.68c4.55,10.78-0.46,18.26-12.3,17.86c-4.47-0.15-8.87-1.91-13.34-2.77
          c-6.41-1.23-12.05-5.42-18.92-4.87c-2.41-2.07-4.83-2.28-7.29-0.13c-6.63,0.04-13.09,0.65-19.62,2.43
          c-18.37,4.99-34.12,2.05-44.35-16.18c-3.28-5.84-8.74-9.78-14.18-13.71c-29.16-21.06-54.2-46.66-78.5-72.9
          c-8.89-9.6-16.36-20.62-22.6-32.49c-12.27-23.35-4.7-43.04,9.56-57.97c3.66-3.83,7.72-7.27,11.59-10.89
          c4.27,0.59,7.46,3.01,9.56,6.53c4.49,7.5,10.98,13.34,16.22,20.16c16.05,20.87,36.29,36.81,58.08,51.08
          c1.2,3.7,1.89,7.67,3.69,11.05C1304,2598.67,1323.46,2619.6,1346.33,2637.55z"
      />
      <path
        class="st5"
        d="M1744.1,2902.72c-8.08,13.32-11.25,12.01,3.12,26.33c10.74,10.7,21.53,21.36,32.06,32.26
          c5.47,5.67,5.61,7.56,1.79,13.81c-6.18,10.12-12.4,20.23-19.02,30.06c-1.72,2.55-4.96,4.08-7.5,6.08
          c-11.96-0.19-23.93-0.34-35.9-0.61c-4.65-0.1-9.41,0.07-13.91-0.86c-9.95-2.08-16.77-8.28-18.82-18.28
          c-1.32-6.44-1.33-13.21-1.33-19.84c-0.07-156.39-0.07-312.78,0.01-469.17c0-5.86-1.25-11.95,2.41-18.98
          c7.92-1.1,16.08,1.73,24.32,2.98c-0.08,132.28-0.13,264.57-0.25,396.85c-0.02,17.96,0.2,18.68,19.1,18.5
          C1734.81,2901.81,1739.45,2902.41,1744.1,2902.72z"
      />
      <path
        class="st4"
        d="M4303.81,2424.96c-26.01,6.04-52.14,11.63-77.21,21.09c-1.99,0.08-4,0.34-5.98,0.2
          c-8.87-0.62-9.62-1.36-7.84-9.2c5-21.98,10.01-43.96,15.44-65.83c1.24-4.98,1.55-10.7,6.21-14.35c21.31,0.15,42.66,1.08,63.93,0.18
          c13.81-0.59,27.53-3.72,41.25-5.97c6.79-1.12,12.74-3.75,15.46-10.97c0.68-1.81,2.17-3.34,3.42-4.89
          c5.25-6.55,11.46-10.89,20.51-10.08c5.95,0.54,11.99,0.2,17.98,0.25c-0.59,19.49-0.58,19.45,18.54,24.76
          c12.76,3.55,25.82,6.16,37.87,11.95c3.77,1.81,4.32,4.94,1.29,7.72c-1.94,1.78-4.77,2.86-6.17,4.95
          c-12.42,18.42-31.66,22.75-51.72,25.63c-3.88,0.56-7.67,1.78-11.5,2.69c-5.26,0.12-10.87-0.9-15.71,0.57
          C4347.54,2410.37,4325.71,2417.79,4303.81,2424.96z"
      />
      <path
        class="st4"
        d="M1518.47,2282.87c1.68,9.48-2.24,16.54-8.93,23.01c-6.14,5.93-12.84,9.12-21.42,8.64
          c-7.94-0.45-15.92,0.51-23.81-1.53c-16.12-4.17-29.25-12.44-38.3-26.67c4.71-20.05,9.44-40.11,14.13-60.17
          c0.6-2.58,1.34-5.17,1.53-7.79c0.27-3.62-0.95-6.52-5.09-7.17c-0.66-0.1-1.33-0.12-1.97-0.29c-16.68-4.31-19.38-14.2-7.12-26.53
          c1.32-1.33,3.49-1.82,5.27-2.7c2.38,1,5.92,3.54,6.96,2.76c9.52-7.14,23.26-11.35,20.55-27.74c-0.9-5.42,1.04-10.74,3.9-15.14
          c2.37-3.65,7.47-1.64,11.09-3.11c2.46-0.99,4.85-2.15,7.31-3.13c4.46-1.79,8.92-2.49,13.46-0.11c8.5,4.45,17.56,5.88,27.06,4.91
          c4.25-0.43,5.83,2.11,6.35,5.8c0.47,3.31,1.02,6.6,4.57,8.19c0.08,10.64,0.18,21.29,0.22,31.93c0.01,4.01-0.21,8.04,3.11,11.11
          c0.68,0.52,1.33,1.06,1.97,1.62c-1.96,2.92-4.7,3.77-8.05,3.03c-4.17-4.64-11.1-8.1-6.32-16.37c0.86-1.48,0.41-3.85,0.22-5.77
          c-0.29-2.92-5.14-4.85-7.73-3.08c-1.04,0.71-1.8,1.86-2.63,2.86c-5.02,6.05-3.68-0.29-4.24-2.28c-0.52-1.83-0.57-3.77-3.16-4.06
          c-0.77,3.84-1.44,7.73-2.34,11.57c-1.78,7.62-1.83,7.61,6.23,13.4c2.68,1.92,2.82,4.46,0.79,6.67c-4.69,5.09,0.03,4.35,2.91,5.35
          c7.57,2.64,8.79-7.4,15-7.27c1.57,19.68-4.64,36.55-18.51,50.54c-1.97,1.98-4.14,4.03-3.33,6.7
          C1510.6,2268.05,1509.58,2277.74,1518.47,2282.87z M1515.63,2218.72c-9.53-0.5-18.74,0.28-27.75-2.43
          c-0.83-0.25-2.11,1.01-3.19,1.56c0.79,0.97,1.48,2.67,2.38,2.79c4.56,0.62,9.2,1.22,13.78,1.06
          C1505.88,2221.54,1511.32,2223.41,1515.63,2218.72z M1468.07,2165.49c8.64,1.64,16.11,0.74,24.43-0.04
          C1482.42,2156.9,1482.42,2156.9,1468.07,2165.49z M1486.95,2181.41c-0.21-3.12-0.56-6.39-4.83-6.84c-3.58-0.37-4.18,2.47-4.66,4.95
          c-0.63,3.23-0.55,6.55,3.19,7.97C1483.15,2188.45,1486.61,2185.02,1486.95,2181.41z M1515.13,2163.83c3.05,3.27,7,2.88,10.81,2.48
          c1.03-0.11,1.94-1.45,2.91-2.22c-0.99-0.79-1.86-1.99-2.99-2.3C1522.07,2160.75,1518.21,2160.09,1515.13,2163.83z"
      />
      <path
        class="st4"
        d="M4360.21,2109.88c-0.46,2.58-0.79,5.2-1.41,7.74c-3.33,13.68-11.05,19.88-24.54,21.07
          c-18.46,1.62-34.99-3.8-50.57-12.9c-6.22-3.63-12.01-7.99-17.99-12.01c2.05-3.7,5.79-5.24,9.3-6.84
          c11.85-5.39,17.8-14.66,19.18-27.4c0.7-6.45,2.04-12.84,3.1-19.25c1.55-4.31,1.71-8.61-0.17-12.87c-0.35-8.65-0.56-8.83-9.64-8.01
          c-2.27-2.4-5.22-4.45-6.67-7.27c-3.64-7.04,0.79-13.81,8.59-13.44c3.27,0.16,6.47,1.35,9.75,1.76c7.5,0.94,13.28-2.3,15.46-9.28
          c1.37-4.4,1.74-9.12,2.4-13.72c0.94-6.59,1.25-13.3,2.68-19.77c4.02-18.21,17.47-29.69,34.67-30.41
          c5.68-0.24,10.29,1.07,13.93,5.89c8.16,10.79,14.72,22.21,15.62,36.06c0.3,4.66,0.17,9.33,2.58,13.55
          c0.05,9.96,0.23,19.92,0.12,29.88c-0.28,27.05-10.16,39.52-37.03,45.83c-6.43,1.51-9.44,7.92-5.39,13.85
          C4348.61,2098.81,4354.79,2104.08,4360.21,2109.88z M4367.02,2000.32c-2.29-0.9-3.61,0.53-4.25,2.38
          c-1.27,3.71-2.58,7.46-3.25,11.31c-1.17,6.78,2.51,12.09,10.08,14.8c1.84,0.66,3.95-0.06,4.62-2.24c1.33-4.29,2.61-8.6,3.56-12.98
          C4379.33,2006.48,4375.26,2001.57,4367.02,2000.32z M4342.24,2048.81c4.92,9.3,10.54,10.61,16.76,10.4
          c4.16-0.14,7.78-1.81,10.35-5.16c0.72-0.94,1.23-2.5,1-3.6c-0.2-0.96-1.93-2.54-2.37-2.36
          C4360.37,2051.26,4352.42,2050.29,4342.24,2048.81z M4346.23,1986.79c-0.99-1.43-1.71-3.61-3.17-4.38
          c-5.05-2.65-10.06-1.67-14.78,1.28c-2.8,1.75-6.4,3.88-5.63,7.22c1.04,4.5,4.95,2.15,7.91,1.56c4.45-0.88,8.97-1.47,13.39-2.51
          C4344.81,1989.77,4345.32,1988.11,4346.23,1986.79z M4338.21,2001.9c-0.62,0.03-1.28-0.07-1.84,0.12
          c-3.61,1.21-4.77,4.26-5.05,7.56c-0.29,3.44,1.11,6.32,4.32,8.01c2.03,1.07,4.03,0.25,5.22-1.17c3.3-3.94,3.3-8.33,0.51-12.57
          C4340.75,2002.92,4339.29,2002.53,4338.21,2001.9z"
      />
      <path
        class="st5"
        d="M4297.11,2047.42c1.88,4.27,1.72,8.56,0.18,12.87c-25.59-8.73-33.6-21.17-30.77-47.39
          c1.2-11.12,1.96-22.3,3.15-33.42c0.63-5.88-2.23-8.34-7.56-9.3c-2.57-0.46-5.02-1.6-7.52-2.43c-3.04-2.46-6.32-4.68-9.07-7.43
          c-20.41-20.44-17.97-51.09,5.37-68.14c2.59-1.89,5.36-3.52,8.05-5.26c3.92-0.65,7.83-1.62,11.78-1.9
          c24.28-1.71,40.94,9.1,47.67,32.8c2.12,7.46,5.48,9.81,12.07,10.22c14.05,0.86,27.38,5.05,40.71,9.04
          c13.61,4.08,22.75,13.28,27.81,26.43c6.5,16.88,3.14,27.14-12.51,39.29c-2.41-4.23-2.28-8.89-2.58-13.55
          c-0.89-13.85-7.46-25.27-15.62-36.06c-3.64-4.82-8.25-6.13-13.93-5.89c-17.2,0.73-30.65,12.2-34.67,30.41
          c-1.43,6.47-1.74,13.19-2.68,19.77c-0.66,4.6-1.03,9.33-2.4,13.72c-2.18,6.98-7.96,10.22-15.46,9.28c-3.27-0.41-6.48-1.6-9.75-1.76
          c-7.8-0.38-12.23,6.39-8.59,13.44c1.46,2.82,4.41,4.87,6.67,7.25C4287.22,2048.75,4288.44,2049.77,4297.11,2047.42z"
      />
      <path
        class="st4"
        d="M4818.29,2122.38c-3.99-0.25-7.99-0.79-11.97-0.67c-4.99,0.15-10.57-5.82-14.73,0.01
          c-3.09,4.33,2.13,8.56,2.84,13.04c1.95,12.39-4.59,21.78-11.16,31c-1.32,1.86-4.48,2.94-6.97,3.35
          c-21.7,3.51-39.53,14.25-55.86,28.45c-25.15,21.87-51.98,41.57-80.41,58.99c-5.55,3.4-11.19,7.2-17.08,7.65
          c-3.63-0.38-4.29-2.57-4.29-4.4c-0.06-16.88-4.37-32.88-9.63-48.72c-3.44-10.37-2.5-13.14,8.01-15.66
          c16.28-3.9,31.56-10.45,47.16-16.17c24.58-9.01,47.21-21.32,66.7-39.18c9.8-8.98,20.45-17.03,30.62-25.62
          c11.86-10.02,25.33-10.97,39.48-6.83c6.35,1.86,12.4,4.79,18.57,7.22C4819.13,2117.35,4818.71,2119.86,4818.29,2122.38z"
      />
      <path
        class="st2"
        d="M1744.1,2902.72c-4.64-0.3-9.28-0.91-13.92-0.86c-18.89,0.18-19.11-0.54-19.1-18.5
          c0.11-132.29,0.16-264.57,0.25-396.85c1.33-0.19,2.64-0.57,3.95-0.56c20.23,0.21,19.63-1.48,19.63,19.5
          c-0.02,116.51-0.06,233.01,0.03,349.52c0.02,28.98-3.18,25.45,25.38,25.81c2,0.03,3.99-0.03,5.99-0.05
          C1761.46,2890.63,1754.4,2898.31,1744.1,2902.72z"
      />
      <path
        class="st4"
        d="M1894.88,2313.71c0.75-1.07,2.08-2.1,2.15-3.22c0.79-11.46,7.72-16.17,18.28-16.6
          c5.54-0.23,10.27-1.76,14.41-5.57c2.73-2.52,6.05-2.49,8.91,0.01c3.28,2.87,4.86,6.91,2.87,10.67c-2.1,3.99-5.28,7.59-8.59,10.73
          c-4.71,4.46-6.48,9.58-6.36,15.95c0.2,11.25-0.04,22.52-0.19,33.78c-0.22,16.58-5.53,30.99-17.6,42.83
          c-5.15,5.06-11.09,9.54-14.4,16.27c-8.02,5.88-16.17,11.59-24.04,17.66c-14.33,11.06-30.09,19.65-46.46,27.15
          c-10.51,4.82-13.5,3.11-13.4-7.91c0.13-14.68-0.34-29.17-3.84-43.6c-1.75-7.22-4.64-13.84-7.06-20.71c-1.96-5.55-0.2-8.4,6.14-9.63
          c13.68-2.66,27.44-4.91,41.09-7.68c11.82-2.4,22.65-7.53,33.56-12.56c9.55-4.41,14.61-10.54,13.86-21.6
          C1893.64,2331.07,1894.6,2322.37,1894.88,2313.71z"
      />
      <path
        class="st1"
        d="M3154.24,2317.83c0.34,4.96-2.68,8.8-4.44,13.04c-15.44,37.27-14.63,74.18,5.51,109.13
          c22.37,38.82,56.05,61.21,101.9,62.71c13.27,0.44,26.54,0.34,39.43-3.94c3.03-1.01,6.55-0.53,9.85-0.74
          c-0.17,5.67-4.29,7.96-8.69,9.73c-40.29,16.12-79.47,13.71-116.23-9.43c-30.59-19.25-49.13-46.72-55.93-83.19
          c-6.42-34.41,1.93-64.12,20.57-92.26C3148.2,2319.86,3150.71,2318.16,3154.24,2317.83z"
      />
      <path
        class="st5"
        d="M1534.02,2154.07c-3.55-1.6-4.1-4.88-4.57-8.19c-0.52-3.69-2.11-6.23-6.35-5.8
          c-9.5,0.97-18.56-0.46-27.06-4.91c-4.53-2.38-9-1.68-13.46,0.11c-2.46,0.99-4.85,2.14-7.31,3.13c-3.62,1.46-8.72-0.54-11.09,3.11
          c-2.86,4.41-4.8,9.72-3.9,15.14c2.71,16.39-11.04,20.6-20.55,27.74c-1.04,0.78-4.58-1.76-6.96-2.76
          c-2.07-12.45-3.31-24.97-3.07-37.6c0.53-26.99,20.56-52.29,52.47-48.23c4.61,0.59,9.21,1.2,13.8,1.91
          c3.44,0.54,6.56,0.22,9.15-2.41c4.08-4.15,8.56-2.96,12.66-0.37c7.98,5.05,14.72,11.42,18.17,20.46c1.14,3,2.81,7.2,1.61,9.44
          C1532.48,2134.3,1537.1,2144.62,1534.02,2154.07z"
      />
      <path
        class="st1"
        d="M3150.75,1994.12c-1.5,13.21-7.7,25.14-9.68,38.4c-5.43,36.42,3.69,68.66,26.82,96.48
          c26.24,31.55,60.79,46,101.85,44.78c10.73-0.32,20.87-3.43,31.11-6.19c3.14-0.85,6.5-0.88,9.76-1.29
          c-0.44,4.52-3.24,7.15-7.21,8.68c-15.49,5.98-30.85,12.48-48.08,11.08c-4.63-0.38-9.32-0.2-13.98-0.03
          c-30.43,1.08-55.41-10.55-76.81-31.7c-12.41-12.27-23.79-24.91-30.5-41.4c-16.1-39.59-13.33-77.59,8.46-114.3
          C3144.39,1995.42,3147.2,1994.17,3150.75,1994.12z"
      />
      <path
        class="st3"
        d="M1346.33,2637.55c-22.87-17.94-42.33-38.88-56.05-64.71c-1.8-3.38-2.49-7.35-3.69-11.05
          c3.73-1.12,7.59-1.95,11.17-3.43c6.04-2.5,9.05-7.28,9.1-13.85c0.02-1.97-0.15-3.95,0.06-5.89c1.14-10.67,6.86-16.24,17.87-16.71
          c7.19-0.31,14.41,0.18,21.62,0.31C1346.39,2560.65,1346.36,2599.1,1346.33,2637.55z"
      />
      <path
        class="st1"
        d="M1230.45,2041.75c-27.33,0-54.65,0.01-81.98-0.01c-4.66,0-9.33-0.01-13.99-0.27c-4.6-0.26-7.27-2.83-7.73-7.49
          c-0.13-1.32-0.21-2.66-0.21-3.99c-0.01-30.66-0.13-61.32,0.06-91.97c0.06-10.01,1.73-11.61,11.79-11.74
          c18.66-0.25,37.32-0.11,55.98-0.11c38.66-0.01,77.31-0.01,115.97,0.01c5.33,0,10.67,0,15.99,0.28c4.6,0.24,7.27,2.83,7.73,7.49
          c0.13,1.32,0.21,2.66,0.21,3.99c0.01,30.66,0.13,61.32-0.06,91.97c-0.06,10-1.73,11.67-11.79,11.74
          c-30.66,0.2-61.31,0.07-91.97,0.07C1230.45,2041.72,1230.45,2041.73,1230.45,2041.75z M1228.35,2034.29c6.65,0,13.29,0,19.94,0
          c21.27,0,42.54,0.1,63.81-0.03c18.02-0.11,18.25-0.3,18.36-17.34c0.15-21.93,0.15-43.87,0-65.8c-0.12-17.21-0.28-17.43-18.23-17.47
          c-54.5-0.1-109-0.04-163.51-0.04c-1.99,0-3.99-0.04-5.98,0.05c-10.77,0.53-12.23,1.78-12.33,13.32
          c-0.21,24.59-0.07,49.18-0.06,73.78c0,1.33,0.03,2.66,0.15,3.98c0.63,7.04,2.53,8.92,10.12,9.37c4.64,0.27,9.3,0.16,13.95,0.16
          C1179.16,2034.3,1203.75,2034.29,1228.35,2034.29z"
      />
      <path
        class="st1"
        d="M3470.72,977.97c-0.01-16.55-0.16-33.11,0.07-49.65c0.1-7.44,2.34-9.46,9.36-9.95
          c1.98-0.14,3.97-0.14,5.95-0.11c12.56,0.22,15.81,2.8,15.91,14.8c0.26,31.12,0.2,62.24,0,93.35c-0.06,9.55-2.02,11.05-12.05,11.37
          c-3.3,0.11-6.63,0.11-9.92-0.11c-7.19-0.49-9.11-2.23-9.23-10.05C3470.55,1011.07,3470.72,994.52,3470.72,977.97z M3494.6,979.43
          c0-11.25,0.02-22.5-0.01-33.75c-0.01-3.97,0.04-7.95-0.23-11.9c-0.3-4.5-2.65-7.25-7.4-7.55c-5.47-0.34-8.6,2.13-8.64,8.34
          c-0.18,30.43-0.07,60.87-0.01,91.3c0,1.28,0.31,2.72,0.95,3.81c1.88,3.22,5.03,4.05,8.49,3.7c2.78-0.27,5.26-1.62,5.87-4.45
          c0.7-3.19,0.91-6.54,0.93-9.82C3494.65,1005.89,3494.59,992.66,3494.6,979.43z"
      />
      <path
        class="st3"
        d="M4514.01,2272.75c0.02,2.63,0.09,5.25,0.04,7.88c-0.08,4.56-2.13,7.67-6.95,7.69
          c-20.36,0.11-40.72,0.12-61.08,0c-4.76-0.03-6.9-3.04-7.04-7.64c-0.08-2.63-0.21-5.25-0.31-7.88c0.46-3.58,2.17-6.19,5.98-6.41
          c5.82-0.34,11.65-0.36,17.47-0.52c4.53-1.08,4.71-5.95,7.45-8.56c5.51-5.27,12.44-5.15,16.22,1.78c3.02,5.55,6.5,7.46,12.24,7.36
          c3.9-0.07,7.83,0.17,11.71,0.64C4512.83,2267.46,4513.75,2269.98,4514.01,2272.75z"
      />
      <path
        class="st1"
        d="M4734.27,1719.91c0,17.89,0.16,35.79-0.06,53.68c-0.13,10.32-2.72,12.4-13.4,12.03
          c-1.32-0.05-2.65-0.08-3.96-0.27c-6.1-0.88-9.08-4.01-9.83-10.57c-0.3-2.62-0.26-5.29-0.26-7.94c-0.01-32.47-0.01-64.95,0.01-97.42
          c0-2.65,0.03-5.31,0.31-7.94c0.49-4.65,3.36-6.95,7.9-7.16c3.31-0.15,6.63-0.25,9.93-0.07c7.01,0.38,9.12,2.35,9.21,9.99
          c0.22,18.55,0.07,37.11,0.07,55.67C4734.22,1719.91,4734.25,1719.91,4734.27,1719.91z M4730.45,1717.48
          c0-13.23,0.01-26.47-0.01-39.7c-0.01-3.97,0.04-7.95-0.21-11.9c-0.29-4.51-2.62-7.26-7.39-7.53c-4.61-0.26-7.69,2.04-8.19,6.57
          c-0.36,3.27-0.42,6.59-0.43,9.89c-0.03,29.11-0.02,58.22-0.01,87.34c0,1.98-0.02,3.97,0.12,5.95c0.45,6.71,2.44,9.2,7.51,9.55
          c5.33,0.37,7.98-2.19,8.43-8.56c0.28-3.95,0.18-7.93,0.19-11.9C4730.46,1743.95,4730.45,1730.72,4730.45,1717.48z"
      />
      <path
        class="st1"
        d="M4770.21,1734.87c0,13.24,0.19,26.48-0.07,39.72c-0.18,9.29-2.55,11.15-12.31,11.04
          c-1.32-0.02-2.66,0.02-3.97-0.12c-6.39-0.68-9.84-3.71-10.71-9.66c-0.38-2.6-0.39-5.28-0.39-7.92
          c-0.02-23.83-0.03-47.66,0.01-71.49c0-2.64,0.17-5.29,0.52-7.9c0.5-3.79,3.01-5.86,6.63-6.14c3.94-0.31,7.94-0.33,11.89-0.08
          c5.85,0.38,8.09,2.53,8.2,8.87c0.25,14.56,0.08,29.12,0.08,43.68C4770.14,1734.87,4770.18,1734.87,4770.21,1734.87z
           M4766.11,1733.62c0.11,0,0.23,0,0.34,0c0-12.56,0.16-25.12-0.06-37.67c-0.12-6.77-2.3-9.2-7.37-9.61
          c-5.84-0.48-8.62,2.35-8.68,10.31c-0.19,23.79-0.06,47.58-0.02,71.37c0,1.31,0.25,2.63,0.48,3.92c0.73,3.96,4.39,6.32,8.91,5.7
          c3.7-0.51,6.26-2.64,6.32-6.38C4766.23,1758.71,4766.11,1746.17,4766.11,1733.62z"
      />
      <path
        class="st1"
        d="M4698.22,1733.52c0,13.9,0.19,27.81-0.07,41.71c-0.16,8.25-2.59,10.29-10.98,10.35
          c-2.63,0.02-5.32-0.06-7.89-0.56c-4.29-0.82-6.91-3.72-7.94-7.87c-0.47-1.9-0.54-3.94-0.54-5.92c-0.03-25.82-0.16-51.64,0.04-77.46
          c0.08-9.84,2.02-11.44,11.72-11.67c2.64-0.06,5.3,0.02,7.94,0.21c4.61,0.34,7.37,2.88,7.52,7.51c0.25,7.94,0.22,15.88,0.26,23.83
          c0.04,6.62,0.01,13.24,0.01,19.86C4698.27,1733.52,4698.24,1733.52,4698.22,1733.52z M4693.96,1733.85c0.1,0,0.21,0,0.31,0
          c0-13.21,0.21-26.43-0.09-39.63c-0.15-6.34-5.2-9.38-10.91-7.42c-4.8,1.65-5.04,5.7-5.06,9.63c-0.09,23.78-0.06,47.57,0.01,71.35
          c0.01,1.95,0.1,4.16,1,5.78c0.85,1.54,2.63,2.93,4.32,3.59c4.73,1.87,10.19-0.94,10.31-5.68
          C4694.17,1758.94,4693.96,1746.4,4693.96,1733.85z"
      />
      <path
        class="st1"
        d="M4330.35,1021.92c-1.59,2.04-2.77,5.05-4.85,5.96c-11.87,5.15-21.23,15.45-34.76,17.07
          c-19.83,2.37-39.32,1.33-58.08-6.29c-4.36-1.77-8.26-4.12-10.2-8.74l-0.11,0.11c5.52-0.45,10.55,1.17,15.24,3.86
          c8.27,4.75,17.74,5.61,26.71,7.55c12.75,2.76,25.02-1.05,36.6-6.65c4.51-2.18,5.36-4.82,3.18-9.75c-1.61-3.65-3.58-7.14-5.29-10.74
          c-2.22-4.68-5.02-6.92-10.94-5.82c-7.11,1.32-14.53,1.13-21.82,1.31c-7.59,0.18-14.45-1.8-19.58-7.89c0,0-0.11,0.12-0.11,0.12
          c2.62,0.06,5.48-0.55,7.82,0.29c19.02,6.89,35.75,0.11,52.26-8.3c0,0-0.1-0.11-0.1-0.11c-1.75,9.05,5.06,14.67,8.7,21.54
          c3.33,6.29,9.1,7.11,15.4,6.56C4330.45,1022.01,4330.35,1021.92,4330.35,1021.92z"
      />
      <path
        class="st1"
        d="M3458.26,967.98c0,19.92,0.14,39.84-0.07,59.76c-0.08,7.59-2.24,9.61-9.32,9.93
          c-3.96,0.18-7.97,0.09-11.91-0.34c-3.72-0.41-5.79-3-6.14-6.65c-0.32-3.29-0.35-6.63-0.36-9.94c-0.02-36.52-0.02-73.04,0-109.57
          c0-3.31,0.04-6.65,0.37-9.94c0.36-3.65,2.48-6.23,6.17-6.64c3.94-0.44,7.95-0.51,11.91-0.34c7.03,0.31,9.2,2.36,9.28,9.98
          C3458.4,925.48,3458.26,946.73,3458.26,967.98z M3454.47,966.83c0.02,0,0.03,0,0.05,0c0-16.65,0.03-33.3-0.02-49.96
          c-0.01-3.99-0.13-7.99-0.46-11.96c-0.23-2.8-1.67-4.92-4.54-5.87c-6.21-2.04-13.1,1.13-14.31,6.99c-0.67,3.22-0.73,6.61-0.73,9.92
          c-0.05,34.64-0.04,69.27,0,103.91c0,2.65,0.24,5.31,0.57,7.94c0.37,2.94,2.28,4.67,5.04,5.43c8.96,2.49,14.19-1.07,14.32-10.47
          C3454.65,1004.13,3454.47,985.48,3454.47,966.83z"
      />
      <path
        class="st1"
        d="M1426.47,1001.89c-13.59-2.51-27.12-5.04-41.14-4.23c-5.04,0.29-10.24-2.09-15.35-3.3
          c-3.08-0.73-7.74,0.07-8.58-3.39c-1.44-5.96,4.82-4.75,7.82-6.36c8.93-4.78,10.23-7.17,9.06-17.29c-0.43-3.76-4.19-7.1-0.89-12.19
          c6.8,1.56,13.31,3.69,16.95,10.88l0.12-0.12c-3.11,0.63-6.61,0.73-7.49,4.76c-0.76,3.48,0.63,6.26,3.14,8.61
          c2.62,2.46,6.59,1.78,7.63-1.67c0.72-2.41,0.52-5.11,0.73-7.67l-0.11,0.12c1.13,0.68,2.78,1.09,3.3,2.08
          c6.12,11.73,14.79,21.32,24.68,29.92L1426.47,1001.89z"
      />
      <path
        class="st1"
        d="M4662.13,1745.74c0,9.93,0.19,19.87-0.06,29.79c-0.19,7.41-2.51,9.73-9.29,9.97
          c-3.28,0.12-6.66,0.05-9.86-0.58c-4.26-0.84-7.01-3.84-7.63-8.1c-0.56-3.91-0.55-7.92-0.56-11.88
          c-0.03-15.23-0.25-30.47,0.15-45.68c0.26-9.76,3.74-12.67,13.61-13.02c1.98-0.07,3.97,0.04,5.95,0.21c4.58,0.39,7.45,2.9,7.53,7.51
          c0.2,10.59,0.06,21.18,0.06,31.78C4662.06,1745.74,4662.09,1745.74,4662.13,1745.74z M4658.35,1745.49c0.02,0,0.03,0,0.05,0
          c0-7.25,0.2-14.52-0.06-21.76c-0.24-6.63-2.45-9.14-7.5-9.51c-5.29-0.39-8.36,2.21-8.46,8.46c-0.25,15.82-0.17,31.65,0.01,47.48
          c0.05,4.48,2.66,7.21,7.38,7.51c5.28,0.33,8.26-2.27,8.5-8.44C4658.59,1761.32,4658.35,1753.4,4658.35,1745.49z"
      />
      <path
        class="st1"
        d="M4806.2,1746.26c0,10,0.21,20-0.07,29.99c-0.2,6.97-2.75,9.12-10.07,9.3c-2.64,0.06-5.32-0.18-7.92-0.66
          c-2.85-0.52-4.64-2.45-5.07-5.37c-0.29-1.97-0.5-3.97-0.5-5.95c-0.04-18.67-0.17-37.33,0.05-55.99
          c0.11-9.46,2.52-11.41,12.13-11.31c1.33,0.01,2.67,0.07,3.99,0.21c4.66,0.49,7.3,3.13,7.36,7.79c0.13,10.66,0.04,21.33,0.04,31.99
          C4806.15,1746.26,4806.18,1746.26,4806.2,1746.26z M4802.2,1746.87c-0.01,0-0.02,0-0.03,0c0-8.57,0.22-17.14-0.09-25.7
          c-0.17-4.75-3.16-7.02-7.79-6.96c-4.61,0.06-7.66,2.36-7.72,7.07c-0.23,16.47-0.23,32.95,0.02,49.42c0.07,4.72,3.14,6.96,7.82,6.95
          c4.66-0.02,7.51-2.25,7.7-7.06C4802.42,1762.69,4802.2,1754.77,4802.2,1746.87z"
      />
      <path
        class="st1"
        d="M4246.48,902.01c7.53-5.95,16.32-7.46,25.65-8.03c11.14-0.69,11.89-1.72,14.43-12.27
          c0.61-2.55,1.33-5.08,1.84-7.65c1.08-5.42-0.33-7.97-5.27-8.08c-13.73-0.31-27.59-2.2-40.96,3.04c-2.35,0.92-5.19,0.61-7.8,0.87
          l0.12,0.14c5.03-9.96,15.33-9.78,24.03-11.43c7.7-1.46,15.92-0.72,23.87-0.22c9.02,0.57,12.65,5.47,11.73,14.8
          c-0.26,2.61-0.97,5.19-1.59,7.76c-4.94,20.3-4.94,20.31-26.23,20.66c-6.65,0.11-13.3,0.18-19.95,0.26L4246.48,902.01z"
      />
      <path
        class="st1"
        d="M1724.28,999.98c-1.04,1.16-2.07,3.15-3.68,3.9c-2.31,1.08-5.07,1.83-7.59,1.7
          c-16.01-0.83-31.31,3.29-46.79,6.19c-1.86,0.35-4.04,0.65-5.68-0.01c-1.63-0.65-3.22-2.33-3.99-3.95c-0.83-1.74-0.38-3.9,1.55-5.12
          c1.66-1.05,3.32-2.37,5.17-2.82c18.6-4.55,37.37-7.66,56.62-5.67C1722.83,994.5,1724.39,996.45,1724.28,999.98z"
      />
      <path
        class="st1"
        d="M1418.06,1236.9c3.78-16.76,8.8-34.56,18.76-50.56c1.46-2.34,4.3-3.65,6.84-2.05
          c1.36,0.86,2.16,3.25,2.41,5.07c0.26,1.89-0.03,4.1-0.81,5.84c-6.83,15.17-12.41,30.74-15.78,47.07c-0.25,1.23-1.03,2.6-2,3.36
          c-1.45,1.13-3.2,2.23-4.96,2.52c-2.49,0.41-3.29-1.91-3.76-3.77C1418.28,1242.48,1418.36,1240.43,1418.06,1236.9z"
      />
      <path
        class="st1"
        d="M3538.34,953.88c2.16,1.73,3.67,3.92,3.68,6.72c0.08,22.6,0.23,45.21,0.03,67.81
          c-0.06,7.08-2.5,8.92-10.01,9.35c-1.33,0.08-2.66,0.06-3.99,0.06c-12-0.03-13.55-1.35-13.65-13.88
          c-0.17-21.28-0.14-42.55,0.08-63.83c0.08-7.51,2.29-9.68,9.35-9.89c5.16-0.16,10.81-1.45,14.64,3.8c-2.66-0.01-5.33-0.19-7.97-0.01
          c-10.65,0.72-12.01,1.69-12.11,11.3c-0.2,19.92-0.15,39.85,0.14,59.78c0.1,6.65,3.38,8.95,10.53,8.67
          c5.88-0.23,8.69-2.59,9.12-8.15c0.3-3.97,0.23-7.96,0.23-11.95C3538.41,993.74,3538.37,973.81,3538.34,953.88z"
      />
      <path
        class="st1"
        d="M1533.81,1564.31c-0.47,2.53-1.99,4.98-4.94,4.82c-2.45-0.13-5.44-1.03-7.13-2.68
          c-12.34-12.02-24.42-24.31-36.53-36.57c-0.9-0.91-1.62-2.2-1.92-3.43c-0.65-2.63-0.73-5.43,1.88-7.09c2.38-1.52,5.09-1.38,7.4,0.29
          c1.58,1.14,3.17,2.47,4.27,4.05c8.81,12.63,19.62,23.21,32.2,32.05C1531.85,1557.72,1533.71,1560.39,1533.81,1564.31z"
      />
      <path
        class="st1"
        d="M1834.87,1017.96c-2.73,0-4.82,0.4-6.7-0.06c-16.07-3.9-32.11-7.94-48.13-12.02
          c-1.22-0.31-2.44-1.07-3.37-1.93c-1.54-1.42-2.03-3.3-1.31-5.36c0.7-2.02,2.2-3.83,4.22-3.48c19.54,3.34,39.24,6.08,57.49,14.52
          c1.89,0.87,3.18,2.38,2.99,4.57C1839.74,1017.86,1836.56,1017.48,1834.87,1017.96z"
      />
      <path
        class="st1"
        d="M1426.27,1422.33c0.29-1.56,0.24-3.02,0.84-4.11c0.57-1.02,1.73-2.06,2.83-2.35c1.11-0.3,2.79-0.1,3.64,0.6
          c1.49,1.21,2.97,2.78,3.72,4.52c6.58,15.23,13.01,30.54,19.45,45.83c0.51,1.21,1.21,2.57,1.07,3.78c-0.22,1.85-0.59,4.33-1.83,5.22
          c-1.18,0.85-3.98,0.63-5.34-0.24c-2.17-1.38-4.41-3.4-5.45-5.68c-6.33-13.87-12.4-27.87-18.37-41.9
          C1426.08,1426.26,1426.41,1424.06,1426.27,1422.33z"
      />
      <path
        class="st1"
        d="M1942,1073.12c-0.68,1.93-1.7,4.01-4.01,3.36c-2.42-0.69-4.73-2.05-6.84-3.48c-13.6-9.23-26.15-20-40.87-27.57
          c-0.57-0.29-1.04-0.78-1.5-1.24c-2.12-2.12-2.51-4.85-1.18-7.21c1.4-2.47,4.41-3.47,6.87-2.17c7.55,3.98,15.14,8,22.2,12.77
          c7.61,5.14,14.74,11.02,21.86,16.84C1941.08,1066.5,1942.8,1069.44,1942,1073.12z"
      />
      <path
        class="st1"
        d="M3582.09,1004.41c0,8.59,0.15,17.17-0.06,25.75c-0.11,4.64-2.99,7.18-7.57,7.41
          c-19.11,0.97-20.2,0.1-19.99-17.08c0.17-13.87-0.04-27.74,0.27-41.61c0.13-5.96,2.62-8.1,8.61-8.5c3.29-0.22,6.61-0.22,9.89-0.02
          c6.14,0.37,8.62,2.46,8.83,8.28c0.31,8.57,0.08,17.17,0.08,25.75C3582.13,1004.41,3582.11,1004.41,3582.09,1004.41z
           M3578.21,1006.82c-0.01,0-0.02,0-0.03,0c0-7.23,0.28-14.47-0.09-21.68c-0.26-5.15-3.59-7.34-9.68-7.34
          c-6.09,0-9.56,2.19-9.67,7.33c-0.31,13.79-0.31,27.6-0.03,41.39c0.11,5.13,3.55,7.29,9.69,7.29c6.14,0,9.43-2.16,9.72-7.29
          C3578.49,1019.98,3578.21,1013.39,3578.21,1006.82z"
      />
      <path
        class="st1"
        d="M1406.42,1316.45c0.38-2.95,0.7-7.54,1.67-11.98c0.57-2.59,2.72-4.73,5.53-4.77c3.02-0.04,4.48,2.22,4.69,5.14
          c0.18,2.62,0.31,5.25,0.34,7.88c0.13,11.81-0.58,23.62,3.1,35.2c1.14,3.58,0.38,7.82,0.15,11.74c-0.16,2.82-1.76,4.96-4.76,5.35
          c-2.99,0.39-5.51-1.28-5.84-3.95C1409.52,1346.77,1408.12,1332.43,1406.42,1316.45z"
      />
      <path
        class="st1"
        d="M1636.61,1620.33c-0.11,2.58-1.13,4.5-3.11,4.87c-2.52,0.46-5.38,0.71-7.77-0.01
          c-16.54-4.99-31.8-12.9-46.84-21.24c-0.56-0.31-1.01-0.86-1.43-1.37c-1.26-1.54-2.58-3.15-1.69-5.31c1.21-2.94,3.94-3.76,6.43-2.73
          c12.2,5.02,24.26,10.37,36.39,15.55c4.85,2.07,9.87,3.76,14.6,6.07C1634.75,1616.91,1635.63,1619.07,1636.61,1620.33z"
      />
      <path
        class="st1"
        d="M4846.48,1952.18c-0.34,2.51-0.24,3.95-0.78,5.08c-0.51,1.07-1.58,2.04-2.63,2.65
          c-2.14,1.22-3.92-0.15-5.18-1.59c-1.25-1.44-2.42-3.26-2.82-5.09c-3.13-14.18-6.43-28.29-11.59-41.91
          c-0.89-2.34-0.74-5.2-0.53-7.78c0.16-1.93,1.21-3.9,3.71-3.74c1.23,0.08,2.46,0.6,3.61,1.11c0.55,0.25,1.07,0.86,1.33,1.44
          C4839.17,1918.58,4843.24,1935.83,4846.48,1952.18z"
      />
      <path
        class="st1"
        d="M4767.95,1806.59c3.74-0.4,6.61,1.46,8.63,4.05c9.33,11.95,18.46,24.05,27.59,36.15
          c0.76,1.01,1.16,2.37,1.46,3.63c0.67,2.84-0.14,5.48-2.87,6.48c-1.58,0.58-3.8-0.01-5.53-0.63c-1.09-0.39-1.9-1.75-2.68-2.79
          c-9.9-13.18-19.84-26.34-29.55-39.67c-0.91-1.24-0.64-3.7-0.2-5.41C4765.02,1807.57,4766.89,1807.16,4767.95,1806.59z"
      />
      <path
        class="st1"
        d="M4818.29,2122.38c0.42-2.52,0.84-5.04,1.27-7.55c3.3-0.57,6.63-1.18,9.2,1.75c1.92,2.2,1.95,4.99,1.32,7.53
          c-0.8,3.19-1.97,6.35-3.44,9.28c-5.96,11.86-11.98,23.7-18.27,35.39c-1.17,2.18-3.53,4.39-6.59,3.11c-3.88-1.62-3.78-5.29-2.84-8.4
          c0.93-3.08,2.94-5.85,4.5-8.74C4809.09,2144.29,4815.71,2134.26,4818.29,2122.38z"
      />
      <path
        class="st1"
        d="M2014.72,1157.27c-4.03,1.18-8.1,1.6-12.22,0.51c-4.79-12.48-13.36-22.59-20.91-33.35
          c-1.88-2.69-3.77-5.4-5.31-8.29c-0.5-0.94-0.39-3.07,0.27-3.58c1.42-1.09,3.58-2.33,5.08-1.98c2.41,0.55,5.26,1.81,6.66,3.7
          c8.63,11.59,17.03,23.35,25.16,35.29C2014.79,1151.51,2014.34,1154.67,2014.72,1157.27z"
      />
      <path
        class="st1"
        d="M4774.29,2212.94c0.21,0.76-1.28,3.61-3.57,6.18c-11.08,12.41-23.69,23.08-37.25,32.65
          c-1.8,1.27-4.06,1.28-5.28-0.39c-1.01-1.39-1.72-3.8-1.21-5.31c0.8-2.39,2.25-5.11,4.26-6.44c12.83-8.51,22.85-20.11,34.1-30.34
          c0.94-0.86,2.24-1.66,3.46-1.82C4770.81,2207.21,4772.61,2208,4774.29,2212.94z"
      />
      <path
        class="st1"
        d="M4635.57,2301.66c-5.17-0.13-6.65-1.24-7.46-3.19c-0.9-2.2,0.4-3.8,1.8-5.19c0.91-0.91,2.04-1.75,3.24-2.19
          c14.17-5.32,28.35-10.61,42.58-15.78c1.78-0.65,3.85-0.83,5.75-0.69c1.12,0.08,2.61,0.98,3.13,1.94c0.53,0.97,0.5,2.74-0.07,3.69
          c-0.98,1.63-2.31,3.46-3.94,4.21c-14.37,6.53-28.8,12.97-44.09,17.16C4635.28,2301.95,4633.88,2301.66,4635.57,2301.66z"
      />
      <path
        class="st1"
        d="M4850.25,2032.15c0,6.64,0.05,13.29-0.02,19.93c-0.06,5.45-1.48,10.52-5.16,14.66
          c-0.68,0.77-2.38,1.17-3.42,0.92c-1.03-0.25-2.29-1.37-2.61-2.38c-0.59-1.85-0.83-3.9-0.75-5.85c0.35-9.25-0.08-18.46,2.55-27.66
          c1.97-6.87,1.71-14.36,2.7-21.53c0.1-0.73,1.97-1.7,2.98-1.67c1.02,0.03,2.8,1.1,2.87,1.85c0.73,7.21,1.17,14.46,1.7,21.69
          C4850.83,2032.14,4850.54,2032.15,4850.25,2032.15z"
      />
      <path
        class="st1"
        d="M1426.34,1002.02c4.56,0.24,8.26-2.13,12.12-4c0,0-0.13-0.13-0.13-0.13c-0.08,4.72,2.41,8.65,3.66,12.86
          c1.64,5.48-7.79,2.23-9.18,7.2c-0.52,1.87-1.73,3.55-2.22,5.42c-3.18,12.17-6.48,24.27-0.84,36.78c1.26,2.8,0.44,6.54,0.58,9.85
          l0.14-0.12c-5.49-2.49-7.95-7.07-7.77-12.77c0.39-12.5-1.78-25.07,2.56-37.5c1.88-5.39,0.89-11.78,1.2-17.72
          C1426.47,1001.89,1426.34,1002.02,1426.34,1002.02z"
      />
      <path
        class="st1"
        d="M4314.46,985.99c0.41-1.9,0.34-4.1,1.32-5.64c6.58-10.41,7.64-21.86,6.93-33.73c-0.19-3.24-0.25-6.5-0.16-9.74
          c0.23-8.85,0.27-8.85,11.92-10.85c0,0-0.09-0.12-0.09-0.12c-3.6,4.3-8.26,7.06-5.62,14.99c4.61,13.87-0.04,27.36-6.38,40.07
          c-1.64,3.3-4.51,4.63-8.01,4.93C4314.36,985.9,4314.46,985.99,4314.46,985.99z"
      />
      <path
        class="st1"
        d="M4358.33,926.03c6.9,5.53,7.82,13.23,7.86,21.32c0.08,16.55-0.9,32.8-11.83,46.53l0.12,0.13
          c0.1-3.26-0.63-6.84,0.46-9.74c4.74-12.64,3.73-25.73,3.77-38.75c0.02-6.54-0.15-13.08-0.23-19.62L4358.33,926.03z"
      />
      <path
        class="st1"
        d="M1546.34,1105.91c-7.21,11.51-19.84,13-31.2,15.24c-9.6,1.89-19.87,0.56-29.84,0.29
          c-5-0.14-8.73-2.88-10.83-7.56c0,0-0.12,0.13-0.12,0.13c5.23,0.09,10.46-0.11,15.49,1.9c6.98,2.8,13.93,2.38,21.1,0.17
          c8.08-2.49,16.43-3.93,24.13-7.86c3.31-1.69,7.56-1.53,11.38-2.21L1546.34,1105.91z"
      />
      <path
        class="st1"
        d="M2494.33,1025.88c-0.65,1.85-0.73,4.59-2.03,5.41c-15.92,9.99-41.03,8.23-55.93-3.9
          c-1.28-1.04-1.3-3.62-1.91-5.48c0,0-0.1,0.1-0.1,0.1c2.48,0.68,5.33,0.81,7.39,2.14c10.49,6.8,22.17,6.83,33.84,5.88
          c6.35-0.52,12.58-2.62,18.86-4.01C2494.45,1026.02,2494.33,1025.88,2494.33,1025.88z"
      />
      <path
        class="st1"
        d="M1570.36,1010.02c3.62,2.25,5.63,6.06,6.17,9.74c3.05,20.9,4.41,41.74-6.2,61.31
          c-1.74,3.21-4.52,4.55-7.99,4.81c0,0,0.13,0.12,0.13,0.12c1.82-4.89,3.48-9.85,5.5-14.65c6.51-15.53,9.51-31.2,3.79-47.81
          c-1.45-4.19-0.9-9.07-1.27-13.64C1570.48,1009.9,1570.36,1010.02,1570.36,1010.02z"
      />
      <path
        class="st1"
        d="M4318.44,885.9c-2.62-0.28-5.43-0.04-7.81-0.94c-6.53-2.47-8.49-5.86-6.94-12.09
          c1.9-7.64,4.03-15.24,6.46-22.73c2.92-8.96,6.22-9.69,14.14-3.44c0.96,0.76,1.37,2.2,2.03,3.32c0,0,0.12-0.14,0.12-0.14
          c-3.9,0.23-9.11-0.95-10.71,3.36c-4.12,11.11-8.44,22.69,2.62,32.77L4318.44,885.9z"
      />
      <path
        class="st1"
        d="M2426.45,1013.89c-2.59-0.38-5.4-0.7-6.77-3.19c-9.55-17.32-13.84-34.82-1.23-52.68c0,0-0.12-0.13-0.12-0.13
          c-1.03,19.23-0.92,38.3,8.02,56.1L2426.45,1013.89z"
      />
      <path
        class="st1"
        d="M4366.34,901.89c-2.43,4.28-7.35,4.98-10.97,7.55c-3.27,2.32-1.87,3.84,0.38,5.77
          c3.39,2.91,2.43,7.04,2.58,10.82c0,0,0.15-0.13,0.15-0.13c-6.1-4.41-13.14-4.1-20.13-4.03l0.12,0.12c5.34-3.89,6-7.96,0.72-12.55
          c-0.72-0.63-0.51-2.32-0.73-3.53c0,0-0.14,0.12-0.14,0.12c3.77-0.48,7.81-0.27,11.24-1.6c5.55-2.16,11.12-2.59,16.89-2.41
          L4366.34,901.89z"
      />
      <path
        class="st1"
        d="M2514.35,954.02c0.51,0.41,1.17,0.72,1.49,1.25c10.48,17.06,8.73,34.56,1.33,52.01
          c-1.14,2.69-4.48,4.44-6.8,6.62l0.1,0.1c-0.38-5.35,1.15-10.2,3.66-14.86c5.19-9.64,6.08-19.68,2.16-29.95
          c-1.91-5-1.83-10.13-1.8-15.31L2514.35,954.02z"
      />
      <path
        class="st1"
        d="M4222.34,929.91c-4.61,16.45-4.77,32.55,3.18,48.24c1.11,2.19,0.58,5.22,0.81,7.86c0,0,0.13-0.13,0.13-0.13
          c-5.3-2.45-6.71-7.34-8.72-12.34c-4.6-11.48-3.48-23.19-2.52-34.89c0.33-4.06,3.35-6.98,7.26-8.61
          C4222.49,930.04,4222.34,929.91,4222.34,929.91z"
      />
      <path
        class="st1"
        d="M4790.37,1666.02c2.55-0.27,5.35-0.25,5.8-3.7c0.31-2.33-1.63-6.02,2.34-6.2c2.94-0.13,3.41,3.34,4.16,5.82
          c0.57,1.88,1.49,3.65,2.05,5.53c2.13,7.07,0.39,9.61-7.1,10.21c-2.61,0.21-5.26,0.19-7.87,0.02c-1.11-0.07-2.18-0.8-3.2-1.2
          c1.31-3.55,2.62-7.09,3.93-10.63C4790.49,1665.87,4790.37,1666.02,4790.37,1666.02z"
      />
      <path
        class="st1"
        d="M3598.37,962c3.32-0.11,6.43-0.66,8.08-4.08l-0.11,0.09c1.13,0.65,2.59,1.03,3.35,1.98
          c6.83,8.49,5.01,15.21-4.69,17.26c-2.54,0.53-5.24,0.48-7.85,0.39c-1.12-0.04-2.8-0.58-3.21-1.4c-1.08-2.17,0.46-3.9,2.24-4.65
          c5.42-2.27,3.6-5.98,2.27-9.69L3598.37,962z"
      />
      <path
        class="st1"
        d="M2442.47,934.01c6.58-8.07,15.72-7.99,24.92-7.98c9.8,0.01,19.7-0.53,26.95,7.98c0,0,0.14-0.14,0.14-0.14
          c-17.37-3.69-34.74-4.02-52.11,0.02L2442.47,934.01z"
      />
      <path
        class="st1"
        d="M1478.48,970.02c2.23-1.24,4.42-3.46,6.7-3.55c10.55-0.43,21.12-0.47,31.68-0.26
          c6.79,0.14,12.91,2.38,17.49,7.79c0,0,0.12-0.12,0.12-0.11c-18.56-3.27-37.23-5.06-56.1-4
          C1478.36,969.89,1478.48,970.02,1478.48,970.02z"
      />
      <path
        class="st1"
        d="M4186.34,921.92c-0.97,14.82-5.56,29.16-5.79,44.18c-2.73-10.24-2.51-20.58-1.48-30.78
          c0.51-5.04,2.58-10.18,7.42-13.28C4186.49,922.04,4186.34,921.92,4186.34,921.92z"
      />
      <path
        class="st1"
        d="M4338.47,905.92c-7.83-5.52-14.63-12.07-20.03-20.02c0,0-0.08,0.11-0.08,0.11
          c15.46,2.42,17.67,4.64,19.96,20.02L4338.47,905.92z"
      />
      <path
        class="st2"
        d="M2252.42,2881.72c-1.61,3.5-3.22,8-7.55,7.64c-5.59-0.46-11.52-1.91-13.06-8.95
          C2238.79,2879.15,2245.6,2880.53,2252.42,2881.72z"
      />
      <path
        class="st1"
        d="M1438.46,998.02c1.43-9.23,6.72-14.59,15.97-16.01c0,0-0.09-0.11-0.09-0.11c-1.32,9.35-6.61,14.74-16.01,15.99
          L1438.46,998.02z"
      />
      <path
        class="st2"
        d="M1882.3,2881.02c-1.68,1.8-3.05,4.1-5.08,5.32c-8.63,5.18-9.24,4.9-16.28-4.75
          C1868.05,2881.39,1875.17,2881.21,1882.3,2881.02z"
      />
      <path
        class="st1"
        d="M4370.37,886.01c1.13,0.68,2.54,1.12,3.34,2.07c4.91,5.82,4.55,9.04-1.6,12.56c-1.63,0.93-3.83,0.86-5.77,1.24
          c0,0,0.12,0.13,0.12,0.13c2.9-4.98,4.69-10.24,4.01-16.12L4370.37,886.01z"
      />
      <path
        class="st1"
        d="M4790.49,1665.87c-5.23-0.24-10.76,1.09-15.37-2.55c-0.83-0.66-1.78-2.4-1.47-3c0.48-0.9,2.16-1.87,3.1-1.69
          c5.25,1.05,10.15,2.94,13.62,7.38C4790.37,1666.02,4790.49,1665.87,4790.49,1665.87z"
      />
      <path
        class="st1"
        d="M4194.34,901.9c0.26,7.84-1.94,14.7-8,20.01c0,0,0.15,0.12,0.15,0.12c-0.17-7.8-0.94-15.83,7.97-20.02
          C4194.45,902.02,4194.34,901.9,4194.34,901.9z"
      />
      <path
        class="st1"
        d="M4676.05,1654.46c1.2,0.51,3.37,1.07,3.34,1.46c-0.3,3.98-3.38,5.07-6.68,5.41c-1.09,0.11-3.31-0.94-3.28-1.34
          C4669.71,1656,4672.86,1655.13,4676.05,1654.46z"
      />
      <path
        class="st1"
        d="M3598.46,961.9c-6.87,0.41-12.84-0.98-16-7.99l-0.1,0.1c6.87-0.41,12.83,1.01,16.01,7.99
          C3598.37,962,3598.46,961.9,3598.46,961.9z"
      />
      <path
        class="st1"
        d="M4756.09,1650.52c3.39,0.26,6.48,1.14,7.46,4.92c0.1,0.38-1.78,1.8-2.76,1.82c-3.45,0.07-6.51-1.02-7.5-4.78
          C4753.19,1652.11,4755.08,1651.21,4756.09,1650.52z"
      />
      <path
        class="st1"
        d="M4699.58,1648.8c-0.42,3.34-6.8,5.86-9.6,3.74c-0.4-0.3-0.77-0.99-0.73-1.46c0.34-3.33,6.78-5.86,9.56-3.76
          C4699.21,1647.63,4699.33,1648.3,4699.58,1648.8z"
      />
      <path
        class="st1"
        d="M4736.09,1642.52c3.4,0.26,6.5,1.15,7.49,4.93c0.1,0.37-1.8,1.8-2.78,1.82c-3.46,0.07-6.54-0.99-7.52-4.78
          C4733.18,1644.11,4735.08,1643.21,4736.09,1642.52z"
      />
      <path
        class="st1"
        d="M4656.22,1662.33c1.43,0.76,2.53,1.34,3.62,1.92c-0.7,1.07-1.16,2.54-2.15,3.13
          c-1.61,0.97-3.53,1.68-5.38,1.88c-1.02,0.11-2.18-1.05-3.28-1.63c0.7-1.07,1.17-2.5,2.15-3.14
          C4652.76,1663.46,4654.67,1662.96,4656.22,1662.33z"
      />
      <path
        class="st1"
        d="M4186.34,986.02c7.12,3.1,8.32,9.16,8.01,15.98c0,0,0.11-0.11,0.11-0.11c-7.08-3.12-8.26-9.18-7.99-15.97
          L4186.34,986.02z"
      />
      <path
        class="st1"
        d="M3542.36,930.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3542.36,930.01z"
      />
      <path
        class="st1"
        d="M3522.36,918.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3522.36,918.01z"
      />
      <path
        class="st1"
        d="M3502.36,906.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3502.36,906.01z"
      />
      <path
        class="st1"
        d="M3462.36,882.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3462.36,882.01z"
      />
      <path
        class="st1"
        d="M3442.36,870.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3442.36,870.01z"
      />
      <path
        class="st1"
        d="M3432.88,862.44c-0.93,0.68-1.86,1.94-2.82,1.95c-2.96,0.04-5.8-3.15-4.44-4.71c0.7-0.8,2.34-1.16,3.51-1.08
          C3431.32,858.75,3432.78,860.03,3432.88,862.44z"
      />
      <path
        class="st1"
        d="M1538.36,978.02c9.5,0.52,9.5,0.52,11.99,7.98c0,0,0.1-0.09,0.1-0.09c-5.65-0.19-9.66-2.83-12-7.99
          L1538.36,978.02z"
      />
      <path
        class="st1"
        d="M3562.36,942.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3562.36,942.01z"
      />
      <path
        class="st1"
        d="M3482.36,894.01c3.16,0.12,6.55-0.52,9.16,1.82c0.68,0.61,1.06,2.44,0.64,3.18c-0.45,0.8-2.17,1.56-3.04,1.31
          c-3.25-0.93-5.35-3.33-6.66-6.41L3482.36,894.01z"
      />
      <path
        class="st1"
        d="M2494.34,934.01c5.02,0.3,7.9,2.75,8.02,7.98c0,0,0.09-0.09,0.09-0.09c-5.04-0.31-7.95-2.74-7.96-8.03
          L2494.34,934.01z"
      />
      <path
        class="st1"
        d="M4214.47,882c0.08-5.24,2.98-7.66,7.98-7.98c0,0-0.12-0.15-0.12-0.15c-0.03,5.27-2.94,7.7-7.96,8.03
          C4214.37,881.9,4214.47,882,4214.47,882z"
      />
      <path
        class="st1"
        d="M4346.36,1005.92c-0.26,5.06-2.8,7.85-7.99,7.98c0,0,0.08,0.08,0.08,0.08c0.28-5.06,2.83-7.85,8.02-7.98
          L4346.36,1005.92z"
      />
      <path
        class="st1"
        d="M2506.37,946.01c5.22,0.12,7.68,2.99,7.98,8.02c0,0,0.14-0.14,0.14-0.14c-5.28-0.03-7.73-2.91-8.03-7.96
          L2506.37,946.01z"
      />
      <path
        class="st1"
        d="M4723.58,1640.08c-1.08,0.5-2.18,1.42-3.24,1.39c-1.04-0.04-2.04-1.05-3.06-1.63c1.08-0.5,2.17-1.43,3.23-1.39
          C4721.56,1638.48,4722.56,1639.49,4723.58,1640.08z"
      />
      <path
        class="st1"
        d="M1434.45,1081.9c-4.78-2.86-3.94-7.58-3.97-12.02c0,0-0.14,0.12-0.14,0.12c4.6,2.91,3.98,7.57,4,12.01
          L1434.45,1081.9z"
      />
      <path
        class="st1"
        d="M4234.37,869.89c-2.94,4.59-7.58,4.02-12.04,3.98c0,0,0.12,0.15,0.12,0.15c2.91-4.66,7.57-4.04,12.04-3.98
          C4234.49,870.04,4234.37,869.89,4234.37,869.89z"
      />
      <path
        class="st6"
        d="M1537.34,2197.11c0.71-2.42,1.36-4.86,2.16-7.25c0.13-0.39,0.98-0.9,1.29-0.78c0.54,0.21,1.19,0.78,1.31,1.31
          c0.78,3.32,0.37,6.31-2.79,8.34C1538.67,2198.17,1538.02,2197.63,1537.34,2197.11z"
      />
      <path
        class="st1"
        d="M4186.47,985.92c-2.4-1.3-3.73-3.35-3.49-6.04c0.09-0.98,1.23-1.86,1.89-2.78c0.33,0.5,0.89,0.97,0.94,1.5
          c0.25,2.47,0.37,4.95,0.53,7.43C4186.34,986.02,4186.47,985.92,4186.47,985.92z"
      />
      <path
        class="st1"
        d="M4362.37,874.01c3.3,1.68,4.01,4.65,3.97,7.99c0,0,0.12-0.09,0.12-0.09c-3.29-1.7-4.03-4.66-3.98-8.01
          L4362.37,874.01z"
      />
      <path
        class="st1"
        d="M1474.48,1113.88c-3.32-0.03-6.45-0.43-7.99-4.01c0,0-0.12,0.14-0.12,0.14c3.27,0.13,6.27,0.8,8,4
          L1474.48,1113.88z"
      />
      <path
        class="st1"
        d="M1466.49,1109.88c-3.34,0.01-6.43-0.47-8.01-3.99c0,0-0.11,0.13-0.11,0.13c3.31,0.06,6.24,0.85,8,4.01
          L1466.49,1109.88z"
      />
      <path
        class="st1"
        d="M4639.67,1671.47c-0.05,0.39,0.04,0.99-0.17,1.13c-0.39,0.25-1.06,0.46-1.42,0.29
          c-1.51-0.72-1.42-1.48,0.22-1.88C4638.7,1670.9,4639.21,1671.3,4639.67,1671.47z"
      />
      <path
        class="st1"
        d="M1458.48,1105.89c-3.33,0.01-6.3-0.71-8.01-3.98c0,0-0.12,0.12-0.12,0.11c3.37-0.08,6.32,0.69,8.01,3.99
          L1458.48,1105.89z"
      />
      <path
        class="st1"
        d="M1562.33,1085.88c0.07,3.36-0.54,6.39-3.96,8.03c0,0,0.09,0.09,0.09,0.09c-0.05-3.36,0.81-6.27,4.01-8
          L1562.33,1085.88z"
      />
      <path
        class="st1"
        d="M2502.35,1021.92c-1.64,3.42-4.68,4-8.02,3.97c0,0,0.12,0.13,0.12,0.13c1.74-3.19,4.65-4.03,8-4
          L2502.35,1021.92z"
      />
      <path
        class="st1"
        d="M1442.45,1093.89c-3.26-1.7-4-4.65-3.98-7.98c0,0-0.11,0.09-0.11,0.09c3.3,1.69,4.05,4.65,3.97,8.02
          L1442.45,1093.89z"
      />
      <path
        class="st1"
        d="M4350.35,997.91c0.01,3.34-0.63,6.35-3.99,8.01c0,0,0.11,0.09,0.11,0.09c-0.03-3.35,0.61-6.36,3.98-8.02
          L4350.35,997.91z"
      />
      <path
        class="st1"
        d="M4238.35,998.03c3.34-0.01,6.42,0.49,8.02,3.98c0,0,0.1-0.12,0.1-0.12c-3.32-0.02-6.27-0.78-7.99-3.99
          L4238.35,998.03z"
      />
      <path
        class="st1"
        d="M1562.37,998c3.42,1.63,3.98,4.67,3.97,8c0,0,0.1-0.08,0.1-0.08c-3.27-1.7-4.01-4.66-3.99-8L1562.37,998z"
      />
      <path
        class="st1"
        d="M1454.44,982c1.68-3.32,4.69-3.97,8.01-3.99c0,0-0.08-0.12-0.08-0.12c-1.66,3.37-4.66,4.04-8.02,4L1454.44,982
          z"
      />
      <path
        class="st1"
        d="M1462.45,978.01c1.62-3.41,4.68-3.98,8-4.01c0,0-0.07-0.11-0.07-0.11c-1.66,3.36-4.71,3.93-8,3.99
          L1462.45,978.01z"
      />
      <path
        class="st1"
        d="M1470.44,974.01c1.62-3.45,4.73-3.93,8.03-3.99c0,0-0.11-0.13-0.11-0.13c-1.59,3.48-4.7,3.93-7.99,4.01
          L1470.44,974.01z"
      />
      <path
        class="st1"
        d="M3606.46,957.92c-0.95-0.79-2.14-1.42-2.79-2.4c-0.93-1.39-0.31-2.54,1.31-2.73c0.29-0.03,0.89,0.92,1.01,1.48
          c0.24,1.22,0.26,2.49,0.37,3.74C3606.35,958.01,3606.46,957.92,3606.46,957.92z"
      />
      <path
        class="st1"
        d="M2422.47,954c-0.01-3.33,0.67-6.31,3.98-7.98c0,0-0.11-0.12-0.11-0.12c0.07,3.36-0.64,6.34-3.97,8.02
          L2422.47,954z"
      />
      <path
        class="st1"
        d="M2434.47,938c1.73-3.21,4.67-3.97,7.99-3.99c0,0-0.1-0.11-0.1-0.11c-1.6,3.48-4.67,4.02-8.02,3.98L2434.47,938
          z"
      />
      <path
        class="st1"
        d="M4226.35,921.92c-0.08,3.29-0.84,6.25-4.01,8c0,0,0.15,0.12,0.15,0.12c0-3.33,0.45-6.44,3.99-8.01
          C4226.47,922.02,4226.35,921.92,4226.35,921.92z"
      />
      <path
        class="st1"
        d="M4230.33,913.9c0.09,3.38-0.69,6.32-3.98,8.02c0,0,0.13,0.11,0.13,0.11c-0.05-3.35,0.7-6.31,3.98-8
          C4230.46,914.02,4230.33,913.9,4230.33,913.9z"
      />
      <path
        class="st1"
        d="M4242.35,905.91c-1.66,3.35-4.63,4.05-7.99,3.97l0.13,0.13c1.69-3.26,4.64-4.01,7.98-3.99
          C4242.45,906.02,4242.35,905.91,4242.35,905.91z"
      />
      <path
        class="st1"
        d="M4326.33,850.03c3.32,0.03,6.46,0.42,8,4c0,0,0.12-0.14,0.12-0.14c-3.28-0.11-6.26-0.81-8-4L4326.33,850.03z"
      />
      <path
        class="st1"
        d="M4334.33,854.03c3.33,0,6.42,0.5,8.02,3.98c0,0,0.1-0.11,0.1-0.11c-3.3-0.07-6.25-0.83-7.99-4.01
          L4334.33,854.03z"
      />
      <path
        class="st1"
        d="M4342.35,858.01c3.35-0.05,6.28,0.75,8,3.98l0.12-0.11c-3.36,0.05-6.37-0.59-8.02-3.98L4342.35,858.01z"
      />
      <path
        class="st1"
        d="M4306.45,994.01c1.33-1.33,2.67-2.67,4-4c0,0-0.09-0.1-0.09-0.1c-1.34,1.33-2.68,2.66-4.02,4L4306.45,994.01z"
      />
      <path
        class="st1"
        d="M4234.45,993.89c-1.33-1.33-2.66-2.66-3.98-3.98l-0.11,0.11c2.11,0.54,3.44,1.87,3.98,3.98
          C4234.34,994,4234.45,993.89,4234.45,993.89z"
      />
      <path
        class="st1"
        d="M1554.37,990.01c2.1,0.56,3.43,1.89,3.99,3.99l0.1-0.08c-1.33-1.33-2.67-2.67-4-4L1554.37,990.01z"
      />
      <path
        class="st1"
        d="M4310.45,990.01c1.34-1.34,2.67-2.68,4.01-4.02c0,0-0.1-0.1-0.1-0.09c-1.33,1.34-2.67,2.67-4,4.01
          C4310.36,989.91,4310.45,990.01,4310.45,990.01z"
      />
      <path
        class="st1"
        d="M4230.47,989.91c-1.34-1.34-2.67-2.69-4.01-4.03c0,0-0.13,0.13-0.13,0.13c1.34,1.34,2.69,2.67,4.03,4
          C4230.36,990.02,4230.47,989.91,4230.47,989.91z"
      />
      <path
        class="st1"
        d="M1550.35,985.99c2.12,0.56,3.45,1.9,4.01,4.01c0,0,0.08-0.09,0.08-0.09c-1.33-1.34-2.67-2.68-4-4.01
          L1550.35,985.99z"
      />
      <path
        class="st1"
        d="M4334.36,1017.92c-0.55,2.12-1.9,3.44-4,4c0,0,0.09,0.09,0.09,0.09c1.33-1.34,2.66-2.68,3.99-4.02
          C4334.44,1017.99,4334.36,1017.92,4334.36,1017.92z"
      />
      <path
        class="st1"
        d="M4218.45,1025.89c-0.59-0.51-1.19-1.02-1.78-1.53c0.35,0.08,0.83,0.06,1.02,0.28c0.31,0.37,0.44,0.9,0.65,1.37
          C4218.35,1026,4218.45,1025.89,4218.45,1025.89z"
      />
      <path
        class="st1"
        d="M4198.36,1006.02c0.55,0.56,1.09,1.11,1.64,1.67c-0.34-0.12-0.81-0.13-0.98-0.37
          c-0.28-0.4-0.37-0.93-0.54-1.41C4198.47,1005.9,4198.36,1006.02,4198.36,1006.02z"
      />
      <path
        class="st1"
        d="M1534.34,974c1.34,1.34,2.68,2.68,4.02,4.02c0,0,0.09-0.1,0.09-0.1c-2.04-0.64-3.5-1.85-3.98-4.03L1534.34,974
          z"
      />
      <path
        class="st1"
        d="M1566.34,1006c1.34,1.34,2.68,2.68,4.02,4.01c0,0,0.12-0.12,0.11-0.12c-2.18-0.48-3.4-1.93-4.03-3.98
          L1566.34,1006z"
      />
      <path
        class="st1"
        d="M2434.47,1021.91c-2.13-0.53-3.46-1.87-4-4c0,0-0.1,0.09-0.1,0.09c1.33,1.34,2.67,2.67,4,4.01L2434.47,1021.91
          z"
      />
      <path
        class="st1"
        d="M1394.34,966c1.34,1.34,2.68,2.68,4.02,4.02c0,0,0.11-0.12,0.11-0.11c-1.34-1.34-2.68-2.69-4.01-4.03
          L1394.34,966z"
      />
      <path
        class="st1"
        d="M4222.47,1029.91c-1.34-1.34-2.68-2.68-4.01-4.02c0,0-0.11,0.11-0.11,0.11c1.34,1.34,2.68,2.68,4.02,4.02
          C4222.36,1030.02,4222.47,1029.91,4222.47,1029.91z"
      />
      <path
        class="st1"
        d="M1438.47,1085.91c-2.14-0.53-3.4-1.96-4.03-4.01c0,0-0.11,0.11-0.11,0.11c2.15,0.51,3.39,1.95,4.02,3.99
          L1438.47,1085.91z"
      />
      <path
        class="st1"
        d="M1446.45,1097.89c-1.33-1.33-2.67-2.67-4-4c0,0-0.11,0.12-0.11,0.12c1.34,1.33,2.68,2.66,4.02,4
          L1446.45,1097.89z"
      />
      <path
        class="st1"
        d="M2418.45,958.02c1.34-1.34,2.68-2.68,4.01-4.02c0,0-0.1-0.08-0.1-0.08c-1.35,1.33-2.69,2.65-4.04,3.97
          C2418.33,957.9,2418.45,958.02,2418.45,958.02z"
      />
      <path
        class="st1"
        d="M3582.46,953.91c-0.59-0.56-1.17-1.11-1.76-1.67c0.37,0.1,0.89,0.08,1.07,0.31c0.31,0.39,0.4,0.96,0.58,1.45
          C3582.36,954.01,3582.46,953.91,3582.46,953.91z"
      />
      <path
        class="st1"
        d="M1558.37,1093.9c-1.33,1.33-2.67,2.67-4,4c0,0,0.08,0.09,0.08,0.09c0.56-2.12,1.89-3.45,4.01-4L1558.37,1093.9
          z"
      />
      <path
        class="st1"
        d="M4198.47,1005.9c-1.34-1.34-2.68-2.67-4.02-4.01c0,0-0.11,0.11-0.11,0.11c1.34,1.34,2.68,2.68,4.02,4.01
          C4198.36,1006.02,4198.47,1005.9,4198.47,1005.9z"
      />
      <path
        class="st1"
        d="M1450.47,1101.91c-1.34-1.34-2.68-2.68-4.02-4.02c0,0-0.1,0.12-0.1,0.12c1.33,1.34,2.66,2.68,4,4.02
          L1450.47,1101.91z"
      />
      <path
        class="st1"
        d="M2502.36,941.99c1.34,1.34,2.68,2.67,4.01,4.01c0,0,0.08-0.09,0.08-0.09c-1.34-1.34-2.67-2.67-4.01-4.01
          L2502.36,941.99z"
      />
      <path
        class="st1"
        d="M2426.45,946.02c1.33-1.33,2.67-2.67,4-4c0,0-0.09-0.11-0.09-0.11c-1.34,1.33-2.68,2.66-4.02,4L2426.45,946.02
          z"
      />
      <path
        class="st1"
        d="M3562.46,941.91c-0.58-0.56-1.17-1.11-1.76-1.67c0.37,0.1,0.89,0.08,1.07,0.32c0.31,0.39,0.4,0.96,0.58,1.45
          C3562.36,942.01,3562.46,941.91,3562.46,941.91z"
      />
      <path
        class="st1"
        d="M1554.37,1097.9c-1.33,1.33-2.67,2.67-4,4c0,0,0.08,0.09,0.08,0.09c0.55-2.11,1.89-3.45,4-4L1554.37,1097.9z"
      />
      <path
        class="st1"
        d="M2430.45,942.02c1.34-1.34,2.68-2.68,4.02-4.02c0,0-0.12-0.12-0.12-0.12c-1.33,1.34-2.66,2.68-3.99,4.02
          C2430.36,941.9,2430.45,942.02,2430.45,942.02z"
      />
      <path
        class="st1"
        d="M1550.37,1101.9c-1.34,1.34-2.68,2.67-4.02,4.01c0,0,0.12,0.11,0.12,0.11c1.33-1.34,2.66-2.69,3.99-4.03
          L1550.37,1101.9z"
      />
      <path
        class="st1"
        d="M2430.47,1017.91c-1.34-1.34-2.68-2.67-4.02-4.01c0,0-0.1,0.1-0.1,0.1c1.34,1.33,2.67,2.67,4.01,4
          L2430.47,1017.91z"
      />
      <path
        class="st1"
        d="M2506.35,1017.91c-1.33,1.33-2.66,2.67-4,4c0,0,0.1,0.1,0.1,0.1c0.56-2.11,1.9-3.44,4-4.01
          C2506.45,1018,2506.35,1017.91,2506.35,1017.91z"
      />
      <path
        class="st1"
        d="M2510.37,1013.9c-1.34,1.34-2.68,2.68-4.02,4.01c0,0,0.1,0.09,0.1,0.09c0.57-2.1,1.89-3.46,4.02-4
          L2510.37,1013.9z"
      />
      <path
        class="st1"
        d="M4334.46,926.03c1.34-1.34,2.67-2.69,4.01-4.03c0,0-0.12-0.12-0.12-0.12c-0.47,2.19-1.94,3.39-3.98,4.03
          L4334.46,926.03z"
      />
      <path
        class="st1"
        d="M4338.37,1013.91c-1.34,1.34-2.67,2.67-4.01,4.01c0,0,0.08,0.07,0.08,0.07c1.34-1.33,2.67-2.67,4.01-4
          L4338.37,1013.91z"
      />
      <path
        class="st1"
        d="M4210.35,885.91c-1.33,1.33-2.66,2.66-3.98,3.98l0.11,0.11c0.53-2.12,1.86-3.45,3.98-3.98
          C4210.46,886.02,4210.35,885.91,4210.35,885.91z"
      />
      <path
        class="st1"
        d="M4366.33,882.01c2.14,0.53,3.41,1.94,4.03,4c0,0,0.1-0.11,0.1-0.11c-1.34-1.33-2.68-2.66-4.02-3.99
          C4366.45,881.92,4366.33,882.01,4366.33,882.01z"
      />
      <path
        class="st1"
        d="M3522.46,917.91c-0.58-0.56-1.17-1.11-1.75-1.67c0.37,0.1,0.89,0.08,1.07,0.32c0.31,0.39,0.4,0.96,0.58,1.45
          C3522.36,918.01,3522.46,917.91,3522.46,917.91z"
      />
      <path
        class="st1"
        d="M4234.35,909.88c-1.34,1.34-2.68,2.68-4.02,4.02c0,0,0.12,0.12,0.12,0.13c1.34-1.34,2.68-2.68,4.02-4.02
          C4234.48,910,4234.35,909.88,4234.35,909.88z"
      />
      <path
        class="st1"
        d="M4210.46,886.02c1.34-1.34,2.67-2.68,4.01-4.02c0,0-0.1-0.1-0.1-0.1c-1.34,1.34-2.68,2.67-4.02,4.01
          C4210.35,885.91,4210.46,886.02,4210.46,886.02z"
      />
      <path
        class="st1"
        d="M3462.46,881.91c-0.59-0.56-1.17-1.11-1.76-1.67c0.37,0.1,0.89,0.08,1.07,0.31c0.31,0.39,0.4,0.96,0.58,1.45
          C3462.36,882.01,3462.46,881.91,3462.46,881.91z"
      />
      <path
        class="st1"
        d="M4246.35,901.87c-1.33,1.35-2.67,2.69-4,4.04c0,0,0.11,0.11,0.11,0.11c1.34-1.34,2.69-2.67,4.03-4.01
          C4246.48,902.01,4246.35,901.87,4246.35,901.87z"
      />
      <path
        class="st1"
        d="M4358.37,870.01c1.33,1.33,2.67,2.67,4,4c0,0,0.11-0.11,0.11-0.11c-1.34-1.33-2.68-2.66-4.02-3.99
          L4358.37,870.01z"
      />
      <path
        class="st1"
        d="M4354.37,866.01c1.33,1.33,2.67,2.67,4,4c0,0,0.08-0.1,0.08-0.1c-2.13-0.54-3.46-1.87-4-4
          C4354.45,865.91,4354.37,866.01,4354.37,866.01z"
      />
      <path
        class="st1"
        d="M3502.46,905.91c-0.59-0.56-1.17-1.11-1.76-1.67c0.37,0.1,0.89,0.08,1.07,0.31c0.31,0.39,0.4,0.96,0.58,1.45
          C3502.36,906.01,3502.46,905.91,3502.46,905.91z"
      />
      <path
        class="st1"
        d="M3442.46,869.91c-0.58-0.56-1.17-1.11-1.75-1.67c0.37,0.1,0.89,0.08,1.07,0.32c0.31,0.39,0.4,0.96,0.58,1.45
          C3442.36,870.01,3442.46,869.91,3442.46,869.91z"
      />
      <path
        class="st1"
        d="M4350.35,862c1.34,1.34,2.68,2.67,4.02,4.01c0,0,0.08-0.1,0.08-0.1c-1.33-1.34-2.66-2.68-3.99-4.03
          L4350.35,862z"
      />
      <path
        class="st1"
        d="M4198.35,897.91c-0.59,2.08-1.85,3.49-4.01,3.99c0,0,0.11,0.11,0.11,0.12c1.33-1.33,2.66-2.67,4-4.01
          L4198.35,897.91z"
      />
      <path
        class="st1"
        d="M4354.36,993.89c-1.34,1.34-2.67,2.68-4.01,4.03c0,0,0.09,0.08,0.09,0.08c0.63-2.05,1.87-3.49,4.04-3.97
          L4354.36,993.89z"
      />
      <path
        class="st1"
        d="M4198.45,898.01c0.58-2.08,1.84-3.49,4-4c0,0-0.1-0.1-0.1-0.1c-1.33,1.33-2.67,2.67-4,4
          C4198.35,897.91,4198.45,898.01,4198.45,898.01z"
      />
      <path
        class="st1"
        d="M4238.48,997.9c-1.34-1.34-2.69-2.67-4.03-4.01c0,0-0.11,0.11-0.11,0.11c1.34,1.34,2.67,2.68,4.01,4.03
          C4238.35,998.03,4238.48,997.9,4238.48,997.9z"
      />
      <path
        class="st1"
        d="M4206.36,889.89c-1.34,1.34-2.67,2.68-4.01,4.02c0,0,0.1,0.1,0.1,0.1c1.34-1.34,2.68-2.67,4.02-4.01
          C4206.47,890,4206.36,889.89,4206.36,889.89z"
      />
      <path
        class="st1"
        d="M3482.46,893.91c-0.58-0.56-1.17-1.11-1.75-1.67c0.37,0.1,0.89,0.08,1.07,0.32c0.31,0.39,0.4,0.96,0.58,1.45
          C3482.36,894.01,3482.46,893.91,3482.46,893.91z"
      />
      <path
        class="st1"
        d="M1558.35,994c2.12,0.55,3.43,1.92,4.02,4.01c0,0,0.08-0.09,0.08-0.09c-1.34-1.33-2.67-2.67-4.01-4L1558.35,994
          z"
      />
      <path
        class="st1"
        d="M3542.46,929.91c-0.59-0.56-1.17-1.11-1.76-1.67c0.37,0.1,0.89,0.08,1.07,0.31c0.31,0.39,0.4,0.96,0.58,1.45
          C3542.36,930.01,3542.46,929.91,3542.46,929.91z"
      />
      <path
        class="st5"
        d="M2356.53,3012.91c-8.39-12.64-16.8-25.27-25.18-37.92c-2.63-3.97-1.71-7.56,1.43-10.74
          c11.24-11.35,22.52-22.65,33.78-33.98c2.81-2.83,5.6-5.69,8.4-8.53c4.84-4.9,1.04-8.28-2.07-11.79c2.08-2.8,4.79-4.64,8.34-4.75
          c5.99-0.19,11.98-0.28,17.98-0.28c473.75-0.01,947.49-0.01,1421.24,0c5.99,0,12,0,17.98,0.37c4.6,0.28,7.11,3.19,7.5,7.75
          c0.23,2.65,0.28,5.32,0.27,7.98c-0.11,29.98-0.25,59.96-0.38,89.94c-5.33-0.1-10.66-0.28-15.99-0.28
          c-165.94-0.01-331.89-0.02-497.83,0c-23.13,0-21.04-1.88-21.05,21.91c-0.03,125.29-0.07,250.58,0.03,375.87
          c0.02,27.11-3.48,24.26,24.64,24.27c113.96,0.07,227.92,0.05,341.89-0.01c12.04-0.01,23.77,1.17,35.61,3.83
          c44.15,9.93,88.47,19.12,132.73,28.58c0.07,28.76,0.14,57.53,0.21,86.29c-8.66-0.11-17.33-0.31-25.99-0.31
          c-370.57-0.02-741.14-0.01-1111.71-0.06c-8.63,0-17.26-0.72-25.9-1.1c0.05-8,0.09-15.99,0.15-23.99
          c0.13-16.78-1.19-15.13,15.9-19.29c17.61-4.29,34.32-10.59,50.07-19.5c2.9-1.64,5.78-3.33,8.77-4.79c3.29-1.61,6.57-1.18,9.24,1.27
          c4.91,4.5,9.42,9.45,14.48,13.78c7.75,6.63,17.84,6.52,25.07-0.42c9.61-9.23,18.98-18.72,28.26-28.29
          c5.42-5.59,5.07-6.93-0.44-13.29c-6.47-7.48-15.52-12.67-19.63-22.21c9.92-0.15,19.84-0.31,29.75-0.44
          c5.29-0.07,10.58-0.08,15.87-0.13c4.65-0.05,7.47-2.38,7.8-7.01c0.33-4.61,0.23-9.25,0.26-13.88c0.13-21.83,0.24-43.65,0.36-65.48
          c4.61-0.27,9.22-0.63,13.83-0.79c4.81-0.16,7.25-2.62,7.31-7.33c0.12-9.24,0.17-18.49,0.17-27.73c0-22.25-0.02-22.25-21.43-24.48
          c-0.08-85.33-0.2-170.66-0.2-255.99c0-19.98-0.16-19.3-17.98-19.29c-159.33,0.06-318.66,0.07-477.99,0.15
          C2366.85,3010.82,2361.41,3009.9,2356.53,3012.91z"
      />
      <path
        class="st3"
        d="M2868.27,3285.95c21.41,2.22,21.43,2.22,21.43,24.48c0,9.24-0.05,18.49-0.17,27.73
          c-0.06,4.71-2.5,7.16-7.31,7.33c-4.62,0.16-9.22,0.52-13.83,0.79c-3.99-0.18-7.98-0.64-11.96-0.48
          c-8.16,0.32-10.17,2.01-12.71,9.91c-0.41,1.26-0.7,2.56-0.98,3.86c-4.86,22.29-12.86,43.31-25.79,62.24
          c-2.48,3.63-2.24,7.51-2.61,11.42c4.11,9.55,13.17,14.74,19.63,22.21c5.5,6.36,5.85,7.71,0.44,13.29
          c-9.28,9.57-18.64,19.06-28.26,28.29c-7.24,6.94-17.33,7.06-25.07,0.42c-5.05-4.33-9.57-9.27-14.48-13.78
          c-2.67-2.45-5.96-2.87-9.24-1.27c-2.99,1.46-5.87,3.15-8.77,4.79c-15.75,8.91-32.46,15.21-50.07,19.5
          c-17.09,4.16-15.77,2.52-15.9,19.29c-0.06,8-0.1,15.99-0.15,23.99c-19.06-0.27-38.12-0.54-57.17-0.8
          c0.04-9.1,0.25-18.22,0.05-27.32c-0.22-9.68-2.47-12.51-12-14.64c-3.71-0.83-7.72-0.35-11.59-0.47
          c-15.03-2.86-29.22-8.22-43.15-14.4c-8.41-3.73-15.89-11.76-25.05-10.95c-10.31,0.91-14.72,12.44-22.66,18.17
          c-4.88-0.49-8.78-2.79-12.16-6.26c-4.18-4.3-8.52-8.44-12.73-12.71c-20.21-20.48-20.75-16.6-1.02-36.43
          c0.94-0.94,1.91-1.86,2.81-2.84c7.4-8.07,7.72-8.71,2.05-19.18c-11.44-21.13-22.25-42.52-26.74-66.36
          c-1.66-8.83-7.11-10.19-14.42-9.63c-10.45-0.61-20.89-1.23-31.34-1.84c0-18.9,0.01-37.81,0.01-56.71
          c10.69-0.63,21.39-1.25,32.08-1.88c7.53,0.87,12.94-1.61,14.82-9.5c0.76-3.21,1.01-6.6,2.15-9.67
          c4.58-12.39,8.85-24.95,14.35-36.94c4.37-9.53,10.37-18.31,15.64-27.43c-4.67-10.66-14.8-16.55-21.95-25.04
          c-4.97-5.91-5.27-8.09-0.14-13.59c10.37-11.13,20.94-22.06,31.42-33.08c4.44,0.17,6.85,3.52,9.53,6.28
          c17.69,18.14,16.98,16.51,38.26,5.71c15.7-7.97,31.97-14.85,50.11-15.09l0.92,0.46c0,0,0.77-0.69,0.77-0.69
          c1.57-0.43,3.15-0.87,4.72-1.3c1.31-0.05,2.63,0,3.93-0.15c10.84-1.28,11.3-1.56,11.53-11.45c0.24-10.49,0.09-20.99,0.1-31.48
          c15.8-0.28,31.61-0.78,47.41-0.74c7.31,0.02,8.98,2.02,9.27,9.77c0.32,8.56,0.19,17.14,0.29,25.71c0.05,4.39,1.83,7.52,6.33,8.78
          c3.17,0.89,6.22,2.29,9.42,2.96c18.88,3.94,36.42,11.17,52.93,21.03c3.43,2.05,7.05,3.59,11.16,3.54c3.47,4.91,7.9,4.96,11.77,1.18
          c8.01-7.82,15.57-16.12,23.31-24.22c16.25,8.55,26.31,23.78,38.95,36.2c2.18,2.15,1.39,5.23-0.49,7.24
          c-6.82,7.28-13.82,14.4-20.86,21.46c-3.85,3.86-4.32,7.4-1.18,12.27c12.66,19.65,22.69,40.52,28.16,63.44
          c3.58,15.01,3.84,14.98,18.23,15.07C2862.96,3286.46,2865.62,3286.12,2868.27,3285.95z M2667.83,3415.27
          c9.33,5.01,16.59-1.56,24.28-4.65c64.46-25.92,78.32-103.19,45.3-149.27c-14.26-19.9-32.4-34.99-56.7-41.44
          c-3.34-0.89-6.46-1.42-9.28,1.16c-4.14,0.97-7.77-1-11.62-1.87c-10.5-2.37-21.14-2.65-31.55,0.2
          c-31.46,8.64-53.07,28.97-67.35,57.71c-7.03,14.14-7.22,29.36-7.24,44.74c-0.07,41.35,22.29,68.56,56.41,87
          c16.37,8.84,34.85,13.2,53.84,6.07C2665.03,3414.51,2666.52,3415.13,2667.83,3415.27z"
      />
      <path
        class="st5"
        d="M2510.9,3499.56c7.95-5.74,12.35-17.27,22.66-18.18c9.16-0.81,16.64,7.22,25.05,10.95
          c13.94,6.18,28.12,11.54,43.16,14.4c4.91,3.38,8.96,6.81,8.52,13.95c-0.6,9.73-0.03,19.53,0.04,29.31
          c-6.61,0.38-13.21,1.08-19.82,1.08c-108.25,0.07-216.49,0.07-324.74,0c-6.61,0-13.22-0.68-19.83-1.05c0.05-18.46,0-36.92,0.2-55.38
          c0.1-8.85,1.04-9.8,9.81-11.95c12.78-3.13,25.58-6.17,38.38-9.25c8.59,0.9,16.16-3.05,24.11-5.13c1.95-0.23,3.94-0.3,5.85-0.71
          c50.2-10.85,100.39-21.73,150.59-32.61c-2.49,8.81-10.2,13.48-15.77,19.84c-5.44,6.2-5.81,8.08-0.72,13.34
          c9.61,9.94,19.31,19.81,29.35,29.32C2494.65,3504.05,2502.63,3504.94,2510.9,3499.56z"
      />
      <path
        class="st4"
        d="M4578.53,2993.58c43.95,0.1,87.91,0.16,131.86,0.32c20,0.07,20.05,0.15,20.17,19.57
          c0.09,13.99,0.18,27.98-0.04,41.96c-0.22,13.18-1.21,14.11-15.49,14.24c-20.64,0.18-41.29,0.04-61.94,0.06
          c-8.66,0.01-17.32,0.04-25.97,0.12c-4.38,0.04-7.62,1.76-9.08,6.22c-1.86,5.68-4.22,11.22-5.79,16.98
          c-7.14,26.2-14.07,52.46-21.07,78.7c-0.34,1.28-0.65,2.57-0.84,3.88c-0.77,5.42,1.95,9.44,7.19,9.81
          c5.97,0.42,11.97,0.28,17.96,0.39c3.33,0.06,6.67-0.04,9.98,0.23c6.21,0.5,8.41,2.48,8.74,8.55c0.36,6.64,0.25,13.31,0.26,19.97
          c0.02,11.99,0.17,23.98-0.11,35.96c-0.21,8.69-2.16,10.78-10.6,10.95c-14.65,0.29-29.3,0.1-43.96,0.11
          c-9.28-6.54-19.57-3.02-29.51-2.99c-8.19,0.23-16.37,0.47-24.56,0.69c-6.25-0.33-12.51-0.64-18.76-0.96
          c-2.6-2.37-3.49-5.43-3.5-8.81c-0.02-18.34-0.03-36.67-0.02-55.01c0-3.37,0.81-6.46,3.35-8.89c6.35-0.24,12.7-0.49,19.04-0.73
          c8.13,0.16,16.27,0.32,24.4,0.47c7.21,0.16,14.43,0.52,21.64,0.4c4.51-0.08,7.36-2.86,7.61-7.37c0.43-7.86,0.47-15.74,0.68-23.61
          c6.72-1.37,7.04-7.41,8.41-12.25c5.02-17.73,9.66-35.56,14.45-53.35c0.86-3.18,1.96-6.3,2.68-9.51c1.32-5.87-0.87-9.31-6.5-9.68
          c-6.6-0.43-13.23-0.35-19.84-0.5c-9.7-1.53-19.39-1.51-29.07,0.2c-2.61-3.31-6.29-3.52-10.08-3.57c-4.34-0.06-8.68-0.24-13.01-0.37
          c0.05-23.83,0.09-47.67,0.13-71.5c7.79-0.24,15.58-0.48,23.38-0.72C4560,2993.56,4569.26,2993.57,4578.53,2993.58z"
      />
      <path
        class="st0"
        d="M2294.32,3473.46c-12.79,3.08-25.6,6.12-38.38,9.25c-8.76,2.15-9.71,3.1-9.81,11.95
          c-0.2,18.46-0.15,36.92-0.2,55.38c-43.65-0.05-87.31-0.09-130.96-0.14c-0.07-19.3-0.3-38.6-0.14-57.9
          c0.06-7.7,2.67-10.78,10.07-12.63c7.71-1.93,15.61-3.1,23.31-5.05c29.14-7.38,57.08-17.71,82.59-33.95
          c1.68-1.07,3.44-2.02,5.2-2.96c8.93-4.78,10.56-4.76,17.57,1.86c6.77,6.39,13.52,12.86,19.75,19.77
          C2279.24,3465.6,2286.11,3470.42,2294.32,3473.46z"
      />
      <path
        class="st5"
        d="M1666.08,3465.78c13.84,0.11,27.68,0.22,41.52,0.33c-2.27,4.98-1.57,10.16,3.66,11.54
          c14.51,3.82,26.92,13.99,42.6,14c2.62,0,5.37-1,7.63,0.96c8.93,7.74,20.71,8.55,31.06,12.82c12.95,5.34,25.74,10.82,36.81,19.63
          c4.21,3.35,7.95,7.04,10.37,11.94c1.29,2.61-0.16,5.17-3.3,5.41c-1.33,0.1-2.66,0.12-3.99,0.12c-65.87-0.06-131.73-0.13-197.6-0.21
          c-2.66,0-5.32-0.03-7.98-0.16c-4.63-0.23-7.23-2.73-7.88-7.3c-1.5-10.62-1.43-21.33,0.15-31.84c1.47-9.81,4.39-19.4,6.67-29.08
          c3.17,0.71,6.34,1.41,9.51,2.12c6.98,1.57,13.89,3.61,20.95,4.59c6.58,0.92,8.22-1,9.58-9.01
          C1666.17,3469.73,1666.01,3467.74,1666.08,3465.78z"
      />
      <path
        class="st5"
        d="M1398.42,3465.83c7.99-0.17,15.97-0.47,23.96-0.47c7.29-0.01,14.69-0.53,17.31,9.24
          c0.67,2.51,4.77,5.51,7.56,5.81c14.62,1.57,26.13,13.49,41.49,12.16c21.46,8.81,44.29,14.44,64.15,26.96
          c8.24,5.2,16.38,10.83,19.72,20.82c-5.93,3.89-11.35,2.45-16.45,2.46c-29.97,0.05-59.93-0.23-89.9-0.22
          c-6.62,0-13.25,0.7-19.87,1.07c-18.65-1.52-37.32-1.6-55.99-0.39c-2.67,0.02-5.34,0.04-8.01,0.06c-7.82-0.4-15.65-0.79-23.47-1.2
          c-4.58-0.24-7.22-2.72-7.71-7.21c-1.28-11.76-1.11-23.5,0.52-35.23c0.63-4.51,0.98-9.06,1.46-13.59c1.64-3.85,3.28-7.7,4.92-11.55
          c4.44,0.83,8.91,1.54,13.31,2.54c4.43,1,8.74,2.66,13.21,3.39c9.95,1.64,12.03-0.13,13.68-10.76
          C1398.52,3468.46,1398.39,3467.13,1398.42,3465.83z"
      />
      <path
        class="st2"
        d="M4550.29,3258.62c9.94-0.04,20.23-3.55,29.51,2.99c-0.28,15.85-0.74,31.7-0.83,47.55
          c-0.14,25.79-0.13,51.58,0.02,77.37c0.04,6.57,0.83,13.14,1.27,19.71c-0.36,7.29-1.02,14.58-1.04,21.87
          c-0.11,34.57-0.03,69.14-0.08,103.72c-0.01,5.3-0.1,10.63-0.65,15.9c-0.18,1.72-1.6,4.28-3.01,4.73
          c-7.08,2.28-14.42,2.19-21.52,0.06c-1.38-0.42-2.82-3.03-2.97-4.73c-0.51-5.94-0.61-11.93-0.61-17.9
          C4550.34,3439.46,4550.32,3349.04,4550.29,3258.62z"
      />
      <path
        class="st0"
        d="M1930.78,3466.77c18.44,4.79,36.93,9.41,55.31,14.45c7.73,2.12,8.11,2.78,8.18,11.23
          c0.17,19.15,0,38.3-0.03,57.45c-31.79,0.06-63.59,0.14-95.38,0.13c-3.94,0-7.93-0.23-11.8-0.89c-2.03-0.35-3.37-2.63-1.99-4.41
          c1.97-2.55,4.7-4.51,7.08-6.77c13.05-12.4,23.66-26.56,31.89-42.65C1928.69,3486.22,1931.44,3476.97,1930.78,3466.77z"
      />
      <path
        class="st0"
        d="M4506.98,3258.36c6.25,0.32,12.51,0.63,18.76,0.96c0.31,6.65,0.89,13.28,0.9,19.92
          c0.06,83.87,0.04,167.74,0.04,251.61c0,0.67,0,1.33,0,2c0.14,20.95,0.5,21.16-20.74,21.03c-0.18-6.65-0.5-13.31-0.5-19.96
          c-0.04-84.59-0.05-169.17,0.01-253.76C4505.45,3272.87,4504.51,3265.48,4506.98,3258.36z"
      />
      <path
        class="st3"
        d="M4749.1,3415.87c-0.38-0.93-1.47-2.27-1.19-3.18c1.64-5.37,6.07-8.64,10.17-11.89
          c44.69-35.47,91.04-68.65,139.13-99.36c12.29-7.85,25.24-14.67,37.93-21.89c10.38-5.91,20.62-12.12,31.27-17.5
          c49.38-24.94,101.15-43.25,155.2-55.07c1.73-0.38,3.86,0.2,5.54,0.94c0.71,0.31,1.35,2.83,0.93,3.25c-1.33,1.31-3.05,2.5-4.83,3.03
          c-8.89,2.64-17.72,5.63-26.79,7.43c-25.53,5.08-49.6,14.4-73.58,24.14c-58.75,23.87-113.01,55.85-165.32,91.35
          c-27.98,18.98-55.29,38.88-81.15,60.71c-7.09,5.98-14.44,11.65-21.73,17.38C4753.1,3416.41,4751.22,3417.01,4749.1,3415.87z"
      />
      <path
        class="st0"
        d="M1699.25,3207.12c4.64-0.23,9.3-0.79,13.92-0.62c8.58,0.33,10.78,2,12.46,9.82
          c2.36,11.02,5.5,21.96,5.08,33.41c-7.22,4.48-15.29,4.22-23.32,4.1C1703.09,3238.54,1696.99,3223.56,1699.25,3207.12z"
      />
      <path
        class="st3"
        d="M4676.92,3477.06c0.05,0.65,0.36,1.42,0.12,1.94c-0.82,1.76-1.51,3.75-2.83,5.08
          c-20.21,20.31-40.5,40.53-60.79,60.76c-2.38,2.37-4.93,4.61-8.49,4.68c-1.18,0.02-2.43-0.74-3.52-1.35
          c-0.28-0.16-0.34-1.29-0.07-1.71c1.03-1.67,2.01-3.43,3.38-4.8c20.72-20.74,41.46-41.46,62.31-62.07c1.8-1.78,4.25-3.01,6.61-4.01
          C4674.38,3475.24,4675.8,3476.51,4676.92,3477.06z"
      />
      <path
        class="st2"
        d="M2610.32,3549.99c-0.07-9.77-0.64-19.58-0.04-29.31c0.44-7.15-3.61-10.58-8.52-13.95
          c3.87,0.12,7.87-0.35,11.59,0.48c9.53,2.13,11.78,4.97,12,14.64c0.21,9.1-0.01,18.22-0.05,27.32
          C2620.47,3552.43,2615.59,3554.83,2610.32,3549.99z"
      />
      <path
        class="st6"
        d="M1666.08,3465.78c-0.07,1.96,0.09,3.95-0.24,5.86c-1.37,8.01-3,9.93-9.58,9.01
          c-7.06-0.98-13.97-3.02-20.95-4.59c-3.17-0.71-6.34-1.41-9.51-2.12c-0.09-2.34-0.19-4.68-0.29-7.01
          C1639.03,3466.55,1652.55,3466.17,1666.08,3465.78z"
      />
      <path
        class="st3"
        d="M4713.08,3445.72c0,0.57,0.27,1.4-0.04,1.85c-6.7,9.74-15.19,17.72-24.65,24.71c-0.69,0.51-2.66,0.07-3.46-0.6
          c-0.79-0.67-1.53-2.66-1.12-3.3c6.53-9.88,15.2-17.69,24.58-24.75c0.68-0.51,2.38,0.18,3.52,0.55
          C4712.4,3444.34,4712.66,3445.13,4713.08,3445.72z"
      />
      <path
        class="st3"
        d="M4721.68,3440.46c-0.64-1.04-2.06-2.39-1.79-3.06c3.33-8.25,9.74-13.69,17.31-17.85
          c0.69-0.38,2.67,0.47,3.26,1.31c0.6,0.85,0.74,2.72,0.19,3.57c-3.98,6.17-9.31,11.09-15.22,15.37
          C4724.51,3440.46,4722.95,3440.26,4721.68,3440.46z"
      />
      <path
        class="st3"
        d="M1382.4,3543.35c2.67-0.02,5.34-0.04,8.01-0.06c-0.12,2.67-0.84,5.25-3.94,5.27
          C1383.4,3548.57,1382.57,3546.02,1382.4,3543.35z"
      />
      <path
        class="st0"
        d="M1950.23,2463.75c0-19.98,0.62-39.99-0.21-59.94c-0.56-13.52,7.82-21.37,21.03-25.09
          c2.5-0.7,5.29-0.53,7.94-0.53c36.64-0.03,73.28-0.03,109.92-0.01c2.66,0,5.37-0.09,7.97,0.37c12.53,2.22,18.8,9.56,21.95,22.26
          c3.25,13.13,3.38,26.3,3.34,39.6c-0.07,19.98-0.42,39.98,0.14,59.95c0.3,10.83-1.68,21.19-4.09,31.5
          c-2.81,12.03-8.77,16.9-20.68,18.1c-1.98,0.2-3.99,0.12-5.99,0.12c-37.97,0.01-75.94,0.03-113.91-0.02
          c-5.28-0.01-10.72,0.59-15.41-2.84c-7.29-5.34-12.33-11.56-12.12-21.51C1950.53,2505.06,1950.23,2484.41,1950.23,2463.75z"
      />
      <path
        class="st0"
        d="M1950.23,2208.24c0-19.97-0.18-39.94,0.06-59.9c0.18-14.92,7.69-23.47,22.09-25.9
          c1.95-0.33,3.98-0.31,5.97-0.31c37.27-0.02,74.54,0.05,111.82-0.08c6.21-0.02,11.88,1.39,17.13,4.47
          c6.64,3.91,9.97,9.44,11.54,17.45c3.74,19.13,3.23,38.32,3.3,57.56c0.05,13.31,0.54,26.65-0.15,39.93
          c-0.61,11.87-1.7,23.87-4.21,35.45c-1.92,8.86-8.68,14.75-17.94,16.74c-2.56,0.55-5.3,0.36-7.96,0.36
          c-38.6,0.02-77.21,0.01-115.81,0.01c-5.35,0-10.66,0-15.13-3.71c-6.36-5.27-11-11.08-10.83-20.18
          C1950.5,2249.51,1950.23,2228.87,1950.23,2208.24z"
      />
      <path
        class="st0"
        d="M2033.74,2038.01c-19.3,0-38.61,0.25-57.9-0.08c-16.97-0.29-25.44-8.85-25.54-26.08
          c-0.23-39.93-0.21-79.87-0.01-119.8c0.07-14.75,7.91-23.47,22.32-25.62c3.92-0.58,7.97-0.32,11.96-0.33
          c33.28-0.02,66.56-0.02,99.83,0c3.99,0,8.04-0.25,11.96,0.34c13.45,2.05,19.97,10.05,22.41,23.74c5.9,33.04,2.7,66.31,3.11,99.46
          c0.13,10.48-1.61,21.23-4.15,31.44c-2.18,8.77-8.79,14.83-18.14,16.52c-3.88,0.7-7.96,0.4-11.95,0.41
          c-17.97,0.03-35.94,0.01-53.91,0.01C2033.74,2038.02,2033.74,2038.02,2033.74,2038.01z"
      />
      <path
        class="st2"
        d="M1949.71,1695.42c-0.01-19.3-0.25-38.61,0.06-57.91c0.29-17.88,8.86-26.96,27.06-27.18
          c38.6-0.47,77.21-0.51,115.81,0.04c17.86,0.25,26.44,9.49,26.61,27.79c0.37,38.6,0.15,77.21,0.07,115.81
          c-0.01,5.97-0.16,11.97-4.05,17.14c-5.61,7.45-12.8,11.01-22.27,10.92c-23.96-0.24-47.92-0.05-71.88-0.05
          c-15.31,0-30.62-0.17-45.92,0.02c-8.67,0.11-15.33-3.24-20.82-9.8c-4.19-5.01-4.66-10.82-4.66-16.88
          C1949.72,1735.35,1949.72,1715.39,1949.71,1695.42z"
      />
      <path
        class="st2"
        d="M2360.41,1668.98c-54.59,0-109.18,0.01-163.77-0.01c-5.32,0-10.71,0.29-15.95-0.42
          c-9.32-1.27-16.11-8.6-16.76-17.18c-0.67-8.87,4.81-16.28,14.66-18.57c5.11-1.19,10.53-1.46,15.81-1.46
          c110.51-0.08,221.02-0.06,331.53-0.05c5.98,0,11.97-0.23,17.75,1.93c8.49,3.17,11.5,8.28,10.86,18.92
          c-0.5,8.4-4.7,14.38-12.49,15.83c-5.82,1.08-11.9,0.98-17.87,0.99C2469.59,1669.01,2415,1668.98,2360.41,1668.98z"
      />
      <path
        class="st0"
        d="M2360.6,2437.75c-56.54,0-113.07-0.24-169.61,0.11c-24.69,0.15-25.57-2.49-24.55-26.4
          c0.29-6.92,2.69-9,10.11-9.42c4.64-0.26,9.31-0.15,13.96-0.15c113.07-0.01,226.14-0.01,339.21-0.01c1.33,0,2.67-0.11,3.99,0.02
          c6.42,0.63,14.82-2.78,18.62,3.62c4.46,7.5,2.9,16.82,0.89,25.25c-0.98,4.14-4.77,6.32-9.07,6.58c-5.3,0.32-10.63,0.39-15.94,0.39
          C2472.34,2437.76,2416.47,2437.75,2360.6,2437.75z"
      />
      <path
        class="st0"
        d="M2360.26,2145.88c56.53,0,113.07-0.02,169.6,0.02c5.97,0,11.96,0.14,17.91,0.6c3.76,0.29,5.79,3.03,6.3,6.61
          c0.38,2.62,0.36,5.3,0.4,7.96c0.25,18.31-1.78,20.61-20.43,20.63c-115.73,0.08-231.46,0.08-347.19,0
          c-18.86-0.01-20.84-2.23-20.63-20.43c0.02-1.33-0.01-2.66,0.06-3.99c0.5-8.96,2.07-10.8,10.4-11.25
          c5.31-0.29,10.64-0.15,15.96-0.15C2248.52,2145.87,2304.39,2145.88,2360.26,2145.88z"
      />
      <path
        class="st0"
        d="M2359.09,1922.22c-55.98,0-111.96,0.02-167.94-0.02c-5.99,0-11.99-0.1-17.96-0.53
          c-3.7-0.27-6.16-2.78-6.45-6.42c-0.43-5.3-0.54-10.65-0.28-15.96c0.35-7.2,2.28-8.88,10.17-9.33c4.65-0.26,9.33-0.14,13.99-0.14
          c113.3-0.01,226.59,0,339.89,0c2.67,0,5.33-0.05,8,0.02c15.13,0.35,15.94,1.17,15.98,15.92c0.04,15.3-0.87,16.39-17.45,16.42
          C2477.71,1922.28,2418.4,1922.21,2359.09,1922.22z"
      />
      <path
        class="st2"
        d="M2295.27,1756.98c-33.91,0-67.82,0.04-101.72-0.04c-5.29-0.01-10.7,0.06-15.85-0.96
          c-8.57-1.7-13.49-8.38-13.74-17.21c-0.21-7.61,4.04-14.49,11.55-16.79c4.99-1.53,10.41-2.29,15.65-2.31
          c71.14-0.14,142.28-0.1,213.42-0.07c3.31,0,6.68,0.04,9.92,0.61c8.96,1.58,14.07,7.12,15.21,16.02c1.25,9.79-3.3,17.22-12.91,19.47
          c-5.09,1.19-10.53,1.22-15.82,1.23C2365.74,1757.03,2330.51,1756.98,2295.27,1756.98z"
      />
      <path
        class="st0"
        d="M2298.76,2013.75c-37.23,0-74.46,0.1-111.69-0.04c-19.29-0.07-21.44-2.53-20.81-22.19
          c0.08-2.64,0.28-5.31,0.83-7.88c0.59-2.78,2.47-4.76,5.4-5.06c3.95-0.4,7.94-0.67,11.91-0.67c75.13-0.04,150.25,0.14,225.38-0.15
          c19.2-0.07,21.2,4.06,20.36,22.65c-0.42,9.39-3.9,12.65-13.7,13.22c-4.64,0.27-9.3,0.11-13.96,0.11
          C2367.91,2013.75,2333.33,2013.75,2298.76,2013.75z"
      />
      <path
        class="st0"
        d="M2296.28,2489.97c37.89,0,75.79,0.28,113.68-0.12c18.54-0.2,20.85,4.19,20.27,20.84
          c-0.39,11.1-3.43,14.38-13.99,15.03c-3.31,0.2-6.65,0.09-9.97,0.09c-71.8,0-143.59-0.19-215.39,0.11
          c-24.39,0.1-25.49-2.25-24.44-26.48c0.3-6.84,2.72-8.9,10.18-9.32c4.64-0.26,9.3-0.15,13.96-0.15
          C2225.81,2489.96,2261.04,2489.97,2296.28,2489.97z"
      />
      <path
        class="st0"
        d="M2296.6,2233.88c37.89,0,75.79,0.33,113.68-0.14c19.22-0.23,20.47,5.57,19.93,21.22
          c-0.38,11.01-3.42,14.11-14.29,14.7c-3.98,0.22-7.98,0.08-11.96,0.08c-70.47,0-140.94,0.02-211.4-0.03
          c-5.97,0-12.01,0.06-17.89-0.76c-4.11-0.58-6.92-3.69-7.86-8.01c-0.14-0.65-0.31-1.31-0.34-1.96c-0.83-25.9-1.58-25.19,22.44-25.13
          C2224.81,2233.95,2260.71,2233.88,2296.6,2233.88z"
      />
      <path
        class="st2"
        d="M2505.71,1756.9c-9.98,0-20,0.52-29.94-0.12c-14.48-0.93-21.85-14.1-15.81-27.44
          c1.73-3.81,4.88-6.19,8.75-7.71c5.08-1.99,10.39-2.07,15.73-2.06c14.64,0.03,29.28-0.02,43.92,0.03c3.32,0.01,6.69-0.01,9.95,0.51
          c13.97,2.22,20.4,13.29,15.12,26.49c-2.74,6.85-8.57,10.08-15.78,10.23C2527.01,1757.07,2516.36,1756.9,2505.71,1756.9z"
      />
      <path
        class="st0"
        d="M2507.39,1978.06c12.65,0,25.3-0.2,37.94,0.09c6.1,0.14,8.57,2.62,8.87,8.47c1.21,24.1,0.3,27.79-23.72,27.22
          c-18.63-0.45-37.28,0.11-55.92-0.18c-13.24-0.21-15.81-3.22-16.13-16.93c-0.42-17.85,0.35-18.66,19.01-18.83
          c9.99-0.09,19.97-0.02,29.96-0.02C2507.39,1977.94,2507.39,1978,2507.39,1978.06z"
      />
      <path
        class="st0"
        d="M2507.65,2234.08c12.65,0,25.3-0.2,37.94,0.09c5.92,0.14,8.08,2.76,8.67,8.73c0.13,1.32,0.15,2.66,0.18,3.99
          c0.48,18.62-0.39,23.53-22.24,22.95c-17.96-0.47-35.95-0.05-53.92-0.13c-3.97-0.02-8.02-0.1-11.9-0.85
          c-3.39-0.65-5.84-3.19-6.98-6.5c-0.64-1.85-0.84-3.91-0.88-5.88c-0.43-22.45-0.78-22.85,21.15-22.62
          c9.32,0.1,18.64,0.02,27.96,0.02C2507.65,2233.95,2507.65,2234.01,2507.65,2234.08z"
      />
      <path
        class="st0"
        d="M2507.37,2490.14c12.59,0,25.18-0.19,37.77,0.08c6.18,0.13,8.41,2.57,9.09,8.22c0.08,0.66,0.16,1.32,0.17,1.98
          c0.44,21.37,0.83,26.08-23.41,25.47c-18.54-0.46-37.11,0.1-55.66-0.17c-14.6-0.21-16.92-2.95-16.9-17.97
          c0-1.33-0.02-2.65,0.06-3.98c0.58-9.99,3.37-13.3,13.1-13.62c11.92-0.4,23.85-0.1,35.78-0.1
          C2507.37,2490.09,2507.37,2490.11,2507.37,2490.14z"
      />
      <path
        class="st2"
        d="M3709.98,2270.04c4.19-10.62,10.19-20.1,18.09-28.34c12.5-13.04,27.39-20.51,45.86-20.48
          c69.08,0.13,138.15-0.17,207.23,0.2c25.39,0.14,44.55,12.2,56.22,34.74c11.91,23.02,10.87,45.87-3.05,68.03
          c-5.38,8.57-12.51,15.4-20.03,21.94c-11.78,1.74-22.24,8.22-34.7,8.15c-68.57-0.34-137.15,0.11-205.72-0.3
          c-33.42-0.2-64.37-30.11-63.66-64C3710.36,2283.34,3710.07,2276.69,3709.98,2270.04z"
      />
      <path
        class="st0"
        d="M3880.46,1850.05c85.86,0,171.73,0,257.59,0c3.99,0,7.99-0.06,11.98,0.01c15.54,0.29,17.1,1.95,16.42,17.32
          c-0.49,11.03-3.2,13.77-14.6,14.34c-4.65,0.23-9.32,0.08-13.98,0.08c-174.39,0-348.78,0-523.17,0c-5.32,0-10.67,0.21-15.97-0.17
          c-8.29-0.6-11.55-4.22-12-12.81c-0.14-2.65-0.16-5.34,0.13-7.98c0.67-6.21,3.98-9.87,10.07-10.4c5.95-0.51,11.96-0.39,17.95-0.39
          C3703.41,1850.04,3791.93,1850.05,3880.46,1850.05z"
      />
      <path
        class="st0"
        d="M3878.07,1981.88c86.52,0,173.05,0,259.57,0c3.99,0,7.99-0.15,11.98,0.04c5.18,0.25,11.99-1.85,14.76,3.4
          c3.78,7.16,3.26,15.49-0.04,23.07c-1.36,3.13-4.66,4.7-8.17,4.89c-4.65,0.26-9.3,0.38-13.96,0.38
          c-177.04,0.02-354.09,0.02-531.13-0.01c-5.31,0-10.69,0.08-15.92-0.68c-4.18-0.61-7.31-3.66-7.82-7.98
          c-0.54-4.57-0.61-9.3-0.1-13.87c0.58-5.19,4.07-8.4,9.3-8.79c5.96-0.45,11.96-0.43,17.95-0.44
          C3702.36,1981.87,3790.21,1981.88,3878.07,1981.88z"
      />
      <path
        class="st0"
        d="M3878.64,2142.09c-87.89,0-175.77,0.01-263.66-0.01c-5.99,0-12,0.11-17.96-0.36
          c-6.06-0.49-9.59-4.21-10.15-10.31c-0.93-10.08,1.1-18.2,15.17-17.65c3.99,0.16,7.99,0.01,11.98,0.01c175.77,0,351.54,0,527.32,0
          c3.33,0,6.66-0.11,9.99,0.04c4.55,0.2,10.22-1.3,12.93,3.21c3.97,6.59,2.83,14.12,0.62,21.18c-0.45,1.43-2.97,2.95-4.67,3.11
          c-5.94,0.58-11.93,0.77-17.91,0.77C4054.41,2142.1,3966.53,2142.09,3878.64,2142.09z"
      />
      <path
        class="st3"
        d="M3709.98,2270.04c0.09,6.65,0.38,13.31,0.24,19.95c-0.71,33.9,30.25,63.81,63.66,64
          c68.57,0.41,137.15-0.04,205.72,0.3c12.46,0.06,22.92-6.42,34.7-8.15c-0.79,2.48-0.81,5.96-2.5,7.27
          c-9.87,7.63-19.59,14.7-32.85,17.16c-13.87,2.57-27.54,3.21-41.41,3.22c-54.41,0.04-108.82-0.09-163.23,0.09
          c-14.15,0.05-27.62-2.54-40.45-8.29c-18.81-8.44-30.11-23.25-35.3-42.91c-4.56-17.28-4.61-20.71,0.56-36.69
          C3701.12,2279.76,3703.2,2273.18,3709.98,2270.04z"
      />
      <path
        class="st0"
        d="M4254.59,1967.75c2.5,0.83,4.95,1.97,7.52,2.43c5.32,0.96,8.18,3.42,7.56,9.3
          c-1.19,11.13-1.95,22.3-3.15,33.42c-2.83,26.23,5.18,38.66,30.77,47.39c-1.06,6.42-2.4,12.8-3.11,19.25
          c-1.39,12.74-7.33,22.01-19.18,27.4c-3.5,1.59-7.25,3.13-9.3,6.84c-10.93-2.11-11.25-2.11-11.36-9.67
          c-0.24-15.81-0.17-31.62-0.12-47.43C4254.31,2027.03,4254.47,1997.39,4254.59,1967.75z"
      />
      <path
        class="st0"
        d="M3749.94,1757.95c-12.74-0.13-19.57-7.03-19.59-19.81c-0.03-12.83,7.46-20.59,19.31-20.02
          c11.92,0.57,20.61,9.13,20.42,20.11C3769.88,1750.14,3761.77,1758.08,3749.94,1757.95z"
      />
      <path
        class="st0"
        d="M3875.86,1757.91c-11.39-0.24-18.09-8.45-17.52-21.48c0.5-11.5,7.69-18.53,18.72-18.31
          c10.21,0.2,20.98,10.34,21.01,19.77C3898.11,1749.6,3888.59,1758.18,3875.86,1757.91z"
      />
      <path
        class="st0"
        d="M4009.5,1718.12c9.83,0.43,20.58,11.93,19.62,20.98c-1.09,10.26-11.51,19.26-21.78,18.82
          c-9.88-0.43-20.52-11.82-19.61-20.98C3988.75,1726.78,3999.29,1717.67,4009.5,1718.12z"
      />
      <path
        class="st0"
        d="M3845.86,3465.16c-44.26-9.47-88.58-18.65-132.73-28.58c-11.85-2.66-23.58-3.84-35.61-3.83
          c-113.96,0.06-227.92,0.08-341.89,0.01c-28.12-0.02-24.62,2.83-24.64-24.27c-0.1-125.29-0.06-250.58-0.03-375.87
          c0-23.79-2.08-21.9,21.05-21.91c165.94-0.02,331.89-0.01,497.83,0c5.33,0,10.66,0.18,15.99,0.28c0.09,26.56,0.25,53.12,0.26,79.68
          c0.03,115.54,0.02,231.08,0,346.62C3846.09,3446.57,3845.94,3455.86,3845.86,3465.16z M3638.47,3153.88c0.62,2,1.22,2.35,1.54,0.99
          c0.03-0.14-1.09-0.56-1.68-0.85c-1.7-3.27-4.72-3.89-8.01-3.98c-0.78-0.93-1.4-2.09-2.37-2.75c-1.37-0.94-2.48-0.36-2.66,1.24
          c-0.03,0.27,0.92,0.83,1.49,0.94c1.2,0.24,2.45,0.28,3.67,0.41C3632.1,3153.27,3635.17,3153.8,3638.47,3153.88z M3498.35,3145.87
          c1.23-0.11,2.47-0.14,3.68-0.38c0.56-0.11,1.52-0.66,1.5-0.94c-0.16-1.61-1.27-2.21-2.65-1.28c-0.97,0.65-1.6,1.81-2.38,2.74
          c-2,0.62-2.34,1.21-0.99,1.51C3497.65,3147.56,3498.06,3146.45,3498.35,3145.87z M3468.28,3217.86c2.78-1.45,1.86-4.3,2.53-6.5
          c0.85-2.79,2.44-4.87,5.36-5.13c8.62-0.77,17.29-1.35,25.81,0.57c1.4,0.32,2.88,2.75,3.29,4.45c0.69,2.88-0.95,5.58-3.77,5.95
          c-5.91,0.77-11.92,0.71-17.88,1.16c-6.51,0.49-8.53,2.31-8.89,8.29c-0.28,4.65-0.15,9.32-0.15,13.98
          c-0.02,17.31-0.14,34.62,0.04,51.93c0.08,7.32-1.01,13.11-9.98,14.08c-4.96,0.54-5.74,4.77-5.95,8.98
          c-0.58,11.65,1.42,13.81,13.25,14.21c2,0.07,3.99,0.03,5.99,0.03c55.26,0,110.52,0.01,165.78-0.02c3.98,0,7.98-0.09,11.95-0.45
          c3.91-0.35,5.66-3.13,6.06-6.72c0.79-7.04,0.18-13.71-7.93-16.17c-5.33-1.61-7.55-4.79-7.54-10.12c0.04-21.3,0-42.61,0.01-63.91
          c0-4.12,1.06-7.67,4.21-10.76c1.75-1.72,3.12-4.45,3.45-6.89c0.71-5.26,2.91-8.52,8.33-9.67c1.79-0.38,3.49-1.9,4.85-3.27
          c0.79-0.8,1.25-2.42,1.08-3.55c-0.35-2.32-2.15-3.31-4.28-2.39c-7,3.04-14.37,2.27-21.64,2.27c-53.93,0.04-107.86,0.02-161.79,0.01
          c-3.99,0-8,0.1-11.98-0.13c-7.58-0.43-9.38-2.29-10.19-9.34c-0.7-6.12,1.39-12.6-2.68-18.43c-1.1,6.57-0.79,13.14-1.18,19.67
          c-0.46,7.82,0.53,14.04,9.06,17.68C3466.07,3208.76,3466.73,3214.32,3468.28,3217.86z M3669.11,3173.4
          c-1.02-1.49-1.83-3.15-3.08-4.34c-1.12-1.06-2.59-0.45-2.8,1.2c-0.32,2.53-0.21,5.24,3.18,5.6
          C3667.11,3175.94,3667.98,3174.47,3669.11,3173.4z"
      />
      <path
        class="st3"
        d="M3981.84,3042.63c-5.21-3.64-7.83-9.19-9.7-14.82c-6.07-18.25-13.03-36.27-13.73-55.92
          c-0.26-7.23-3.47-14.47-3.09-21.59c1.48-27.84,4.45-55.35,14.21-81.98c16.44-44.86,45.67-79.48,84.19-106.18
          c22.45-15.56,47.73-25.99,74.79-31.97c27.07-5.98,54.32-3.38,81.53-3.79c11.65-0.17,22.09,5.23,32.62,8.74
          c59.68,19.85,104.33,57.84,131.55,114.88c13.58,28.45,20.34,58.56,20.95,90.57c0.81,42.66-9.79,81.7-31.3,117.97
          c-8.78,14.81-18.26,29.44-32.87,39.6c-17.14-0.08-34.27-0.12-51.4-0.26c-3.94-0.03-7.93-0.09-11.81-0.69
          c-2.82-0.44-4.58-2.77-4.38-5.64c0.18-2.57,1.33-5.05,1.8-7.61c3.05-16.72,8.78-32.96,9.14-50.17c4.47-6.57,9.24-12.95,13.37-19.73
          c18.48-30.34,23.34-63.01,16.18-97.63c-7.53-36.46-27.67-63.67-59.08-83.65c-29.78-18.95-62.62-24.31-95.59-17.42
          c-52.14,10.89-87.08,43.44-101.45,95.65c-4.01,14.59-7.74,29.75-5.05,45.14c2.04,11.71,5.1,23.26,8.28,34.73
          c1.42,5.11,3.95,9.89,2.79,15.42c-11.84-2.59-21.72-9.39-32.13-15.02c-4.06-2.2-7.85-4.9-11.97-6.95c-4.1-2.04-7.8-0.46-9.64,3.87
          c-1.03,2.41-1.56,5.04-2.4,7.54C3992.38,3011.33,3987.11,3026.98,3981.84,3042.63z"
      />
      <path
        class="st0"
        d="M4176.55,3350.34c-60.59,0-121.18,0-181.77-0.01c-3.32,0-6.69,0.22-9.96-0.24
          c-9.6-1.35-16.98-8.48-18.76-18.12c-0.6-3.24-0.61-6.62-0.62-9.94c-0.05-23.3-0.24-46.61,0.04-69.91
          c0.24-20.5,8.54-28.65,29.11-29.43c3.99-0.15,7.99-0.04,11.98-0.04c113.19,0,226.38-0.01,339.58,0.02c5.99,0,12.06-0.35,17.94,0.51
          c13.14,1.93,21.7,11.07,21.9,24.43c0.4,25.96,0.46,51.94-0.24,77.89c-0.43,15.86-9.46,24.17-25.44,24.54
          c-17.97,0.42-35.95,0.13-53.93,0.13c-43.28,0.01-86.56,0-129.84,0C4176.55,3350.23,4176.55,3350.29,4176.55,3350.34z"
      />
      <path
        class="st1"
        d="M3981.84,3042.63c5.27-15.65,10.55-31.3,15.8-46.96c0.84-2.5,1.37-5.13,2.4-7.54c1.85-4.32,5.55-5.9,9.64-3.87
          c4.12,2.05,7.91,4.75,11.97,6.95c10.41,5.64,20.29,12.44,32.14,15.02c0,0,0.5,0.27,0.5,0.27c1.32,1.18,2.65,2.35,3.97,3.53
          c5.75,2.84,7.98,8.75,11.6,13.42c42.73,55.03,114.43,68.94,172.97,34.52c11.54-6.78,21.04-16.11,31.49-24.24
          c-0.35,17.21-6.08,33.45-9.14,50.17c-0.47,2.56-1.62,5.05-1.8,7.61c-0.2,2.87,1.56,5.21,4.38,5.64c3.88,0.6,7.86,0.66,11.81,0.69
          c17.13,0.14,34.27,0.18,51.4,0.26c-17.52,22.82-41.68,36.65-67.11,48.28c-28.1,12.85-58.17,19.54-88.86,18.55
          c-79.66-2.56-142.69-36.37-186.46-103.97C3984.96,3055.42,3981.3,3049.75,3981.84,3042.63z"
      />
      <path
        class="st0"
        d="M4527.36,2994.27c-0.05,23.83-0.09,47.67-0.15,71.5c-0.18,16.55-0.35,33.1-0.54,49.65
          c-0.27,23.16-0.55,46.33-0.83,69.49c-6.35,0.25-12.69,0.49-19.04,0.74c-2.33-7.83-1.35-15.87-1.36-23.82
          c-0.06-161.05-0.03-322.1-0.06-483.15c-0.01-27.56,2.37-24.66-24.47-24.73c-41.93-0.1-83.85-0.02-125.78-0.03
          c-4.66,0-9.32-0.12-13.97-0.07c-4.32,0.04-7.95-1.45-10.07-5.23c-5.78-10.3-12.25-20.28-16.42-30.7c3.56-5.21,7.84-3.77,11.47-3.79
          c27.28-0.15,54.57-0.03,81.86-0.13c6.62-0.02,13.33,0.84,19.83-1.34c25.9,0.14,51.78,0.21,77.66,0.47
          c7.78,0.08,10.25,1.58,11.48,8.1c4.29,22.78,11.31,45.03,13.39,68.24c-5.86,6.19-3.58,13.9-3.59,20.99
          c-0.15,88.62-0.11,177.24-0.07,265.86C4526.71,2982.3,4527.13,2988.29,4527.36,2994.27z"
      />
      <path
        class="st2"
        d="M1360.83,2685.72c2.46-2.15,4.89-1.94,7.29,0.13c-0.49,5.27-1.19,10.53-1.45,15.82
          c-0.45,9.26,0.09,18.47-2.86,27.65c-1.77,5.5-1.06,11.86-1.09,17.83c-0.16,31.96-0.08,63.93-0.33,95.89
          c-0.12,15.23,1.46,30.55-3.22,45.57c-1.33,4.27-0.45,9.26-0.46,13.91c-0.03,19.31-0.06,38.62,0,57.93
          c0.03,9.95-0.17,19.77,2.77,29.64c1.83,6.16,0.91,13.2,0.92,19.85c0.13,75.91,0.18,151.82,0.31,227.73
          c0.02,9.27-0.29,18.47,2.64,27.66c1.75,5.51,1.05,11.86,1.07,17.84c0.14,35.29,0.07,70.58,0.3,105.87
          c0.08,12.57-1.47,25.29,2.88,37.61c0.44,1.26-1.16,3.62-2.37,4.97c-1.14,1.26-4.59-2.02-4.71-4.75c-0.21-4.65-0.3-9.31-0.32-13.97
          c-0.06-15.32,0.04-30.63-0.11-45.95c-0.11-11.26,0.64-22.49-2.87-33.63c-1.73-5.5-0.84-11.88-0.86-17.86
          c-0.13-53.27-0.18-106.54-0.29-159.81c-0.02-7.96,0.12-15.82-2.22-23.72c-1.65-5.59-1.43-11.83-1.43-17.78
          c-0.08-105.21-0.08-210.42-0.06-315.63c0-7.29,0.02-14.5,2.04-21.75c1.58-5.64,1.43-11.81,1.68-17.76
          c0.86-20.51-1.94-41.2,3.71-61.5C1362.79,2693.95,1361.22,2689.67,1360.83,2685.72z"
      />
      <path
        class="st2"
        d="M1517.87,3045.75c-2.61-23.03-6.19-45.48-0.63-67.84c1.57-6.31,0.66-13.24,0.92-19.89
          c0.73-18.53-1.12-37.07,3.39-55.57c2.3-9.45,0.47-19.88,0.67-29.86c0.25-11.92-0.81-23.88,3.14-35.6
          c1.43-4.23,0.69-9.23,0.75-13.89c0.11-9.32,0-18.64,0.15-27.96c0.26-16.21,0.32-16.21,14.97-21.29c8.45-2.93,8.75-2.93,8.79-10.61
          c0.04-8.6,0.98-17.25-1.89-25.73c-3.5-10.34-1.26-20.93-0.72-31.61c4.06,6.7,6.7,13.8,6.93,21.36c0.42,13.97,0.25,27.96,0.26,41.94
          c0,4.43-1.84,7.83-6.42,8.82c-9.92,2.15-13.6,8.7-15.25,18.25c-1.01,5.9-2.25,11.67-2.26,17.68c-0.02,11.98-0.07,23.97-0.15,35.95
          c-0.05,7.3-0.07,14.51-2.26,21.72c-1.51,4.96-1.35,10.49-1.55,15.78c-0.79,21.19,0.99,42.39-3.51,63.55
          c-2.31,10.84-0.44,22.55-0.65,33.87c-0.2,11.25,0.06,22.39-2.9,33.63C1517.39,3027.11,1518.35,3036.62,1517.87,3045.75z"
      />
      <path
        class="st6"
        d="M1398.42,3465.83c-0.03,1.3,0.09,2.62-0.1,3.9c-1.65,10.63-3.73,12.4-13.68,10.76
          c-4.47-0.74-8.78-2.39-13.21-3.39c-4.4-1-8.87-1.7-13.31-2.54c0.26-4.25,1.3-8.24,6.44-8.38
          C1375.84,3465.87,1387.13,3465.93,1398.42,3465.83z"
      />
      <path
        class="st0"
        d="M2610.04,3125.83c-1.57,0.43-3.15,0.87-4.72,1.3c0,0-1.7,0.23-1.7,0.23c-18.14,0.24-34.4,7.12-50.11,15.09
          c-21.28,10.79-20.57,12.42-38.26-5.71c-2.69-2.75-5.1-6.1-9.53-6.28c-7.62-2.04-13.71-0.02-19.25,5.81
          c-7.59,7.99-15.83,15.35-23.8,22.98c-4.35-4.2-3.79-9.66-3.85-14.96c-0.11-8.65-0.03-17.32-0.36-25.96
          c-0.17-4.59-3.07-7.46-7.64-7.54c-14.65-0.26-29.3-0.2-43.95-0.31c-20.72-0.14-18.85,1.76-23.84-18.34
          c-5.62-22.65-11.75-44.99-22.18-65.99c-2.04-4.12-2.92-8.81-4.33-13.24c4.88-3.01,10.32-2.1,15.58-2.1
          c159.33-0.07,318.66-0.09,477.99-0.15c17.82-0.01,17.98-0.69,17.98,19.29c-0.01,85.33,0.12,170.66,0.2,255.99
          c-2.66,0.17-5.31,0.51-7.96,0.5c-14.4-0.09-14.65-0.06-18.23-15.07c-5.47-22.92-15.5-43.78-28.16-63.44
          c-3.14-4.87-2.66-8.41,1.18-12.27c7.04-7.07,14.04-14.18,20.86-21.46c1.88-2.01,2.67-5.1,0.49-7.24
          c-12.65-12.42-22.71-27.66-38.95-36.2c-6.72-1.53-12.72-0.96-17.71,4.7c-5.56,6.31-11.56,12.24-17.37,18.33
          c-4.11,0.05-7.73-1.49-11.16-3.54c-16.51-9.86-34.05-17.1-52.93-21.03c-3.21-0.67-6.25-2.07-9.42-2.96
          c-4.5-1.26-6.28-4.39-6.33-8.78c-0.09-8.57,0.04-17.15-0.29-25.71c-0.29-7.75-1.96-9.75-9.27-9.77
          c-15.8-0.04-31.61,0.46-47.41,0.74c-1.26-0.26-2.52-0.75-3.78-0.74c-9.67,0.07-10.46,0.67-10.77,9.08
          C2610.63,3102.67,2610.37,3114.25,2610.04,3125.83z"
      />
      <path
        class="st1"
        d="M2127.25,3018.16c5.51,0.39,10.54,2.71,14.37,6.17c18.1,16.39,36.76,32.37,48.75,54.25
          c1.87,3.41,2.79,7.33,4.15,11.02c-1.27,2.25-3.49,4.42-3.65,6.75c-0.72,9.89-1.63,19.83,1.77,29.56c2.94,8.4,3.44,16.99-0.79,25.14
          c-5.21,10.05-11.14,19.78-19.99,27.01c-21.01,17.18-41.95,34.47-63.64,50.76c-12.33,9.27-22.76,20.3-33.35,31.23
          c-11.41,11.77-16.36,26.42-11.12,42.01c3.3,9.8,0.86,18.14-0.69,27.12c-11.4,2.06-22.26-0.73-33.3-3.14
          c-30.63-6.69-57.2-21.46-79.37-42.88c-18.56-17.94-33.92-38.83-39.92-64.97c5.48-2.82,9.64-7.24,13.2-12.01
          c16.75-22.39,37.4-41.3,55.44-62.54c15.53-18.28,35.13-32.44,52.36-49c12.5-12.02,26.36-22.22,39.76-33.06
          c13.45-10.89,28.1-20.03,42.4-29.69C2118.91,3028.33,2125.81,3025.76,2127.25,3018.16z"
      />
      <path
        class="st0"
        d="M2474.87,3435c-50.19,10.88-100.39,21.76-150.59,32.61c-1.91,0.41-3.9,0.48-5.85,0.71
          c0.66-5.8,4.85-9.29,8.59-13.04c11.76-11.79,23.55-23.54,35.3-35.34c3.29-3.3,6.66-6.55,9.64-10.12c3.66-4.37,3.78-8.27-0.16-12.49
          c-7.72-8.27-15.82-16.19-23.77-24.25c-4.21-4.27-8.59-8.37-12.64-12.79c-5.98-6.53-5.99-8.13-1.41-15.66
          c6.21-10.22,12.84-20.23,18.47-30.76c14.17-26.51,25.18-54.24,30.75-83.94c0.86-4.56,2.39-8.99,3.36-13.53
          c1.02-4.74,4.17-6.6,8.57-6.65c13.32-0.14,26.64-0.11,39.95-0.19c5.32-0.03,10.66-0.01,15.97-0.31c4.65-0.26,6.9-3.07,7.52-7.65
          c0.96-7.05-1.88-14.86,4.08-20.93c5.7,5.44,11.43,10.83,17.06,16.33c2.63,2.57,3.18,5.67,1.24,8.89c-2.37,3.94-5.05,7.7-7.35,11.68
          c-12.25,21.22-22.12,43.31-24.18,68.15c-10.69,0.63-21.39,1.25-32.08,1.88c-1.97-0.2-3.94-0.54-5.92-0.56
          c-3.6-0.04-7.18,1.28-7.27,5.09c-0.34,15.88-0.34,31.78-0.01,47.66c0.08,3.82,3.65,5.14,7.26,5.1c1.98-0.03,3.95-0.37,5.92-0.58
          c10.45,0.61,20.89,1.23,31.34,1.84c2.49,25.61,11.72,48.89,24.53,70.92c2,3.43,4.14,6.8,5.86,10.36
          C2481.14,3431.69,2477.39,3433.09,2474.87,3435z"
      />
      <path
        class="st1"
        d="M2115.02,3010.13c2.79,0.41,5.57,0.84,7.16,3.63c-1.56,2.09-2.67,5.06-4.75,6.11
          c-14.29,7.14-26.76,16.94-39.57,26.26c-29.94,21.77-57.64,46.24-84.72,71.38c-14.5,13.46-28.22,27.72-40.54,43.47
          c-10.93,13.98-25.53,24.9-35.59,39.91c-2.46,3.68-6.88,6.04-10.4,9.01c-4.31-17.25-9.5-34.47-8.01-52.5
          c2.86-34.73,12.9-66.82,33.55-95.64c13.43-18.74,30.4-32.76,49.5-44.83c5.06-3.2,10.16-6.21,16.27-6.9
          C2036.94,3011.29,2075.98,3011.11,2115.02,3010.13z"
      />
      <path
        class="st0"
        d="M2063.06,3329.18c1.55-8.98,3.99-17.32,0.69-27.12c-5.25-15.59-0.29-30.24,11.12-42.01
          c10.59-10.93,21.01-21.96,33.35-31.23c21.68-16.3,42.63-33.59,63.64-50.76c8.85-7.24,14.78-16.97,19.99-27.01
          c4.23-8.15,3.73-16.74,0.79-25.14c-3.4-9.73-2.49-19.67-1.77-29.56c0.17-2.32,2.38-4.5,3.65-6.75c7.73,8.91,10.1,20.2,12.64,31.06
          c13.29,56.9,1.34,107.83-37.29,152.12c-21.09,24.18-46.87,41.17-77.74,50.04C2082.61,3325.55,2072.76,3327.09,2063.06,3329.18z"
      />
      <path
        class="st3"
        d="M1906.6,3209.89c3.52-2.97,7.93-5.34,10.4-9.01c10.06-15.01,24.66-25.93,35.59-39.91
          c12.32-15.75,26.04-30.01,40.54-43.47c27.07-25.13,54.78-49.6,84.72-71.38c12.81-9.32,25.28-19.11,39.57-26.26
          c2.08-1.04,3.2-4.02,4.75-6.11c1.68,1.46,3.37,2.93,5.07,4.39c-1.43,7.61-8.33,10.17-13.59,13.73
          c-14.31,9.66-28.95,18.8-42.4,29.69c-13.4,10.84-27.26,21.05-39.76,33.06c-17.23,16.56-36.83,30.72-52.36,49
          c-18.05,21.24-38.7,40.15-55.44,62.54c-3.57,4.77-7.72,9.2-13.2,12.01C1907.4,3216.26,1906.59,3213.27,1906.6,3209.89z"
      />
      <path
        class="st2"
        d="M2449.44,3285.71c2.07-24.84,11.93-46.93,24.18-68.15c2.3-3.98,4.98-7.74,7.35-11.68
          c1.94-3.22,1.39-6.32-1.24-8.89c-5.63-5.5-11.36-10.89-17.06-16.33c-4.59-7.15-4.58-14.29,0-21.42
          c7.96-7.62,16.21-14.98,23.8-22.97c5.54-5.83,11.63-7.85,19.25-5.81c-10.49,11.02-21.06,21.95-31.42,33.08
          c-5.13,5.5-4.83,7.68,0.14,13.59c7.15,8.5,17.28,14.38,21.95,25.04c-5.27,9.12-11.27,17.9-15.64,27.43
          c-5.5,11.99-9.76,24.54-14.35,36.94c-1.13,3.07-1.38,6.45-2.15,9.67C2462.38,3284.1,2456.97,3286.58,2449.44,3285.71z"
      />
      <path
        class="st5"
        d="M2115.02,3010.13c-39.04,0.98-78.07,1.17-117.11-0.11C2036.96,2988.68,2076,2987.93,2115.02,3010.13z"
      />
      <path
        class="st2"
        d="M4578.53,2993.58c-9.27-0.01-18.53-0.02-27.8-0.03c-0.03-30.43-0.06-60.86-0.09-91.29
          c9.52-1.34,19.05-1.28,28.58-0.16c0.25,11.07,0.5,22.13,0.75,33.2c0.92,9.72,1.83,19.44,2.74,29.16
          c-2.85,2.86-3.65,6.43-3.73,10.31C4578.87,2981.04,4578.69,2987.31,4578.53,2993.58z"
      />
      <path
        class="st4"
        d="M4651.59,2902.26c-3-4.44-3.96-9.48-4.51-14.69c-0.94-8.9-0.18-9.61,10.96-9.62
          c0.01,5.94,0.15,11.88-0.02,17.81C4657.9,2899.71,4654.86,2901.2,4651.59,2902.26z"
      />
      <path
        class="st0"
        d="M3180.45,1321.69c378.64,0,757.28-0.03,1135.92,0.05c29.99,0.01,26.04-3.7,26.32,26.76
          c0.15,16,0.12,32-0.12,47.99c-0.12,7.77-1.6,9-9.62,9.33c-4.66,0.19-9.33,0.1-14,0.1c-759.94,0-1519.89,0.01-2279.83-0.02
          c-27.2,0-24.23,2.65-24.38-24.81c-0.09-16.66-0.11-33.33,0.12-49.99c0.11-7.82,1.49-8.98,9.67-9.29c6.66-0.25,13.33-0.11,20-0.11
          C2423.17,1321.69,2801.81,1321.69,3180.45,1321.69z"
      />
      <path
        class="st1"
        d="M1849.73,1333.33c8.52-10.38,18.98-12.98,31.47-8.07c11.55,4.54,17.87,12.68,18.14,25.28
          c0.12,5.83-1.37,11.77,1.03,17.47c-1.03,1.1-2.06,2.21-3.09,3.31c-1.94-0.09-3.87-0.19-5.81-0.28c-1.27-1.25-2.53-2.51-3.79-3.76
          c1.2-2.3,2.34-4.63,3.58-6.9c4.35-8,2.17-15.35-3.02-21.74c-5.76-7.08-13.7-10.05-22.68-7.72c-4.28,1.11-8.13,3.9-12.17,5.93
          C1852.16,1335.68,1850.95,1334.5,1849.73,1333.33z"
      />
      <path
        class="st1"
        d="M1888.57,1383.72c-4.87-2.55-9.99-2.85-15.46-2.79c-8.07,0.09-16.04-1.21-20.87-9.08
          c1.02-0.97,2.05-1.95,3.07-2.92c11.15,6.55,21.94,6.27,32.35-1.65c1.27,1.25,2.53,2.51,3.79,3.76c-1.2,3.25-1.02,6.42,0.57,9.5
          C1890.87,1381.6,1889.72,1382.66,1888.57,1383.72z"
      />
      <path
        class="st1"
        d="M1848.46,1368.01c-6.35-4.29-6.61-10.84-6.52-17.53c0.09-6.86,1.82-12.96,7.79-17.14
          c1.22,1.17,2.44,2.34,3.65,3.51c-5.45,9.05-4.43,18.52-1.76,28.09C1850.56,1365.96,1849.51,1366.98,1848.46,1368.01z"
      />
      <path
        class="st1"
        d="M1920.44,1387.95c1.85,1.83,3.92,3.98,2.22,6.56c-2.06,3.13-4.57,6.11-7.41,8.55
          c-4.98,4.29-7.96,0.96-10.35-3.42c1.04-1.22,2.07-2.44,3.11-3.66c4.6,1.58,6.75-1.2,8.46-4.77
          C1917.79,1390.12,1919.11,1389.04,1920.44,1387.95z"
      />
      <path
        class="st1"
        d="M1892.64,1387.78c-1.35-1.36-2.71-2.72-4.07-4.07c1.14-1.05,2.3-2.11,3.44-3.17c1.29,1.31,2.58,2.61,3.87,3.92
          C1894.81,1385.57,1893.73,1386.67,1892.64,1387.78z"
      />
      <path
        class="st1"
        d="M1900.36,1368.01c1.36,1.35,2.72,2.71,4.08,4.06c-1.08,1.03-2.17,2.07-3.25,3.1
          c-1.31-1.28-2.62-2.57-3.93-3.85C1898.3,1370.22,1899.33,1369.11,1900.36,1368.01z"
      />
      <path
        class="st1"
        d="M1852.24,1371.85c-1.26-1.29-2.51-2.59-3.77-3.86c1.04-1.01,2.09-2.03,3.14-3.06c1.23,1.33,2.47,2.67,3.71,4
          C1854.29,1369.9,1853.27,1370.88,1852.24,1371.85z"
      />
      <path
        class="st1"
        d="M1904.44,1372.07c1.33,1.33,2.66,2.67,4,4c-1.08,1.03-2.16,2.06-3.24,3.09c-1.33-1.33-2.67-2.66-4.01-3.99
          C1902.27,1374.14,1903.36,1373.1,1904.44,1372.07z"
      />
      <path
        class="st1"
        d="M1908.44,1376.07c1.33,1.33,2.66,2.67,4,4c-1.08,1.03-2.16,2.06-3.24,3.08c-1.33-1.33-2.67-2.66-4.01-3.99
          C1906.28,1378.13,1907.36,1377.1,1908.44,1376.07z"
      />
      <path
        class="st1"
        d="M1904.9,1399.64c-1.36-1.36-2.72-2.71-4.08-4.07c1.1-1.11,2.2-2.23,3.3-3.33c1.29,1.25,2.59,2.49,3.89,3.74
          C1906.97,1397.19,1905.93,1398.41,1904.9,1399.64z"
      />
      <path
        class="st1"
        d="M1900.81,1395.57c-1.33-1.33-2.67-2.67-4-4c1.1-1.11,2.2-2.22,3.31-3.33c1.34,1.33,2.67,2.66,4.01,3.99
          C1903.02,1393.34,1901.92,1394.46,1900.81,1395.57z"
      />
      <path
        class="st1"
        d="M1912.44,1380.07c1.33,1.34,2.67,2.68,4,4.02c-1.08,1.02-2.16,2.05-3.24,3.06c-1.33-1.33-2.66-2.66-4-3.99
          C1910.28,1382.13,1911.36,1381.1,1912.44,1380.07z"
      />
      <path
        class="st1"
        d="M1916.44,1384.09c1.33,1.3,2.66,2.6,4,3.88c-1.32,1.07-2.64,2.16-3.97,3.24c-1.09-1.35-2.18-2.7-3.27-4.05
          C1914.27,1386.13,1915.36,1385.11,1916.44,1384.09z"
      />
      <path
        class="st1"
        d="M1896.81,1391.56c-1.38-1.27-2.77-2.54-4.16-3.8c1.08-1.09,2.16-2.2,3.23-3.3c1.41,1.26,2.82,2.51,4.23,3.77
          C1899.01,1389.35,1897.91,1390.46,1896.81,1391.56z"
      />
      <path
        class="st1"
        d="M4550.64,2902.27c0.03,30.43,0.06,60.86,0.09,91.29c-7.8,0.24-15.59,0.47-23.38,0.71
          c-0.22-5.98-0.64-11.97-0.65-17.95c-0.04-88.62-0.08-177.24,0.07-265.86c0.01-7.08-2.27-14.8,3.59-20.99
          c5.53,7.92,4.75,17.64,7.34,26.42c2.6,8.82,3.26,18.21,5.82,27.05c7.84,26.99,9.13,54.52,7.86,82.33
          C4549.19,2850.91,4550.54,2876.59,4550.64,2902.27z"
      />
      <path
        class="st4"
        d="M4550.64,2902.27c-0.1-25.67-1.45-51.35,0.74-77c5.62,0.57,10.17,2.14,11.49,8.75
          c2.21,11.04,4.82,22,7.4,32.96c1.96,8.35,3.13,9.58,12.05,10.22c8.58,0.62,17.22,0.59,25.83,0.69c8.5,0.1,17.01,0.02,27.43,0.02
          c-7.02,10.86-13.85,19.79-15.17,31.36c-0.53,4.61-4.68,6.2-9.12,5.09c-5.12-1.28-10.08-3.23-15.23-4.4
          c-3.15-0.72-6.5-0.52-9.77-0.73c-0.17-4.55-2.36-7.09-7.08-7.12C4569.69,2900.99,4560.16,2900.92,4550.64,2902.27z"
      />
      <path
        class="st1"
        d="M4303.81,2424.96c21.9-7.17,43.73-14.58,65.76-21.3c4.85-1.48,10.45-0.45,15.71-0.57
          c-0.38,4.56-0.35,9.2-1.24,13.65c-1.81,9.13-2.6,10.1-11.44,9.89C4349.68,2426.09,4326.66,2428.75,4303.81,2424.96z"
      />
      <path
        class="st3"
        d="M4579.22,2902.1c4.72,0.03,6.91,2.58,7.08,7.12c-2.1,8.7-4.21,17.41-6.32,26.09
          C4579.72,2924.23,4579.47,2913.17,4579.22,2902.1z"
      />
      <path
        class="st0"
        d="M3264.78,380.9c0-15.97-0.19-31.94,0.07-47.91c0.15-8.86,1.5-9.93,10.5-11.02c15.25-1.85,30.48-2.3,45.73,0.32
          c29.15,5,46.81,22.9,48.85,49.74c2.37,31.27-11.95,54.81-39.62,64.14c-18.65,6.29-37.91,6.54-57.23,3.9
          c-5.91-0.81-8.11-3.25-8.18-9.26c-0.2-16.63-0.07-33.27-0.07-49.9C3264.82,380.9,3264.8,380.9,3264.78,380.9z"
      />
      <path
        class="st5"
        d="M1529.99,2202.79c-6.21-0.13-7.42,9.91-15,7.27c-2.88-1-7.6-0.26-2.91-5.35c2.03-2.21,1.89-4.74-0.79-6.67
          c-8.06-5.79-8.01-5.77-6.23-13.4c0.9-3.84,1.57-7.73,2.34-11.57c2.59,0.29,2.64,2.23,3.16,4.06c0.56,1.98-0.78,8.33,4.24,2.28
          c0.83-1,1.59-2.15,2.63-2.86c2.59-1.77,7.44,0.16,7.73,3.08c0.19,1.92,0.63,4.29-0.22,5.77c-4.77,8.27,2.16,11.73,6.32,16.37
          C1531.26,2201.77,1529.99,2202.79,1529.99,2202.79z"
      />
      <path
        class="st5"
        d="M1515.63,2218.72c-4.31,4.69-9.75,2.82-14.79,2.99c-4.58,0.15-9.21-0.45-13.78-1.06
          c-0.9-0.12-1.59-1.82-2.38-2.79c1.07-0.56,2.36-1.81,3.19-1.56C1496.89,2219,1506.1,2218.22,1515.63,2218.72z"
      />
      <path
        class="st5"
        d="M1468.07,2165.49c14.35-8.58,14.35-8.58,24.43-0.04C1484.18,2166.23,1476.71,2167.13,1468.07,2165.49z"
      />
      <path
        class="st5"
        d="M1486.95,2181.41c-0.34,3.61-3.8,7.04-6.3,6.09c-3.74-1.42-3.81-4.74-3.19-7.97c0.48-2.49,1.08-5.33,4.66-4.95
          C1486.39,2175.02,1486.74,2178.3,1486.95,2181.41z"
      />
      <path
        class="st5"
        d="M1515.13,2163.83c3.08-3.74,6.94-3.08,10.73-2.04c1.12,0.31,2,1.51,2.99,2.3c-0.97,0.77-1.87,2.11-2.91,2.22
          C1522.13,2166.71,1518.17,2167.1,1515.13,2163.83z"
      />
      <path
        class="st5"
        d="M4367.02,2000.32c8.25,1.25,12.31,6.15,10.77,13.28c-0.95,4.38-2.23,8.7-3.56,12.98
          c-0.67,2.18-2.78,2.9-4.62,2.24c-7.57-2.72-11.25-8.03-10.08-14.8c0.66-3.85,1.98-7.6,3.25-11.31
          C4363.4,2000.85,4364.73,1999.42,4367.02,2000.32z"
      />
      <path
        class="st5"
        d="M4342.24,2048.81c10.18,1.48,18.13,2.45,25.74-0.72c0.44-0.18,2.17,1.4,2.37,2.36c0.23,1.1-0.28,2.66-1,3.6
          c-2.57,3.35-6.19,5.02-10.35,5.16C4352.78,2059.42,4347.16,2058.11,4342.24,2048.81z"
      />
      <path
        class="st5"
        d="M4346.23,1986.79c-0.91,1.32-1.42,2.98-2.28,3.18c-4.42,1.03-8.93,1.62-13.39,2.51
          c-2.96,0.59-6.86,2.94-7.91-1.56c-0.78-3.34,2.83-5.47,5.63-7.22c4.72-2.95,9.73-3.93,14.78-1.28
          C4344.52,1983.18,4345.24,1985.36,4346.23,1986.79z"
      />
      <path
        class="st5"
        d="M4338.21,2001.9c1.07,0.64,2.54,1.02,3.15,1.95c2.79,4.23,2.79,8.63-0.51,12.57
          c-1.19,1.42-3.19,2.25-5.22,1.17c-3.21-1.69-4.61-4.57-4.32-8.01c0.28-3.31,1.45-6.35,5.05-7.56
          C4336.93,2001.83,4337.6,2001.93,4338.21,2001.9z"
      />
      <path
        class="st1"
        d="M4297.11,2047.42c-8.67,2.35-9.89,1.32-9.64-8.02C4296.54,2038.59,4296.76,2038.77,4297.11,2047.42z"
      />
      <path
        class="st0"
        d="M1228.35,2034.29c-24.59,0-49.18,0.01-73.78-0.01c-4.65,0-9.31,0.11-13.95-0.16
          c-7.58-0.45-9.49-2.33-10.12-9.37c-0.12-1.32-0.15-2.66-0.15-3.98c-0.01-24.59-0.15-49.19,0.06-73.78
          c0.1-11.54,1.56-12.79,12.33-13.32c1.99-0.1,3.99-0.05,5.98-0.05c54.5,0,109-0.07,163.51,0.04c17.95,0.03,18.11,0.26,18.23,17.47
          c0.15,21.93,0.15,43.87,0,65.8c-0.12,17.03-0.34,17.22-18.36,17.34c-21.27,0.13-42.54,0.03-63.81,0.03
          C1241.64,2034.29,1234.99,2034.29,1228.35,2034.29z M1202.45,1993.9c0.56,2.11,1.9,3.45,4.02,4.01c0.43,0.48,0.78,1.22,1.32,1.41
          c10.81,3.69,34.44,3.69,45.25,0c0.54-0.18,0.88-0.92,1.32-1.4c2.11-0.56,3.45-1.9,4.01-4.01c5.17-2.45,8.3-6.21,6.95-12.19
          c-0.37-1.65-1.84-3.66-3.33-4.33c-2.45-1.11-3.14,1.27-3.24,3.1c-0.24,4.5-0.21,9.01-0.29,13.51c-1.34,1.34-2.67,2.68-4.01,4.02
          c-16.03,0-32.06,0-48.08,0c-1.34-1.34-2.68-2.68-4.01-4.01c-0.31-5.35-0.62-10.7-0.93-16.05
          C1198.88,1983.67,1196.32,1989.44,1202.45,1993.9z M1310.37,2001.9c2.55-0.56,5.57-0.61,6.67-3.51c2.44-6.41,1.17-13.17,1.22-19.77
          c0.03-3.71,1.41-8.14-3.8-10.99c-1.33,11.66,2.93,23.78-3.99,34.39c-1.27,0.1-2.56,0.12-3.8,0.35c-0.58,0.11-1.56,0.7-1.53,0.99
          c0.17,1.66,1.32,2.28,2.75,1.34C1308.9,2004.04,1309.56,2002.85,1310.37,2001.9z M1171.04,2022.68c0.07,1.04,0.13,2.09,0.2,3.13
          c7.85,0,15.71,0.02,23.56,0c31.11-0.1,62.22-0.21,93.34-0.37c0.55,0,1.23-0.58,1.63-1.08c1.66-2.08-2.83-0.4,1.67-1.69
          C1250.01,2022.68,1210.52,2022.68,1171.04,2022.68z M1171.04,1942.82c31.94,4.77,106.86,3.93,120.4,0
          C1250.03,1942.82,1210.54,1942.82,1171.04,1942.82z M1145.78,2000.19c0.37-10.65-0.02-21.31,0.24-31.96
          C1140.87,1978.81,1141.77,1989.48,1145.78,2000.19z M1164.21,1955.37c0.42-0.26,1.06-0.43,1.19-0.79c0.17-0.44,0.12-1.25-0.18-1.55
          c-0.95-0.95-1.71-0.54-1.8,1.02C1163.4,1954.48,1163.93,1954.93,1164.21,1955.37z M1296.88,1952.69c-0.42,0.1-1.05,0.06-1.22,0.32
          c-0.27,0.41-0.48,1.17-0.27,1.52c0.78,1.33,1.61,1.17,2-0.4C1297.49,1953.72,1297.06,1953.17,1296.88,1952.69z M1163.84,2012.8
          c-0.17,0.49-0.58,1.04-0.47,1.46c0.45,1.74,1.26,1.82,2,0.21c0.18-0.39-0.05-1.1-0.32-1.5
          C1164.9,2012.74,1164.26,2012.84,1163.84,2012.8z M1297.49,2013.67c-0.31-0.44-0.62-0.89-0.93-1.33c-0.38,0.38-1.03,0.71-1.1,1.14
          c-0.25,1.49,0.38,2.14,1.46,1.61C1297.27,2014.92,1297.31,2014.16,1297.49,2013.67z"
      />
      <path
        class="st0"
        d="M3494.6,979.43c0,13.23,0.05,26.47-0.04,39.7c-0.02,3.28-0.23,6.63-0.93,9.82c-0.62,2.82-3.09,4.17-5.87,4.45
          c-3.45,0.34-6.61-0.49-8.49-3.7c-0.64-1.09-0.94-2.53-0.95-3.81c-0.06-30.43-0.17-60.87,0.01-91.3c0.04-6.21,3.17-8.69,8.64-8.34
          c4.76,0.3,7.1,3.04,7.4,7.55c0.26,3.96,0.22,7.93,0.23,11.9C3494.62,956.93,3494.6,968.18,3494.6,979.43z"
      />
      <path
        class="st0"
        d="M4730.45,1717.48c0,13.23,0.01,26.47-0.01,39.7c-0.01,3.97,0.09,7.95-0.19,11.9c-0.45,6.37-3.1,8.93-8.43,8.56
          c-5.07-0.35-7.05-2.83-7.51-9.55c-0.13-1.98-0.11-3.97-0.12-5.95c-0.01-29.11-0.02-58.22,0.01-87.34c0-3.3,0.06-6.62,0.43-9.89
          c0.5-4.53,3.58-6.83,8.19-6.57c4.77,0.27,7.1,3.03,7.39,7.53c0.26,3.96,0.21,7.93,0.21,11.9
          C4730.47,1691.02,4730.45,1704.25,4730.45,1717.48z"
      />
      <path
        class="st0"
        d="M4766.11,1733.62c0,12.55,0.12,25.1-0.07,37.64c-0.06,3.73-2.61,5.87-6.32,6.38c-4.52,0.62-8.18-1.73-8.91-5.7
          c-0.24-1.29-0.48-2.61-0.48-3.92c-0.04-23.79-0.17-47.58,0.02-71.37c0.06-7.96,2.84-10.79,8.68-10.31
          c5.07,0.41,7.25,2.84,7.37,9.61c0.22,12.55,0.06,25.11,0.06,37.67C4766.34,1733.62,4766.22,1733.62,4766.11,1733.62z"
      />
      <path
        class="st0"
        d="M4693.96,1733.85c0,12.54,0.21,25.09-0.11,37.62c-0.12,4.74-5.58,7.55-10.31,5.68
          c-1.68-0.66-3.47-2.05-4.32-3.59c-0.9-1.62-0.99-3.83-1-5.78c-0.07-23.78-0.09-47.57-0.01-71.35c0.01-3.93,0.26-7.98,5.06-9.63
          c5.71-1.96,10.77,1.07,10.91,7.42c0.3,13.21,0.09,26.42,0.09,39.63C4694.17,1733.85,4694.06,1733.85,4693.96,1733.85z"
      />
      <path
        class="st0"
        d="M4354.48,994.01c-2.17,0.48-3.41,1.93-4.04,3.97c-3.37,1.66-4.01,4.67-3.98,8.02
          c-5.19,0.13-7.73,2.92-8.02,7.98c-1.34,1.33-2.67,2.67-4.01,4c-1.33,1.34-2.66,2.68-3.99,4.02c-6.3,0.56-12.07-0.27-15.4-6.56
          c-3.64-6.87-10.45-12.49-8.7-21.54c1.34-1.33,2.68-2.66,4.02-4c1.33-1.34,2.67-2.67,4-4.01c3.5-0.3,6.36-1.64,8.01-4.93
          c6.34-12.71,10.99-26.19,6.38-40.07c-2.64-7.93,2.02-10.69,5.62-14.99c2.03-0.64,3.51-1.84,3.98-4.03
          c6.99-0.07,14.03-0.38,20.13,4.03c0.08,6.54,0.25,13.08,0.23,19.62c-0.04,13.02,0.98,26.11-3.77,38.75
          C4353.85,987.17,4354.59,990.75,4354.48,994.01z"
      />
      <path
        class="st0"
        d="M3454.47,966.83c0,18.65,0.18,37.3-0.07,55.95c-0.13,9.4-5.35,12.95-14.32,10.47
          c-2.77-0.77-4.67-2.49-5.04-5.43c-0.33-2.63-0.57-5.29-0.57-7.94c-0.04-34.64-0.05-69.27,0-103.91c0-3.31,0.07-6.7,0.73-9.92
          c1.21-5.86,8.1-9.03,14.31-6.99c2.88,0.95,4.31,3.07,4.54,5.87c0.33,3.97,0.45,7.97,0.46,11.96c0.05,16.65,0.02,33.3,0.02,49.96
          C3454.51,966.83,3454.49,966.83,3454.47,966.83z"
      />
      <path
        class="st0"
        d="M1394.46,965.87c1.34,1.34,2.67,2.69,4.01,4.03c-0.21,2.57-0.01,5.26-0.73,7.67c-1.03,3.45-5,4.13-7.63,1.67
          c-2.51-2.35-3.9-5.13-3.14-8.61C1387.86,966.6,1391.35,966.5,1394.46,965.87z"
      />
      <path
        class="st0"
        d="M4658.35,1745.49c0,7.91,0.23,15.84-0.07,23.74c-0.24,6.16-3.22,8.77-8.5,8.44c-4.73-0.3-7.33-3.03-7.38-7.51
          c-0.18-15.83-0.26-31.66-0.01-47.48c0.1-6.25,3.17-8.85,8.46-8.46c5.05,0.37,7.26,2.88,7.5,9.51c0.26,7.25,0.06,14.51,0.06,21.76
          C4658.39,1745.49,4658.37,1745.49,4658.35,1745.49z"
      />
      <path
        class="st0"
        d="M4802.2,1746.87c0,7.91,0.23,15.83-0.08,23.72c-0.19,4.81-3.04,7.04-7.7,7.06c-4.67,0.02-7.75-2.23-7.82-6.95
          c-0.24-16.47-0.25-32.95-0.02-49.42c0.06-4.71,3.11-7.01,7.72-7.07c4.62-0.06,7.61,2.21,7.79,6.96c0.31,8.56,0.09,17.13,0.09,25.7
          C4802.18,1746.87,4802.19,1746.87,4802.2,1746.87z"
      />
      <path
        class="st0"
        d="M3538.34,953.88c0.03,19.93,0.07,39.85,0.08,59.78c0,3.98,0.08,7.98-0.23,11.95
          c-0.43,5.56-3.24,7.92-9.12,8.15c-7.15,0.28-10.44-2.01-10.53-8.67c-0.29-19.92-0.35-39.85-0.14-59.78
          c0.1-9.61,1.46-10.58,12.11-11.3c2.64-0.18,5.31,0,7.97,0.01C3538.48,954.03,3538.34,953.88,3538.34,953.88z"
      />
      <path
        class="st0"
        d="M3578.21,1006.82c0,6.57,0.28,13.16-0.09,19.71c-0.29,5.13-3.57,7.28-9.72,7.29c-6.14,0-9.59-2.16-9.69-7.29
          c-0.29-13.79-0.28-27.6,0.03-41.39c0.11-5.14,3.59-7.33,9.67-7.33c6.09,0,9.41,2.19,9.68,7.34c0.37,7.21,0.09,14.45,0.09,21.68
          C3578.19,1006.82,3578.2,1006.82,3578.21,1006.82z"
      />
      <path
        class="st0"
        d="M1558.45,993.91c1.34,1.33,2.67,2.67,4.01,4c-0.02,3.34,0.72,6.3,3.99,8c0.63,2.05,1.85,3.5,4.03,3.98
          c0.37,4.56-0.17,9.44,1.27,13.64c5.73,16.61,2.73,32.27-3.79,47.81c-2.02,4.81-3.67,9.76-5.5,14.65c-3.2,1.73-4.06,4.64-4.01,8
          c-2.12,0.55-3.46,1.88-4.01,4c-2.11,0.55-3.45,1.89-4,4c-1.33,1.34-2.66,2.69-3.99,4.03c-3.82,0.68-8.07,0.52-11.38,2.21
          c-7.7,3.93-16.05,5.37-24.13,7.86c-7.17,2.21-14.12,2.63-21.1-0.17c-5.02-2.02-10.26-1.82-15.49-1.9c-1.73-3.2-4.73-3.87-8-4
          c-1.75-3.16-4.69-3.95-8-4.01c-1.69-3.3-4.64-4.07-8.01-3.99c-1.33-1.34-2.66-2.68-4-4.02c-1.34-1.33-2.68-2.66-4.02-4
          c0.08-3.37-0.66-6.33-3.97-8.02c-0.63-2.04-1.87-3.47-4.02-3.99c-0.02-4.44,0.6-9.1-4-12.01c-0.14-3.31,0.68-7.05-0.58-9.85
          c-5.64-12.52-2.35-24.61,0.84-36.78c0.49-1.87,1.7-3.56,2.22-5.42c1.39-4.96,10.82-1.72,9.18-7.2c-1.26-4.2-3.75-8.14-3.66-12.86
          c9.41-1.25,14.69-6.64,16.01-15.99c3.36,0.04,6.36-0.63,8.02-4c3.3-0.07,6.35-0.63,8-3.99c3.29-0.09,6.4-0.53,7.99-4.01
          c18.87-1.07,37.54,0.72,56.1,4c0.48,2.18,1.94,3.39,3.98,4.03c2.34,5.16,6.36,7.8,12,7.99c1.33,1.34,2.67,2.68,4,4.01
          C1555.78,991.25,1557.11,992.58,1558.45,993.91z M1470.46,1073.89c1.34,1.34,2.68,2.68,4.01,4.02c0.22,4.52,2.67,7.62,6.79,8.86
          c15.7,4.72,31.24,6.32,45.56-4.12c1.52-1.11,2.37-3.13,3.53-4.73c1.34-1.34,2.68-2.68,4.02-4.02c3.28-1.69,4-4.65,3.98-7.98
          c2.16-0.52,3.36-2,4.02-4.02c5.88-10.62,6.28-21.24-0.03-31.86c-0.04-3.32-0.42-6.47-3.99-8.03c-1.83-14.07-1.89-14.13-15.98-15.98
          c-1.56-3.58-4.71-3.96-8.03-3.99c-11.84-5.68-19.37-5.69-31.86-0.03c-1.34,1.34-2.67,2.68-4.01,4.02
          c-3.34-0.03-6.32,0.65-7.99,3.97c-1.34,1.34-2.68,2.68-4.02,4.02c-2.69,1.33-5.38,2.66-8.08,4c-0.84-0.91-1.54-2.04-2.55-2.68
          c-1.44-0.92-2.54-0.3-2.63,1.35c-0.02,0.28,0.97,0.8,1.55,0.9c1.23,0.21,2.49,0.21,3.74,0.3c-3.32,11.24-5.14,22.7-3.8,34.4
          c0.89,7.72,1.78,15.8,11.77,17.61C1467,1072.01,1468.34,1073.34,1470.46,1073.89z"
      />
      <path
        class="st0"
        d="M2510.47,1014c-2.13,0.54-3.44,1.9-4.02,4c-2.1,0.57-3.44,1.91-4,4.01c-3.35-0.03-6.26,0.81-8,4
          c-6.28,1.39-12.51,3.49-18.86,4.01c-11.66,0.95-23.34,0.93-33.84-5.88c-2.06-1.34-4.9-1.46-7.39-2.14c-1.33-1.34-2.67-2.67-4-4.01
          c-1.34-1.33-2.67-2.67-4.01-4c-8.95-17.8-9.05-36.87-8.02-56.1c1.35-1.33,2.69-2.65,4.04-3.98c3.33-1.68,4.04-4.66,3.97-8.02
          c1.34-1.33,2.68-2.66,4.02-4c1.33-1.34,2.66-2.68,3.99-4.02c3.35,0.03,6.41-0.51,8.02-3.98c17.37-4.04,34.74-3.72,52.11-0.02
          c0.01,5.29,2.92,7.72,7.96,8.03c1.34,1.34,2.67,2.67,4.01,4.01c0.3,5.05,2.75,7.93,8.03,7.96c-0.03,5.18-0.11,10.31,1.8,15.31
          c3.93,10.27,3.03,20.31-2.16,29.95C2511.62,1003.8,2510.09,1008.65,2510.47,1014z M2467.08,1016.33c4.65-3.72,8.43-6.41,11.83-9.52
          c9.91-9.07,9.54-18.83-1.11-26.52c-3.74-2.7-8.1-4.54-12.01-7.04c-2.41-1.53-3.25-3.98-2.56-6.89c0.53-2.23,2.13-3.43,4.1-3.76
          c3.26-0.55,6.61-0.52,9.87-1.05c1.11-0.18,2.4-1.11,3.03-2.07c1.42-2.2-0.13-4.09-1.82-4.87c-4.18-1.93-6.34-6.95-12.31-7.39
          c-3.59,3.94-7.79,8.04-11.4,12.61c-6.7,8.47-5.37,16.05,3.51,21.75c4.44,2.85,9.19,5.24,13.45,8.33c2.18,1.58,2.6,4.46,1.89,7.26
          c-0.78,3.07-3.26,4.47-5.87,4.38c-3.9-0.14-7.77-1.2-11.66-1.82c-2.32-0.37-3.74,0.87-4.44,2.9c-0.7,2.04-0.3,4.1,1.54,5.28
          C2457.47,1010.7,2461.97,1013.27,2467.08,1016.33z"
      />
      <path
        class="st0"
        d="M4350.46,861.89c1.33,1.34,2.66,2.68,3.99,4.03c0.54,2.12,1.87,3.46,4,4c1.34,1.33,2.68,2.66,4.02,3.99
          c-0.05,3.35,0.69,6.32,3.98,8.01c1.34,1.33,2.68,2.66,4.02,3.99c0.68,5.87-1.11,11.14-4.01,16.12c-5.77-0.18-11.34,0.25-16.89,2.41
          c-3.43,1.34-7.47,1.12-11.24,1.6c-2.3-15.38-4.51-17.6-19.96-20.02c-11.07-10.08-6.75-21.65-2.63-32.77
          c1.6-4.31,6.82-3.12,10.71-3.36c1.74,3.19,4.72,3.89,8,4c1.74,3.18,4.69,3.95,7.99,4.01
          C4344.09,861.29,4347.1,861.93,4350.46,861.89z"
      />
      <path
        class="st0"
        d="M2814.35,3433.22c0.37-3.91,0.13-7.79,2.61-11.42c12.92-18.92,20.92-39.94,25.79-62.24
          c0.28-1.3,0.58-2.6,0.98-3.86c2.54-7.89,4.55-9.59,12.71-9.91c3.97-0.15,7.97,0.3,11.96,0.48c-0.12,21.83-0.23,43.65-0.36,65.48
          c-0.03,4.63,0.07,9.27-0.26,13.88c-0.33,4.63-3.15,6.97-7.8,7.01c-5.29,0.05-10.58,0.06-15.87,0.13
          C2834.18,3432.91,2824.26,3433.07,2814.35,3433.22z"
      />
      <path
        class="st0"
        d="M2667.83,3415.27c-1.31-0.14-2.81-0.76-3.92-0.35c-18.99,7.13-37.47,2.77-53.84-6.07
          c-34.13-18.44-56.48-45.65-56.41-87c0.02-15.38,0.22-30.6,7.24-44.74c14.28-28.74,35.89-49.07,67.35-57.71
          c10.41-2.86,21.05-2.57,31.55-0.2c3.85,0.87,7.48,2.84,11.61,1.87c4.24,1.91,8.57,3.64,12.71,5.75
          c66.08,33.74,76.46,120.34,20.23,168.55C2693.6,3404.59,2681.17,3410.76,2667.83,3415.27z"
      />
      <path
        class="st2"
        d="M2667.83,3415.27c13.33-4.5,25.77-10.67,36.52-19.9c56.23-48.21,45.85-134.8-20.23-168.55
          c-4.14-2.11-8.47-3.84-12.71-5.75c2.83-2.58,5.94-2.05,9.28-1.16c24.3,6.45,42.44,21.54,56.7,41.44
          c33.02,46.08,19.16,123.34-45.3,149.27C2684.42,3413.71,2677.17,3420.27,2667.83,3415.27z"
      />
      <path
        class="st2"
        d="M2474.87,3435c2.52-1.91,6.27-3.31,4.22-7.57c-1.72-3.57-3.87-6.93-5.86-10.36
          c-12.82-22.04-22.04-45.31-24.53-70.92c7.31-0.55,12.75,0.8,14.42,9.63c4.49,23.84,15.3,45.23,26.74,66.36
          c5.67,10.47,5.35,11.1-2.05,19.18c-0.9,0.98-1.87,1.9-2.81,2.84c-19.73,19.83-19.19,15.95,1.02,36.43
          c4.21,4.27,8.55,8.42,12.73,12.71c3.38,3.47,7.28,5.77,12.16,6.26c-8.27,5.39-16.25,4.5-23.18-2.06
          c-10.04-9.5-19.74-19.37-29.35-29.32c-5.09-5.27-4.72-7.14,0.72-13.34C2464.67,3448.48,2472.38,3443.81,2474.87,3435z"
      />
      <path
        class="st2"
        d="M2417.35,3344.3c-1.97,0.2-3.94,0.55-5.92,0.58c-3.6,0.05-7.18-1.28-7.26-5.1
          c-0.33-15.88-0.33-31.78,0.01-47.66c0.08-3.81,3.66-5.13,7.27-5.09c1.98,0.02,3.95,0.36,5.92,0.56
          C2417.36,3306.5,2417.36,3325.4,2417.35,3344.3z"
      />
      <path
        class="st2"
        d="M2610.04,3125.83c0.33-11.58,0.58-23.17,1.02-34.75c0.31-8.41,1.1-9.01,10.77-9.08
          c1.26-0.01,2.52,0.48,3.78,0.74c-0.02,10.5,0.13,20.99-0.11,31.48c-0.23,9.89-0.69,10.17-11.53,11.45
          C2612.68,3125.83,2611.36,3125.79,2610.04,3125.83z"
      />
      <path
        class="st2"
        d="M2762.42,3153.8c5.81-6.09,11.81-12.02,17.37-18.33c4.99-5.66,10.99-6.23,17.71-4.7
          c-7.74,8.1-15.3,16.4-23.31,24.22C2770.32,3158.77,2765.89,3158.71,2762.42,3153.8z"
      />
      <path
        class="st2"
        d="M2603.63,3127.37c0,0,1.7-0.24,1.7-0.23c0,0-0.77,0.69-0.77,0.69L2603.63,3127.37z"
      />
      <path
        class="st2"
        d="M4580.18,3154.8c-0.21,7.87-0.24,15.75-0.68,23.61c-0.25,4.52-3.1,7.3-7.61,7.37
          c-7.21,0.12-14.43-0.24-21.64-0.39c0.02-38.55,0.04-77.11,0.06-115.68c9.68-1.71,19.37-1.73,29.07-0.2
          C4579.16,3097.96,4577.65,3126.4,4580.18,3154.8z"
      />
      <path
        class="st1"
        d="M4550.31,3069.72c-0.02,38.56-0.04,77.12-0.06,115.68c-8.13-0.16-16.27-0.32-24.4-0.48
          c0.27-23.17,0.56-46.33,0.82-69.5c0.19-16.55,0.36-33.1,0.54-49.65c4.35,0.13,8.68,0.31,13.02,0.37
          C4544.02,3066.2,4547.7,3066.41,4550.31,3069.72z"
      />
      <path
        class="st3"
        d="M4580.18,3154.8c-2.53-28.41-1.03-56.85-0.8-85.28c6.62,0.14,13.25,0.06,19.85,0.49
          c5.63,0.37,7.81,3.81,6.5,9.68c-0.72,3.21-1.82,6.33-2.68,9.51c-4.8,17.79-9.44,35.62-14.45,53.35
          C4587.22,3147.4,4586.9,3153.43,4580.18,3154.8z"
      />
      <path
        class="st1"
        d="M3468.28,3217.86c-1.55-3.55-2.21-9.1-4.78-10.2c-8.53-3.63-9.52-9.86-9.06-17.68
          c0.38-6.53,0.08-13.1,1.18-19.67c4.07,5.83,1.98,12.31,2.68,18.43c0.81,7.06,2.6,8.92,10.19,9.34c3.98,0.22,7.99,0.13,11.98,0.13
          c53.93,0.01,107.86,0.02,161.79-0.01c7.28,0,14.64,0.77,21.64-2.27c2.12-0.92,3.93,0.07,4.28,2.39c0.17,1.13-0.29,2.75-1.08,3.55
          c-1.36,1.38-3.06,2.89-4.85,3.27c-5.42,1.15-7.62,4.41-8.33,9.67c-0.33,2.44-1.69,5.17-3.45,6.89c-3.14,3.09-4.21,6.64-4.21,10.76
          c0,21.3,0.03,42.61-0.01,63.91c-0.01,5.33,2.22,8.51,7.54,10.12c8.11,2.45,8.72,9.13,7.93,16.17c-0.4,3.59-2.15,6.36-6.06,6.72
          c-3.96,0.36-7.96,0.45-11.95,0.45c-55.26,0.03-110.52,0.02-165.78,0.02c-2,0-4,0.04-5.99-0.03c-11.83-0.4-13.83-2.56-13.25-14.21
          c0.21-4.21,0.99-8.44,5.95-8.98c8.97-0.98,10.06-6.76,9.98-14.08c-0.18-17.31-0.06-34.62-0.04-51.93c0-4.66-0.13-9.33,0.15-13.98
          c0.36-5.98,2.38-7.8,8.89-8.29c5.96-0.45,11.97-0.39,17.88-1.16c2.82-0.37,4.46-3.07,3.77-5.95c-0.41-1.7-1.89-4.14-3.29-4.45
          c-8.51-1.92-17.19-1.33-25.81-0.57c-2.92,0.26-4.51,2.34-5.36,5.13C3470.15,3213.56,3471.06,3216.42,3468.28,3217.86z
           M3560.98,3306.06c0-0.02,0-0.05,0-0.07c15.96,0,31.93,0.18,47.88-0.08c7.24-0.12,9.42-2.37,9.57-9.81
          c0.31-15.29-0.1-30.6,0.3-45.89c0.33-12.64-1.21-24.24-7.51-35.9c-3.58-6.63-6.94-8.66-13.34-8.65
          c-25.27,0.03-50.55,0.01-75.82,0.02c-1.33,0-2.66,0.08-3.98,0.21c-3.58,0.35-6.66,1.91-7.21,5.61c-0.82,5.42-3.01,9.98-6.02,14.51
          c-1.71,2.57-2.47,6.22-2.5,9.39c-0.21,19.95-0.27,39.91-0.02,59.85c0.11,8.46,2.27,10.58,10.77,10.73
          C3529.06,3306.25,3545.02,3306.06,3560.98,3306.06z M3560.5,3309.87c0,0.01,0,0.01,0,0.02c-26.54,0-53.08-0.04-79.62,0.04
          c-4.62,0.01-9.31,0.18-13.83,1c-2.72,0.49-4,3.24-4.21,6.05c-0.27,3.52,0.56,6.96,4.14,8.1c3.08,0.98,6.53,1.03,9.82,1.04
          c54.41,0.06,108.81,0.06,163.22,0c3.29,0,6.68-0.19,9.84-0.99c2.73-0.69,4.03-3.25,4.25-6.06c0.28-3.53-0.62-7.2-4.17-8.07
          c-4.43-1.08-9.19-1.08-13.81-1.1C3610.92,3309.82,3585.71,3309.87,3560.5,3309.87z M3478.39,3262.99c0,11.24-0.05,22.48,0.03,33.72
          c0.03,3.35,0.73,6.58,4.21,8.22c7.67,3.6,15.54-0.33,15.66-8.39c0.3-20.49,0.15-40.99,0.1-61.48c-0.01-1.94-0.43-3.94-0.99-5.81
          c-1.48-4.92-7.01-7.87-12.8-6.79c-4.81,0.9-6.13,4.58-6.18,8.8C3478.31,3241.83,3478.39,3252.41,3478.39,3262.99z M3642.62,3264.37
          c-0.16,0-0.31,0-0.47,0c0-11.9,0.18-23.79-0.1-35.68c-0.09-4.15-3.95-6.67-8.4-6.42c-6.11,0.35-10.35,3.54-10.82,9.43
          c-0.63,7.89-0.48,15.85-0.53,23.78c-0.07,11.25-0.12,22.49,0.01,33.74c0.16,14.38,1.89,16.95,10.68,16.71
          c7.84-0.21,9.41-2.67,9.6-15.76C3642.73,3281.57,3642.62,3272.96,3642.62,3264.37z M3632.41,3218.5c4.61-0.41,9.2-0.5,13.63-1.41
          c1.37-0.28,2.81-2.62,3.29-4.28c0.8-2.79-0.74-5.59-3.35-6.09c-9-1.74-18.13-1.7-27.13,0.04c-1.36,0.26-2.81,2.63-3.29,4.29
          c-0.82,2.8,0.74,5.5,3.35,6.06C3623.31,3218.07,3627.92,3218.09,3632.41,3218.5z"
      />
      <path
        class="st1"
        d="M3669.11,3173.4c-1.12,1.07-2,2.53-2.71,2.46c-3.38-0.35-3.5-3.07-3.18-5.6c0.21-1.65,1.69-2.25,2.8-1.2
          C3667.28,3170.26,3668.08,3171.91,3669.11,3173.4z"
      />
      <path
        class="st1"
        d="M3498.49,3146.01c0.78-0.93,1.41-2.09,2.38-2.74c1.38-0.92,2.49-0.33,2.65,1.28c0.03,0.27-0.93,0.83-1.5,0.94
          c-1.21,0.23-2.45,0.26-3.68,0.38C3498.35,3145.87,3498.49,3146.01,3498.49,3146.01z"
      />
      <path
        class="st1"
        d="M3630.45,3149.88c-1.23-0.12-2.47-0.16-3.67-0.41c-0.56-0.11-1.52-0.68-1.49-0.94
          c0.18-1.61,1.29-2.18,2.66-1.24c0.96,0.66,1.59,1.81,2.37,2.75L3630.45,3149.88z"
      />
      <path
        class="st1"
        d="M3630.32,3150.04c3.28,0.09,6.3,0.71,8.01,3.98l0.14-0.14c-3.3-0.07-6.37-0.6-8.02-3.99
          C3630.45,3149.88,3630.32,3150.04,3630.32,3150.04z"
      />
      <path
        class="st1"
        d="M3498.35,3145.87c-0.29,0.58-0.7,1.69-0.85,1.65c-1.36-0.3-1.01-0.9,0.99-1.51
          C3498.49,3146.01,3498.35,3145.87,3498.35,3145.87z"
      />
      <path
        class="st1"
        d="M3638.33,3154.01c0.59,0.29,1.71,0.71,1.68,0.85c-0.32,1.36-0.92,1.01-1.54-0.99
          C3638.47,3153.87,3638.33,3154.01,3638.33,3154.01z"
      />
      <path
        class="st2"
        d="M4274.33,3033.73c-10.46,8.13-19.95,17.46-31.49,24.24c-58.55,34.41-130.24,20.5-172.97-34.52
          c-3.63-4.67-5.85-10.57-11.6-13.42c-0.56-2.03-1.64-3.49-3.97-3.53c0,0-0.5-0.27-0.5-0.27c1.16-5.53-1.37-10.31-2.79-15.42
          c-3.18-11.47-6.23-23.02-8.28-34.73c-2.69-15.39,1.04-30.54,5.05-45.14c14.37-52.21,49.31-84.76,101.45-95.65
          c32.96-6.89,65.8-1.53,95.59,17.42c31.41,19.98,51.54,47.19,59.08,83.65c7.15,34.61,2.3,67.29-16.18,97.63
          C4283.57,3020.78,4278.8,3027.17,4274.33,3033.73z"
      />
      <path
        class="st3"
        d="M4054.29,3006.51c2.34,0.04,3.41,1.5,3.97,3.53C4056.94,3008.86,4055.62,3007.69,4054.29,3006.51z"
      />
      <path
        class="st2"
        d="M1855.31,1368.93c-1.24-1.33-2.47-2.66-3.71-4c-2.67-9.57-3.69-19.04,1.76-28.09c4.05-2.02,7.9-4.8,12.18-5.92
          c8.98-2.34,16.92,0.63,22.68,7.72c5.19,6.39,7.37,13.74,3.02,21.74c-1.24,2.28-2.39,4.6-3.58,6.9
          C1877.26,1375.2,1866.46,1375.48,1855.31,1368.93z"
      />
      <path
        class="st2"
        d="M1900.12,1388.24c-1.41-1.25-2.82-2.51-4.23-3.77c-1.29-1.31-2.58-2.61-3.87-3.92
          c-1.58-3.09-1.76-6.26-0.56-9.51c1.94,0.09,3.88,0.19,5.81,0.28c1.31,1.28,2.62,2.57,3.92,3.85c1.33,1.33,2.67,2.66,4.01,3.99
          c1.33,1.33,2.67,2.66,4,4c1.33,1.33,2.66,2.66,3.99,4c1.09,1.35,2.18,2.7,3.27,4.05c-1.71,3.57-3.86,6.35-8.46,4.77
          c-1.3-1.25-2.6-2.49-3.89-3.74C1902.79,1390.9,1901.45,1389.57,1900.12,1388.24z"
      />
      <path
        class="st1"
        d="M1171.04,2022.68c39.48,0,78.97,0,120.39,0c-4.5,1.29-0.01-0.4-1.67,1.69c-0.39,0.49-1.08,1.07-1.63,1.08
          c-31.11,0.16-62.22,0.27-93.34,0.37c-7.85,0.03-15.71,0-23.56,0C1171.17,2024.77,1171.11,2023.73,1171.04,2022.68z"
      />
      <path
        class="st1"
        d="M1171.04,1942.82c39.5,0,78.99,0,120.4,0C1277.9,1946.75,1202.98,1947.58,1171.04,1942.82z"
      />
      <path
        class="st1"
        d="M1206.36,1998.01c16.03,0,32.06,0,48.08,0l-0.1-0.1c-0.43,0.48-0.78,1.22-1.32,1.4
          c-10.81,3.69-34.44,3.69-45.25,0c-0.54-0.18-0.88-0.92-1.32-1.4C1206.46,1997.91,1206.36,1998.01,1206.36,1998.01z"
      />
      <path
        class="st1"
        d="M1145.78,2000.19c-4.02-10.71-4.92-21.38,0.24-31.96C1145.77,1978.89,1146.15,1989.55,1145.78,2000.19z"
      />
      <path
        class="st1"
        d="M1310.48,2002.02c6.92-10.61,2.66-22.73,3.99-34.39c5.2,2.85,3.83,7.28,3.8,10.99
          c-0.05,6.6,1.22,13.35-1.22,19.77c-1.1,2.9-4.12,2.94-6.67,3.51L1310.48,2002.02z"
      />
      <path
        class="st1"
        d="M1258.46,1994c0.08-4.5,0.05-9.01,0.29-13.51c0.1-1.83,0.79-4.21,3.24-3.1c1.49,0.67,2.95,2.67,3.33,4.33
          c1.35,5.98-1.79,9.74-6.95,12.19C1258.36,1993.9,1258.46,1994,1258.46,1994z"
      />
      <path
        class="st1"
        d="M1202.45,1993.9c-6.13-4.46-3.57-10.22-1.03-15.96c0.31,5.35,0.62,10.7,0.93,16.05L1202.45,1993.9z"
      />
      <path
        class="st1"
        d="M1164.21,1955.37c-0.28-0.44-0.82-0.89-0.79-1.31c0.09-1.56,0.85-1.97,1.8-1.02c0.3,0.3,0.35,1.1,0.18,1.55
          C1165.27,1954.94,1164.63,1955.11,1164.21,1955.37z"
      />
      <path
        class="st1"
        d="M1296.88,1952.69c0.18,0.48,0.61,1.03,0.51,1.44c-0.39,1.56-1.22,1.73-2,0.4c-0.21-0.35,0-1.11,0.27-1.52
          C1295.83,1952.74,1296.46,1952.78,1296.88,1952.69z"
      />
      <path
        class="st1"
        d="M1310.37,2001.9c-0.81,0.96-1.47,2.14-2.48,2.81c-1.43,0.95-2.58,0.33-2.75-1.34
          c-0.03-0.29,0.95-0.88,1.53-0.99c1.24-0.24,2.53-0.25,3.8-0.35C1310.48,2002.02,1310.37,2001.9,1310.37,2001.9z"
      />
      <path
        class="st1"
        d="M1163.84,2012.8c0.42,0.04,1.05-0.06,1.21,0.17c0.27,0.4,0.5,1.11,0.32,1.5c-0.75,1.61-1.55,1.53-2-0.21
          C1163.26,2013.84,1163.67,2013.29,1163.84,2012.8z"
      />
      <path
        class="st1"
        d="M1297.49,2013.67c-0.18,0.49-0.23,1.25-0.57,1.42c-1.09,0.53-1.72-0.12-1.46-1.61
          c0.07-0.43,0.72-0.76,1.1-1.14C1296.88,2012.78,1297.18,2013.22,1297.49,2013.67z"
      />
      <path
        class="st1"
        d="M1202.35,1994c1.34,1.34,2.68,2.68,4.01,4.02c0,0,0.1-0.1,0.1-0.1c-2.11-0.56-3.45-1.9-4.02-4.01
          C1202.45,1993.9,1202.35,1994,1202.35,1994z"
      />
      <path
        class="st1"
        d="M1254.45,1998.01c1.34-1.34,2.67-2.68,4.01-4.02c0,0-0.1-0.1-0.1-0.1c-0.56,2.11-1.9,3.45-4.01,4.01
          C1254.35,1997.91,1254.45,1998.01,1254.45,1998.01z"
      />
      <path
        class="st1"
        d="M1458.37,1018.01c2.69-1.33,5.38-2.67,8.08-4c0,0-0.11-0.11-0.11-0.11c0.39,4,2.1,6.9,5.75,9.11
          c5.85,3.54,12.03,3.36,18.37,2.88c-0.21,3.82,0.52,8.3-2.76,10.83c-4.54,3.5-4.3,7.1-2.58,11.81c1.07,2.93,0.94,6.31,1.35,9.48
          c-4.46-2.27-7.26-5.73-7.74-10.83c-0.31-3.3-0.23-6.7-0.99-9.9c-1.06-4.4-8.17-9.52-12.18-8.73c-4.67,0.92-5.56,5.41-6.46,9.05
          c-1.47,5.9-0.94,11.77,1.74,17.5c2.23,4.78,3.71,9.9,5.52,14.87l0.1-0.11c-9.99-1.8-10.88-9.89-11.77-17.6
          c-1.34-11.7,0.47-23.16,3.8-34.4C1458.48,1017.89,1458.37,1018.01,1458.37,1018.01z"
      />
      <path
        class="st1"
        d="M1530.34,1077.91c-1.16,1.6-2.01,3.63-3.52,4.73c-14.32,10.44-29.86,8.84-45.56,4.12
          c-4.11-1.24-6.57-4.34-6.79-8.86c0,0-0.11,0.11-0.11,0.11c4.99,1.49,10.17,2.53,14.92,4.57c8.99,3.85,17.58,4.41,26.21-0.8
          c4.56-2.75,9.55-4.22,14.97-3.75C1530.46,1078.03,1530.34,1077.91,1530.34,1077.91z"
      />
      <path
        class="st1"
        d="M1522.36,1006.02c14.09,1.84,14.15,1.91,15.98,15.98c0,0,0.13-0.09,0.13-0.09
          c-6.56-4.12-11.89-9.47-16.02-16.02L1522.36,1006.02z"
      />
      <path
        class="st1"
        d="M1542.33,1030.03c6.31,10.61,5.91,21.23,0.03,31.85c0,0,0.12,0.13,0.12,0.13c0-10.7-0.01-21.4-0.01-32.1
          L1542.33,1030.03z"
      />
      <path
        class="st1"
        d="M1482.47,1002c12.49-5.66,20.02-5.65,31.86,0.03c0,0,0.12-0.14,0.12-0.14c-10.7,0-21.4,0-32.1-0.01
          L1482.47,1002z"
      />
      <path
        class="st1"
        d="M1538.34,1065.91c0.02,3.33-0.7,6.29-3.98,7.98c0,0,0.12,0.12,0.12,0.12c-0.09-3.37,0.68-6.32,3.97-8.02
          L1538.34,1065.91z"
      />
      <path
        class="st1"
        d="M1458.48,1017.89c-1.25-0.09-2.51-0.09-3.74-0.3c-0.58-0.1-1.56-0.62-1.55-0.9c0.09-1.65,1.19-2.27,2.63-1.35
          c1.01,0.64,1.71,1.77,2.55,2.68C1458.37,1018.01,1458.48,1017.89,1458.48,1017.89z"
      />
      <path
        class="st1"
        d="M1470.47,1010c1.67-3.32,4.65-4,7.99-3.97l-0.09-0.11c-1.7,3.28-4.65,4.04-8.01,3.97L1470.47,1010z"
      />
      <path
        class="st1"
        d="M1514.33,1002.03c3.33,0.03,6.47,0.42,8.03,3.99c0,0,0.09-0.13,0.09-0.13c-3.27-0.13-6.26-0.81-8-4
          L1514.33,1002.03z"
      />
      <path
        class="st1"
        d="M1538.34,1022c3.57,1.56,3.95,4.71,3.99,8.03c0,0,0.14-0.12,0.14-0.12c-3.19-1.74-3.87-4.73-4-8.01
          L1538.34,1022z"
      />
      <path
        class="st1"
        d="M1542.36,1061.89c-0.65,2.03-1.86,3.5-4.02,4.02c0,0,0.1,0.08,0.1,0.08c1.35-1.33,2.69-2.65,4.04-3.98
          L1542.36,1061.89z"
      />
      <path
        class="st1"
        d="M1470.46,1073.88c-2.12-0.55-3.46-1.87-4.01-3.99c0,0-0.1,0.11-0.1,0.11c1.34,1.33,2.67,2.67,4.01,4
          L1470.46,1073.88z"
      />
      <path
        class="st1"
        d="M1474.47,1077.91c-1.34-1.34-2.68-2.68-4.01-4.02c0,0-0.1,0.11-0.1,0.11c1.33,1.34,2.67,2.68,4,4.01
          L1474.47,1077.91z"
      />
      <path
        class="st1"
        d="M1534.36,1073.89c-1.34,1.34-2.68,2.68-4.02,4.02c0,0,0.12,0.12,0.12,0.12c1.34-1.34,2.68-2.68,4.02-4.02
          L1534.36,1073.89z"
      />
      <path
        class="st1"
        d="M1466.45,1014.01c1.34-1.34,2.68-2.68,4.02-4.02c0,0-0.11-0.11-0.11-0.11c-1.34,1.34-2.68,2.68-4.02,4.02
          C1466.33,1013.9,1466.45,1014.01,1466.45,1014.01z"
      />
      <path
        class="st1"
        d="M1478.46,1006.02c1.34-1.34,2.67-2.68,4.01-4.02c0,0-0.12-0.12-0.12-0.11c-0.48,2.18-1.95,3.39-3.98,4.03
          L1478.46,1006.02z"
      />
      <path
        class="st1"
        d="M2467.08,1016.33c-5.11-3.06-9.61-5.63-13.96-8.42c-1.84-1.18-2.23-3.24-1.54-5.28
          c0.69-2.03,2.11-3.28,4.44-2.9c3.89,0.63,7.76,1.69,11.66,1.82c2.61,0.09,5.09-1.3,5.87-4.38c0.71-2.8,0.29-5.68-1.89-7.26
          c-4.26-3.09-9.01-5.48-13.45-8.33c-8.89-5.7-10.21-13.28-3.51-21.75c3.61-4.57,7.81-8.67,11.4-12.61
          c5.97,0.44,8.13,5.47,12.31,7.39c1.69,0.78,3.24,2.67,1.82,4.87c-0.62,0.97-1.92,1.89-3.03,2.07c-3.26,0.53-6.61,0.5-9.87,1.05
          c-1.96,0.33-3.57,1.53-4.1,3.76c-0.69,2.9,0.15,5.35,2.56,6.89c3.91,2.49,8.27,4.33,12.01,7.04c10.65,7.69,11.02,17.45,1.11,26.52
          C2475.51,1009.92,2471.73,1012.61,2467.08,1016.33z"
      />
      <path
        class="st0"
        d="M3560.98,3306.06c-15.96,0-31.93,0.19-47.88-0.08c-8.5-0.14-10.66-2.26-10.77-10.73
          c-0.25-19.95-0.2-39.91,0.02-59.85c0.03-3.17,0.79-6.82,2.5-9.39c3.01-4.53,5.2-9.09,6.02-14.51c0.56-3.7,3.63-5.26,7.21-5.61
          c1.32-0.13,2.65-0.21,3.98-0.21c25.27-0.01,50.55,0,75.82-0.02c6.4-0.01,9.76,2.02,13.34,8.65c6.3,11.67,7.84,23.26,7.51,35.9
          c-0.4,15.29,0.01,30.59-0.3,45.89c-0.15,7.44-2.33,9.69-9.57,9.81c-15.96,0.26-31.92,0.08-47.88,0.08
          C3560.98,3306.01,3560.98,3306.04,3560.98,3306.06z"
      />
      <path
        class="st0"
        d="M3560.5,3309.87c25.21,0,50.42-0.05,75.64,0.04c4.62,0.02,9.38,0.02,13.81,1.1c3.55,0.87,4.45,4.53,4.17,8.07
          c-0.22,2.81-1.52,5.36-4.25,6.06c-3.16,0.8-6.55,0.99-9.84,0.99c-54.41,0.05-108.81,0.05-163.22,0c-3.29,0-6.75-0.06-9.82-1.04
          c-3.59-1.14-4.41-4.59-4.14-8.1c0.21-2.81,1.49-5.56,4.21-6.05c4.52-0.82,9.21-0.99,13.83-1c26.54-0.08,53.08-0.04,79.62-0.04
          C3560.5,3309.88,3560.5,3309.88,3560.5,3309.87z"
      />
      <path
        class="st0"
        d="M3478.39,3262.99c0-10.58-0.07-21.16,0.03-31.73c0.04-4.22,1.37-7.91,6.18-8.8c5.79-1.08,11.31,1.87,12.8,6.79
          c0.56,1.87,0.99,3.87,0.99,5.81c0.06,20.49,0.21,40.99-0.1,61.48c-0.12,8.06-7.99,11.99-15.66,8.39c-3.48-1.64-4.18-4.87-4.21-8.22
          C3478.34,3285.46,3478.39,3274.22,3478.39,3262.99z"
      />
      <path
        class="st0"
        d="M3642.62,3264.37c0,8.6,0.1,17.2-0.02,25.8c-0.19,13.09-1.76,15.55-9.6,15.76
          c-8.79,0.24-10.51-2.33-10.68-16.71c-0.13-11.24-0.08-22.49-0.01-33.74c0.05-7.93-0.1-15.89,0.53-23.78
          c0.47-5.89,4.71-9.08,10.82-9.43c4.45-0.25,8.3,2.26,8.4,6.42c0.27,11.89,0.1,23.79,0.1,35.68
          C3642.31,3264.36,3642.47,3264.36,3642.62,3264.37z"
      />
      <path
        class="st0"
        d="M3632.41,3218.5c-4.49-0.41-9.1-0.44-13.5-1.39c-2.61-0.56-4.17-3.26-3.35-6.06c0.49-1.66,1.93-4.02,3.29-4.29
          c9-1.74,18.13-1.78,27.13-0.04c2.61,0.5,4.14,3.3,3.35,6.09c-0.48,1.66-1.92,4-3.29,4.28
          C3641.61,3218,3637.02,3218.09,3632.41,3218.5z"
      />
      <path
        class="st0"
        d="M1478.37,1005.91c2.04-0.64,3.5-1.85,3.98-4.03c10.7,0,21.4,0,32.1,0c1.74,3.19,4.73,3.87,8,4
          c4.13,6.55,9.46,11.9,16.02,16.02c0.13,3.27,0.81,6.26,4,8.01c0,10.7,0.01,21.4,0.01,32.1c-1.35,1.33-2.69,2.65-4.04,3.98
          c-3.29,1.7-4.06,4.65-3.97,8.02c-1.34,1.34-2.68,2.68-4.02,4.02c-5.42-0.47-10.41,1-14.97,3.75c-8.64,5.21-17.23,4.66-26.21,0.8
          c-4.75-2.04-9.93-3.08-14.92-4.57c-1.33-1.34-2.67-2.68-4-4.01c-1.34-1.33-2.67-2.67-4.01-4c-1.81-4.97-3.29-10.1-5.52-14.87
          c-2.68-5.73-3.21-11.6-1.74-17.5c0.91-3.65,1.79-8.13,6.46-9.05c4.02-0.79,11.13,4.33,12.18,8.73c0.77,3.19,0.68,6.59,0.99,9.9
          c0.49,5.1,3.28,8.56,7.74,10.83l-0.17-0.14c8.37,9.47,10.4,9.88,20.22,4.01l-0.22,0.14c10.97-1.27,10.97-1.27,12.18-12.18
          l-0.14,0.22c5.67-4.14,4.15-9.93,3.13-15.32c-0.66-3.47-3.97-4.4-7.15-4.88l0.17,0.17c-0.59-2.59-0.91-5.74-3.77-6.57
          c-7.08-2.06-14.25-3.32-20.43,2.55l0.17-0.13c-6.34,0.48-12.52,0.66-18.37-2.88c-3.65-2.21-5.36-5.11-5.75-9.11
          c1.34-1.34,2.68-2.68,4.02-4.02C1473.72,1009.96,1476.67,1009.19,1478.37,1005.91z"
      />
      <path
        class="st0"
        d="M1486.47,1058.02c-0.41-3.18-0.27-6.55-1.35-9.48c-1.73-4.71-1.97-8.31,2.58-11.81
          c3.29-2.53,2.55-7.01,2.76-10.83c0,0-0.17,0.13-0.17,0.13c8.52-1.39,16.22,2.17,24.2,4.02c0,0-0.17-0.17-0.17-0.17
          c0.92,6.82,5.45,12.91,4.02,20.2c0,0,0.14-0.22,0.14-0.22c-5.07,3.05-9.29,6.95-12.18,12.18c0,0,0.22-0.14,0.22-0.14
          c-6.74-1.34-13.48-2.67-20.22-4.01L1486.47,1058.02z M1499.61,1040.15c-0.24-0.4-0.4-1.02-0.74-1.15
          c-0.42-0.16-1.19-0.11-1.48,0.18c-0.91,0.91-0.51,1.64,0.97,1.73C1498.76,1040.94,1499.19,1040.42,1499.61,1040.15z"
      />
      <path
        class="st1"
        d="M1514.49,1030.04c-7.98-1.85-15.68-5.41-24.2-4.02c6.17-5.88,13.35-4.61,20.42-2.55
          C1513.58,1024.3,1513.9,1027.44,1514.49,1030.04z"
      />
      <path
        class="st1"
        d="M1486.3,1057.88c6.74,1.34,13.48,2.67,20.22,4.01C1496.7,1067.76,1494.67,1067.35,1486.3,1057.88z"
      />
      <path
        class="st1"
        d="M1518.34,1050.07c1.43-7.28-3.09-13.38-4.02-20.2c3.18,0.48,6.49,1.41,7.15,4.88
          C1522.49,1040.14,1524.01,1045.93,1518.34,1050.07z"
      />
      <path
        class="st1"
        d="M1506.3,1062.03c2.89-5.23,7.11-9.12,12.18-12.18C1517.27,1060.76,1517.27,1060.76,1506.3,1062.03z"
      />
      <path
        class="st1"
        d="M1499.61,1040.15c-0.42,0.27-0.85,0.78-1.25,0.76c-1.49-0.09-1.88-0.82-0.97-1.73
          c0.29-0.29,1.05-0.35,1.48-0.18C1499.21,1039.13,1499.37,1039.75,1499.61,1040.15z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "LoginSVG",
  data: function () {
    return {};
  },
};
</script>
<style type="text/css">
.st0 {
  fill: transparent;
}
.st1 {
  fill: var(--v-primary-lighten3);
}
.st2 {
  fill: var(--v-primary-darken1);
}
.st3 {
  fill: var(--v-primary-darken1);
}
.st4 {
  fill: var(--v-misc-base);
}
.st5 {
  fill: var(--v-primary-base);
}
.st6 {
  fill: var(--v-primary-base);
}

.login--text {
  fill: var(--v-primary-darken1);
  font-size: 20rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
</style>
