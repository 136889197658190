import call from "@/packages/service/http";
import constants from "./constants";
export default {
  namespaced: true,
  state: {
    selectedObjective: null,
    selectedActivity: null,
    selectedContract: null,
    contracts: [],
    objectives: [],
    contractloading: false,
    activityloading: false,
    editActivityloading: false,
    activities: [],
    assignees: [],
    subAwardeeAssignees: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: { programmeGetters: (state) => (val) => state[val] },
  actions: {
    updateSelectedContract: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedContract", value: payload });
    },
    updateSelectedObjective: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedObjective", value: payload });
    },
    updateActivities: ({ commit }, payload) => {
      commit("MUTATE", { state: "activities", value: payload });
    },
    updateSelectedActivity: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedActivity", value: payload });
    },
    fetchContract: ({ commit, state }) => {
      state.contracts = [];
      commit("MUTATE", { state: "contractloading", value: true });
      call("get", constants.contract)
        .then((res) => {
          commit("MUTATE", { state: "contracts", value: res.data.data });
          commit("MUTATE", { state: "contractloading", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "contractloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    fetchActivities: ({ commit }, payload) => {
      commit("MUTATE", { state: "activityloading", value: true });
      commit("MUTATE", { state: "activities", value: [] });
      call("get", constants.activities(payload))
        .then((res) => {
          commit("MUTATE", { state: "activities", value: res.data.data });
          commit("MUTATE", { state: "activityloading", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "activityloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    fetchContractActivities: ({ commit, dispatch, state }, payload) => {
      state.contracts = [];
      dispatch("updateSelectedObjective", payload);
      commit("MUTATE", { state: "contractloading", value: true });
      commit("MUTATE", { state: "activities", value: [] });
      call("get", constants.activities(payload))
        .then((res) => {
          commit("MUTATE", { state: "contracts", value: res.data.data });
          commit("MUTATE", { state: "contractloading", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "contractloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    fetchDocument: ({ commit, dispatch }, payload) => {
      commit("MUTATE", { state: "documentloading", value: true });
      call("post", constants.document, payload)
        .then((res) => {
          dispatch("updateSelectedObjective", res.data.data);
          dispatch("updateSelectedActivity", res.data.data.activity);
          Event.$emit("selectRow");
          commit("MUTATE", { state: "documentloading", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "documentloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    fetchAssignees: ({ commit }, type) => {
      call("get", type !== 'subAwardee' ? constants.employeeAssignees :  constants.subawardeeAssignees )
        .then((res) => {
          commit("MUTATE", type !== 'subAwardee' ?  {state: "assignees", value: res.data.data} : {state: "subAwardeeAssignees", value: res.data.data} );
        })
        .catch((err) => {
          commit("MUTATE", { state: "assigneeloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getObjectives: ({ commit, state, dispatch }, payload) => {
      // get single objective
      state.contracts.filter((obj) => {
        if (obj.no === payload) {
          dispatch("updateSelectedContract", obj);
          commit("MUTATE", {
            state: "objectives",
            value: obj.contractActivitiesLines,
          });
        }
      });
    },
    editActivity: ({ commit, state, dispatch }, payload) => {
      commit("MUTATE", { state: "editActivityloading", value: true });
      call("post", constants.editActivity, payload)
        .then(() => {
          payload.type === "assignee"
            ? dispatch("fetchAssigneeActivities", {
              ...state.selectedObjective,
              type: "Standard",
            })
            : dispatch("fetchActivities", {
              ...state.selectedObjective,
              type: "Standard",
            });
          Event.$emit("close-dialog");
          commit("MUTATE", { state: "editActivityloading", value: false });
          Event.$emit("ApiSuccess", "Activity assigned successfully");
        })
        .catch((err) => {
          commit("MUTATE", { state: "editActivityloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    fetchAssigneeActivities: ({ commit }, payload) => {
      commit("MUTATE", { state: "activityloading", value: true });
      commit("MUTATE", { state: "activities", value: [] });
      call("get", constants.assigneeActivities(payload))
        .then((res) => {
          commit("MUTATE", { state: "activities", value: res.data.data });
          commit("MUTATE", { state: "activityloading", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "activityloading", value: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    deleteAttachment({ commit }, payload) {
      commit("MUTATE", { state: "editActivityloading", value: true });
      call("post", constants.DELETE_ATTACHMENT, payload)
        .then((res) => {
          Event.$emit('remove-attachment', payload);
          Event.$emit("ApiSuccess", res.data.message);
          commit("MUTATE", { state: "editActivityloading", value: false });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("MUTATE", { state: "editActivityloading", value: false });
        });
    },
  },
};
