import { AuthService } from "@/packages/auth";
const listener = {
  mounted: function () {
    Event.$on("ApiError", (message) => {
      this.$toast.warning(message, {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    });
    Event.$on("ApiSuccess", (message) => {
      this.$toast.success(message, {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    });
    Event.$on("ApiInfo", (message) => {
      this.$toast.info(message, {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    });

    Event.$on("ApiWarning", (message) => {
      this.$toast.warning(message, {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    });

    Event.$on("reload", () => {
      this.$router.go();
    });

    Event.$on("refetch", (action) => {
      this.$store.dispatch(`${action}`);
    });

    Event.$on("back", () => {
      this.$router.back();
    });
    Event.$on("resetForm", () => {
      this.$refs.issueForm.reset();
    });

    Event.$on("route-change", (name) => {
      this.$router.push({ name: name });
    });

    Event.$on("back-landing", () => {
      window.location.href = "/dashboard";
    });

    Event.$on("signup-verify", (payload) => {
      this.$verify.show({
        title: "Account OTP Verification",
        payload: {
          name: payload.user.name,
          phone: payload.user.profile.phoneNo,
          email: payload.user.email,
        },
        onVerify: () => {
          AuthService.login(payload.token, payload.user);
          window.location.href = "/";
        },
      });
    });
    Event.$on("login-verify", (payload) => {
      this.$verify.show({
        title: "Device Verification Code",
        payload: {
          name: payload.user.name,
          phone: payload.user.profile.phoneNo,
          email: payload.user.email,
        },
        onVerify: () => {
          AuthService.login(payload.token, payload.user);
          window.location.reload();
        },
      });
    });
  },
};
export default listener;
