<template>
  <div>
    <div class="mt-n6 d-flex justify-end">
      <v-btn text color="secondary" @click="skip">
        skip
        <v-icon small>arrow_forward</v-icon>
      </v-btn>
    </div>

    <div class="d-flex justify-center">
      <v-avatar size="100" color="grey">
        <v-img
          :src="
            profilePicture ? profilePicture : require('@/assets/avatar.png')
          "
        />
      </v-avatar>
    </div>

    <div>
      <v-form ref="resetPasswordForm" @submit.prevent="reset">
        <v-row>
          <v-col cols="12" class="mb-2">
            <v-file-input
              @change="uploadProfilePicture($event)"
              dense
              outlined
              append-icon="mdi-paperclip"
              accept=".jpeg,.png,.jpg,GIF"
              placeholder="Upload Profile"
              prepend-inner-icon="account_circle"
              persistent-placeholder
              prepend-icon=""
              @keyup.enter="reset()"
              :rules="rules.attachment"
            />
          </v-col>
          <v-col cols="12" class="mt-n4">
            <v-text-field
              v-model="formData.password"
              outlined
              dense
              persistent-placeholder
              @copy.prevent
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :rules="rules.password"
              prepend-inner-icon="lock"
              autocomplete="false"
              @keyup.enter="reset()"
            >
              <template v-slot:append>
                <v-btn @click="showPassword = !showPassword" icon>
                  <v-icon small>
                    {{ showPassword ? "visibility" : "visibility_off" }}
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="mt-n4">
            <v-text-field
              v-model="formData.confirmPassword"
              outlined
              persistent-placeholder
              dense
              @copy.prevent
              label="Confirm Password"
              prepend-inner-icon="lock"
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="rules.confirmPassword"
              autocomplete="false"
              @keyup.enter="reset()"
            >
              <template v-slot:append>
                <v-btn @click="showConfirmPassword = !showConfirmPassword" icon>
                  <v-icon small>
                    {{ showConfirmPassword ? "visibility" : "visibility_off" }}
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div>
      <v-btn color="primary" block v-if="!submitting" @click="reset"
        >submit</v-btn
      >
      <v-btn color="accent" block v-else>
        <i class="sync loading-button-spinner"></i>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { helper } from "@/packages/utils";
export default {
  name: "LandingActivation",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitting: false,
      formData: {
        password: "",
        confirmPassword: "",
        avatar: null,
        token: "",
      },
      showPassword: false,
      showConfirmPassword: false,
      image_url: "",
    };
  },
  computed: {
    profile() {
      return null;
    },
    profileDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.password || "Passwords do not match",
        ],
        attachment: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2 MB!",
        ],
      };
    },
    profilePicture() {
      return this.image_url;
    },
  },
  methods: {
    reset: function () {
      this.submitting = true;
      if (!this.$refs.resetPasswordForm.validate()) {
        this.submitting = false;
      } else {
        this.formData.token = this.$route.params.token;
        this.$store.dispatch(
          "Auth/updateProfile",
          helper.prepareFormData(this.formData)
        );
        setTimeout(() => {
          this.submitting = false;
        }, 5000);
      }
    },
    uploadProfilePicture(event) {
      this.formData.avatar = event;
      this["image_url"] = URL.createObjectURL(event);
    },
    skip() {
      const token = { token: this.$route.params.token };
      this.$store.dispatch("Auth/skipChangeDetails", {
        ...token,
      });
    },
  },
};
</script>
<style>
@import url("../style.css");
</style>
