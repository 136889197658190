<template>
  <v-card flat>
    <div id="barChart" style="width: 100%; height: 55vh"></div>
  </v-card>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "BarChart",
  props: { barData: Object },
  data: function () {
    return {};
  },
  watch: {
    barData() {
      var chartDom = document.getElementById("barChart");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    },
  },
  mounted() {
    if (Object.keys(this.barData).length !== 0) {
      var chartDom = document.getElementById("barChart");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    }
  },
  computed: {
    candidates() {
      return this.$store.getters["Home/candidateStats"];
    },
    data() {
      const data = this.barData.data;
      const backgroundColor = this.barData.backgroundColor;
      return data.map((el, i) => {
        const obj = { itemStyle: {} };
        obj.value = el;
        obj.itemStyle.color = backgroundColor[i];
        return obj;
      });
    },
    option() {
      return {
        title: {
          text: this.barData.title,
          left: "center",
          top: 10,
          textStyle: {
            color: "#000",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#283b56",
            },
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 30, // If the label names are too long you can manage this by rotating the label.
            },
            data: this.barData.labels,
          },
        ],
        grid: { containLabel: true },
        yAxis: [
          {
            type: "value",
            name: "Item Count",
          },
          {
            type: "value",
            name: "Amount",
          },
        ],
        series: [
          {
            data: this.data,
            type: "bar",
            name: "Item Count",
            yAxisIndex: 0,
          },
          {
            name: "Amount",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            data: this.barData.amount,
          },
        ],
      };
    },
  },
};
</script>
