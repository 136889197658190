import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import ProfileCard from "./components/ProfileCard";
import Auth from "@/router/Middleware/Auth";

export default [
  {
    path: "/profile",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/profile/card",
      },
      {
        path: "card",
        name: "ProfileCard",
        components: { view: ProfileCard },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
