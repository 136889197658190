<template>
  <v-app id="inspire" style="background-color: #f0f0f0">
    <AppBarTopVue />
    <DrawerBarVue />
    <div>
      <router-view name="view" />
      <router-view name="action" />
    </div>
    <spinner />
    <confirmation />
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/shared/Footer.vue";
import Spinner from "@/plugins/loader/views/Spinner.vue";
import { AuthService } from "@/packages/auth";
/** components */
import AppBarTopVue from "@/components/shared/AppBarTop.vue";
import DrawerBarVue from "@/components/shared/DrawerBar.vue";
export default {
  name: "DashboardLayout",
  components: {
    Spinner,
    Footer,
    AppBarTopVue,
    DrawerBarVue,
  },
  data: () => ({
    drawer: null,
    leftDrawer: false,
    mini: false,
    selectedItem: 0,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Landing/getCompanyInfo");
      v.$store.dispatch("Dashboard/getUser");
      // v.$store.dispatch("Programme/fetchObjectives");
      v.$store.dispatch("Programme/fetchActivities", { type: "Standard" });
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    links() {
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled && item.title !== "Administration")
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },
    adminLinks() {
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled && item.title === "Administration")
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },
    actions() {
      return this.$store.getters["Dashboard/actions"];
    },
    menus() {
      return this.$store.getters["Dashboard/menus"];
    },
  },
  methods: {
    logout() {
      AuthService.logout();
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
