<template>
  <v-card flat>
    <div
      id="PieChart"
      style="width: 100%; height: 55vh"
      data-echart-responsive="true"
    ></div>
  </v-card>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "PieChart",
  props: { pieData: Object },
  data: function () {
    return {};
  },
  watch: {
    pieData() {
      var chartDom = document.getElementById("PieChart");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
      myChart.resize();
    },
  },
  mounted() {
    if (Object.keys(this.pieData).length !== 0) {
      var chartDom = document.getElementById("PieChart");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
      myChart.resize();
    }
  },
  computed: {
    candidates() {
      return this.$store.getters["Home/candidateStats"];
    },
    data() {
      const data = this.pieData.data;
      const backgroundColor = this.pieData.backgroundColor;
      return data
        .map((el, i) => {
          const obj = { itemStyle: {} };
          obj.value = el;
          obj.name = this.pieData.labels[i];
          obj.itemStyle.color = backgroundColor[i];
          return obj;
        })
        .sort(function (a, b) {
          return a.value - b.value;
        });
    },
    option() {
      return {
        title: {
          text: this.pieData.title,
          left: "center",
          top: 10,
          textStyle: {
            color: "#000",
          },
        },
        labelLine: {
          show: false,
          lineStyle: {
            length: 1,
            length2: 5,
            smooth: 0.7,
          },
        },
        grid: { containLabel: true },
        tooltip: {
          trigger: "item",
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: this.data,
          },
        ],
      };
    },
  },
};
</script>
