import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import ProgrammeList from "./components/ProgrammeList";
import TabView from "./components/TabView";
import ActivityView from "./components/ActivityView";
import Auth from "@/router/Middleware/Auth";

export default [
  {
    path: "/programme",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/programme/list",
      },
      {
        path: "activities",
        name: "ProgrammeActivitiesList",
        components: { view: ProgrammeList },
        meta: { middleware: [Auth] },
      },
      {
        path: "objectives",
        name: "ProgrammeObjectivesList",
        components: { view: ProgrammeList },
        meta: { middleware: [Auth] },
      },
      {
        path: "objective/:documentNo",
        name: "ObjectiveList",
        components: { view: TabView },
        meta: { middleware: [Auth] },
      },
      {
        path: "card/:documentType/:documentNo/:no",
        name: "ActivityView",
        components: { view: TabView, action: ActivityView },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
