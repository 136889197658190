<template>
  <v-container fluid>
    <v-card class="mx-auto" height="100" color="primary" flat />

    <v-sheet
      class="mt-n9 pl-7 mx-auto d-flex"
      elevation="0"
      height="140"
      style="background-color: white"
    >
      <v-avatar size="120" color="white">
        <v-avatar size="112">
          <v-img
            :src="profilePic"
            :lazy-src="require('@/assets/blank-profile.png')"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="primary lighten-5" />
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </v-avatar>

      <v-card class="mt-9 d-flex my-auto" width="100%" flat>
        <div>
          <v-card-title
            >@{{
              profile ? `${profile.name + " " + profile.name2}` : ""
            }}</v-card-title
          >
          <v-card-subtitle>
            Update your photo and personal details
          </v-card-subtitle>
        </div>
      </v-card>
    </v-sheet>

    <v-divider></v-divider>

    <v-card flat class="px-10" style="background-color: white">
      <v-tabs v-model="tab">
        <v-tab>Profile</v-tab>
        <v-tab>Password</v-tab>

        <v-tabs-items v-model="tab">
          <v-form v-model="isValid" ref="profileUpdate">
            <!-- User details -->
            <v-tab-item class="mt-5">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="formData.name"
                    filled
                    single-line
                    dense
                    label="First Name"
                    :rules="rules.required"
                    @keyup.enter="reset()"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="formData.name2"
                    filled
                    single-line
                    dense
                    label="Last Name"
                    :rules="rules.required"
                    @keyup.enter="reset()"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="formData.searchName"
                    filled
                    single-line
                    dense
                    label="Username"
                    readonly
                    @keyup.enter="reset()"
                  />
                </v-col>
                <v-col cols="12" md="4" class="mt-n6">
                  <v-text-field
                    v-model="formData.phoneNo"
                    filled
                    single-line
                    dense
                    label="Phone Number"
                    append-icon="mdi-phone-classic"
                    :rules="rules.phoneNo"
                    @keyup.enter="reset()"
                  />
                </v-col>
                <v-col cols="12" md="4" class="mt-n6">
                  <v-text-field
                    v-model="formData.eMail"
                    filled
                    single-line
                    dense
                    readonly
                    label="eMail"
                    append-icon="mdi-at"
                    :rules="rules.eMail"
                    @keyup.enter="reset()"
                  />
                </v-col>
                <v-col cols="12" md="4" class="mt-n6">
                  <v-text-field
                    v-model="formData.address"
                    filled
                    single-line
                    dense
                    label="Physical Address"
                    append-icon="mdi-map-marker"
                    :rules="rules.address"
                    @keyup.enter="reset()"
                  />
                </v-col>
                <v-col cols="12" class="mt-n6">
                  <v-file-input
                    @change="uploadProfilePicture($event)"
                    filled
                    single-line
                    dense
                    :rules="rules.attachment"
                    append-icon="mdi-paperclip"
                    accept=".jpeg,.png,.jpg,GIF"
                    placeholder="Upload Profile"
                    prepend-icon=""
                    @keyup.enter="reset()"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Password -->
            <v-tab-item class="mt-5">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    ref="password"
                    v-model="passwordForm.current_password"
                    filled
                    dense
                    @copy.prevent
                    label="Old Password"
                    placeholder="Old password"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    :rules="rules.password"
                    autocomplete="false"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="showCurrentPassword = !showCurrentPassword"
                        icon
                      >
                        <v-icon>{{
                          showCurrentPassword ? "visibility" : "visibility_off"
                        }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n6">
                  <v-text-field
                    ref="password"
                    v-model="passwordForm.new_password"
                    filled
                    dense
                    @copy.prevent
                    label="New Password"
                    placeholder="New password"
                    :type="showNewPassword ? 'text' : 'password'"
                    :rules="rules.password"
                    autocomplete="false"
                  >
                    <template v-slot:append>
                      <v-btn @click="showNewPassword = !showNewPassword" icon>
                        <v-icon>{{
                          showNewPassword ? "visibility" : "visibility_off"
                        }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n6">
                  <v-text-field
                    ref="password"
                    v-model="passwordForm.new_confirm_password"
                    filled
                    dense
                    @copy.prevent
                    label="Confirm New Password"
                    placeholder="New password"
                    :type="showNewPassword ? 'text' : 'password'"
                    :rules="rules.password"
                    autocomplete="false"
                  >
                    <template v-slot:append>
                      <v-btn @click="showNewPassword = !showNewPassword" icon>
                        <v-icon>{{
                          showNewPassword ? "visibility" : "visibility_off"
                        }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-form>
        </v-tabs-items>
      </v-tabs>
    </v-card>

    <v-divider></v-divider>

    <v-card-actions style="background-color: white">
      <v-spacer></v-spacer>
      <v-btn color="primary" depressed @click="saveProfile()">save</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { helper } from "@/packages/utils";
export default {
  name: "ProfileCard",
  data() {
    return {
      tab: 0,
      formData: {
        first_name: "",
        name: "",
        username: "",
        phoneNo: "",
        avatar: "",
        eMail: "",
        address: "",
      },
      passwordForm: {
        new_password: "",
        current_password: "",
        new_confirm_password: "",
      },
      showPassword: false,
      showConfirmPassword: false,
      showNewPassword: false,
      showCurrentPassword: false,
      isValid: false,
      url: process.env.VUE_APP_TEMP_URL,
    };
  },
  beforeRouteEnter(to, from, next) {
    next( () => {
      // v.$store.dispatch("Profile/getMyProfile");
    });
  },
  computed: {
    profile() {
      return this.$store.getters["Dashboard/AuthUser"];
    },
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
        currentPassword: [(v) => !!v || "Current Password is required"],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.new_password || "Password does not match",
        ],
        phoneNo: [
          (v) => !!v || "Field is required",
          (v) =>
            !v ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Invalid phone number format",
        ],
        attachment: [
          (v) =>
            this.formData.avatar && !!v
              ? v.size < 2000000 || "Attachment should be less than 2MB"
              : true,
        ],
      };
    },
    profilePic() {
      return this.profile.b64Image
        ? `${this.url}/storage/${this.profile.b64Image}`
        : require("@/assets/blank-profile.png");
    },
  },
  methods: {
    saveProfile() {
      if (!this.isValid) {
        this.$refs.profileUpdate.validate();
      } else {
        let data = {};
        if (this.tab === 0) {
          data = this.formData;
        } else {
          data = this.passwordForm;
        }
        this.$store.dispatch("Profile/updateMyProfile", {
          data: helper.prepareFormData(data),
          id: this.profile.id,
          passwordReset: this.tab === 1,
        });
      }
    },
    uploadProfilePicture(event) {
      this.formData.avatar = event;
    },
  },
  watch: {
    profile() {
      Object.keys(this.formData).map((item) => {
        if (this.profile[item]) {
          this.formData[item] = this.profile[item];
        }
      });
    },
  },
};
</script>
