<template>
  <v-row>
    <v-col cols="12" :md="headColumn">
      <v-data-table
        dense
        :headers="headers"
        :items="objectives"
        @click:row="selectRow"
        item-key="code"
        single-select
        calculate-widths
        fixed-header
        :loading="objectiveloading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.ID`]="{ index }">
          <span>
            {{ index + 1 }}
          </span>
        </template>
        <template v-slot:[`item.objective`]="{ item }">
          {{ truncateText(item.description) }}
        </template>
        <template v-slot:[`item.action`]="{}">
          <v-icon small color="primary">mdi-arrow-right</v-icon>
        </template>
      </v-data-table>
    </v-col>

    <v-col
      cols="12"
      :md="itemColumn"
      v-if="selectedObjective && selectedObjective.code"
    >
      <v-card outlined class="py-3 my-3">
        <v-card-title>
          Objective: &nbsp;
          {{ selectedObjective.code }}
          <v-spacer></v-spacer>
          <v-icon color="error" @click="closeRow">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text>
          <!-- description -->
          <div>
            <span class="text-button">Description</span> <br />
            <p>
              {{ selectedObjective.description }}
            </p>
          </div>
          <!-- activities -->
          <span class="text-button">Activities</span> <br />
          <Activities />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Activities from "./Activities.vue";
import programmeMixin from "../programmeMixin";
export default {
  name: "Objectives",
  data() {
    return {
      headColumn: 12,
      itemColumn: 0,
      selectedId: -1,
    };
  },
  components: { Activities },
  mixins: [programmeMixin],
  mounted() {
    this.closeRow();
    if (this.selectedObjective !== null && this.selectedObjective.code) {
      this.headColumn = 4;
      this.itemColumn = 8;
    } else {
      this.headColumn = 12;
      this.itemColumn = 0;
    }
    Event.$on("selectRow", () => {
      this.selectRow(this.selectedObjective);
      this.$store.dispatch("Programme/fetchAssignees", 'subAwardee');
      this.$store.dispatch("Programme/fetchAssignees", 'employees');
    });
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "ID",
        },
        {
          text: "Objective",
          align: "left",
          sortable: false,
          value: "objective",
        },
        {
          text: "Budgeted",
          value: "budgeted",
          align: "left",
          sortable: false,
        },
        {
          text: "Target",
          value: "target",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false,
        },
      ].filter((i) => {
        if (this.selectedObjective !== null && this.selectedObjective.code) {
          return ["ID", "objective", "budgeted", "accepted"].includes(i.value);
        } else {
          return i;
        }
      });
    },
    objectives() {
      return this.$store.getters["Programme/programmeGetters"]("objectives");
    },
  },
  methods: {
    closeDrawer(mini) {
      this.$store.dispatch("Dashboard/setMiniDrawer", mini);
    },
    selectRow: function (event, row) {
      row.select(true);
      this.selectedId = event.code;
      this.$store.dispatch("Programme/updateSelectedObjective", event);
      this.$router.push({
        path: this.$route.path, // Keep the current path
        query: {
          code: event.code, // Add objective code
        },
      });
      if (this.selectedObjective.activities) {
        this.$store.dispatch("Programme/fetchAssigneeActivities", {
          ...event,
          type: "Standard",
        });
      } else {
        this.$store.dispatch("Programme/fetchActivities", {
          ...event,
          type: "Standard",
        });
      }
      this.closeDrawer(true);
    },
    closeRow: function () {
      this.$store.dispatch("Programme/updateSelectedObjective", null);
      this.closeDrawer(false);
    },
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: var(--v-primary-base) !important;
  color: white !important;
}
</style>
