import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";

import Dashboard from "./packages/dashboard";
import ProgrammeMngmt from "./packages/programmeMngmt";
import Profile from "./packages/Profile"

/**
 * misconceptions
 */
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueEllipseProgress from "vue-ellipse-progress";

/**
 * App Global mixins
 */
import { listener, FileMixin, RouterMixin, DateMixin } from "@/packages/mixin";

import vuetify from "./plugins/vuetify";
import confirmation from "./plugins/confirmation";

Vue.config.productionTip = false;

window.Event = new Vue();

const options = {
  store,
  router,
};

Vue.use(VueEllipseProgress);
Vue.use(Toast);
Vue.use(VueI18n);

Vue.use(Dashboard, options);
Vue.use(ProgrammeMngmt, options);
Vue.use(Profile, options);
Vue.use(confirmation);

new Vue({
  router,
  store,
  vuetify,
  mixins: [listener, FileMixin, RouterMixin, DateMixin],
  render: (h) => h(App),
}).$mount("#app");
