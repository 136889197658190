import _ from "lodash";
import dashboardConstants from "./constants";
import call from "@/packages/service/http";
import { AuthService } from "../auth";
export default {
  namespaced: true,
  state: {
    loading: false,
    state: { loading: false },
    miniDrawer: false,
    dialogLoading: false,
    links: [],
    companyInfo: {},
    topBarLinks: [],
    toolBarTopLinks: [],
    actions: [],
    menus: [],
    companies: [],
    AuthUser: [],
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },

    SET_COMPANIES: (state, payload) => {
      state.companies = payload;
    },

    SET_SET_MENU: (state, payload) => {
      if (_.findIndex(state.menu, payload) === -1) {
        state.menus.push(payload);
      }
    },

    SET_LINKS: (state, payload) => {
      if (_.findIndex(state.links, payload) === -1) {
        if (payload.access && AuthService.user) {
          if (AuthService.user.user_type === "SUPER_ADMIN") {
            state.links.push(payload);
          }
        } else {
          state.links.push(payload);
        }
      }
    },

    SET_TOP_BAR_LINKS: (state, payload) => {
      if (_.findIndex(state.links, payload) === -1) {
        state.links.push(payload);
      }
    },

    SET_TOOL_BAR_TOP_LINKS: (state, payload) => {
      state.toolBarTopLinks = payload;
    },

    SET_ACTIONS: (state, payload) => {
      if (_.findIndex(state.actions, payload) === -1) {
        state.actions.push(payload);
      }
    },

    SET_MINI_DRAWER: (state, payload) => {
      state.miniDrawer = payload;
    },

    SET_DIALOG_LOADING: (state, payload) => {
      state.dialogLoading = payload;
    },
    
    SET_AUTH_USER: (state, payload) => {
      state.AuthUser = payload;
    },

    SET_COMPANY_INFO: (state, payload) => {
      state.companyInfo = payload;
    },
  },

  getters: {
    loading: (state) => state.loading,
    dialogLoading: (state) => state.dialogLoading,
    actions: (state) => state.actions,
    menus: (state) => state.menus,
    companies: (state) => state.companies,
    links: (state) => {
      return _.orderBy(state.links, (link) => link.order);
    },
    topBarLinks: (state) => {
      return _.orderBy(state.topBarLinks, (link) => link.order);
    },
    toolBarTopLinks: (state) => {
      return _.orderBy(state.toolBarTopLinks, (link) => link.order);
    },
    miniDrawer: (state) => state.miniDrawer,
    AuthUser: (state) => state.AuthUser,
    companyInfo: (state) => state.companyInfo,
  },

  actions: {
    resetPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", dashboardConstants.password, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Password Updated");
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCompanies: ({ commit }) => {
      call("get", dashboardConstants.companies)
        .then((res) => {
          commit("SET_COMPANIES", res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", "We could not get companies");
        });
    },

    setMiniDrawer: ({ commit }, data) => {
      commit("SET_MINI_DRAWER", data);
    },

    setToolBarLinks: ({ commit }, data) => {
      commit("SET_TOOL_BAR_TOP_LINKS", data);
    },

    setLinks: ({ commit }, data) => {
      commit("SET_LINKS", data);
    },
    updateProfile: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", dashboardConstants.updateProfile(data.id), data.sentData)
        .then(() => {
          Event.$emit("ApiSuccess", "Sucessfully  Updated Details");
          commit("Dashboard/SET_LOADING", false, { root: true });
          data.passwordReset ? AuthService.logout() : dispatch("getUser");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getUser({ commit }) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", dashboardConstants.getAuthUser)
        .then((res) => {
          commit("SET_AUTH_USER", res.data.data);
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getCompanyInfo({ commit }) {
      call("get", dashboardConstants.company)
        .then((res) => {
          commit("SET_COMPANY_INFO", res.data.data);
        })
        .catch((error) => {
          console.error(error);
          Event.$emit("ApiError", "Error getting company information");
        });
    },
  },
};
