import mime from "mime-types";

export default {
  getColor(status) {
    let colorObject = {};
    switch (status) {
      case "Open":
      case "Draft":
        colorObject = {
          icon_color: "amber lighten-1",
          icon: "error_outline",
          color: "warning",
        };
        break;
      case "Review":
        colorObject = {
          icon_color: "orange lighten-1",
          icon: "rate_review",
          color: "orange",
        };
        break;
      case "Pending":
      case "Pending Approval":
        colorObject = {
          icon_color: "yellow darken-1",
          icon: "rate_review",
          color: "yellow darken-2",
        };
        break;
      case "Released":
      case "Issued":
        colorObject = {
          icon_color: "green lighten-1",
          icon: "done",
          color: "success",
        };
        break;
      case "Posted":
        colorObject = {
          icon_color: "teal lighten-1",
          icon: "done",
          color: "teal accent-3",
        };
        break;
      case "Approved":
        colorObject = {
          icon_color: "green lighten-1",
          icon: "done",
          color: "success",
        };
        break;
      case "Rejected":
      case "Declined":
      case "Canceled":
        colorObject = {
          icon_color: "deep-orange lighten-1",
          icon: "close",
          color: "red",
        };
        break;
      case true:
        colorObject = {
          icon_color: "green lighten-1",
          icon: "close",
          color: "green",
        };
        break;
      case false:
        colorObject = {
          icon_color: "orange darken-4",
          icon: "close",
          color: "orange",
        };
        break;
      default:
        colorObject = {
          icon_color: "blue lighten-1",
          icon: "new_releases",
          color: "info",
        };
        break;
    }
    return colorObject;
  },

  getFileIcon(fileName) {
    if (!fileName) return "fa fa-file-o";
    const fileExtension = fileName.name.split(".").pop();
    switch (fileExtension) {
      case "docx":
        return "fa fa-file-word-o";
      case "pdf":
        return "fa fa-file-pdf-o";
      case "pptx":
        return "fa fa-file-powerpoint";
      case "xls":
        return "fa fa-file-excel";
      case "xlsx":
        return "fa fa-file-excel";
      case "png":
        return "fa fa-file-image-o";
      case "jpg":
        return "fa fa-file-image-o";
      default:
        return "fa fa-file";
    }
  },

  getFileIconColor(fileName) {
    if (!fileName) return "#727071";
    const fileExtension = fileName.name.split(".").pop();
    switch (fileExtension) {
      case "docx":
        return "#0276c0";
      case "pdf":
        return "#e62f34";
      case "pptx":
        return "#f39301";
      case "xls":
        return "#82bd8d";
      case "xlsx":
        return "#82bd8d";
      case "png":
        return "#a7ce59";
      case "jpg":
        return "#a7ce59";
      default:
        return "#727071";
    }
  },

  async getFileBlob(url, fileName) {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: mime.lookup(url),
    };
    return new File([data], fileName, metadata);
  },

  prepareFormData(formData) {
    const data = new FormData();
    for (const key in formData) {
      if (formData[key] === null) formData[key] = "";
      data.append(key, formData[key]);
    }
    return data;
  },

  stringToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  },
};
