import call from "@/packages/service/http";
import constants from "./constants";

export default {
  namespaced: true,
  state: {
    company: {},
  },
  mutations: {
    SET_COMPANY_LOGO: (state, payload) => {
      state.company = payload;
    },
  },
  getters: {
    company: (state) => state.company,
  },
  actions: {
    getCompanyInfo: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.company)
        .then((res) => {
          commit("SET_COMPANY_LOGO", res.data.data);
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          this?.$emit("ApiError", "Unable to fetch company information");
        });
    },
  },
};
