import store from "./store";
import links from "./links";
import routes from "./routes";

export default {
  install(Vue, options) {
    options.router.addRoute(...routes);
    options.store.registerModule("Programme", store);
    options.store.commit("Dashboard/SET_LINKS", links);
  },
};
