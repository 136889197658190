import call from "@/packages/service/http";
import { AuthConstants } from "./index";
import AuthService from "./AuthService";

export default {
  namespaced: true,
  state: {
    terms: {},
    requirements: [],
    verified: "pending",
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    MUTATE() {},
    SET_VERIFY: (state, payload) => {
      state.verified = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    error: (state) => state.error,
    verified: (state) => state.verified,
    alert: (state) => state.alert,
  },
  actions: {
    sendActivationLink: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", false, { root: true });
      call("post", AuthConstants.REQUEST_CREDENTIALS, data)
        .then((res) => {
          res.data === null
            ? Event.$emit(
                "ApiWarning",
                `${res.data.message}! Please try again later`
              )
            : Event.$emit(
                "ApiSuccess",
                "Your credentials will be sent to your email shortly!"
              );
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", `${error.response.data.message}`);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },

    login: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.data.is_verified === 0) {
            Event.$emit("ApiWarning", "Verify Your Account To Login");
          } else {
            if (res.data.status !== "Success") {
              Event.$emit("login-verify", res.data.data);
            } else {
              AuthService.login(res.data.data.token, res.data.data.user);
              Event.$emit("ApiSuccess", "Successfully Logged in");
            }
            commit("Dashboard/SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          Event.$emit("loggedIn", false);
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiError",
            "Sorry, we could not log you in. Please try again!"
          );
        });
    },

    setCompany: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.setCompany, data)
        .then((res) => {
          console.log(res);
          AuthService.setUser(res.data.data);
          Event.$emit("ApiSuccess", "Company Successfully Changed");
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    verify: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.VERIFY_ACCOUNT, data)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("SET_VERIFY", "success");
        })
        .catch((err) => {
          commit("SET_VERIFY", "failed");
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    forgotPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.passwordReset, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.data);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    resetPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.password, data)
        .then((res) => {
          console.log("store reset Password called");
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "success");
          AuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          console.log(err);
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err);
        });
    },
    updateProfile: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.updateProfile, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "success");
          AuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    changePassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.CHANGE_PASSWORD, data)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Success");
          setTimeout(() => {
            AuthService.logout();
          }, 3000);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
          setTimeout(() => {
            Event.$emit(
              "ApiInfo",
              "TIP: Is current password matches the previous password?"
            );
          }, 5000);
        });
    },

    user: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", AuthConstants.user).then((res) => {
        AuthService.setUser(res.data.data);
        commit("Dashboard/SET_LOADING", false, { root: true });
      });
    },

    logout: () => {
      //this should be a GET request!!
      call("post", AuthConstants.logout)
        .then(() => {
          AuthService.logout();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    skipChangeDetails: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.SKIP, payload)
        .then((res) => {
          if (res.data.data.user) {
            AuthService.login(res.data.data.token, res.data.data.user);
          }
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
