var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.itemHeaders,"items":_vm.activities,"calculate-widths":"","fixed-header":"","loading":_vm.activityloading,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.selectActivity},scopedSlots:_vm._u([{key:`item.ID`,fn:function({ index }){return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:`item.activity`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.truncateText(item.description))+" ")]}},{key:`item.description`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.truncateText(item.plannedActivity))+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-1",attrs:{"size":"14","color":item.accepted ? 'success' : item.rejected ? 'error' : 'warning'}},[_vm._v(" mdi-radiobox-marked ")]),_c('span',{class:`${
          item.accepted
            ? 'green--text'
            : item.rejected
            ? 'red--text'
            : 'amber--text'
        } text--darken-1`},[_vm._v(" "+_vm._s(item.accepted ? "Accepted" : item.rejected ? "Rejected" : "Awaiting")+" ")])]}},{key:`item.submittedDate`,fn:function({ item }){return [(item.submitted)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.submittedDate)))]):_vm._e()]}},{key:`item.submitted`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-1",attrs:{"size":"14","color":_vm.getStatusColor(item.submitted).color}},[_vm._v(" mdi-radiobox-marked ")]),_c('span',{class:`${
          _vm.getStatusColor(item.submitted).color
        }--text text--darken-1`},[_vm._v(" "+_vm._s(item.submitted ? "Submitted" : "Not Submitted")+" ")])]}},{key:`item.action`,fn:function({}){return [_c('v-chip',{attrs:{"label":"","small":"","text":"","color":"primary"}},[_vm._v("view")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }