<template>
  <v-app>
    <v-main class="d-flex main--landing">
      <router-view />
    </v-main>
    <spinner />
  </v-app>
</template>

<script>
import Spinner from "@/plugins/loader/views/Spinner.vue";
import { AuthService } from "../index";
export default {
  name: "AuthLayout",
  components: {
    Spinner,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$router.push({ name: "Main" });
      }
      v.$store.dispatch("Landing/getCompanyInfo");
    });
  },
  computed: {
    auth() {
      return AuthService;
    },
    company() {
      return this.$store.getters["Landing/company"];
    },
  },
  watch: {
    "$route.name": {
      handler: function () {
        this.$store.commit("Auth/SET_ALERT", null);
      },
    },
  },
};
</script>

<style scoped>
@import url("../style.css");
</style>
