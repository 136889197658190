<template>
  <v-app>
    <v-container>
      <v-card class="mb-2">
        <UserDetailsVue :user="AuthUserDetails" />
      </v-card>
      <v-card>
        <v-card class="pa-6">
          <StatisticsVue :activities="activities" />
        </v-card>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import UserDetailsVue from "./UserDetails.vue";
import StatisticsVue from "./Statistics.vue";

export default {
  name: "MainDashboard",
  components: {
    UserDetailsVue,
    StatisticsVue,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      // v.$store.dispatch("Programme/fetchObjectives");
      v.$store.dispatch("Programme/fetchActivities", { type: "Standard" });
    });
  },
  mounted() {
    console.log(this.activities);
  },
  computed: {
    AuthUserDetails() {
      return this.$store.getters["Dashboard/AuthUser"];
    },
    activities() {
      return this.$store.getters["Programme/programmeGetters"]("activities");
    },
  },
};
</script>
