import DashboardLayout from "./views/DashboardLayout";
import Auth from "@/router/Middleware/Auth";
import MainDashboard from "./components/MainDashboard";

export default [
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/dashboard/main",
      },
      {
        path: "main",
        name: "Main",
        components: { view: MainDashboard },
        meta: {
          middleware: Auth,
        },
      },
    ],
  },
];
