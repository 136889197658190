<template>
  <v-navigation-drawer
    v-model="openDrawer"
    app
    temporary
    floating
    right
    absolute
    width="40%"
    height="100%"
    dark
  >
    <v-list>
      <v-list-item>
        <v-list-item-title>Activity comments</v-list-item-title>
      </v-list-item>

      <v-timeline dense v-if="selectedActivity !== null">
        <v-slide-x-reverse-transition group hide-on-leave>
          <v-timeline-item
            v-for="item in selectedActivity.activityLineComments
              .slice()
              .reverse()"
            :key="item.lineNo"
            small
            dense
          >
            <v-card class="elevation-2" dark>
              <v-card-subtitle class="text-overline">
                {{ selectedActivity.ownerName }}
              </v-card-subtitle>
              <v-card-text>
                {{ item.comment }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import programmeMixin from "../programmeMixin";
export default {
  name: "ActivityCommentsDrawer",
  mixins: [programmeMixin],
  props: {
    drawer: Boolean,
  },
  computed: {
    openDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },
  },
};
</script>
