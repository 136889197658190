export default {
  login: "auth/login",
  passwordReset: "auth/reset/password",
  CHANGE_PASSWORD: "auth/change-password",
  password: "auth/setting/new/password",
  updateProfile: "auth/password",
  logout: "auth/logout",
  user: `auth/user`,
  setCompany: `auth/set-company`,
  REQUEST_CREDENTIALS: `auth/request-credentials/agent`,
  VERIFY_ACCOUNT: `auth/verify`,
  SKIP: "auth/skip-change-details",
};
