<template>
  <v-container
    fluid
    class="signup-request-white auth fill-height d-flex flex-row align-center"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5">
          <div class="d-flex flex-column justify-center">
            <Logo class="my-3" height="150" />
          </div>
          <v-card-text class="text-justify mt-4 pb-0">
            <v-form
              ref="requestAccessForm"
              @submit.prevent="requestCredentials"
            >
              <v-alert type="info" text>
                Enter Your email address to activate.
              </v-alert>
              <v-text-field
                ref="email"
                v-model="formData.email"
                dense
                outlined
                @copy.prevent
                @paste.prevent
                type="email"
                label="Email Address"
                prepend-inner-icon="email"
                placeholder="Enter your Email Address"
                :rules="rules.email"
              />
            </v-form>
          </v-card-text>

          <v-card-actions class="">
            <v-row>
              <v-col cols="12">
                <span class="me-2">Already have an account?</span>
                <router-link
                  :to="{ name: 'SignIn' }"
                  class="text-decoration-none"
                >
                  Sign In
                </router-link>
              </v-col>
              <v-col cols="12">
                <v-btn
                  v-if="!submitting"
                  color="primary"
                  block
                  @click="requestCredentials"
                >
                  Send Activation Link
                </v-btn>
                <v-btn v-if="submitting" color="primary" block>
                  <i class="sync loading-button-spinner"></i>
                  Processing...Please wait
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Logo from "@/components/shared/Logo.vue";
export default {
  name: "RequestCredentials",
  components: { Logo },
  data: function () {
    return {
      submitting: false,
      formData: {
        email: "",
      },
    };
  },
  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      };
    },
  },
  methods: {
    requestCredentials: function () {
      this.submitting = true;
      if (
        this.formData.email.length === 0 ||
        !/.+@.+/.test(this.formData.email)
      ) {
        this.$refs.requestAccessForm.validate();
        this.submitting = false;
      } else {
        this.$store.dispatch("Auth/sendActivationLink", {
          email: this.formData.email,
        });
        setTimeout(() => {
          this.submitting = false;
          this.$router.push({ name: "SignIn" });
        }, 10000);
      }
    },
  },
};
</script>

<style scoped>
.loading-button-spinner {
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
}
</style>
