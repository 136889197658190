<template>
  <div>
    <v-data-table
      :headers="itemHeaders"
      :items="activities"
      @click:row="selectActivity"
      calculate-widths
      fixed-header
      :loading="activityloading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.ID`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.activity`]="{ item }">
        {{ truncateText(item.description) }}
      </template>
      <template v-slot:[`item.description`]="{ item }">
        {{ truncateText(item.plannedActivity) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon
          size="14"
          class="mr-1"
          :color="
            item.accepted ? 'success' : item.rejected ? 'error' : 'warning'
          "
        >
          mdi-radiobox-marked
        </v-icon>
        <span
          :class="`${
            item.accepted
              ? 'green--text'
              : item.rejected
              ? 'red--text'
              : 'amber--text'
          } text--darken-1`"
        >
          {{
            item.accepted ? "Accepted" : item.rejected ? "Rejected" : "Awaiting"
          }}
        </span>
      </template>
      <template v-slot:[`item.submittedDate`]="{ item }">
        <span v-if="item.submitted"> {{ formatDate(item.submittedDate) }}</span>
      </template>
      <template v-slot:[`item.submitted`]="{ item }">
        <v-icon
          size="14"
          class="mr-1"
          :color="getStatusColor(item.submitted).color"
        >
          mdi-radiobox-marked
        </v-icon>
        <span
          :class="`${
            getStatusColor(item.submitted).color
          }--text text--darken-1`"
        >
          {{ item.submitted ? "Submitted" : "Not Submitted" }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{}">
        <v-chip label small text color="primary">view</v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import programmeMixin from "../programmeMixin";
import RouterMixin from "../../../router/RouterMixin";
import {DateMixin } from  "../../mixin"
export default {
  name: "Activities",
  mixins: [programmeMixin, RouterMixin, DateMixin],
  computed: {
    itemHeaders() {
      return [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "ID",
        },
        {
          text: "Activity",
          align: "left",
          sortable: false,
          value: "activity",
        },
        {
          text: "Planned Activity",
          value: "plannedActivity",
          align: "left",
          sortable: false,
        },
        {
          text: "Assigned To",
          value: "assignedName",
          align: "left",
          sortable: false,
        },
        {
          text: "Budgeted",
          value: "budgeted",
          align: "left",
          sortable: false,
        },
        {
          text: "Target",
          value: "target",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          align: "left",
          sortable: false,
        },
        {
          text: "Submitted",
          value: "submitted",
          align: "left",
          sortable: false,
        },
        {
          text: "Submitted Date",
          value: "submittedDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false,
        },
      ];
    },
    activities() {
      return this.$store.getters["Programme/programmeGetters"]("activities");
    },
  },
  methods: {
    selectActivity: function (event) {
      this.$router.push({
        name: "ActivityView",
        params: {
          no: event.code,
          beginObjectiveCodes: event.beginObjectiveCodes,
          documentNo: event.documentNo,
          documentType: event.documentType,
        },
        query: {
          ...this.$route.query, // Preserve the existing query parameters
        },
      });
      this.$store.dispatch("Programme/updateSelectedActivity", event);
      this.$store.dispatch("Programme/fetchAssignees", 'employees');
      this.$store.dispatch("Programme/fetchAssignees", 'subAwardee');
    },
  },
};
</script>
