<template>
  <v-row>
    <!-- uploader -->
    <v-col cols="12">
      <v-alert
        type="error"
        dense
        text
        icon="mdi-cloud-alert"
        border="left"
        v-if="attachmentError"
      >
        Each attachment should be less than 2MB
      </v-alert>
      <div
        class="border"
        @click="$refs.uploader.$refs.input.click()"
        v-if="!canAssign && !selectedActivity?.submitted"
      >
        <div class="mx-auto d-flex">
          <v-icon :size="40" class="mr-3"> mdi-cloud-upload-outline </v-icon>

          <div class="mt-10">
            <span>Click here to add files</span>
          </div>

          <v-file-input
            accept="application/pdf,.jpeg,.png,.jpg,GIF"
            ref="uploader"
            hide-input
            prepend-icon=""
            multiple
            @change="uploadFile"
          />
        </div>
      </div>
    </v-col>

    <v-col
      cols="12"
      md="3"
      sm="3"
      v-for="(attachment, i) in documents"
      :key="i"
    >
      <v-sheet class="d-flex" light outlined rounded height="50" width="200">
        <v-row no-gutters>
          <v-col cols="9">
            <v-list-item link @click="viewDoc(attachment)" class="pr-0">
              <v-list-item-icon class="mr-2">
                <v-icon :color="fileMeta(attachment).color">
                  {{ fileMeta(attachment).icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span
                    v-text="
                      attachment.name.length > 10
                        ? attachment.name.slice(0, 10)
                        : attachment.name
                    "
                  />
                  <!-- {{ attachment.name }} -->
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="3" class="d-flex justify-start align-center">
            <v-list-item>
              <v-list-item-icon>
                <v-icon @click="deleteAttachment(attachment)">
                  mdi-delete
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-dialog
      v-model="dialog"
      persistent
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            label
            small
            dense
            dark
            @click="download(document.download)"
            class="ml-3"
          >
            <v-icon>mdi-download-circle</v-icon>Download
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div v-if="show === true">
          <!-- pdf -->
          <v-card-text
            v-if="document.fileExtension === 'pdf'"
            class="overflow-y-auto overflow-x-hidden mt-3"
            style="max-height: 70vh"
          >
            <vue-pdf-embed
              style="width: 100%"
              :source="`data:application/pdf;base64,${document.attachment}`"
              :page="page"
              @rendered="handleDocumentRender"
              ref="pdfRef"
            />
          </v-card-text>

          <!-- image -->
          <v-card-text
            v-else
            class="overflow-y-auto overflow-x-hidden mt-3"
            style="max-height: 70vh"
          >
            <v-img
              class="pa-9"
              :src="`data:image/${document.fileExtension};base64,${document.attachment}`"
            />
          </v-card-text>
        </div>

        <!-- skeleton loader -->
        <v-card-text
          v-else
          class="overflow-y-auto overflow-x-hidden mt-3"
          style="max-height: 70vh"
        >
          <v-skeleton-loader type="image" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            depressed
            color="error"
            plain
            @click="(dialog = false), (show = false)"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import helper from "@/packages/utils/helpers/helper.js";
import programmeMixin from "../programmeMixin";
import vuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "AttachmentView",
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [programmeMixin],
  components: { vuePdfEmbed },
  data() {
    return {
      dialog: false,
      document: null,
      show: false,
      attachmentError: false,
      page: 1,
    };
  },
  mounted() {
    Event.$on("remove-attachment", (payload) => {
      this.removeAttachment(payload);
    });
  },
  computed: {
    documents: {
      get() {
        return this.attachments;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    uploadFile: async function (value) {
      if (value) {
        if (value.length !== 0) {
          value.forEach((item) => {
            if (item.size > 2000000) {
              this.attachmentError = true;
            } else {
              this.attachmentError = false;
              this.documents.push(item);
              this.$forceUpdate();
            }
          });
        }
      }
    },
    fileMeta: function (file) {
      return {
        icon: helper.getFileIcon(file),
        color: helper.getFileIconColor(file),
      };
    },
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    viewDoc(attachment) {
      const link = attachment.name;
      const fileExtension = link.split(".").pop();
      if (this.ifPreview(fileExtension)) {
        attachment.pages =
          fileExtension === "pdf" ? this.pageCount(attachment.download) : 0;
        this.dialog = true;
      } else {
        this.download(attachment);
      }
      this.document = attachment;
      this.document.fileExtension = fileExtension;
    },
    ifPreview(ext) {
      const array = ["pdf", "jpg", "jpeg", "png"];
      return array.includes(ext);
    },
    pageCount: function (value) {
      if (!value) return 0;
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
    download(item) {
      var a = document.createElement("a");
      a.href = `data:${helper.detectMimeType(item.name)};base64,${
        item.attachment
      }`;
      a.download = `${this.document.name}`;
      a.click();
    },
    deleteAttachment(item) {
      if (item.entryNo) {
        item.index = this.documents.indexOf(item);
        this.$store.dispatch("Programme/deleteAttachment", item);
      } else {
        this.removeAttachment(item);
      }
    },
    removeAttachment(item) {
      this.documents.splice(this.documents.indexOf(item), 1);
      this.$forceUpdate();
    },
  },
  watch: {
    document: {
      handler: function (n) {
        if (n.length !== 0) {
          setTimeout(() => {
            this.show = true;
          }, 1000);
        }
      },
    },
  },
};
</script>

<style scoped>
.border {
  height: 110px;
  border: 1.9px dashed #838383;
  background: rgba(227, 242, 253, 0.45) none repeat scroll 0% 0%;
  border-radius: 4px;
  padding-top: 10px;
  display: flex !important;
  cursor: pointer;
  width: 100%;
}
.v-input__slot::before {
  border-style: none !important;
}
</style>
