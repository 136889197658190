<template>
  <v-card class="mx-auto mb-2" flat>
    <v-card-text class="mt-n5">
      <v-row>
        <v-col cols="12" md="4">
          <v-list-item class="mt-n1">
            <v-list-item-content>
              <v-list-item-title
                class="text-lg-h5 font-weight-bold text-capitalize"
              >
                {{ personalDetails.search_name ?? name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-lg-h5 black--text">
                <!-- {{ personalDetails.subawardee.type }} -->
                <v-chip
                  small
                  class="text-body-2 mx-2 green lighten-4 green--text text--darken-4"
                >
                  {{ personalDetails.type ? personalDetails.type : "" }}
                  <v-icon
                    color="blue"
                    v-if="
                      personalDetails.subawardee &&
                      personalDetails.subawardee.activated
                    "
                    class="mx-1"
                    >verified</v-icon
                  ></v-chip
                >
              </v-list-item-subtitle>
              <v-chip small label class="my-1">
                <v-icon class="mr-2">email</v-icon>
                {{ email }}
              </v-chip>
              <v-chip small label class="my-1">
                <span class="mr-2">User Created On:</span>
                {{ createdOn }}
              </v-chip>
              <v-chip small label class="my-1">
                <span class="mr-2">Search Name:</span>
                {{
                  personalDetails.search_name ?? name
                }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="3">
          <v-list-item class="mt-3">
            <v-list-item-content>
              <v-chip small label class="mt-5">
                <v-icon class="mr-2">phone</v-icon>
                {{ personalDetails.phone_no ?? phoneNumber }}
              </v-chip>
              <v-chip small label class="mt-5">
                User Code:
                <v-icon class="mr-2"></v-icon>
                {{ userCode }}
              </v-chip>
              <v-chip small label class="my-2">
                <span class="mr-2">Telex Number:</span>
                {{ personalDetails.telexNo ? personalDetails.telexNo : "" }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4">
          <v-list-item class="mt-3">
            <v-list-item-content>
              <v-chip small label class="mt-5">
                Contact Person:
                <v-icon class="ml-4"></v-icon>
                {{ personalDetails.contact ? personalDetails.contact : "" }}
              </v-chip>
              <v-chip small label class="my-1">
                <span class="mr-2">Address:</span>
                {{ personalDetails.address ? personalDetails.address : "" }}
              </v-chip>
              <v-chip small label class="my-1">
                <span class="mr-2">Address 2:</span>
                {{ personalDetails.address2 ? personalDetails.address2 : "" }}
              </v-chip>
              <v-chip small label class="my-1">
                <span class="mr-2">City:</span>
                {{ personalDetails.city ? personalDetails.city : "" }}
              </v-chip>
              <v-chip small label class="mt-1">
                County:
                <v-icon class="ml-4"></v-icon>
                {{ personalDetails.county ? personalDetails.county : "" }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "PersonalDetails",
  props: {
    personalDetails: {
      required: true,
    },
  },
  computed: {
    name() {
      const first_name = this.personalDetails.name
        ? this.personalDetails.name
        : "";
      const last_name = this.personalDetails.last_name
        ? this.personalDetails.last_name
        : "";
      return `${first_name} ${last_name}`;
    },
    email() {
      return this.personalDetails.email;
    },
    createdOn() {
      return this.$root.formatDate(this.personalDetails.created_at);
    },
    phoneNumber() {
      return this.personalDetails.phoneNo;
    },
    userCode() {
      return this.personalDetails.no;
    },
  },
};
</script>
