<template>
  <v-card class="d-flex pa-6">
    <UserAvatureVue :avatar="userAvater" />
    <Details :personalDetails="personalDetails" />
  </v-card>
</template>
<script>
import UserAvatureVue from "./UserAvature.vue";
import Details from "./Details.vue";
import { tempUrl } from "@/environment";
export default {
  name: "UserAvature",
  props: {
    user: {
      required: true,
    },
  },
  components: {
    UserAvatureVue,
    Details,
  },
  computed: {
    userAvater() {
      return `${tempUrl}${this.user.avatar}`;
    },
    personalDetails() {
      const { avatar, ...user } = this.user;
      console.log(avatar);
      return user;
    },
  },
};
</script>
