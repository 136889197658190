export default {
  contract: `contract/objectives`,
  activities: (payload) =>
    `contract/objectives/${payload.type}${
      payload.code ? "/" + payload.code : ""
    }?documentNo=${payload.documentNo}&documentType=${payload.documentType}`,
  assigneeActivities: (payload) =>
    `contract/assignee/objectives/${payload.code}?documentNo=${payload.documentNo}&documentType=${payload.documentType}`,
  document: `contract/document`,
  employeeAssignees: "contract/assignables/employee",
  subawardeeAssignees: "contract/assignables/subAwardee",
  editActivity: "contract/reactions/activity",
  DELETE_ATTACHMENT: "contract/delete/attachment",
  // objective: "contract/document/header",
};
