/* eslint-disable linebreak-style */
export default {
  to: "/programme",
  order: 2,
  icon: "ballot",
  title: "Programme",
  disabled: false,
  sublinks: [
    {
      to: "/programme/objectives",
      icon: "mdi-trophy",
      title: "Owned Objectives",
      disabled: false,
      value: 0,
    },
    {
      to: "/programme/activities",
      icon: "mdi-calendar-multiselect",
      title: "Assigned Activities",
      disabled: false,
      value: 1,
    },
  ],
};
