<template>
  <v-app-bar app elevation="0" color="topbar" height="50%">
    <v-btn icon color="topbarText" @click="closeDrawer">
      <v-icon>apps</v-icon>
    </v-btn>
    <div
      class="topbarText--text text--darken-1 text-body-1 text-no-wrap font-weight-medium"
    >
      {{ company.displayName }} <span class="mx-2">|</span> Project Management
    </div>
    <v-spacer />
    <div>
      {{ AuthUserDetails.subawardee ? AuthUserDetails.subawardee.name : "" }}
      <span class="mx-2">|</span>{{ AuthUserDetails.user_code }}
    </div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img
              :src="
                profile.avatar ? profilePicture : require('@/assets/avatar.png')
              "
            ></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar size="40px">
              <v-img
                :src="
                  profile.avatar
                    ? profilePicture
                    : require('@/assets/avatar.png')
                "
              ></v-img>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align: middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              <span>{{ name }}</span>
            </span>
            <small class="text--disabled text-capitalize mt-1">{{
              profile ? profile.user_type : ""
            }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item link @click="editProfile()">
          <v-list-item-icon class="me-2">
            <v-icon size="22"> account_circle </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon size="22">logout</v-icon>Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { AuthService } from "@/packages/auth";
export default {
  name: "AppBarTop",
  data: function () {
    return {
      tabs: null,
      url: process.env.VUE_APP_TEMP_URL,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getUser");
    });
  },
  computed: {
    AuthUserDetails() {
      return this.$store.getters["Dashboard/AuthUser"];
    },
    profile() {
      return AuthService.user;
    },
    name() {
      const first_name = this.profile.name ? this.profile.name : "";
      const last_name = this.profile.last_name ? this.profile.last_name : "";
      return `${first_name} ${last_name}`;
    },
    profilePicture() {
      return this.AuthUserDetails.avatar;
    },
    company: function () {
      return this.$store.getters["Dashboard/companyInfo"];
    },
    mini() {
      return this.$store.getters["Dashboard/miniDrawer"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/logout");
    },
    editProfile() {
      this.$router.replace({
        path: "/profile",
      });
    },
    closeDrawer() {
      this.$store.dispatch("Dashboard/setMiniDrawer", !this.mini);
    },
  },
};
</script>
<style>
.v-app-bar-title__content {
  width: 300px !important;
}
</style>
