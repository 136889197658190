<template>
  <v-container fluid>
    <!-- <v-card class="mb-6" flat>
      <v-toolbar>
        <v-toolbar-title>Title</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card> -->

    <v-card flat>
      <v-card-title>Contracts</v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="contract"
        :loading="contractloading"
        @click:row="selectRow"
        elevation="0"
      >
        <template v-slot:[`item.ID`]="{ index }">
          <span :class="{ 'selected-row': index === selectedRowIndex }">
            {{ index + 1 }}
          </span>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ truncateText(item.description) }}
        </template>
        <template v-slot:[`item.validFrom`]="{ item }">
          {{ formatDate(item.validFrom, 6) }}
        </template>
        <template v-slot:[`item.validTo`]="{ item }">
          {{ formatDate(item.validTo, 6) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon
            size="14"
            class="mr-1"
            :color="getStatusColor(item.status).color"
          >
            mdi-radiobox-marked
          </v-icon>
          <span
            :class="`${getStatusColor(item.status).color}--text text--darken-1`"
          >
            {{ item.status }}
          </span>
        </template>
        <template v-slot:[`item.action`]="{}">
          <v-chip label small text color="primary">view</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import programmeMixin from "../programmeMixin";
export default {
  name: "ProgrammeList",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.inAssignedActivities) {
        vm.$store.dispatch("Programme/fetchContractActivities", {
          type: "Standard",
        });
      } else {
        vm.$store.dispatch("Programme/fetchContract");
      }
    });
  },
  mixins: [programmeMixin],
  data() {
    return {
      selectedRowIndex: -1,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "ID",
        },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: "Valid From",
          align: "left",
          sortable: false,
          value: "validFrom",
        },
        {
          text: "Valid To",
          align: "left",
          sortable: false,
          value: "validTo",
        },
        {
          text: "Stage",
          value: "stage",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    selectRow: function (event) {
      this.$router.push({
        name: "ObjectiveList",
        params: {
          documentNo: event.no,
        },
      });
    },
  },
};
</script>
<style>
.selected-row {
  background-color: #add8e6;
}
</style>
