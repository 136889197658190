/* eslint-disable linebreak-style */
import { AuthRoutes, AuthStore } from "../auth";
import { landingStore } from "../landing";
import dashboardRoutes from "./routes";
import dashboardStore from "./store";
import dashboardLinks from "./links";

export { dashboardRoutes, dashboardStore, dashboardLinks };
export default {
  install(Vue, options) {
    // eslint-disable-next-line no-global-assign
    Event = new Vue();

    if (options.router) {
      options.router.addRoute(...dashboardRoutes);
      options.router.addRoute(...AuthRoutes);
    }

    if (options.store) {
      options.store.registerModule("Dashboard", dashboardStore);
      options.store.registerModule("Auth", AuthStore);
      options.store.registerModule("Landing", landingStore);
      options.store.dispatch("Dashboard/setLinks", dashboardLinks);
    }
  },
};
