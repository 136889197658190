<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="70%"
    height="70vh"
    style="overflow-x: hidden"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="px-5" :disabled="editActivityloading">
      <v-card-title>
        Activity {{ selectedActivity?.code }}
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="mr-2"
          depressed
          small
          v-if="canEdit && !canReact"
          @click="canAssign ? openDialog() : saveNarrative()"
        >
          {{
            canAssign
              ? selectedActivity.assignedCode !== ""
                ? "Re-assign"
                : "Assign"
              : "save"
          }}
        </v-btn>
        <v-btn
          color="warning"
          class="mr-2"
          depressed
          small
          v-if="!canAssign && canEdit"
          @click="sendForApproval"
        >
          send for approval
        </v-btn>
        <v-btn
          color="success"
          class="mr-2"
          depressed
          small
          v-if="canAssign && canReact"
          @click="passActivity()"
        >
          Accept
        </v-btn>
        <v-btn
          color="error"
          class="mr-2"
          depressed
          small
          v-if="canAssign && canReact"
          @click="FailActivity()"
        >
          reject
        </v-btn>
        <v-chip
          :color="getStatusColor(selectedActivity?.submitted).color"
          dark
          class="mr-2"
        >
          {{ selectedActivity.submitted ? "Submitted" : "Not Submitted" }}
        </v-chip>
        <div
          class="mr-3"
          v-if="selectedActivity.activityLineComments.length > 0"
        >
          <v-badge
            bordered
            color="success"
            icon="mdi-lock"
            :content="selectedActivity.activityLineComments.length"
            overlap
            ><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="30"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="drawer = !drawer"
                  >mdi-comment-text-multiple</v-icon
                >
              </template>
              <span>view comments</span>
            </v-tooltip>
          </v-badge>
        </div>

        <v-icon color="error" @click="closeDialog()">mdi-close</v-icon>
      </v-card-title>

      <v-progress-linear
        :active="editActivityloading"
        :indeterminate="editActivityloading"
        absolute
        top
        height="6"
        color="primary"
      ></v-progress-linear>

      <v-divider></v-divider>

      <v-card-text
        style="max-height: 70vh"
        class="overflow-y-auto overflow-x-hidden pb-13"
      >
        <!-- contract description -->
        <v-row v-if="selectedContract.no">
          <v-col cols="12" md="12">
            <v-row no-gutters>
              <v-col cols="11">
                <span class="text-button">Contract Description &nbsp;</span>
                {{ selectedContract.no }}
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="showContract = !showContract"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ showContract ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </template>
                  <span>View more</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <!-- description -->
          <v-col cols="12" class="mt-n7">
            <p>
              {{ selectedContract.description }}
            </p>
          </v-col>
          <!-- contract number -->
          <v-col
            cols="12"
            md="4"
            v-show="showContract"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Document</span>
              <v-text-field
                filled
                single-line
                dense
                :value="
                  selectedContract.documentType + ': ' + selectedContract.no
                "
                disabled
              />
            </div>
          </v-col>
          <!-- owner -->
          <v-col
            cols="12"
            md="4"
            v-show="showContract"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Programme Officer</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedContract.programmeOfficerName"
                disabled
              />
            </div>
          </v-col>
          <!-- validfrom -->
          <v-col
            cols="12"
            md="4"
            v-show="showContract"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Valid From</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedContract.validFrom"
                disabled
              />
            </div>
          </v-col>
          <!-- validTo -->
          <v-col
            cols="12"
            md="4"
            v-show="showContract"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Valid To</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedContract.validTo"
                disabled
              />
            </div>
          </v-col>
        </v-row>

        <v-divider class="mx-5"></v-divider>

        <!-- objective description -->
        <v-row v-if="selectedObjective.code">
          <v-col cols="12" md="12">
            <v-row no-gutters>
              <v-col cols="11">
                <span class="text-button">objective description &nbsp;</span>
                {{ selectedObjective.code }}
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="showObjective = !showObjective"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        showObjective ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}
                    </v-icon>
                  </template>
                  <span>View more</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <!-- description -->
          <v-col cols="12" class="mt-n7">
            <p>
              {{ selectedObjective.description }}
            </p>
          </v-col>
          <!-- contract number -->
          <v-col
            cols="12"
            md="4"
            v-show="showObjective"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Document</span>
              <v-text-field
                filled
                single-line
                dense
                :value="
                  selectedObjective.documentType +
                  ': ' +
                  selectedObjective.documentNo
                "
                disabled
              />
            </div>
          </v-col>
          <!-- owner -->
          <v-col
            cols="12"
            md="4"
            v-show="showObjective"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Owner</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedObjective.ownerName"
                disabled
              />
            </div>
          </v-col>
          <!-- budget -->
          <v-col
            cols="12"
            md="4"
            v-show="showObjective"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Budgeted Amount</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedObjective.budgeted"
                disabled
              />
            </div>
          </v-col>
          <!-- target -->
          <v-col
            cols="12"
            md="4"
            v-show="showObjective"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Target</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedObjective.target"
                disabled
              />
            </div>
          </v-col>
        </v-row>

        <v-divider class="mx-5"></v-divider>

        <!-- activity description -->
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="11">
                <span class="text-button"> activity description</span> <br />
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="showActivity = !showActivity"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ showActivity ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </template>
                  <span>View more</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <!-- description -->
          <v-col cols="12" class="mt-n7">
            <p>
              {{ selectedActivity.description }}
            </p>
          </v-col>
          <!-- assigned -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Assigned To</span>
              <v-text-field
                filled
                single-line
                dense
                disabled
                :value="
                  selectedActivity.assigneeCode
                    ? ''
                    : `${selectedActivity.assignedName} - ${selectedActivity.assignedCode}`
                "
              />
            </div>
          </v-col>
          <!-- planned activity -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Planned Activity</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedActivity.plannedActivity"
                disabled
              />
            </div>
          </v-col>
          <!-- expenditure -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Expenditure</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedActivity.expenditure"
                disabled
              />
            </div>
          </v-col>
          <!-- Frequency and Schedule -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Frequency and Schedule</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedActivity.frequencyAndSchedule"
                disabled
              />
            </div>
          </v-col>
          <!-- BaseLine -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>BaseLine</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedActivity.baseLine"
                disabled
              />
            </div>
          </v-col>
          <!-- target -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Target</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedActivity.target"
                disabled
              />
            </div>
          </v-col>
          <!-- audience -->
          <v-col
            cols="12"
            md="3"
            v-show="showActivity"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n9' : ''"
          >
            <div class="d-flex flex-column">
              <span>Audience</span>
              <v-text-field
                filled
                single-line
                dense
                :value="selectedActivity.audience"
                disabled
              />
            </div>
          </v-col>
          <!-- narrative -->
          <v-col
            cols="12"
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n5' : ''"
            class="ml-4 mr-4"
          >
            <v-row class="d-flex flex-column">
              <span class="text-button">Narrative</span> <br />
              <Editor
                class="mt-1 mb-10 editor"
                v-model="selectedActivity.narrative"
                :editorToolbar="customToolbar"
                :editorOptions="editorSettings"
                :disabled="canAssign || selectedActivity.submitted"
              />
            </v-row>
          </v-col>
          <!-- attachments -->
          <v-col cols="12" class="mt-7">
            <span
              class="text-button"
              v-if="!selectedActivity.submitted && !canAssign"
            >
              attachments
            </span>
            <br />
            <AttachmentView
              :attachments="attachments"
              :selectedItem="selectedActivity"
              :canAssign="canAssign"
            />
          </v-col>
        </v-row>

        <!-- comments -->
        <ActivityCommentsDrawer :drawer="drawer" />
      </v-card-text>

      <!-- move from one selectedActivity to another -->

      <!-- <v-card-actions>
        <v-btn fab small dark>
          <v-icon>mdi-arrow-left-drop-circle-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn fab small dark>
          <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
        </v-btn>
      </v-card-actions> -->

      <!-- hidden editor input data editing  -->
      <div id="editorInput" style="display: none">
        <div style="display: none" class="ql-editor" id="quizOutput"></div>
      </div>
    </v-card>

    <!-- assignees list -->
    <ActivityAssigneeList :employeeDialog="employeeDialog" />
  </v-dialog>
</template>

<script>
import programmeMixin from "../programmeMixin";
import { VueEditor as Editor } from "vue2-editor";
import AttachmentView from "./AttachmentsView.vue";
import ActivityCommentsDrawer from "./ActivityCommentsDrawer";
import ActivityAssigneeList from "./ActivityAssigneeList";
import helper from "../../utils/helpers/helper";
export default {
  name: "ActivityView",
  mixins: [programmeMixin],
  data() {
    return {
      dialog: true,
      employeeDialog: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      editorSettings: {
        modules: {},
      },
      showContract: false,
      showObjective: false,
      showActivity: false,
      selectedItem: null,
      drawer: false,
    };
  },
  components: {
    Editor,
    AttachmentView,
    ActivityCommentsDrawer,
    ActivityAssigneeList,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      // Check if the user is entering this route and if it is in the activity tab which checks the code, possibly after a page refresh
      // Page refresh detected
      if (
        from.name === null &&
        to.name === "ActivityView" &&
        v.$route.params.code !== undefined
      ) {
        v.$store.dispatch("Programme/fetchDocument", v.$route.params);
      }
      Event.$on("close-dialog", () => {
        v.closeDialog();
      });
    });
  },
  computed: {
    attachments() {
      return this.selectedActivity.activitiesAttachments;
    },
  },
  methods: {
    openDialog() {
      this.employeeDialog = true;
    },
    saveNarrative() {
      const { narrative, code, documentNo, documentType, beginObjectiveCodes } =
        this.selectedActivity;

      const formData = helper.prepareFormData({
        narrative: this.convertTopPlainText(narrative), //fn->(narrative) to plain-text
        code,
        documentNo,
        documentType,
        beginObjectiveCodes,
        type: "assignee",
      });
      this.attachments.forEach((file) => {
        if (!file.entryNo) formData.append("attachments[]", file);
      });
      this.$store.dispatch("Programme/editActivity", formData);
    },
    sendForApproval() {
      const { code, documentNo, documentType } = this.selectedActivity;
      this.$store.dispatch("Programme/editActivity", {
        code,
        documentNo,
        documentType,
        submitted: true,
        type: "assignee",
      });
    },
    passActivity() {
      const { code, documentNo, documentType, beginObjectiveCodes } =
        this.selectedActivity;
      this.$store.dispatch("Programme/editActivity", {
        code,
        documentNo,
        documentType,
        accepted: true,
        beginObjectiveCodes,
        type: "assignor",
      });
    },
    FailActivity() {
      this.$confirm.show({
        title: `Activity ${this.selectedActivity.code} `,
        text: `Are you sure you want reject this submission?`,
        input: true,
        label: "Comments",
        onConfirm: (comments) => {
          const data = { ...this.selectedActivity };
          data.rejected = true;
          data.submitted = false;
          data.activityLineComments = comments;
          const {
            code,
            documentNo,
            documentType,
            rejected,
            submitted,
            activityLineComments,
            beginObjectiveCodes,
          } = data;
          this.$store.dispatch("Programme/editActivity", {
            code,
            documentNo,
            documentType,
            rejected,
            submitted,
            activityLineComments,
            beginObjectiveCodes,
            type: "assignor",
          });
        },
      });
    },
    closeDialog() {
      this.$router.back();
      this.$store.dispatch("Programme/updateSelectedActivity", null);
      if (this.$route.query.code === undefined)
        this.$store.dispatch("Programme/updateSelectedObjective", null);
    },

    convertTopPlainText(htmlFormatedTxt) {
      return String(htmlFormatedTxt).replace(/<[^>]+>/g, "");
    },
  },
};
</script>

<style>
.v-input__slot::before {
  border-style: none !important;
}
.editor {
  width: 100vh;
  height: 250px;
}
.ql-editor {
  min-height: 0 !important;
}
.v-dialog {
  overflow-x: hidden;
}
</style>
