<template>
  <div>
    <slot name="stats">
      <v-layout row wrap>
        <v-flex
          v-for="(stat, i) in stats"
          :key="i"
          xs6
          sm3
          xl2
          class="lg5-custom mr-6 mb-6"
        >
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 10 : 1"
              :class="{ 'on-hover': hover }"
              style="background-color: #ffffff"
            >
              <v-list dense>
                <v-list-item dense>
                  <v-list-item-content>
                    <div class="text-lg-h4">{{ stat.value }}</div>
                    <v-list-item-subtitle>{{
                      stat.caption
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar :color="stat.avatarColor" size="50">
                      <v-icon :color="stat.iconColor">{{ stat.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </slot>
  </div>
</template>
<script>
export default {
  name: "StatisticsCardLiat",
  props: {
    stats: {
      required: true,
    },
  },
};
</script>
<style scoped>
@media (min-width: 960px) {
  .flex.md3.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>
